class AddFeeController {

    constructor($state, UsersModel, FlashService){
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;

        this.inactivate_student = true;
    }

    addFee() {
        this.submitted = true;
        let data = {
            'id': this.student.id,
            'amount': this.amount * 100,
            'inactivate_student': this.inactivate_student,
            'notes': this.notes
        };

        this.UsersModel.addStudentFee(data).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.submitted = false;
            response.fee.status = 'Unpaid';
            this.fees.push(response.fee)
            this.fee = {}
            if(this.inactivate_student === true) {
                this.$state.go('drivecoach.admin.students.view', {'userId': this.student.id}, {'reload': true});
            }
        }).catch(response => {
            this.submitted = false;
        });
    }

}
AddFeeController.$inject = ['$state', 'UsersModel', 'FlashService'];
export default AddFeeController;