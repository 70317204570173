import angular from 'angular'
import controller from './purchase-new-services.controller'
import template from './purchase-new-services.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.purchase-new-services', {
            'url' : '/purchase-new-services',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.orders.create', [] )
    .config(config)
    .controller( 'PurchaseNewServicesController', controller )
;