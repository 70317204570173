function AdminSchoolsVehiclesViewController($state, LocationsModel, $stateParams, VehicleAPI, FlashService, VehiclesModel, SchoolsModel) {

    const vm = this;
    vm.school = null;
    vm.vehicles = null;
    vm.makes = null;
    vm.models = null;
    vm.years = null;
    vm.locations = null;
    vm.vehicle = null;

    VehiclesModel.getVehicleByID($stateParams.vehicleId).then(function (response) {
        vm.vehicle = response.vehicle;
    });

}
AdminSchoolsVehiclesViewController.$inject = ['$state', 'LocationsModel', '$stateParams', 'VehicleAPI', 'FlashService', 'VehiclesModel', 'SchoolsModel'];
export default AdminSchoolsVehiclesViewController;
