import {toQueryString} from "../helpers/queryString";

function ProductsModel($http, APPURL) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/crm/products',
            UPDATE : APPURL + '/api/v1/crm/products',
            CREATE : APPURL + '/api/v1/crm/products'
        },
        events;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        events = extract( result );
        return events;
    }

    model.show = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.get = function (data = null) {
        let url = URLS.FETCH;
        if(data !== null){
            url = URLS.FETCH + '?' + toQueryString( data );
        }
        return $http.get(url).then( cacheResults );
    };

    model.update = function ( request ) {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.toggleData = (product) => {
        return $http.patch(URLS.FETCH + '/toggle/' + product.id, product).then(cacheResults)
    }

    model.create = function ( request ) {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.delete = function ( data ) {
        return $http.delete( URLS.FETCH + '/' + data.id ).then( cacheResults );
    };

}
ProductsModel.$inject = ['$http', 'APPURL'];
export default ProductsModel;