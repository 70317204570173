"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var time_1 = require("../../../../../common/helpers/time");
var StudentCertificateController = /** @class */ (function () {
    function StudentCertificateController(student, UsersModel, FlashService, $window, CertificateModel) {
        var _this = this;
        this.loading = false;
        this.student = student;
        this.UsersModel = UsersModel;
        this.CertificateModel = CertificateModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.certificate_number = "";
        this.certificate_issue_date = "";
        this.submit = this.submit.bind(this);
        this.updateCertificateIssueDate = this.updateCertificateIssueDate.bind(this);
        this.CertificateModel.get(this.student.id).then(function (response) {
            _this.certificate_number = response.certificate_number;
            _this.certificate_issue_date = (0, time_1.dateOrEmptyString)(response.certificate_issue_date);
        });
    }
    StudentCertificateController.prototype.updateCertificateIssueDate = function (newDate) {
        this.certificate_issue_date = newDate.selected[0];
    };
    StudentCertificateController.prototype.submit = function () {
        var _this = this;
        // @ts-ignore
        var certificate_issue_date = (0, time_1.toYMD)(this.certificate_issue_date);
        this.CertificateModel.update(this.student.id, this.certificate_number, certificate_issue_date).then(function (response) {
            _this.FlashService.setMessage({
                'message': response.message,
                type: 'success'
            });
        });
    };
    return StudentCertificateController;
}());
StudentCertificateController.$inject = [
    "student",
    "UsersModel",
    "FlashService",
    "$window",
    "CertificateModel"
];
exports.default = StudentCertificateController;
