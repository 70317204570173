class AdminInstructorsViewTimeOffRequestsController {

    constructor(UserService, $state, $stateParams, UsersModel, TimeoffRequestsModel, FlashService, $window){
        this.UserService = UserService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.TimeoffRequestsModel = TimeoffRequestsModel;
        this.FlashService = FlashService;
        this.$window = $window;

        this.requests = [];
        UsersModel.getInstructorByID(UserService.getCurrentUser().id).then(response => {
            this.instructor = response;
        });

        UsersModel.getInstructorTimeOffRequests({'id': UserService.getCurrentUser().id}).then(response => {
            this.requests = response.timeoffrequests.data;
            this.totalItems = response.timeoffrequests.total;
        });
    }

    pageChanged() {
        this.UsersModel.getInstructorTimeOffRequests({
            'id': this.UserService.getCurrentUser().id,
            'page': this.currentPage
        }).then(response => {
            this.requests = response.timeoffrequests.data;
            this.totalItems = response.timeoffrequests.total;
        });
    }

}
AdminInstructorsViewTimeOffRequestsController.$inject = ['UserService', '$state', '$stateParams', 'UsersModel', 'TimeoffRequestsModel', 'FlashService', '$window'];
export default AdminInstructorsViewTimeOffRequestsController;
