function AdminSchoolLocationCreateController($state, $stateParams, SchoolsModel, SchoolService, LocationsModel, FlashService) {

    const vm = this;
    vm.location = {
        'school_id': $stateParams.schoolId
    };

    vm.createLocation = createLocation;

    SchoolsModel.getSchoolByID($stateParams.schoolId).then(function (response) {
        vm.school = response;
    });

    function createLocation(isValid) {
        vm.submitted = true;

        if (isValid) {
            LocationsModel.createLocation(vm.location).then(function (response) {
                FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                $state.go('drivecoach.admin.schools.view', {'schoolId': $stateParams.schoolId}, {'reload': true});
                vm.location = {
                    'school_id': $stateParams.schoolId
                };
                vm.submitted = false;
            });
        } else {
            FlashService.setMessage({'type': 'error', 'message': 'Please fill the form.'});
        }
    }
}
AdminSchoolLocationCreateController.$inject = ['$state', '$stateParams', 'SchoolsModel', 'SchoolService', 'LocationsModel', 'FlashService'];

export default AdminSchoolLocationCreateController;