<template>
  <div :class="{ 'input-group': pickerWrap === true }" v-if="showElement">
    <flat-pickr 
      v-bind="$attrs"
      :value="datetime"
      :class="pickerClasses"
      :config="config[pickerType]"
      :placeholder="placeholder"
      ref="flatpickr"
      @on-close="handleClose"
      @on-change="handleChange"
      >
    </flat-pickr>
    
    <!--  shows calendar toggle button  -->
    <span class="input-group-btn" v-if="pickerWrap === true">
      <button type="button" class="btn btn-default" data-toggle>
        <i class="fa fa-fw fa-calendar"></i>
      </button>
    </span>
    
  </div>
</template>

<script>
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  
  export default {
    inheritAttrs: false,
    name: 'dcDatepicker', 
    components: { flatPickr },
    props: {
      'showElement': { type: Boolean, default: true },
      'pickerClasses': { type: [ Array, String ], default: 'form-control' },
      'pickerPlaceholder': { type: String },
      'pickerType': { type: String, default: 'date' }, //options are 'date', or 'time' (datetime can also be used but functionality needs to be added to handleClose/handleChange methods)
      'pickerDatetime': { type: [ Date, String ], default: null },
      'pickerMinDate': { type: [ Date, String ], default: null },
      'pickerMaxDate': { type: [ Date, String ], default: null },
      'pickerMinTime': { type: Date, default: null },
      'pickerMaxTime': { type: Date, default: null },
      'pickerWrap': { type: Boolean, default: false }, //currently only set up to work with "date" picker type but can be extended in the future if necessary
      'pickerIndex': { type: Number },
    },
    data() {
      return {
      }
    },
    methods: {
      // for some reason it works best to handle updating time pickers in the on-close hook and date pickers in the on-change hook. hence the below two methods
      handleClose: function(selected, currentDateStr, picker) {
        if(this.pickerType === 'time') {
          this.$emit('datetime-change', {
            selected: selected, //selected param is passed from flatpickr
            pickerType: this.pickerType,
            //bottom two are only used for form inputs that use index
            index: this.pickerIndex,
            name: this.$attrs.name
          });
          this.$emit('input', selected[0]); //this line updates v-model in parent
        }
      }, 
      handleChange: function(selected) {
        if (selected.length === 0) {
          this.$emit('datetime-change', {
            selected: [], //selected param is passed from flatpickr
            pickerType: this.pickerType,
            //bottom two are only used for form inputs that use index
            index: this.pickerIndex,
            name: this.$attrs.name
          });
          this.$emit('input', null);
          return false;
        }
        if(this.pickerType === 'date') {
          if(this.pickerDatetime) { //if picker datetime is not null, check to see if it needs to be updated
            let pickerDatetime = this.pickerDatetime.getTime(); //v-model/from angular controller, getTime compares unix milliseconds
            let selectedDatetime = selected[0].getTime(); //newly selected date from picker, getTime compares unix milliseconds
            if(pickerDatetime === selectedDatetime) {
              //do nothing if time is the same (stops infinite loop from onchange firing in response to prop changing)
              return false;
            } else {
              this.$emit('datetime-change', {
                selected: selected, //selected param is passed from flatpickr
                pickerType: this.pickerType,
                //bottom two are only used for form inputs that use index
                index: this.pickerIndex,
                name: this.$attrs.name
              });
              this.$emit('input', selected[0]); //this line updates v-model in parent
            }
          } else { 
            //this block fires if datetime is not already set
            this.$emit('datetime-change', {
              selected: selected, //selected param is passed from flatpickr
              pickerType: this.pickerType,
              //bottom two are only used for form inputs that use index
              index: this.pickerIndex,
              name: this.$attrs.name
            });
            this.$emit('input', selected[0]); //this line updates v-model in parent
          }

        }
      }
    },
    mounted () {
    },
    destroyed () {
      // console.log('destroyed')
    },
    created () {
    },
    computed: {
      //making these computed properties instead of part of the regular data object makes them reactive to changes in the angular state
      config: function() {
        return {
          date: {
            dateFormat: 'Y-m-d',
            altFormat: 'm/d/Y',
            altInput: true,
            //alt format & input used for display
            maxDate: this.pickerMaxDate,
            minDate: this.pickerMinDate,
            //wrap is used to wrap button and input in input group
            wrap: this.pickerWrap,
            allowInput: true
          }, 
          time: {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i:s',
            altFormat: 'h:i K',
            altInput: true,
            maxTime: this.pickerMaxTime,
            minTime: this.pickerMinTime,
            allowInput: false
          }, 
          datetime: {
            altFormat: 'm/d/Y h:i K',
            altInput: true,
            dateFormat: 'Y-m-d H:i:s',
            maxDate: this.pickerMaxDate,
            minDate: this.pickerMinDate,
            allowInput: false,
          },
        }
      },
      datetime: function() {
        return this.pickerDatetime
      },
      minDate: function() {
        return this.pickerMinDate
      },
      maxDate: function() {
        return this.pickerMaxDate
      },
      placeholder: function() {
        if(this.pickerPlaceholder) {
          return this.pickerPlaceholder;
        }
        
        let placeholder = '__/__/____';

        if(this.pickerType === 'time') {
          placeholder = '_ _ : _ _ AM'
        } else if (this.pickerType === 'datetime') {
          placeholder = '__/__/____ _ _ : _ _ AM'
        }
        return placeholder;
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>