class AdminSchoolsVehiclesIndexController {
    constructor(UserService, $state, $stateParams, VehiclesModel, UsersModel){
        this.UserService = UserService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.VehiclesModel = VehiclesModel;
        this.UsersModel = UsersModel;

        this.vehicles = [];
        this.school = UserService.getCurrentUser().school;

        // TODO: Get this list of available status' from the server
        this.statusOptions = [
            'All',
            'Available',
            'Disabled'
        ];
        this.searchStatus = 'All';

        this.processvehicles = this.processvehicles.bind(this);
        VehiclesModel.getVehicles().then(this.processvehicles);

    }

    processvehicles(response) {
        this.vehicles = response.vehicles.data;
        this.totalItems = response.vehicles.total;
    }

    search() {
        let searchData = {};
        if (this.searchStatus !== 'All') {
            searchData.status = this.searchStatus;
        }
        if (typeof this.searchName !== 'undefined') {
            searchData.name = this.searchName
        }
        this.VehiclesModel.getVehicles(searchData).then(this.processvehicles);
    }

    pageChanged() {
        let searchData = {
            'page': this.currentPage
        };
        if (this.searchStatus !== 'All') {
            searchData.status = this.searchStatus;
        }
        if (typeof this.searchName !== 'undefined') {
            searchData.name = this.searchName
        }
        this.VehiclesModel.getVehicles(searchData).then(this.processvehicles)
    }
}

AdminSchoolsVehiclesIndexController.$inject = ['UserService', '$state', '$stateParams', 'VehiclesModel', 'UsersModel'];
export default AdminSchoolsVehiclesIndexController;