import angular from 'angular'
import template from './admin-campus-vehicles.html'
import AdminSchoolLocationViewVehiclesController from './admin-campus-vehicles.controller'
import AdminCampusVehiclesIndex from './index/admin-campus-vehicles-index.module'
import AdminCampusVehiclesCreate from './create/admin-campus-create-vehicle.module'
import AdminCampusVehiclesView from './view/admin-campus-view-vehicle.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.schools.view.locations.view.vehicles', {
            'url': '/vehicles',
            'controller': AdminSchoolLocationViewVehiclesController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.schools.view.locations.view.vehicles', [
    AdminCampusVehiclesIndex.name,
    AdminCampusVehiclesCreate.name,
    AdminCampusVehiclesView.name
])
    .config(config)
    .controller('AdminSchoolLocationViewVehiclesController', AdminSchoolLocationViewVehiclesController)