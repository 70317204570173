import cloneDeep from 'lodash/cloneDeep'
import {States} from "../../../../../../../common/constants/states";

class AdminSchoolLocationZoneCreateController {

    constructor($stateParams, LocationsModel, ZonesModel, FlashService) {
        this.$stateParams = $stateParams;
        this.ZonesModel = ZonesModel;
        this.LocationsModel = LocationsModel;
        this.FlashService = FlashService;
        this.states = States;
        this.custom_center = false;
        this.submitting = false;
        this.zone = {
            'sectors' : 0,
            'location_id' : $stateParams.locationId
        };
        this.zone_center = {};

        this.zone.location_id = $stateParams.locationId;
    }

    setPickupLocation() {
        this.submitted = true;
        let schoolLocation = cloneDeep(this.location);
        let locationData = {
            'street': schoolLocation.street,
            'city': schoolLocation.city,
            'state': schoolLocation.state,
            'zip': schoolLocation.zip,
            'sectors': this.sectors,
            'radius': this.radius
        };
        this.SchoolsModel.previewServiceAreaForLocation(locationData).then(response => {
            this.submitted = false;
            response.data.location.address = angular.fromJson(response.data.location.address);
            response.data.location.zoneparts = response.data.zoneparts;
            this.school = {
                'locations': [response.data.location]
            };
        }).catch(response => {
            this.submitted = false;
        });
    }

     createZone() {
        this.submitting = true;
        // set zone center
         if (this.zone_center.street && this.zone_center.city && this.zone_center.state && this.zone_center.state) {
             this.zone.location = this.zone_center.street + ',' + this.zone_center.city + ',' + this.zone_center.state + ',' + this.zone_center.zip;
         }

        this.ZonesModel.createZone(this.zone).then(response => {
            this.submitting = false;
            this.FlashService.setMessage( { 'type' : 'success', 'message' : response.message } );
            this.zone = { 'sectors' : 0, 'location_id' : this.$stateParams.locationId };
            this.zone_center = {};
            this.custom_center = false;
        });
    }

}
AdminSchoolLocationZoneCreateController.$inject = [ '$stateParams', 'LocationsModel', 'ZonesModel', 'FlashService'];
export default AdminSchoolLocationZoneCreateController;