import moment from 'moment'
import {States} from "../../../common/constants/states";
import cloneDeep from "lodash/cloneDeep";

class InstructorEditController {
    
    constructor(UserService, $state, UsersModel, FlashService){
        this.UserService = UserService;
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.instructor = UserService.getCurrentUser();
        this.states = States;

        UsersModel.getInstructorByID( this.instructor.id ).then( response => {
            if(response.usermeta.license !== '' && response.usermeta.license.hasOwnProperty('expiration_date')) {
                response.usermeta.license.expiration_date = moment(response.usermeta.license.expiration_date, 'MMDDYYYY').format('MM/DD/YYYY');
            }
            this.instructor = response;
        } ); 
    }

    editInstructor(isValid) {
        this.submitted = true;

        if (isValid) {
            let payload = cloneDeep(this.instructor);
            payload.usermeta.license.expiration_date = moment(payload.usermeta.license.expiration_date, 'MMDDYYYY').format('YYYY-MM-DD');

            this.UsersModel.updateInstructor(payload).then( response => {
              this.FlashService.setMessage( {
                    'type' : 'success',
                    'message' : response.message
                } );
                this.submitted = false;
                this.$state.go( 'drivecoach.instructor.dashboard' );
            } ).catch(Error => this.submitted = false);
        } else {
            this.FlashService.setMessage( { 'type' : 'error', 'message' : 'Please fill the form.' } );
        }
    }

}
InstructorEditController.$inject = ['UserService', '$state', 'UsersModel', 'FlashService'];
export default InstructorEditController;