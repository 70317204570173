import angular from "angular";
import Locations from "./locations/student-locations.module";
import controller from "./student-dashboard.controller";
import template from "./student-dashboard.html";
import "ngVue";

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.student.dashboard", {
    url: "/dashboard",
    template: template,
    controller: controller,
    controllerAs: "vm",
    data: {
      authorizedRoles: [
        USER_ROLES.admin,
        USER_ROLES.parent,
        USER_ROLES.owner,
        USER_ROLES.student,
      ],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("student.dashboard", [Locations.name])
  .config(config)
  .controller("StudentDashboardController", controller);
