function StudentAccountController( UserService, UsersModel ) {

    const vm = this;
    vm.user = UserService.getCurrentUser();
    init();

    function init(){
        UsersModel.getStudentByID( vm.user.id ).then( response => {
            vm.user = response;
        } )
    }

}
StudentAccountController.$inject = ['UserService', 'UsersModel'];
export default StudentAccountController;
