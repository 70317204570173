"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StudentNotesController = /** @class */ (function () {
    function StudentNotesController($rootScope, $state, $sce, UsersModel, UserService) {
        var _this = this;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.successMessage = "";
        this.$sce = $sce;
        this.UserService = UserService;
        this.currentUser = this.UserService.getCurrentUser();
        this.UsersModel = UsersModel;
        this.studentNotes = {
            currentPage: 0,
            page_by: 5,
            data: [],
            total: 0,
        };
        UsersModel.getStudentNotes(this.currentUser.id).then(function (response) {
            _this.studentNotes.data = response.notes.data;
            _this.studentNotes.total = response.notes.total;
            _this.studentNotes.currentPage = response.notes.current_page;
        }).catch(function (Error) {
        });
    }
    StudentNotesController.prototype.formatNoteContent = function (note) {
        return this.$sce.trustAsHtml(note);
    };
    StudentNotesController.prototype.getRole = function (role) {
        if (role === 1) {
            return 'Students';
        }
        if (role === 3) {
            return 'Instructors';
        }
        if (role === 4) {
            return 'Owners';
        }
        if (role === 5) {
            return 'Administrators';
        }
        if (role === 9) {
            return 'Staff';
        }
        return 'Owner';
    };
    StudentNotesController.prototype.studentNotesPageChanged = function () {
        var _this = this;
        this.submitting = true;
        this.UsersModel.getStudentNotes(this.currentUser.id, { page: this.studentNotes.currentPage }).then(function (response) {
            _this.studentNotes.data = response.notes.data;
            _this.studentNotes.total = response.notes.total;
            _this.studentNotes.currentPage = response.notes.current_page;
            _this.submitting = false;
        }).catch(function (Error) {
            _this.submitting = false;
        });
    };
    return StudentNotesController;
}());
StudentNotesController.$inject = ["$rootScope", "$state", "$sce", "UsersModel", "UserService"];
exports.default = StudentNotesController;
