import filter from 'lodash/filter'

function AdminInstructorsIndexController( UsersModel , UserService, FlashService, $window ) {

    const vm = this;
    vm.busy = true;
    vm.currentPage = 1;
    vm.per_page = 20;
    vm.currentUser = UserService.getCurrentUser();
    // TODO: Get this list of available status' from the server
    vm.statusOptions = [
        'All',
        'Active',
        'Disabled'
    ];
    vm.searchStatus = 'All';

    vm.pageChanged = pageChanged;
    vm.search = search;
    vm.setSectorPopover = setSectorPopover;
    vm.sendWelcomeEmail = sendWelcomeEmail;

    init();

    function setSectorPopover(zone){
        vm.zone = zone;
    }

    function sendWelcomeEmail(id){
        const confirm = $window.confirm('Are you sure you want to send the welcome email? This will also reset this users password.');
        if (confirm === false) {
            return false;
        }
        vm.busy = true;
        UsersModel.sendInstructorWelcomeEmail(id).then(response => {
            FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            vm.busy = false;
        }).catch(Error => {
            vm.busy = false;
        })
    }

    function processInstructors(response) {
        response.instructors.data.map(instructor => {
            instructor.phone = filter(instructor.usermeta, {'meta_key' : 'phone'} )[0].meta_value;
            return instructor;
        });
        vm.instructors = response.instructors.data;
        vm.totalItems = response.instructors.total;
        vm.busy = false;
        vm.currentPage = 1;
    }

    function init(){
        UsersModel.getInstructors({'page' : vm.currentPage, 'page_by' : vm.per_page}).then(processInstructors).catch(Error => {
            vm.busy = false;
        });
    }

    function search(){
        vm.busy = true;
        const searchData = {
            page_by: vm.per_page
        };
        if( vm.searchStatus !== 'All' ){
            searchData.status = vm.searchStatus;
        }
        if( typeof vm.searchName !== 'undefined' ){
            searchData.name = vm.searchName
        }
        UsersModel.getInstructors(searchData).then( processInstructors ).catch(Error => {
            vm.busy = false;
        });
    }

    function pageChanged() {
        vm.busy = true;
        const searchData = {
            'page' : vm.currentPage,
	        'page_by' : vm.per_page
        };

        if( vm.searchStatus !== 'All' ){
            searchData.status = vm.searchStatus;
        }
        if( typeof vm.searchName !== 'undefined' ){
            searchData.name = vm.searchName
        }
        UsersModel.getInstructors( searchData ).then(response => {
            response.instructors.data.filter(function(instructor){
                instructor.phone = filter(instructor.usermeta, {'meta_key' : 'phone'} )[0].meta_value;
                return instructor;
            });
            vm.instructors = response.instructors.data;
            vm.busy = false;
        } ).catch(Error => {
            vm.busy = false;
        })
    }

}

AdminInstructorsIndexController.$inject = ['UsersModel', 'UserService', 'FlashService', '$window'];
export default AdminInstructorsIndexController;
