import angular from 'angular'
import template from './report-card-settings.html'
import AdminSettingsReportCardController from './report-card-settings.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.settings.reportcard', {
            url : '/report-card',
            controller : AdminSettingsReportCardController,
            controllerAs : 'vm',
            template: template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.settings.reportcard', [] )
    .config(config)
    .controller('AdminSettingsReportCardController', AdminSettingsReportCardController);
