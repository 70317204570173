import filter from 'lodash/filter'
function FutureEntitiesRepository( $http, APPURL ) {

    const model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/future-entities',
            UPDATE : APPURL + '/api/v1/future-entities',
            CREATE : APPURL + '/api/v1/future-entities'

        };
    let data;

    model.destroy = destroy;
    model.show = show;
    model.hide = hide;
    model.reassign = reassign;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        data = extract( result );
        return data;
    }

    function destroy(data){
        return $http.post( URLS.FETCH + '/deletes', data ).then( cacheResults );
    }

    function show(data){
        data = filter(data, (entity, key) => {
            if( entity.type === 'Drive Session' ){
                entity.private = 'N'
            }
            return entity.type === 'Drive Session';
        });
        let payload = {
            'entities' : data,
            'private' : 'N'
        }
        return $http.post( URLS.FETCH , payload).then( cacheResults );
    }

    function hide(data){
        data = filter(data, (entity, key) => {
            if( entity.type === 'Drive Session' ){
                entity.private = 'Y'
            }
            return entity.type === 'Drive Session';
        });
        let payload = {
            'entities' : data,
            'private' : 'Y'
        }
        return $http.post( URLS.FETCH , payload).then( cacheResults );
    }

    function reassign(data){
        return $http.post(URLS.FETCH, data).then(cacheResults);
    }

}
FutureEntitiesRepository.$inject = ['$http', 'APPURL'];
export default FutureEntitiesRepository;