"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var NotificationModel = /** @class */ (function () {
    function NotificationModel($http, APPURL) {
        var _this = this;
        this.getNotifications = function (data) {
            if (data === void 0) { data = null; }
            if (data !== null) {
                return _this.$http.get(_this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(_this.cacheResults);
            }
            return _this.$http.get(_this.URLS.FETCH).then(_this.cacheResults);
        };
        this.$http = $http;
        this.APPURL = APPURL;
        this.URLS = {
            FETCH: APPURL + '/api/v1/notifications',
            UPDATE: APPURL + '/api/v1/notifications',
            CREATE: APPURL + '/api/v1/notifications'
        };
        this.tasks = [];
        this.cacheResults = this.cacheResults.bind(this);
        this.extract = this.extract.bind(this);
    }
    NotificationModel.prototype.extract = function (result) {
        return result.data;
    };
    NotificationModel.prototype.cacheResults = function (result) {
        this.tasks = this.extract(result);
        return this.tasks;
    };
    return NotificationModel;
}());
NotificationModel.$inject = ["$http", "APPURL"];
exports.default = NotificationModel;
