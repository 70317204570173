import { nav } from '../../../common/helpers/nav'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

function StudentSidebarNavigationController(UserService, UsersModel){

    const vm = this;
    vm.currentUser = UserService.getCurrentUser();
    const currentUser = UserService.getCurrentUser();
    UsersModel.getStudentByID( currentUser.id ).then( response => {
        vm.canAddPickupLocations = response.school.allows_custom_pickup_locations;
        this.studentCanSchedule = true;
        filter( response.school.policies , ( policy, key) => {
            return key === 'drives';
        }).forEach( drives => {
            if( drives.policymeta.hasOwnProperty('allow_students_to_schedule') && ! isEmpty( drives.policymeta.allow_students_to_schedule ) ){
                this.studentCanSchedule = drives.policymeta.allow_students_to_schedule == 1;
            }
        });
    });

    nav();
}
StudentSidebarNavigationController.$inject = ['UserService', 'UsersModel'];
export default StudentSidebarNavigationController;