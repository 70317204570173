import angular from 'angular'
import controller from './task.controller'
import template from './task.html'

function dsTask() {
    return {
        restrict: 'E',
        scope: {
            'task' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.task', [])
    .controller('dsTaskController', controller)
    .directive('dsTask', dsTask);
