"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var TimeoffRequestsModel = /** @class */ (function () {
    function TimeoffRequestsModel($http, APPURL) {
        this.$http = $http;
        this.APPURL = APPURL;
        this.URLS = {
            FETCH: APPURL + '/api/v1/timeoffrequests',
            UPDATE: APPURL + '/api/v1/timeoffrequests',
            CREATE: APPURL + '/api/v1/timeoffrequests'
        };
        this.cacheResults = this.cacheResults.bind(this);
    }
    TimeoffRequestsModel.prototype.extract = function (result) {
        return result.data;
    };
    TimeoffRequestsModel.prototype.cacheResults = function (result) {
        var timeoffrequests = this.extract(result);
        return timeoffrequests;
    };
    TimeoffRequestsModel.prototype.getRequestByID = function (id) {
        return this.$http.get(this.URLS.FETCH + '/' + id).then(this.cacheResults);
    };
    TimeoffRequestsModel.prototype.get = function (data) {
        if (data && (data.hasOwnProperty('instructor_id') ||
            data.hasOwnProperty('status') || data.hasOwnProperty('page'))) {
            return this.$http.get(this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    TimeoffRequestsModel.prototype.update = function (request) {
        return this.$http.put(this.URLS.FETCH + '/' + request.id, request).then(this.cacheResults);
    };
    TimeoffRequestsModel.prototype.create = function (request) {
        return this.$http.post(this.URLS.FETCH, request).then(this.cacheResults);
    };
    TimeoffRequestsModel.prototype.delete = function (request) {
        return this.$http.delete(this.URLS.FETCH + '/' + request.id).then(this.cacheResults);
    };
    TimeoffRequestsModel.prototype.deleteDay = function (request) {
        return this.$http.post(this.URLS.FETCH + '/' + request.id + '/day', { 'rto': request }).then(this.cacheResults);
    };
    return TimeoffRequestsModel;
}());
TimeoffRequestsModel.$inject = ['$http', 'APPURL'];
exports.default = TimeoffRequestsModel;
