import moment from 'moment'
function InstructorRequestTimeOffDayController( UserService, $state, UsersModel, TimeoffRequestsModel, FlashService ) {

    const vm = this;
    vm.date = new Date();
    vm.currentUser = UserService.getCurrentUser();
    vm.requestDayOff = {}

    vm.requestType = {
        'dayOff' : false,
        'moreThanDay' : false,
        'partialDay' : false
    };
  
    vm.requestDayOff = {};

    vm.submitDayRequest = submitDayRequest;
    vm.updateDayOffDate = updateDayOffDate;
    vm.updateFromTime = updateFromTime;
    vm.updateToTime = updateToTime;

    init();

    function init(){
        //set initial value for time pickers
        vm.requestDayOff.from = new Date();
        vm.requestDayOff.from.setHours(9);
        vm.requestDayOff.from.setMinutes(0);
        vm.requestDayOff.to   = new Date();
        vm.requestDayOff.to.setHours(12);
        vm.requestDayOff.to.setMinutes(0);
    }
  
  function updateDayOffDate(newDate) {
    vm.requestDayOff.date = newDate.selected[0]
  }
  
  function updateFromTime(newTime) {
    vm.requestDayOff.from = newTime.selected[0]
  }
  
  function updateToTime(newTime) {
    vm.requestDayOff.to = newTime.selected[0]
  }

  function submitDayRequest( isValid ) {
        vm.submitted = true;
        if ( isValid ) {
            if( vm.requestType.partialDay === false ) {
                //if its a full day request, set start and end time
                vm.requestDayOff.from = new Date();
                vm.requestDayOff.from.setHours(0);
                vm.requestDayOff.from.setMinutes(0);

                vm.requestDayOff.to   = new Date();
                vm.requestDayOff.to.setHours(23);
                vm.requestDayOff.to.setMinutes(59);
            }

            const beginString = moment(vm.requestDayOff.date).format('YYYY-MM-DD') + ' ' + moment(vm.requestDayOff.from).format('H:mm');
            const endString = moment(vm.requestDayOff.date).format('YYYY-MM-DD') + ' ' + moment(vm.requestDayOff.to ).format('H:mm');
            const begin = moment(beginString, 'YYYY-MM-DD H:mm');
            const end   = moment(endString, 'YYYY-MM-DD H:mm');

            const request = {
                'user_id' : vm.currentUser.id,
                'begin' : begin.format( 'YYYY-MM-DD HH:mm:ss' ),
                'end' : end.format( 'YYYY-MM-DD HH:mm:ss' ),
                'type' : vm.requestType.partialDay ? 'partial' : 'full',
                'status' : 'Pending',
                'reason' : vm.requestDayOff.reason
            };
          
            TimeoffRequestsModel.create( request ).then( response => {
                FlashService.setMessage( {
                    'type' : 'success',
                    'message' : response.message
                } );
                vm.submitted = false;
                $state.go( 'drivecoach.instructor.dashboard' );
            } );
        } else {
            FlashService.setMessage( { 'type' : 'error', 'message' : 'Please fill the form.' } );
        }

    }

}
InstructorRequestTimeOffDayController.$inject = ['UserService', '$state', 'UsersModel', 'TimeoffRequestsModel', 'FlashService'];
export default InstructorRequestTimeOffDayController;

