import angular from "angular";
import template from "./admin-complete-drive.html";
import controller from "./admin-complete-drive.controller";

function getDriveSession($stateParams, DrivesModel) {
  return DrivesModel.getDriveByID($stateParams.driveId).then(response => {
    return response.drive;
  });
}
getDriveSession.$inject = ["$stateParams", "DrivesModel"];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.admin.completedrive", {
    url: "/drive/:driveId/complete",
    controller: controller,
    controllerAs: "vm",
    template: template,
    resolve: {
      driveSession: getDriveSession,
    },
    data: {
      authorizedRoles: [
        USER_ROLES.admin,
        USER_ROLES.administrator,
        USER_ROLES.owner,
      ],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("admin.dashboard.completedrive", [])
  .controller("AdminCompleteDriveController", controller)
  .config(config);
