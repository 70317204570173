import moment from 'moment'
import sortBy from 'lodash/sortBy'

class InstructorAgendaController {

    constructor(UserService, $state, $stateParams, CalendarModel, $window, sessionTypes, VueStoreFactory) {
        this.UserService = UserService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.CalendarModel = CalendarModel;
        this.VueStoreFactory = VueStoreFactory;
        this.$window = $window;
        this.currentUser = UserService.getCurrentUser();        
        this.date = $stateParams.date;
        this.viewCourse = this.viewCourse.bind(this);
        this.agendaData = {
            'instructor_id': this.currentUser.id,
            'date': $stateParams.date
        };

        this.cardLabels = [
            {'class': 'attn-text', 'name': 'ACTION REQUIRED'},
            {'class': 'open-text', 'name': 'OPEN SESSION'},
            {'class': 'rto-text', 'name': 'RTO'},
            {'class': 'event-text', 'name': 'EVENT'},
            {'class': 'course-text', 'name': 'COURSE'},
        ];

        sessionTypes.forEach(type => {
            this.cardLabels.push({
                'name': type.name.toUpperCase(),
                'class': type.name.replace(/\s/g, '-').toLowerCase() + '-text'
            })
        });

       CalendarModel.getInstructorAgenda(this.agendaData).then(response => {

            let _allItems = [];
            if (typeof response.data.drives !== 'undefined') {
                response.data.drives.filter(drive => {
                    drive.date = drive.drive_date;
                    drive.startTime = moment(drive.start_time, 'HH:mm:s').format('h:mm a');
                    drive.typeDisplay = drive.type.name;
                    drive.type = 'Drive Session';
                    if (drive.private === 'Y') {
                        drive.typeDisplay = drive.typeDisplay + ' (Hidden)';
                    }
                    drive.timestamp = moment(drive.drive_date + ' ' + drive.start_time).unix();
                    if (drive.location) {
                        drive.location.address = drive.location.address;
                    }

                    if (drive.pickuplocation && drive.pickuplocation.hasOwnProperty('address')) {
                        drive.pickuplocation.address = drive.pickuplocation.address;
                    }
                    if (drive.dropofflocation && drive.dropofflocation.hasOwnProperty('address')) {
                        drive.dropofflocation.address = drive.dropofflocation.address;
                    }

                    const minutes = moment.duration(drive.session_length, 'minutes');
                    drive.endTime = moment(drive.start_time, 'HH:mm:s').add(minutes, 'minutes').format('h:mm a');

                    drive.duration = moment.duration(drive.session_length, 'minutes').asHours();
                    drive.duration = moment.duration(drive.duration, 'hours').format("h [hours], m [minutes]");

                    drive.styling = CalendarModel.getStylingForDriveCard(drive); 
                    return drive;
                }).forEach(drive => {
                    _allItems.push(drive);
                });
            }

            if (typeof response.data.courses !== 'undefined') {
                response.data.courses.filter(course => {
                    course.type = 'Course';
                    course.typeDisplay = course.name;
                    course.location = angular.fromJson(course.location);
                    course.timestamp = moment(moment(course.date).format('YYYY-MM-DD') + ' ' + course.time).unix();
                    const minutes = moment.duration(course.duration, 'minutes');
                    course.endTime = moment(course.time, 'HH:mm:s').add(minutes, 'minutes').format('h:mm a');

                    course.startTime = moment(course.time, 'HH:mm:s').format('h:mm a');
                    course.duration = moment.duration(course.duration, 'minutes').asHours();
                    course.duration = moment.duration(course.duration, 'hours').format("h [hours], m [minutes]");

                    course.styling = 'course';
                    return course;
                }).forEach(course => {
                    _allItems.push(course);
                });
            }

            if (typeof response.data.events !== 'undefined') {
                response.data.events.filter(event => {
                    event.typeDisplay = 'Event';

                    event.type = 'Event';
                    event.location = angular.fromJson(event.location);
                    event.timestamp = moment(moment(event.date).format('YYYY-MM-DD') + ' ' + event.time).unix();

                    const minutes = moment.duration(event.duration, 'minutes');
                    event.endTime = moment(event.time, 'HH:mm:s').add(minutes, 'minutes').format('h:mm a');

                    event.startTime = moment(event.time, 'HH:mm:s').format('h:mm a');
                    event.duration = moment.duration(event.duration, 'minutes').asHours();
                    event.duration = moment.duration(event.duration, 'hours').format("h [hours], m [minutes]");

                    event.styling = 'event';
                    return event;
                }).forEach(event => {
                    _allItems.push(event);
                });
            }

            if (typeof response.data.timeoffrequests !== 'undefined') {
                response.data.timeoffrequests.filter(rto => {
                    rto.typeDisplay = 'RTO';

                    const _start_ = moment(rto.begin, 'YYYY-MM-DD HH:mm:s');
                    const _end_ = moment(rto.end, 'YYYY-MM-DD HH:mm:s');

                    rto.timestamp = _start_.unix();

                    rto.styling = 'rto';

                    // Dont show times for full or range type RTO
                    if (rto.type === 'full' || rto.type === 'range') {
                        const s_moment = moment(this.date, 'YYYY-MM-DD').hours(23).minutes(59);
                        const e_moment = moment(this.date, 'YYYY-MM-DD').hours(0).minutes(0);
                        rto.endTime = s_moment.format('h:mm a');
                        rto.startTime = e_moment.format('h:mm a');
                        rto.duration = moment.duration(s_moment.diff(e_moment)).format("h [hours], m [minutes]");
                    } else {
                        rto.endTime = moment(rto.end, 'YYYY-MM-DD HH:mm:s').format('h:mm a');
                        rto.startTime = moment(rto.begin, 'YYYY-MM-DD HH:mm:s').format('h:mm a');
                        rto.duration = moment.duration(_end_.diff(_start_)).format("h [hours], m [minutes]");
                    }
                    return rto;
                }).forEach(rto => {
                    _allItems.push(rto);
                });
            }

            this.items = sortBy(_allItems, item => {
                return item.timestamp;
            });

        });

    }

    next() {
        this.$state.go('drivecoach.instructor.dashboard.agenda', {
            'date': moment(this.date).add(1, 'days').format('YYYY-MM-DD')
        })
    }

    prev() {
        this.$state.go('drivecoach.instructor.dashboard.agenda', {
            'date': moment(this.date).subtract(1, 'days').format('YYYY-MM-DD')
        })
    }

    print() {
        this.$window.print();
    }

    isEvent(item) {
        return item.type === 'Event';
    }

    isCourse(item) {
        return item.type === 'Course';
    }

    viewCourse(course){
        // This makes sure that when viewing a single course from this screen, the first classdate is selected
        if (course.classes.length > 0) {
            let data = {
                id: course.id,
                date: new Date(course.classes[0].start).toLocaleDateString(),
                class_id: course.classes[0].id,
                start: new Date(course.classes[0].start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
                end: new Date(course.classes[0].end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
                instructor: course.classes[0].instructor,
                instructor_id: course.classes[0].instructor_id
            };
            this.VueStoreFactory.dispatch('SET_VIEW_COURSE', data);
        }
        this.$state.go('drivecoach.instructor.dashboard.course.view', { 'courseId' : course.id })
    }

}

InstructorAgendaController.$inject = ['UserService', '$state', '$stateParams', 'CalendarModel', '$window', 'sessionTypes', 'VueStoreFactory'];
export default InstructorAgendaController;
