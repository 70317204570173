import { Payment } from "./Payment";

class Order {

    constructor() {
        this.$products = [];
        this.$student = null;
        this.$coupon = null;
        this.$total = 0;
        this.$payments = [
            new Payment()
        ];
        this.$lineItems = [];
        this.$paid = false;
        this.$notes = null;

        // if (this.student !== null && !this.student.guardian) {
        //     this.payments.map((payment) => {
        //         payment.creditCard().setName(this.student.fullname());
        //         return payment;
        //     });
        // }
    }

    id(){
        return this.$id;
    }

    paid(){
        return this.$paid;
    }

    setNotes(notes){
        this.$notes = notes;
    }

    createdAt(){
        return this.$created_at;
    }

    setStatus(status){
        this.$status = status;
    }

    status()
    {
        return this.$status;
    }

    /**
     * @param {Boolean} paid
     */
    setPaid(paid = false) {
        this.$paid = paid;
    }

    /**
     * @param {Array} lineItems
     */
    setLineItems(lineItems = []){
        this.$lineItems = lineItems
    }

    hasStudent() {
        return this.$student !== null;
    }

    total() {
        return this.$total;
    }

    student() {
        return this.$student;
    }

    coupon(){
        return this.$coupon;
    }

    payments() {
        if (!this.$payments){
            return [];
        }
        return this.$payments;
    }

    products() {
        if (!this.$products){
            return [];
        }
        return this.$products;
    }

    setProducts(products){
        this.$products = products;
    }

    setPayments(payments){
        this.$payments = payments;
    }

    /**
     * Used (right now only during checkout) to set the coupon being used
     *
     * @param {Coupon} coupon The coupon used on this order.
     */
    setCoupon(coupon){
        this.$coupon = coupon;
    }

    removeCoupon(){
        this.$coupon = null;
    }

    /**
     * Sets the student associated with this order. The student is also the customer.
     *
     * @param {Student} student
     */
    setStudent(student){
        this.$student = student;
    }

    /**
     * Remove the currently attached student. Currently used during check-out if admins select the wrong kid.
     */
    removeStudent(){
        delete this.$student;
    }

    setTotal(total){
        this.$total = total;
    }

    lineItems()
    {
        return this.$lineItems;
    }

    notes(){
        return this.$notes;
    }

}

export { Order };
