import angular from 'angular'
import template from './user-search.html'
import controller from './user-search.controller'
function dsUserSearch() {
    return {
        restrict: 'E',
        scope: {
            'ngModel' : '=',
            'onSelect' : '=',
            'userType' : '=',
            'disabled' : '=',
            'schooloverride' : '=',
            'placeholder' : '=?'
        },
        template: template,
        link: (scope, element, attrs) => {
            if (typeof scope.onSelect === 'undefined') {
                scope.onSelect = ($item, $model) => {
                    
                }
            }
            if (typeof scope.vm.placeholder === 'undefined') {
                scope.vm.placeholder = 'Select a User'
            }

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.usersearch', [])
    .controller('dsUserSearchController', controller)
    .directive('dsUserSearch', dsUserSearch);

