function CourseProductsModel($http, APPURL) {

  let model = this,
      URLS = {
        FETCH: APPURL + '/api/v1/courses',
        UPDATE: APPURL + '/api/v1/courses',
        CREATE: APPURL + '/api/v1/courses'

      },
      courses;

  function extract(result) {
    return result.data;
  }

  function cacheResults(result) {
    courses = extract(result);
    return courses;
  }

  function toQueryString(obj) {
    var parts = [];
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
      }
    }
    return parts.join("&");
  }

  model.create = function (product, courseId) {
    return $http.post(URLS.FETCH + '/' + courseId + '/products', product).then(cacheResults);
  };

}
CourseProductsModel.$inject = ['$http' , 'APPURL'];
export default CourseProductsModel;
