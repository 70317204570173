import {Months} from "../../../common/constants/months_and_years";
import {Years} from "../../../common/constants/months_and_years";
import {States} from "../../../common/constants/states";
import moment from 'moment'

class InstructorStudentDrivesController {

    constructor($stateParams, UsersModel, FlashService, student, UserService){
        this.$stateParams = $stateParams;
        this.student = student;
        this.loading = true;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.payment = {}
        this.drives  = [];
        this.addresses = [];
        this.order = null;
        this.months = Months;
        this.years = Years;
        this.states = States;
        this.currentPage = 1;
        this.totalItems = 0;
        this.UserService = UserService;
        this.currentUser = UserService.getCurrentUser();

        this.options = {
            'id' : this.student.id,
            'page_results' : true
        };
    
        this.getDrives();
    }

    pageChanged() {
        this.getDrives();
    }

    getDrives(){
        this.submitted = true;
        this.UsersModel.getStudentDrives( this.options ).then(response => {
            this.totalItems = response.drives.total;
            this.currentPage = response.drives.current_page;
            this.drives = response.drives.filter( drive => {
                let minutes = moment.duration(drive.session_length, 'minutes');
                drive.startTime = moment(drive.start_time, 'HH:mm:s').format('h:mm a');
                drive.endTime = moment(drive.start_time, 'HH:mm:s').add( minutes, 'minutes').format('h:mm a');
                drive.duration = moment.duration(drive.session_length, 'minutes').asHours();
                drive.duration = moment.duration(drive.duration, 'hours').humanize();
                if( drive.observation === true ){
                    drive.type.name = drive.type.name + ' (Observation)';
                }
                return drive;
            });
        });
    }
}

InstructorStudentDrivesController.$inject = ['$stateParams', 'UsersModel', 'FlashService', 'student', 'UserService'];
export default InstructorStudentDrivesController;
