"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SchoolSetupModel = /** @class */ (function () {
    function SchoolSetupModel($http, APPURL) {
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + '/api/v1/school/setup',
        };
        this.cacheResults = this.cacheResults.bind(this);
        this.extract = this.extract.bind(this);
    }
    SchoolSetupModel.prototype.extract = function (result) {
        return result.data;
    };
    SchoolSetupModel.prototype.cacheResults = function (result) {
        var data = this.extract(result);
        return data;
    };
    SchoolSetupModel.prototype.get = function () {
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    ;
    SchoolSetupModel.prototype.create = function (request) {
        return this.$http.post(this.URLS.FETCH, request).then(this.cacheResults);
    };
    ;
    SchoolSetupModel.prototype.update = function (request) {
        return this.$http.put(this.URLS.FETCH, request).then(this.cacheResults);
    };
    ;
    SchoolSetupModel.prototype.completeSetup = function () {
        return this.$http.put(this.URLS.FETCH, { step: 5 }).then(this.cacheResults);
    };
    return SchoolSetupModel;
}());
SchoolSetupModel.$inject = ['$http', 'APPURL'];
exports.default = SchoolSetupModel;
