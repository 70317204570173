import angular from 'angular'
import template from './instructor-teamcalendar.html'
import InstructorTeamCalendarController from './instructor-teamcalendar.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
  $stateProvider
    .state( 'drivecoach.instructor.dashboard.teamcalendar', {
    'url' : '/teamcalendar',
    'controller' : InstructorTeamCalendarController,
    'controllerAs' : 'vm',
    'template' : template,
    data : {
      authorizedRoles : [
        USER_ROLES.admin,
        USER_ROLES.owner,
        USER_ROLES.instructor
      ]
    }
  } );
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.teamcalendar', [])
  .config( config )
  .controller( 'InstructorTeamCalendarController', InstructorTeamCalendarController );