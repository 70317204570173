
class Service {

    constructor(data){
        this.$originalData = data;
        const {name, price, allow_custom_pickup_locations, allow_custom_dropoff_locations, hidden, pay_ratio, 
            send_instructor_change_notification, send_last_minute_availability_alert, 
            disable_notifications_after_completion_of_curriculum, auto_verify_on_purchase} = data;
        this.$name = name;
        this.$price = price;
        this.$allow_custom_pickup_locations = allow_custom_pickup_locations;
        this.$allow_custom_dropoff_locations = allow_custom_dropoff_locations;
        this.$hidden = hidden || false;
        this.$pay_ratio = pay_ratio || 1;
        this.$send_instructor_change_notification = send_instructor_change_notification;
        this.$send_last_minute_availability_alert = send_last_minute_availability_alert;
        this.$disable_notifications_after_completion_of_curriculum = disable_notifications_after_completion_of_curriculum;
        this.$auto_verify_on_purchase = auto_verify_on_purchase || false;
        
    }

    name() {
        return this.$name;
    }

    price() {
        return this.$price;
    }

    allowCustomPickupLocations() {
        return this.$allow_custom_pickup_locations;
    }

    allowCustomDropOffLocations() {
        return this.$allow_custom_dropoff_locations;
    }

    hidden() {
        return this.$hidden;
    }

    payRatio() {
        return this.$pay_ratio;
    }

    sendInstructorChangeNotification() {
        return this.$send_instructor_change_notification;
    }

    sendLastMinuteAvailabilityAlert() {
        return this.$send_last_minute_availability_alert;
    }

}

export {Service}