<template>
    <section>
        <form @submit.prevent="updateDriveDeadline()">
            <label>Drive Deadline</label>
            <dcDatepicker :picker-datetime="driveDeadline" v-model="driveDeadline"></dcDatepicker>
            <div style="margin-top: 2em;">
                <button class="btn btn-dc btn-block text-center">Update Drive Deadline</button>
            </div>
        </form>
    </section>
</template>

<script>
import dcDatepicker from '../../../../../common/directives/vue-directives/datepicker/Datepicker.vue'
export default {
    name: 'DriveDeadlineForm',
    components: {
        dcDatepicker
    },
    props: {
        deadline: {
            required: true,
        }
    },
    data() {
        return {
            driveDeadline: '',
        }
    },
    computed: {
        created() {
            this.driveDeadline = this.deadline;
        }
    },
    methods: {
        updateDriveDeadline(){
            this.$emit('drive-deadline-updated', new Date(this.driveDeadline));
        }
    }
}
</script>
