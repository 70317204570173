import angular from 'angular'
import template from './admin-settings-policies-calendar.html'
import AdminSettingsPoliciesCalendarController from './admin-settings-policies-calendar.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.settings.policies.calendar', {
            url: '/calendar',
            controller: AdminSettingsPoliciesCalendarController,
            controllerAs: 'vm',
            template: template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.settings.policies.calendar', [])
    .config(config)
    .controller('AdminSettingsPoliciesCalendarController', AdminSettingsPoliciesCalendarController)