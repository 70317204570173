<template>
  <div>
    <div class="agenda-header">
      <h2>Schedule Feed</h2>
      <div>
        <button type="button" class="btn btn-wht float-left" :class="{'btn-active': showOpen}" @click="handleOpenClick">Open</button>
        <button type="button" class="btn btn-wht" :class="{'btn-active': !showOpen}" @click="handleAllClick">All</button>
      </div>
    </div>
    
    <section class="feed-wrap">
      <div class="filter-feed">
        <div class="inner" v-if="!showOpen">
          <div v-for="event, index in events" class="agenda-item" :class="event.classNames" @click="handleEventClick(event)">
            <div v-if="event.rto_type === 'range'" class="col"><strong>{{ event.startDate }} - {{ event.endDate }}<br /></strong></div>
            <div v-else-if="event.rto_type === 'full'" class="col"><strong>{{ event.startDate }}<br />All Day</strong></div>
            <div v-else class="col"><strong>{{ event.startDate }}<br />{{ event.start }} - {{ event.end }}</strong></div>
            <div class="col"><div class="agenda-badge" v-tooltip="event.tooltipOptions"></div>{{ event.agendaDisplay }}</div>
          </div>
        </div>
        <div class="inner" v-if="showOpen">
          <div v-for="event in openEvents" class="agenda-item" :class="event.classNames" @click="handleEventClick(event)">
            <div class="col"><strong>{{ event.startDate }}<br />{{ event.start }} - {{ event.end }}</strong></div>
            <div class="col"><div class="agenda-badge" v-tooltip="event.tooltipOptions"></div>{{ event.agendaDisplay }}</div>
          </div>
        </div>
      </div>
      
    </section>
  </div>
</template>

<script>
  import moment from 'moment'
  import sortBy from 'lodash/sortBy'
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    name: 'dc-mini-calendar-agenda-vue',
    props: {
      'context': { type: String, default: '' },
      'agendaEvents': { type: Array, default: function() {
          return []
        }
      },
      'showElement': { type: Boolean, default: true }
    },
    data() {
      return {
        showOpen: true
      }
    },
    methods: {
      handleOpenClick: function(e) {
        this.showOpen = true
      },
      handleAllClick: function(e) {
        this.showOpen = false
      },
      handleEventClick(event) {
        let eventName = event.label.name
        this.$emit('agenda-event-click', {
          id: event.id,
          context: this.context,
          eventName: eventName,
          event: event,
          miniAgenda: true
        })
      },
      formatDateRTO(event) {
        let startDate = moment(event.begin).format('ddd D MMM')
        let endDate = moment(event.end).format('ddd D MMM')
        
        event.startDate = startDate
        
        if(event.rto_type === 'range') {
          event.endDate = endDate
        } 

        return event
      },
      formatEvents: function(events) {
        return events.map((event) => {
          //standardize date format
          let date = moment(event.date).format('ddd D MMM')
          
          let formattedEvent = cloneDeep(event)
          
          if(formattedEvent.type === 'RTO' || formattedEvent.label.type === 'RTO') {
            formattedEvent = this.formatDateRTO(formattedEvent)
          } else {
            formattedEvent.startDate = date
            formattedEvent.start = moment(formattedEvent.start).format('h:mm a')
            formattedEvent.end = moment(formattedEvent.end).format('h:mm a')
          }
          
          formattedEvent.classNames = ['agenda-item']
          formattedEvent.tooltipOptions = {
            content: 'Open Session',
            classes: 'v-tooltip'
          }
          
          //set title and classes based on event type
          switch(formattedEvent.type) {
            case 'Course' :
              formattedEvent.tooltipOptions.content = formattedEvent.type
              formattedEvent.classNames.push('course')
              break;
            case 'Event' :
              formattedEvent.tooltipOptions.content = formattedEvent.type
              formattedEvent.classNames.push('event')
              break;
            case 'RTO' :
              formattedEvent.tooltipOptions.content = formattedEvent.type
              formattedEvent.classNames.push('rto')
              break;
            case 'Drive Session' :
              formattedEvent.classNames.push('session')
              if(formattedEvent.status === "Taken" || formattedEvent.student_id !== 0) {
                formattedEvent.tooltipOptions.content = 'Booked Session'
                formattedEvent.classNames.push('booked-session')
              } else if (formattedEvent.type === 'Drive Session' && moment().unix() >= formattedEvent.timestamp) {
                formattedEvent.classNames.push('past-session')
                formattedEvent.tooltipOptions.content = 'Past Open Session'
              }
              break;
            default : 
              formattedEvent.classNames.push('session')
          }

          
          return formattedEvent;
        })
      }
    },
    computed: {
      events: function() {
        //sort by date
        return sortBy(this.formatEvents(this.agendaEvents), item => {
            return item.timestamp;
        });
      },
      openEvents: function() {
        let events = this.agendaEvents.filter((event) => {
          return (event.status === 'Available' && event.type === 'Drive Session')
        })
        return sortBy(this.formatEvents(events), item => {
          return item.timestamp;
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../../../../../assets/css/partials/variables.scss';
  
  .agenda-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 5px;
    margin-top: 15px;
    .btn {
      margin-bottom: 15px;
    }
    @media #{$small} {
      flex-direction: column;
      align-items: stretch;
      > div {
        display: flex;
        > .btn {
          flex-basis: 50%;
        }
      }
      h2 {
        text-align: center;
      }
    }
  }
  #admin-calendar {
    .agenda {
      h2 {
        @media #{$small} {
          text-align: center;
        }
      }
      .feed-wrap {
        .filter-feed {
          @media #{$small} {
            overflow-y: auto;
          }
          .inner {
            border: 1px solid #EAF0F4;
          }
        }
      }
    }
  }
  .agenda-item {
    background: $white;
    font-size: 11px;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    border-bottom: 1px solid #EAF0F4;
    cursor: pointer;
    transition: all .2s ease-in-out;
    &:hover {
      background: $light;
    }
    .col {
      padding: 7px 12px;
      flex-basis: 50%;
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      &:last-child {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 1;
        border-left: 1px solid #EAF0F4;
        flex-wrap: nowrap;
      }
    }
    .agenda-badge {
      border-radius: 50%;
      height: 15px;
      width: 15px;
      margin-right: 8px;
      flex-shrink: 0;
    }
    &.session {
      .agenda-badge {
        background-color: $blue;
      }
    }
    &.booked-session {
      .agenda-badge {
        background-color: $green;
      }
    }
    &.past-session {
      .agenda-badge {
        background-color: $blue-grey;
      }
    }
    &.course {
      .agenda-badge {
        background-color: $teal;
      }
    }
    &.event {
      .agenda-badge {
        background-color: $light-purple;
      }
    }
    &.rto {
      .agenda-badge {
        background-color: $light-grey;
      }
    }
  }
  .btn-wht {
    color: $grey;
    border-color: $light-grey !important;
    border-radius: 0px;
    text-transform: none;
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
      border-left: 0;
    }
  }
  button.btn-active {
    color: $blue;
    background-color: $white;
    &:hover, &:active {
      background-color: $grey !important;
      color: $white !important;
    }
  }
</style>