import angular from "angular";
import template from "./instructor-create-drive.html";
import controller from "./instructor-create-drive.controller";

function getVehicles(VehiclesModel) {
  return VehiclesModel.getVehicles({ all: true, status: "available" }).then(
    (response) => {
      return [{'id' : 0, 'name' : 'None'}].concat(response.vehicles);
    }
  );
}
getVehicles.$inject = ["VehiclesModel"];

function getInstructors(UsersModel) {
  return UsersModel.getInstructors({ all: true, status: "Active" }).then(
    (response) => {
      return [{'id' : 0, 'fullname' : 'None'}].concat(response.instructors);
    }
  );
}
getInstructors.$inject = ["UsersModel"];

function getInstructor(UserService, UsersModel) {
  return UsersModel.getInstructorByID(UserService.getCurrentUser().id).then(response => {
    return response;
  })
}
getInstructor.$inject = ['UserService', 'UsersModel'];

function getSesssionTypes(DriveSessionTypesModel) {
  return DriveSessionTypesModel.get().then((response) => {
    return response.sessionTypes.filter((type) => {
      return type.status === "Active";
    });
  });
}
getSesssionTypes.$inject = ["DriveSessionTypesModel"];


function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.instructor.dashboard.createdrive", {
    url: "/create-appointment",
    controller: controller,
    controllerAs: "vm",
    template: template,
    resolve: {
      driveSession: () => {
        return {
          date: new Date(),
          time: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes(), 0, 0),
          length: {
            hours: 2,
            minutes: "00"
          },
          sectors: [],
          zones: [],
          private: "N",
          observation: false,
        };
      },
      sessionTypes: getSesssionTypes,
      vehicles: getVehicles,
      instructors: getInstructors,
      instructor: getInstructor,
    },
    data: {
      authorizedRoles: [
        USER_ROLES.admin,
        USER_ROLES.administrator,
        USER_ROLES.owner,
      ],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("instructor.dashboard.createdrive", [])
  .controller("InstructorCreateDriveController", controller)
  .config(config);
