"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StudentAvailableCoursesController = /** @class */ (function () {
    function StudentAvailableCoursesController(student, UsersModel) {
        var _this = this;
        this.class_dates = [];
        this.student = student;
        this.UsersModel = UsersModel;
        this.filterOptions = {
            days: "all",
            times: "all",
        };
        this.UsersModel.getStudentAvailableClasses(student.id, this.filterOptions).then(function (response) {
            _this.class_dates = response.class_dates;
        });
    }
    StudentAvailableCoursesController.prototype.applyFilter = function () {
        var _this = this;
        this.applyingFilter = true;
        var filterQuery = {
            days: this.filterOptions.days,
            times: this.filterOptions.times,
        };
        this.UsersModel.getStudentAvailableClasses(this.student.id, filterQuery)
            .then(function (response) {
            _this.applyingFilter = false;
            _this.class_dates = response.class_dates;
        })
            .catch(function (Error) {
            _this.applyingFilter = false;
            console.error(Error);
        });
    };
    return StudentAvailableCoursesController;
}());
StudentAvailableCoursesController.$inject = ["student", "UsersModel"];
exports.default = StudentAvailableCoursesController;
