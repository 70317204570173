import angular from 'angular'
import template from './coupons.html'
import CouponsController from './coupons.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.coupons', {
            url: '/coupons',
            template: template,
            controller: CouponsController,
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.crm.coupons', [])
    .config(config)
    .controller('CouponsController', CouponsController)
;