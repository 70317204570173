import moment from 'moment'

class InstructorTeamCalendarController {

  constructor($rootScope, CalendarService, DrivesFilterService, UserService, CalendarModel, $state){
    
    this.entities = [];
    this.$rootScope = $rootScope;
    this.CalendarModel = CalendarModel;
    this.UserService = UserService;
    this.CalendarService = CalendarService;
    this.DrivesFilterService = DrivesFilterService;
    this.$state = $state;
    
    this.updateEntities = this.updateEntities.bind(this);
    this.handleAgendaClick = this.handleAgendaClick.bind(this);
    
    this.loading = false;
    
    $rootScope.$on('UPDATE_CALENDAR', this.updateEntities);
    $rootScope.$on('CALENDAR_LOADING', () => {
      this.loading = true;
    });

  }
  
  updateEntities() {
    const entities = this.CalendarService.getCalendarItems();
    this.entities = entities
    this.loading = false;
  }
  
  handleAgendaClick(data) {
    this.$state.go( 'drivecoach.instructor.dashboard.teamagenda', { 'date' : data.dateStr } );
  }

}
InstructorTeamCalendarController.$inject = ['$rootScope', 'CalendarService', 'DrivesFilterService', 'UserService', 'CalendarModel', '$state'];
export default InstructorTeamCalendarController;
