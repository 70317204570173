import { saveToDisk } from "../../../common/helpers/saveToDisk";
class ExportsController {
  constructor($state, ExportsModel, FlashService) {
    this.busy = false;
    this.ExportsModel = ExportsModel;
    this.loading = false;
    this.FlashService = FlashService;
  }

  downloadReport() {
    this.busy = true;

    this.ExportsModel.fetchReport(this.report_type)
      .then((response) => {
	let filename = response.report_url.split("/")[response.report_url.split("/").length-1]
        saveToDisk(response.report_url, filename);
        this.FlashService.setMessage({
          type: "success",
          message: response.message,
        });
        this.busy = false;
      })
      .catch((Error) => {
        this.busy = false;
      });
  }
}
ExportsController.$inject = ["$state", "ExportsModel", "FlashService"];

export default ExportsController;
