import {States} from "../../../common/constants/states";

class AddFeeController {

    constructor($rootScope, $state, UsersModel, FlashService, UserService, SchoolsModel){
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;

        this.loading = true;
        this.busy = true;
        this.currentUser = UserService.getCurrentUser();
        this.UsersModel = UsersModel;
        this.SchoolsModel = SchoolsModel;
        this.states = States;

        this.pickuplocation = {};
        this.submitted = false;
    }

    submitPickupLocation() {
        this.submitted = true;
        this.SchoolsModel.addAdhocPickupLocation({
            'school_id' : this.currentUser.school_id,
            'name' : this.pickuplocation.name,
            'street': this.pickuplocation.street,
            'city': this.pickuplocation.city,
            'state': this.pickuplocation.state,
            'zip': this.pickuplocation.zip
        }).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.pickuplocation = {};
            this.submitted = false;
            this.$rootScope.$broadcast('ADHOC_PICKUP_LOCATION_CREATED', {'data' : response.location});
        }).catch(Error => {
            this.submitted = false;
        });
    }

}
AddFeeController.$inject = ['$rootScope', '$state', 'UsersModel', 'FlashService', 'UserService', 'SchoolsModel'];
export default AddFeeController;