import angular from 'angular'
import template from './admin-instructors-index.html'
import AdminInstructorsIndexController from './admin-instructors-index.controller'
import { telFormat } from '../../../../common/filters/telFormat';

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.instructors.index', {
            'url' : '/index',
            'controller' : AdminInstructorsIndexController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


export default angular.module( 'admin.dashboard.instructors.index', [] )
    .config(config)
    .controller( 'AdminInstructorsIndexController', AdminInstructorsIndexController )
    .filter('telFormat', telFormat);
;
