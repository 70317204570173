
class AdminInstructorEmailsController {

    constructor(instructor, APPURL, PostOfficeModel) {
        this.instructor = instructor;
        this.APPURL = APPURL;
        PostOfficeModel.getSenders(this.instructor.email).then(response => {
            this.emails = response.data;
        }).catch(Error => {
            console.error(Error)
        })
    }

}

AdminInstructorEmailsController.$inject = ['instructor', 'APPURL', 'PostOfficeModel'];
export default AdminInstructorEmailsController;