"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LessonPlansController = /** @class */ (function () {
    function LessonPlansController($window, $rootScope, $state, LessonPlanModel, FlashService, courseTypes, sessionTypes) {
        var _this = this;
        this.$window = $window;
        this.$state = $state;
        this.LessonPlanModel = LessonPlanModel;
        this.FlashService = FlashService;
        this.loading = true;
        this.lessonPlans = [];
        this.page_by = 10;
        this.totalItems = 0;
        this.currentPage = 1;
        this.courseTypes = courseTypes;
        this.sessionTypes = sessionTypes;
        this.removeLessonPlan = this.removeLessonPlan.bind(this);
        this.LessonPlanModel.get({ 'page': this.currentPage, 'page_by': this.page_by }).then(function (response) {
            _this.lessonPlans = response.lesson_plans.data;
            _this.currentPage = response.lesson_plans.current_page;
            _this.totalItems = response.lesson_plans.total;
            _this.loading = false;
        }).catch(function (Error) {
            _this.loading = false;
        });
    }
    LessonPlansController.prototype.removeLessonPlan = function (plan) {
        var _this = this;
        var confirm = this.$window.confirm('Are you sure you want to delete this lesson plan?');
        if (confirm === false) {
            return;
        }
        this.LessonPlanModel.delete(plan).then(function (response) {
            _this.FlashService.setMessage({
                message: response.message,
                type: 'success'
            });
            _this.lessonPlans = _this.lessonPlans.filter(function (lessonPlan) {
                return lessonPlan.id !== plan.id;
            });
        }).catch(function (Error) {
        });
    };
    return LessonPlansController;
}());
LessonPlansController.$inject = [
    "$window",
    "$rootScope",
    "$state",
    "LessonPlanModel",
    "FlashService",
    "courseTypes",
    "sessionTypes"
];
exports.default = LessonPlansController;
