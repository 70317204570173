import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import {Hours, Minutes} from '../../../common/constants/times'
import { Task, taskRequestTransformer } from "../../../classes/Task";

class TaskController {
    constructor($state, CrmTasksModel, FlashService, $rootScope, AdminTasksService){
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.AdminTasksService = AdminTasksService;
        this.CrmTasksModel = CrmTasksModel;
        this.FlashService = FlashService;
        this.hours = Hours;
        this.minutes = Minutes()
        this.assign_user = null;
        this.today = new Date;
        this.datepicker = {
            'open': ($event) => {
                this.datepicker.status.opened = true;
            },
            dateOptions: {
                formatYear: 'yy',
                startingDay: 7,
                showWeeks: false
            },
            'format': 'dd-MMMM-yyyy',
            'status': {
                opened: false
            }
        };
        let due_on_moment = moment(this.task.$due_on);
        this.due_date = due_on_moment.toDate()
        this.due_on_hours = due_on_moment.hours().toString();
        this.due_on_minutes = due_on_moment.minutes();
      
        this.updateDueDate = this.updateDueDate.bind(this)
    }

    goToUser(user){
        if ( user.usertype_id === 1 ) {
            this.$state.go( 'drivecoach.admin.students.view', {'userId' : user.id} );
            this.$rootScope.$broadcast('CLOSE_TASK_MODAL');
        }

        if ( user.usertype_id === 3 ) {
            // instructor
            this.$state.go( 'drivecoach.admin.instructors.view', {'instructorId' : user.id} )
            this.$rootScope.$broadcast('CLOSE_TASK_MODAL');
        }

        if ( user.usertype_id === 4 || user.usertype_id === 8 ) {
            // steve / owner
            this.$state.go( 'drivecoach.admin.staff.view', {'staffId' : user.id} )
            this.$rootScope.$broadcast('CLOSE_TASK_MODAL');
        }

        if ( user.usertype_id === 5 || user.usertype_id === 9) {
            // admins & staff
            this.$state.go( 'drivecoach.admin.staff.view', {'staffId' : user.id} )
            this.$rootScope.$broadcast('CLOSE_TASK_MODAL');
        }
    }

    addUser(){
        this.task.$users.push(this.assign_user);
    }

    removeUser(index){
        this.task.$users.splice(index, 1)
    }
    
    updateDueDate(newDate) {
      this.due_date = newDate.selected[0]
    }  
  
    submit(){
        this.submitted = true;
        let data = taskRequestTransformer(this.task);
        data.due_date = moment(this.due_date).format('MM/DD/YYYY')
        data.due_time = moment(this.due_on_hours + ' ' + this.due_on_minutes, 'HH:mm').format('HH:mm');
        this.CrmTasksModel.update(data).then(response => {
            this.submitted = false;
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            if( this.AdminTasksService.task.id === response.task.id ){
                // update tasks
                let task = new Task(response.task);
                this.AdminTasksService.updateTask(task)
            }
            this.$rootScope.$broadcast('TASKS_UPDATED')
        }).catch(response => {
            this.submitted = false;
        })
    }
}
TaskController.$inject = ['$state', 'CrmTasksModel', 'FlashService', '$rootScope', 'AdminTasksService'];
export default TaskController;