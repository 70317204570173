<template>
  <section id="admin-edit-course" class="">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 text-center">
          <h1 class="page-heading">Edit Course</h1>
          <div class="alert inline alert-warning" v-if="courseStarted">Some Course information can no longer be edited because students are booked and/or the course has started.</div>
        </div>
      </div>
    </div>
    
    <dcLoading v-if="loading"></dcLoading>
    
    <div v-else-if="!loading" class="container">
      <div class="row">
        <div class="col-sm-9">
          <form name="forms" role="form" novalidate>
            <div class="form-group row">
              <div class="col-sm-6 col-md-8" :class="{'form-group--error': $v.course.name.$error }">
                <label for="course-name">Course Name</label>
                <input type="text" v-model="course.name" @input="validateInput('name')" class="form-control" id="course-name" placeholder="Enter Course Name, e.g. Drivers Ed July 2020" :readonly="courseStarted" :disabled="courseStarted">
              </div>
              <div class="col-sm-6 col-md-4" :class="{'form-group--error': $v.course.type.$error }">
                <label for="course-type">Course Type</label>
                <select v-if="options.courseTypes.length" v-model="course.type" :readonly="courseStarted" :disabled="courseStarted" class="form-control" id="course-type">
                  <option v-for="(type) in options.courseTypes" :value="type.id" :key="type.id">
                    {{ type.name }}
                  </option>
                </select>
                <p v-else>Please create a course type <a @click="handleLinkClick('drivecoach.admin.settings.policies.courses')">in your settings</a></p>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-4" :class="{'form-group--error': $v.course.number_of_seats.$error }">
                <label for="course-number-of-seats">Number of Students</label>
                <input type="number" min="0" v-model="course.number_of_seats" @input="validateInput('number_of_seats')" :readonly="courseStarted" :disabled="courseStarted" class="form-control" id="course-number-of-seats" placeholder="10">
              </div>
              <div class="col-sm-4" :class="{'form-group--error': $v.course.location.$error }">
                <label for="course-location">Location</label>
                <select v-model="course.location" :readonly="courseStarted" :disabled="courseStarted" class="form-control" id="course-location" @change="handleLocationChange($event)">
                  <option v-for="(location) in options.locations" :value="location.id" :key="location.id">
                    {{ location.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4" :class="{'form-group--error': $v.course.classroom.$error }">
                <label for="course-classroom">Classroom</label>
                <select v-if="options.classrooms.length" v-model="course.classroom" class="form-control" id="course-classroom" :readonly="courseStarted" :disabled="courseStarted">
                  <option v-for="(classroom) in options.classrooms" :value="classroom.id" :key="classroom.id">
                    {{ classroom.name }}
                  </option>
                </select>
                <p v-else>Please add a classroom for this location</p>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <label for="course-description">Course Description</label>
                <dcTextEditor :content="course.description" @editor-content-change="handleEditorContentChange($event, 'description')" :placeholder="'Driving School - Alpharetta'" :readOnly="courseStarted" :disabled="courseStarted"></dcTextEditor>
              </div>
            </div>

            <hr>
            <div class="form-group row flex align-items-end" v-for="(classDate, index) in course.classes" :key="classDate.id">
              <div class="col-sm-3">
                <label for="">Class Date</label>
                <dcDatepicker :picker-datetime="classDate.date" v-model="classDate.date" :readonly="courseStarted" :disabled="courseStarted"></dcDatepicker>
              </div>
              <div class="col-sm-3">
                <label for="">Start time</label>
                <dcDatepicker picker-type="time" :picker-datetime="classDate.startTime" :picker-max-time="classDate.endTime" v-model="classDate.startTime" :readonly="courseStarted" :disabled="courseStarted"></dcDatepicker>
              </div>
              <div class="col-sm-3">
                <label for="">End time</label>
                <dcDatepicker picker-type="time" :picker-datetime="classDate.endTime" :picker-min-time="classDate.startTime" v-model="classDate.endTime" :readonly="courseStarted" :disabled="courseStarted"></dcDatepicker>
              </div>
              <div class="col-sm-3">
                <label for="">Instructor</label>
                <select v-model="classDate.instructor" class="form-control" :readonly="courseStarted" :disabled="courseStarted">
                  <option v-for="(instructor) in options.instructors" :value="instructor.id" :key="instructor.id">
                    {{ instructor.fullname }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <button class="btn btn-red btn-block" :disabled="(courseStarted) || (course.classes.length <=1 && index === 0)" type="button" @click="removeClassDate(index)" :title="getTitle(index)">Remove</button>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <button class="btn btn-blue btn-block" type="button" @click="addClassDate" :disabled="courseStarted">Add Additional Class Date</button>
              </div>
            </div>

          </form>

        </div>

        <div class="col-sm-3">
          <dcCoursePreview :course="coursePreview" :type="'edit-course'" :formInvalid="$v.$invalid" :errors="$v.course" @submit="submit" @emit-event="emitEvent($event.eventName, $event.dataObject)" :buttonDisabled="courseStarted"></dcCoursePreview>
        </div>

      </div>
    </div>

  </section>
</template>

<script>
  import moment from 'moment'
  import cloneDeep from 'lodash/cloneDeep'
  
  import dcDatepicker from '../../../common/directives/vue-directives/datepicker/Datepicker.vue'
  import dcTextEditor from '../../../common/directives/vue-directives/text-editor/TextEditor.vue'
  import dcLoading from '../../../common/directives/vue-directives/loading/Loading.vue'
  import dcCoursePreview from '../components/CoursePreview.vue'
  
  //form validation
  import { validationMixin } from 'vuelidate'
  import { required, minLength, minValue } from 'vuelidate/lib/validators'

  //validation helpers
  const isDate = (value) => value instanceof Date;

  export default {
    name: 'dcEditCourse',
    components: {
      dcDatepicker, dcCoursePreview, dcTextEditor, dcLoading
    },
    mixins: [validationMixin],
    props: {
      currentCourse: { type: Object, default: () => {} },
      courseTypes: { type: Array, default: () => [] },
      locations: { type: Array, default: () => [] },
      classrooms: { type: Array, default: () => [] },
      instructors: { type: Array, default: () => {} },
      schoolId: { required: true },
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        today: new Date(),
        course: {
          name: '',
          type: null,
          number_of_seats: 1,
          location: null, 
          classroom: null,
          description: '',
          classes: [],
          school_id: null,
          id: null
        }
      }
    },
    validations: {
      course: {
        name: { required, minLength: minLength(3) },
        type: { required },
        number_of_seats: { required },
        location: { required, minLength: minLength(1) },
        classroom: { required, minLength: minLength(1) },
        description: { minLength: minLength(10) },
        classes: {
          $each: {
            date: { required },
            startTime: { required },
            endTime: { required },
            instructor: { required, minLength: minLength(1) }
          }
        }
      }
    },
    methods: {
      addClassDate: function() {
        let classesLength = this.course.classes.length;
        let classes = this.course.classes;
        const instructor = this.course.instructor;

        if(classesLength > 0) {
          //set initial info to have value of last added class period
          let date = cloneDeep(classes[classesLength-1].date);
          date = new Date(date);
          
          //add 1 day 
          date.setDate(date.getDate() + 1)
          classes.push({
            date: date,
            instructor: classes[classesLength-1].instructor,
            startTime: classes[classesLength-1].startTime,
            endTime: classes[classesLength-1].endTime,
          })
        } else {
          classes.push({
            date: new Date(),
            instructor: this.options.instructors[0].id,
            startTime: moment().set({hour:9,minute:0, second:0 }).toDate(),
            endTime: moment().set({hour:16,minute:0, second:0}).toDate(),
          })
        }
      },
      emitEvent: function(eventName, dataObject) {
        this.$emit(eventName, dataObject)
      },
      getTitle: function(index) {
        if(index === 0 && this.course.classes.length <= 1) {
          return 'You must have at least one Class Date in your Course.';
        } else return false;
      },
      handleEditorContentChange: function(value, key) {
        this.course[key] = value.html;
        this.validateInput(key);
      },
      handleLinkClick: function(destination) {
        this.$emit('link-click', destination);
      },
      handleLocationChange: function(event) {
        let location_id = event.target.value;
        this.$emit('location-change', location_id);
      },
      removeClassDate: function(index) {
        this.course.classes.splice(index, 1);
      },
      validateInput(key) {
        this.$v.course[key].$touch();
      },
      submit: function() {
        if (this.$v.$invalid) {
          return;
        }
        
        let course = cloneDeep(this.course);
        course.location = { id: this.course.location }
        course.classroom = { id: this.course.classroom }
        course.number_of_seats = parseInt(course.number_of_seats);

        if (!course.description || course.description === "") {
          delete course.description;
        }

        course.type = this.course.type;

        course.formattedDate = moment(course.classes[0].date).format('YYYY-MM-DD');

        course.formattedTime = moment(course.classes[0].startTime).format('HH:mm:ss');

        let date = moment(course.classes[0].date).format('YYYY-MM-DD');
        let startTime = moment(course.classes[0].startTime).format('HH:mm:ss');
        let endTime = moment(course.classes[0].endTime).format('HH:mm:ss');

        let start = moment(date + " " + startTime);
        let end = moment(date + " " + endTime);

        let duration = moment.duration(end.diff(start));
        course.duration = { 
          hours: duration.hours(),
          minutes: duration.minutes()                 
        }

        course.classes = course.classes.map((el) => {
          let classDate = {};
          let date = moment(el.date).format('YYYY-MM-DD');
          let startTime = moment(el.startTime).format('HH:mm:ss');
          let endTime = moment(el.endTime).format('HH:mm:ss');

          classDate.start = date + " " + startTime;
          classDate.end = date + " " + endTime;
          classDate.instructor_id = el.instructor;
          if(el.id) {
            classDate.id = el.id;
          }

          return classDate;
        });

        this.$emit('submit', course);
        
      },
    },
    computed: {
      studentsAttached: function() {
        return this.currentCourse.students ? this.currentCourse.students.length > 0 : false;
      },
      courseStarted: function() {
        if (this.course.classes.length > 0){
          let start = moment(this.course.classes[0].startTime);
          let now = moment();

          return now.isAfter(start);
        }

        return false;
      },
      coursePreview: function() {
        let course = this.course;
        
        return {
          name: course.name,
          type: this.selectedCourseType ? this.selectedCourseType.name : '',
          number_of_seats: course.number_of_seats,
          location: this.selectedLocation ? this.selectedLocation.name : '', 
          classroom: this.selectedClassroom ? this.selectedClassroom.name : '',
          description: course.description,
          classes: this.previewClasses
        }
      },
      options: function() {
        return {
          courseTypes: this.courseTypes,
          locations: this.locations,
          classrooms: this.classrooms,
          instructors: this.instructors,
        }
      },
      selectedLocation: function() { return this.locations ? this.locations.find(location => location.id === this.course.location) : null },
      selectedCourseType: function() { return this.courseTypes ? this.courseTypes.find(type => type.id === this.course.type) : null },
      selectedClassroom: function() { return this.classrooms ? this.classrooms.find(classroom => classroom.id === this.course.classroom) : null },
      formattedClasses: function() {
        //formats classes passed from backend
        if (this.currentCourse.classes && this.currentCourse.classes.length) {
          return this.currentCourse.classes.map(classTime => {
            return {
              date: moment(classTime.start).toDate(),
              startTime: moment(classTime.start).toDate(),
              endTime: moment(classTime.end).toDate(),
              instructor: classTime.instructor.id,
              id: classTime.id
            }
          });
        }

        //old course format without class array
        let date = moment(this.currentCourse.date).format('MM/DD/YYYY');
        let startTime = moment(this.currentCourse.time).format('h:mm a');
        let endTime = moment(this.currentCourse.time).add('minutes', this.currentCourse.duration.minutes).add('hours', this.currentCourse.duration.hours).format('h:mm a');
        return [
          {
            date: moment(this.currentCourse.date).toDate(),
            startTime: moment(`${date} ${startTime}`).toDate(),
            endTime: moment(`${date} ${endTime}`).toDate(),
            instructor: this.currentCourse.instructor_id
          }
        ];                        
      },
      previewClasses: function() {
        if (this.course.classes && this.course.classes.length) {
          const classes = this.course.classes.map(classTime => {
            return {
              date: moment(classTime.date).format('M / D / YYYY'),
              startTime: moment(classTime.startTime).format('hh:mm A'),
              endTime: moment(classTime.endTime).format('hh:mm A'),
              instructor: this.instructors.find(instructor => instructor.id === classTime.instructor)
            }
          });
          return classes;
        }

        return [];
      }
    },
    watch: {
      currentCourse: {
        handler(val) {
          for (const key in this.course) {
            this.course[key] = this.currentCourse[key];
          }
          this.course.type = this.course.type.id;
          this.course.location = this.course.location.id;
          this.course.classroom = this.course.classroom.id;
          this.course.classes = this.formattedClasses;
          return this.course;
        },
        deep: true
      }
    }
    
  }

</script>

<style lang="scss" scoped>
  @import '../../../../assets/css/partials/admin-courses.scss'
</style>
