"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LoginService = /** @class */ (function () {
    function LoginService($q, $state, AuthService, FlashService, UserService, $rootScope, ENV) {
        this.submitted = false;
        this.$q = $q;
        this.$state = $state;
        this.AuthService = AuthService;
        this.FlashService = FlashService;
        this.UserService = UserService;
        this.$rootScope = $rootScope;
        this.ENV = ENV;
    }
    LoginService.prototype.setSubmitted = function (submitted) {
        this.submitted = submitted;
    };
    LoginService.prototype.activateFullStory = function () {
        if (this.ENV === 'production' && window.FS) {
            var user = this.UserService.getCurrentUser();
            FS.identify(user.id, {
                displayName: user.firstname + ' ' + user.lastname,
                email: user.email,
                username: user.username,
                school_id: user.school.id,
                schoolName_str: user.school.name,
            });
        }
    };
    LoginService.prototype.activateIntercom = function () {
        // Intercom
        if (this.ENV === 'production') {
            var user = this.UserService.getCurrentUser();
            window.intercomSettings = {
                app_id: 'zyog25by',
                name: user.firstname + ' ' + user.lastname,
                email: user.email,
                username: user.username,
                user_id: user.id,
                school: user.school.name,
                user_hash: user.intercom_hash
            };
            window.Intercom('boot', {
                app_id: 'zyog25by',
            });
        }
    };
    /**
     * Log a user in
     * @param credentials
     */
    LoginService.prototype.login = function (credentials) {
        var _this = this;
        this.setSubmitted(true);
        this.AuthService.login(credentials)
            .then(function (user) {
            if (user.access_token) {
                _this.FlashService.setMessage({
                    type: 'success',
                    message: 'You have logged in.',
                });
                if (user.usertype_id === 1) {
                    _this.$state.go('drivecoach.student.dashboard');
                }
                if (user.usertype_id === 3) {
                    // instructor
                    _this.$state.go('drivecoach.instructor.dashboard');
                }
                if (user.usertype_id === 4 || user.usertype_id === 8) {
                    _this.activateIntercom();
                    _this.activateFullStory();
                    _this.$state.go('drivecoach.admin.dashboard');
                }
                if (user.usertype_id === 5 || user.usertype_id === 9) {
                    // admins & staff
                    _this.activateIntercom();
                    _this.activateFullStory();
                    _this.$state.go('drivecoach.admin.dashboard');
                }
                _this.setSubmitted(false);
            }
        })
            .catch(function (response) {
            _this.setSubmitted(false);
        });
    };
    LoginService.prototype.remindPassword = function (username) {
        var _this = this;
        return this.AuthService.remindPassword(username).then(function (response) {
            _this.FlashService.setMessage({
                type: 'success',
                message: response.message,
            });
            _this.$state.go('drivecoach.login');
        });
    };
    LoginService.prototype.resetPassword = function (data) {
        var _this = this;
        this.AuthService.resetPassword(data).then(function (response) {
            _this.FlashService.setMessage({
                type: 'success',
                message: response.message,
            });
            _this.$state.go('drivecoach.login');
        });
    };
    LoginService.prototype.logout = function (showMessage) {
        var _this = this;
        if (showMessage === void 0) { showMessage = true; }
        var defer = this.$q.defer();
        this.AuthService.logout().then(function (response) {
            _this.UserService.removeCurrentUser();
            _this.AuthService.setToken();
            _this.AuthService.setTokenExpiration();
            if (_this.ENV === 'production' && window.FS) {
                FS.clearUserCookie();
            }
            if (showMessage === true) {
                _this.FlashService.setMessage({
                    type: 'success',
                    message: 'You have logged out.',
                });
            }
            defer.resolve(response);
        });
        return defer.promise;
    };
    return LoginService;
}());
LoginService.$inject = [
    '$q',
    '$state',
    'AuthService',
    'FlashService',
    'UserService',
    '$rootScope',
    'ENV',
];
exports.default = LoginService;
