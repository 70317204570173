function InvitationsModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/invitations',
            UPDATE : APPURL + '/api/v1/invitations',
            CREATE : APPURL + '/api/v1/invitations'

        },
        tasks;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        tasks = extract( result );
        return tasks;
    }

    model.getInvitationByID = ( id ) => {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.get = () => {
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = ( request ) => {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.sendInvitation = ( request ) => {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.destroy = ( task ) => {
        return $http.delete( URLS.FETCH + '/' + task.id ).then( cacheResults );
    }

}
InvitationsModel.$inject = ['$http', 'APPURL'];
export default InvitationsModel;
