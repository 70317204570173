import cloneDeep from "lodash/cloneDeep";
import {States} from "../../../../common/constants/states";
import { isJson } from "../../../../common/helpers/json";

class AdminStaffEditController {

    constructor($state, $stateParams, UsersModel, FlashService){
        this.submitted = false;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        let usermeta = {};
        this.statusOptions = [
            'Active',
            'Disabled'
        ];
        this.formData = {};
        this.editPassword = false;
        this.states = States;
        UsersModel.getUserByID($stateParams.staffId).then(response => {
            response.usermeta.forEach(meta => {
                usermeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
            });
            response.usermeta = usermeta;
            this.user = response;
        });
    }
    
    submit(isValid) {
        this.submitted = true;

        if (isValid) {
            let payload = cloneDeep(this.user);
            this.UsersModel.updateStaff(payload).then(response => {
                this.FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                this.$state.go('drivecoach.admin.staff.index');
                this.submitted = false;
            }).catch(Error => {
                this.submitted = false;
            });
        } else {
            this.FlashService.setMessage({'type': 'error', 'message': 'Please fill out the form.'});
            this.submitted = false;
        }
    }

}
AdminStaffEditController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService'];
export default AdminStaffEditController;