import {States} from "../../../../common/constants/states";

class AdminStaffCreateController {

    constructor(UserService, $state, UsersModel, FlashService){
        this.submitted = false;
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.states = States;

        this.currentUser = UserService.getCurrentUser();

        //TODO Get this from the api in the future
        this.roles = [
            {'label': 'Administrator', 'id': 5},
            {'label': 'Office staff', 'id': 9}
        ];

        if (this.currentUser.role.type === 'owner') {
            this.roles.push({
                'label': 'Owner', 'id': 4
            })
        }

        this.formData = {
            'school_id': this.currentUser.school_id
        };
    }
    
     createStaff(isValid) {
        this.submitted = true;

        if (isValid) {
            this.UsersModel.createStaff(this.formData).then(response => {
                this.FlashService.setMessage({'type': 'success', 'message': response.message});
                this.formData = {
                    'school_id': this.currentUser.school_id
                };
                this.submitted = false;
                this.$state.go('drivecoach.admin.staff.index');
            }).catch(Error => {
                this.submitted = false;
            });
        } else {
            this.FlashService.setMessage({'type': 'error', 'message': 'Please fill the form.'});
            this.submitted = false;
        }
    }

}

AdminStaffCreateController.$inject = ['UserService', '$state', 'UsersModel', 'FlashService'];
export default AdminStaffCreateController;