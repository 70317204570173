import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import FileUpload from './FileUpload.vue'

export default angular.module( 'directives.fileupload', [] )
  .directive('dcFileUpload', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcFileUpload', FileUpload))
  }])
