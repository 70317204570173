export function saveToDisk(fileURL, fileName) {
    if (!window.ActiveXObject) {
        let save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        save.download = fileName || 'unknown';

        let event = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false
        });
        save.dispatchEvent(event);

        (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
        let _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL);
        _window.close();
    }
}