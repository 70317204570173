import moment from 'moment'
function AdminSettingsRunPayrollController(PayrollModel) {
    let vm = this;
    vm.runPayroll = runPayroll;
    vm.updateFromDate = updateFromDate;
    vm.updateToDate = updateToDate;

    function runPayroll() {
        vm.loading = true;
        PayrollModel.runPayroll({
            'from': moment(vm.from).format('YYYY-MM-DD'),
            'to': moment(vm.to).format('YYYY-MM-DD')
        }).then(function (response) {
            vm.loading = false;
            saveToDisk(response.file.url, response.file.name);
        })
    }

    // TODO: Refactor to a service or something, this is also in the admin student reportcard view
    function saveToDisk(fileURL, fileName) {
        if (!window.ActiveXObject) {
            let save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName || 'unknown';

            let evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);

            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            let _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }
  
    function updateFromDate(newDate) {
      vm.from = newDate.selected[0]
    }
  
    function updateToDate(newDate) {
      vm.to = newDate.selected[0]
    }

}

AdminSettingsRunPayrollController.$inject = ['PayrollModel'];
export default AdminSettingsRunPayrollController;
