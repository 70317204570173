import angular from 'angular'
import controller from './online-drivers-ed.controller'
import template from './online-drivers-ed.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.onlinedriversed', {
            'url' : '/online-drivers-ed',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.onlinedriversed', [] )
    .config(config)
    .controller( 'StudentOnlineDriversEdController', controller )
;
