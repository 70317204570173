<template>
    <section>
        <p v-if="emails.length === 0">No email activity for this user.</p>
        <table v-if="emails.length > 0" class="table table-bordered table-striped table-hover">
            <thead>
                <tr>
                    <th>Subject</th>
                    <th>Date Sent</th>
                    <th>Times Opened</th>
                    <th>State</th>
                </tr>
            </thead>
            <tbody>
            <tr v-bind:key="email.ts" v-for="email in emails">
                <td>{{ email.subject }}</td>
                <td>{{ formatTimestamp(email.ts) }}</td>
                <td>{{ email.opens }}</td>
                <td>{{ email.state }}</td>
            </tr>
            </tbody>
        </table>
    </section>
</template>
<script>
export default {
  name: 'email-template',

  props: {
    'emails': { type: Array, default: () => []},
  },
  data() {
    return {
    }
  },
  methods: {
      viewContent(email){
        //   console.log(email)
      },
      formatTimestamp(timestamp) {
          const date = new Date(timestamp * 1000)
          return date.toLocaleDateString();
      }
  },
  computed: {
    
  }
}
</script>