"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var CoursesModel = /** @class */ (function () {
    function CoursesModel($http, APPURL) {
        this.$http = $http;
        this.APPURL = APPURL;
        this.URLS = {
            FETCH: APPURL + '/api/v1/courses',
            UPDATE: APPURL + '/api/v1/courses',
            CREATE: APPURL + '/api/v1/courses'
        };
        this.data = [];
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    CoursesModel.prototype.extract = function (result) {
        return result.data;
    };
    CoursesModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    CoursesModel.prototype.show = function (id) {
        return this.$http.get(this.URLS.FETCH + '/' + id).then(this.cacheResults);
    };
    CoursesModel.prototype.get = function (data) {
        if (data) {
            return this.$http.get(this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    CoursesModel.prototype.update = function (request) {
        return this.$http.put(this.URLS.FETCH + '/' + request.id, request).then(this.cacheResults);
    };
    CoursesModel.prototype.create = function (request) {
        return this.$http.post(this.URLS.FETCH, request).then(this.cacheResults);
    };
    ;
    CoursesModel.prototype.delete = function (data) {
        return this.$http.delete(this.URLS.FETCH + '/' + data.id).then(this.cacheResults);
    };
    ;
    CoursesModel.prototype.search = function (params) {
        return this.$http.post(this.URLS.FETCH + '/search', params).then(this.cacheResults);
    };
    ;
    CoursesModel.prototype.batchCreate = function (courses) {
        return this.$http.post(this.URLS.CREATE + '/batch', courses).then(this.cacheResults);
    };
    ;
    CoursesModel.prototype.getCourseStudents = function (courseId) {
        return this.$http.get(this.URLS.FETCH + '/' + courseId + '/students').then(this.cacheResults);
    };
    CoursesModel.prototype.getCourseClassDateStudents = function (course_id, class_date_id) {
        return this.$http.get(this.URLS.FETCH + '/' + course_id + '/classes/' + class_date_id + '/students').then(this.cacheResults);
    };
    CoursesModel.prototype.addStudentToCourse = function (courseId, studentId, classDates) {
        return this.$http.post(this.URLS.CREATE + '/' + courseId + '/students', { student_id: studentId, class_dates: classDates }).then(this.cacheResults);
    };
    CoursesModel.prototype.removeStudentFromCourse = function (courseId, studentId) {
        return this.$http.delete(this.URLS.FETCH + '/' + courseId + '/students/' + studentId).then(this.cacheResults);
    };
    CoursesModel.prototype.printRoster = function (data) {
        if (data.class_id) {
            return this.$http.get(this.URLS.FETCH + '/' + data.id + '/print?class_id=' + data.class_id).then(this.cacheResults);
        }
        return this.$http.get(this.URLS.FETCH + '/' + data.id + '/print').then(this.cacheResults);
    };
    CoursesModel.prototype.notifyStudents = function (data) {
        return this.$http.post(this.URLS.FETCH + '/' + data.course_id + '/notify-students', {
            subject: data.subject,
            body: data.body,
            to: data.to
        }).then(this.cacheResults);
    };
    CoursesModel.prototype.updateRosterRow = function (student_id, course_id, received_contract) {
        var req = {
            "student_id": student_id,
            "course_id": course_id,
            "received_contract": received_contract === 1 ? "1" : "0"
        };
        return this.$http.patch("".concat(this.URLS.FETCH, "/").concat(course_id, "/students/").concat(student_id), req).then(this.cacheResults);
    };
    CoursesModel.prototype.getCourseAttendance = function (courseId) {
        return this.$http.get(this.URLS.FETCH + '/' + courseId + '/attendance').then(this.cacheResults);
    };
    CoursesModel.prototype.getCourseClassAttendance = function (course_id, class_id) {
        return this.$http.get(this.URLS.FETCH + '/' + course_id + '/attendance/' + class_id).then(this.cacheResults);
    };
    CoursesModel.prototype.updateCourseAttendance = function (data) {
        return this.$http.put(this.URLS.UPDATE + '/' + data.courseId + '/attendance', data.students).then(this.cacheResults);
    };
    CoursesModel.prototype.updateCourseClassAttendance = function (data) {
        return this.$http.put(this.URLS.UPDATE + '/' + data.courseId + '/attendance/' + data.class_id, data.students).then(this.cacheResults);
    };
    CoursesModel.prototype.getClassDates = function (course_id) {
        return this.$http.get(this.URLS.FETCH + '/' + course_id + '/classes').then(this.cacheResults);
    };
    CoursesModel.prototype.getClassDate = function (course_id, class_id) {
        return this.$http.get(this.URLS.FETCH + '/' + course_id + '/classes/' + class_id).then(this.cacheResults);
    };
    return CoursesModel;
}());
CoursesModel.$inject = ['$http', 'APPURL'];
exports.default = CoursesModel;
