import { percentageOfXInCents } from '../../DriveScout/Billing/Teller';
import {isJson} from '../common/helpers/json'
import { Product } from './Product';
import {Service} from './Service'

class LineItem {

    constructor(data){
        this.$originalData = data;
        let {allow_partial_payment, deposit_required, deposit_type, 
            deposit_amount, minimum_payment_amount, description, price, product_name, order_id, product_details} = data;
        this.$order_id = order_id;
        this.$product_name = product_name;
        this.$price = price;
        this.$description = description;

        this.$allow_partial_payment = allow_partial_payment || false;
        this.$deposit_required = deposit_required || null;
        this.$deposit_type = deposit_type || null;
        this.$deposit_amount = deposit_amount || null;
        this.$minimum_payment_amount = minimum_payment_amount || null;

        // not the best way to do this, but right now surcharges product details are strings.
        // otherwise, product_details is a json string containing the product this line-item referred to
        // at the time of purchase. We're going to use this to instantiate the corresponding product for this
        product_details = isJson(product_details) ? JSON.parse(product_details) : product_details;
        this.$productDetails = product_details;
        
        if (typeof product_details !== 'string') {
            this.$product = new Product(this.$productDetails);
        } else {
            this.$product = new Product({
                id: null,
                name: 'Surcharge/Processing Fee',
                description: product_details,
                created_at: this.$originalData.created_at,
                price: price
            })
        }

        if (this.$productDetails.hasOwnProperty('sessiontypes')) {
            this.$services = this.$productDetails.sessiontypes.map(service => new Service(service))
            this.$product.setServices(this.$services);
        } else {
            this.$services = [];
        }
    }

    isProcessingFee()
    {
        return this.$product_name === 'Processing Fee';
    }

    price(){
        return this.$price;
    }

    product(){
        return this.$product;
    }

    description(){
        return this.$description;
    }

    productName(){
        return this.$product_name;
    }

    productDetails()
    {
        return this.$productDetails;
    }

    /**
     * The services sold in this line item
     */
    services()
    {
        return this.$services;
    }

    allowsPayLater()
    {
        return this.$allow_partial_payment
    }

    requiresDeposit()
    {
        return this.$deposit_required;
    }

    depositType()
    {
        return this.$deposit_type;
    }

    depositAmount()
    {
        return this.$deposit_amount;
    }

    minimumPaymentAmount()
    {
        return this.$minimum_payment_amount;
    }


    calculateMinimumDepositRequired(amount)
    {
        if (this.allowsPayLater() === false) {
            return amount;
        }

        if (this.requiresDeposit() === false) {
            return 0;
        }

        if (this.depositType() === 'fixed') {
            amount = this.depositAmount();
        }
        if (this.depositType() === 'percentage') {
            amount = Math.round(parseFloat(amount * (this.depositAmount() / 100)));
        }

        return amount;
    }

    calculateMinimumPaymentRequired(amount)
    {
        if (this.isProcessingFee()) {
            return 0;
        }

        if (this.allowsPayLater() === false) {
            return amount;
        }

        if (this.depositType() === 'fixed') {
            amount = this.minimumPaymentAmount();
        }
        if (this.depositType() === 'percentage') {
            amount = percentageOfXInCents(amount, this.minimumPaymentAmount());
        }

        return amount;
    }
}

export {LineItem}