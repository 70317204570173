import moment from 'moment'
function InstructorLogHoursController( $state, UsersModel, HoursLogModel, FlashService, UserService ) {

    const vm = this;
    let user = UserService.getCurrentUser();
    vm.formData = {
        'date' : new Date(),
        'user_id' : user.id,
        'hours' : ''
    };

    vm.submitHours = submitHours;
    vm.updateDate = updateDate;

    init();

    function init(){
        UsersModel.getInstructorByID( user.id ).then( function ( response ) {
            vm.instructor = response;
            vm.loggedhours = response.loggedhours;
        } );
    }

    function submitHours( isValid ) {
        vm.submitted = true;

        if ( isValid ) {
            let data = Object.assign({}, vm.formData);
            data.date = moment( vm.formData.date ).format( 'YYYY-MM-DD' );
            HoursLogModel.create( data ).then( response => {
                FlashService.setMessage( {
                    'type' : 'success',
                    'message' : response.message
                } );
                vm.submitted = false;
                $state.go( 'drivecoach.instructor.dashboard' );
            } );
        } else {
            FlashService.setMessage( { 'type' : 'error', 'message' : 'Fill in the required fields.' } );
        }
    }
  
    function updateDate(newDate) {
      this.formData.date = newDate.selected[0]
    }

}
InstructorLogHoursController.$inject = [ '$state', 'UsersModel', 'HoursLogModel', 'FlashService', 'UserService'];
export default InstructorLogHoursController;