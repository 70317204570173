import angular from 'angular'
import template from './admin-batch-create-events.html'
import AdminEventsBatchCreateController from './admin-batch-create-events.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.events.batch', {
            'url' : '/batch-create',
            'controller' : AdminEventsBatchCreateController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )

    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.events.batch', [] )
    .config(config)
    .controller( 'AdminEventsBatchCreateController', AdminEventsBatchCreateController )
;
