"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var saveToDisk_1 = require("../../../common/helpers/saveToDisk");
var json_1 = require("../../../common/helpers/json");
var AdminViewCourseController = /** @class */ (function () {
    function AdminViewCourseController($state, CoursesModel, course, UsersModel, FlashService, $window, PoliciesModel, VueStoreFactory) {
        this.CoursesModel = CoursesModel;
        this.UsersModel = UsersModel;
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;
        this.VueStoreFactory = VueStoreFactory;
        this.$state = $state;
        this.course = course;
        this.$window = $window;
        this.searchableStudents = [];
        this.submitSuccess = false;
        //used to trigger vue modal inside vue component
        this.loading = true;
        this.printing = false;
        this.getStudents = this.getStudents.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.handleAddStudent = this.handleAddStudent.bind(this);
        this.handleRemoveStudent = this.handleRemoveStudent.bind(this);
        this.handleSaveRoster = this.handleSaveRoster.bind(this);
        this.searchStudents = this.searchStudents.bind(this);
        this.print = this.print.bind(this);
        this.deleteCourse = this.deleteCourse.bind(this);
        this.sendCourseEmail = this.sendCourseEmail.bind(this);
        this.setCourseClassDate = this.setCourseClassDate.bind(this);
        this.policies = {};
        this.setup();
    }
    AdminViewCourseController.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var policiesResponse, policy, policyMeta;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.PoliciesModel.getByID('courses')];
                    case 1:
                        policiesResponse = _a.sent();
                        policy = policiesResponse.policy;
                        this.policies['courses'] = { 'id': policy.id };
                        policyMeta = {};
                        policy.policymeta.forEach(function (meta) {
                            policyMeta[meta.meta_key] = (0, json_1.isJson)(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
                        });
                        this.policies['courses'].policymeta = policyMeta;
                        //@ts-ignore
                        this.course.policies = this.policies.courses; // this is going to go in vuex so we can ignore the ts error
                        return [4 /*yield*/, this.getStudents(this.course.id)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminViewCourseController.prototype.setCourseClassDate = function (classDate) {
        var _this = this;
        return this.CoursesModel.getCourseClassDateStudents(this.course.id, classDate.id).then(function (response) {
            _this.course.students = response.students;
            _this.loading = false;
        }).catch(function (Error) {
            console.error(Error);
            _this.loading = false;
        });
    };
    AdminViewCourseController.prototype.getStudents = function (courseId) {
        var _this = this;
        var class_id = this.VueStoreFactory.state.viewingCourse.class_id;
        if (class_id !== 0) {
            return this.CoursesModel.getCourseClassDateStudents(this.course.id, class_id).then(function (response) {
                _this.course.students = response.students;
                _this.loading = false;
            }).catch(function (Error) {
                console.error(Error);
                _this.loading = false;
            });
        }
        return this.CoursesModel.getCourseStudents(courseId).then(function (response) {
            _this.course.students = response.students;
            _this.loading = false;
        }).catch(function (error) {
            console.log('error', error);
            _this.loading = false;
        });
    };
    AdminViewCourseController.prototype.handleAddStudent = function (data) {
        var _this = this;
        var student = data.student;
        var classes = data.classes;
        this.submitSuccess = false;
        this.submitted = true;
        var classDates = classes.map(function (classDate) {
            return { id: classDate.id };
        });
        //student object has keys id and name
        this.CoursesModel.addStudentToCourse(this.course.id, student.id, classDates).then(function (response) {
            // console.log('response', response)
            _this.getStudents(_this.course.id);
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            _this.submitted = false;
            _this.submitSuccess = true;
            _this.searchableStudents = [];
        }).catch(function (error) {
            console.log('error', error);
            _this.submitted = false;
        });
    };
    AdminViewCourseController.prototype.handleRemoveStudent = function (student) {
        var _this = this;
        var class_id = this.VueStoreFactory.state.viewingCourse.class_id;
        if (class_id !== 0) {
            var classDate = this.VueStoreFactory.state.viewingCourse.date;
            var classStart = this.VueStoreFactory.state.viewingCourse.start;
            var classEnd = this.VueStoreFactory.state.viewingCourse.end;
            var confirmDelete_1 = this.$window.confirm('Are you sure you want to remove ' + student.fullname + ' from the class on ' + classDate + ' from ' + classStart + ' to ' + classEnd + '?');
            if (!confirmDelete_1) {
                return;
            }
            return this.UsersModel.cancelStudentClass(student.id, class_id).then(function (response) {
                _this.getStudents(_this.course.id);
                _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
                _this.submitted = false;
            }).catch(function (Error) {
                console.error(Error);
                _this.loading = false;
            });
        }
        var confirmDelete = this.$window.confirm('Are you sure you want to remove ' + student.fullname + ' from the course?');
        if (!confirmDelete) {
            return;
        }
        this.CoursesModel.removeStudentFromCourse(this.course.id, student.id).then(function (response) {
            // console.log('response', response)
            _this.getStudents(_this.course.id);
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            _this.submitted = false;
        }).catch(function (error) {
            console.log('error', error);
            _this.submitted = false;
        });
    };
    AdminViewCourseController.prototype.handleSaveRoster = function (data) {
        var _this = this;
        this.CoursesModel.updateRosterRow(data.student_id, data.course_id, data.received_contract).then(function (response) {
            _this.getStudents(_this.course.id);
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            _this.submitted = false;
        }).catch(function (error) {
            console.log('error', error);
            _this.submitted = false;
        });
    };
    AdminViewCourseController.prototype.sendCourseEmail = function (data) {
        var _this = this;
        this.emailSent = false;
        this.CoursesModel.notifyStudents(data).then(function (response) {
            _this.emailSent = true;
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        }).catch(function (Error) {
            console.error(Error);
        });
    };
    AdminViewCourseController.prototype.handleLinkClick = function (data) {
        //data object contains destination which is a string value and params object
        this.$state.go(data.destination, data.params);
    };
    AdminViewCourseController.prototype.searchStudents = function (search) {
        var _this = this;
        //used in vue-select component to add student
        if (this.searchingStudents === true) {
            return;
        }
        var searchParams = {
            name: ""
        };
        this.searchingStudents = false;
        this.FlashService.clearFlash();
        if (search && search.length >= 3 && !this.searchingStudents) {
            searchParams.name = search;
        }
        if (searchParams.name === "") {
            return;
        }
        this.searchingStudents = true;
        this.UsersModel.searchStudentsByName(searchParams).then(function (response) {
            _this.searchableStudents = response.students.map(function (student) {
                return {
                    id: student.id,
                    name: student.fullname
                };
            });
            _this.searchingStudents = false;
        });
    };
    AdminViewCourseController.prototype.print = function () {
        var _this = this;
        this.printing = true;
        this.CoursesModel.printRoster({ 'id': this.course.id, 'class_id': this.VueStoreFactory.state.viewingCourse.class_id }).then(function (response) {
            _this.printing = false;
            (0, saveToDisk_1.saveToDisk)(response.file.url, response.file.name);
        });
    };
    AdminViewCourseController.prototype.deleteCourse = function () {
        var _this = this;
        this.CoursesModel.delete({ id: this.course.id }).then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message
            });
            _this.submitted = false;
            _this.$state.go('drivecoach.admin.calendar');
        })
            .catch(function (error) {
            console.error(error);
            _this.submitted = false;
        });
    };
    return AdminViewCourseController;
}());
AdminViewCourseController.$inject = ['$state', 'CoursesModel', 'course', 'UsersModel', 'FlashService', '$window', 'PoliciesModel', 'VueStoreFactory'];
exports.default = AdminViewCourseController;
