"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var IntegrationsModel = /** @class */ (function () {
    function IntegrationsModel($http, APPURL) {
        var _this = this;
        this.show = function (id) {
            return _this.$http.get(_this.URLS.FETCH + "/" + id).then(_this.cacheResults);
        };
        this.get = function (data) {
            if (data === void 0) { data = null; }
            if (data !== null) {
                return _this.$http
                    .get(_this.URLS.FETCH + "?" + (0, queryString_1.toQueryString)(data))
                    .then(_this.cacheResults);
            }
            return _this.$http.get(_this.URLS.FETCH).then(_this.cacheResults);
        };
        this.downloadWordPressPlugin = function () {
            return _this.$http.get(_this.URLS.FETCH + "/wordpress/download", { responseType: "blob" });
        };
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + "/api/v1/integrations",
        };
        this.lesson_plans = [];
        this.cacheResults = this.cacheResults.bind(this);
        this.extract = this.extract.bind(this);
    }
    IntegrationsModel.prototype.extract = function (result) {
        return result.data;
    };
    IntegrationsModel.prototype.cacheResults = function (result) {
        this.lesson_plans = this.extract(result);
        return this.lesson_plans;
    };
    return IntegrationsModel;
}());
IntegrationsModel.$inject = ["$http", "APPURL"];
exports.default = IntegrationsModel;
