import cloneDeep from 'lodash/cloneDeep'
class AdminSchoolsVehiclesEditController {

    constructor($q, $state, $stateParams, VehicleAPI, FlashService, VehiclesModel, SchoolsModel, $scope){
        this.$scope = $scope;
        this.school = null;
        this.vehicles = null;
        this.vehicleOptions = {
          makes: [],
          models: [],
          years: []
        }
        this.locations = null;
        this.vehicle = {};
        this.selectedMake = null;
        this.loading = true;

        this.FlashService = FlashService;
        this.VehiclesModel = VehiclesModel;
        this.VehicleAPI = VehicleAPI;
        this.SchoolsModel = SchoolsModel;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.submitting = false;

        this.handleChange = this.handleChange.bind(this);
        this.getModelsByYear = this.getModelsByYear.bind(this)
        this.editVehicle = this.editVehicle.bind(this);
        this.setup = this.setup.bind(this);
        this.processvehicle = this.processvehicle.bind(this);
        this.VehicleAPI.getModelsByYear = this.VehicleAPI.getModelsByYear.bind(this)
        this.VehicleAPI.getMakes = this.VehicleAPI.getMakes.bind(this)

        
        let getschool = SchoolsModel.getSchoolByID($stateParams.schoolId);
        let getvehicle = VehiclesModel.getVehicleByID($stateParams.vehicleId);
        $q.all([getschool, getvehicle]).then((res) => {
          this.setup(res).then(() => {
            this.$scope.$apply(() => this.loading = false)
          })
        });
    }
    
    processschool(response) {
        this.locations = response.locations;
        this.school = response;
        return this.school;
    }

    processvehicle(response) {
      this.vehicle = response.vehicle;
      this.vehicle.make = response.vehicle.make.toUpperCase()
      this.locations.forEach(location => {
          if (this.vehicle.location_id === location.id) {
              this.vehicle.location = location;
          }
      })
      
      this.vehicleOptions.years = []
      let years = this.VehicleAPI.getYears();
      this.vehicleOptions.years.push(...years)
      
      this.VehicleAPI.getMakes().then(response => {
        this.vehicleOptions.makes = [];
        this.vehicleOptions.makes.push(...response)
      })
      return this.getModelsByYear(this.vehicle).then((res) => {
        if(res.error) {
          return false;
        } else {
          this.vehicleOptions.models = [];
          this.vehicleOptions.models.push(...res);
        }
      });
    }

    async setup(data) {
        const school = await this.processschool(data[0]);
        const vehicle = await this.processvehicle(data[1]);
        return;
    }
  
    getModelsByYear(vehicle) {
      if(vehicle.year && vehicle.make) {
        this.vehicleOptions.models = []
        this.noModelsError = '';
        return this.VehicleAPI.getModelsByYear(vehicle.make, vehicle.year).then(result => {
          if(result.error) {
            this.$scope.$apply(() => this.noModelsError = result.error)
            return result;
          } else {
            return result;
          }
        });
      }
    }
  
    handleChange(vehicle) {
      this.getModelsByYear(vehicle).then((res) => {
        if(res.error) {
          return false;
        } else {
          this.$scope.$apply(() => {
            this.vehicleOptions.models = [];
            this.vehicleOptions.models.push(...res);
            this.vehicle.model = this.vehicleOptions.models[0]
          })
        }
      });
    }

    editVehicle() {
        this.vehicle.id = this.$stateParams.vehicleId;
        let vehicle = cloneDeep(this.vehicle);
        this.submitting = true;
        this.VehiclesModel.editVehicle(vehicle).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            this.vehicle = response.vehicle;
            this.submitting = false;
            this.$state.go('drivecoach.admin.schools.vehicles.index', {
                'schoolId': this.$stateParams.schoolId,
                'vehicleId': this.vehicle.id
            });
        }).catch(error => {
            this.submitting = false;
        });
    }

}

AdminSchoolsVehiclesEditController.$inject = ['$q', '$state', '$stateParams', 'VehicleAPI', 'FlashService', 'VehiclesModel', 'SchoolsModel', '$scope'];
export default AdminSchoolsVehiclesEditController;
