<template>
  <div>
    <p style="margin-bottom: 1em; text-align: center;">Passed: {{ final.final_results.finalPassed ? 'Yes' : 'No' }}</p>
    <p style="margin-bottom: 2em; text-align: center;">Final Score: {{ final.final_results.finalScore }}%</p>
    <button class="btn btn-dc btn-block" @click="viewFinalResults(final.final_results.finalQuestions)">View Test Results</button>
    
    <dcModal :modalShown="modalShown" :modalHeader="false" :modalClasses="['col-sm-12']" :modalCenter="true">
      <template #body>
        <div v-for="(question, index) in questions" :key="index" style="margin-bottom: 1em;">
            <p><strong>{{ question.question }}</strong></p>
            <p>Answered Correctly: {{ isCorrectAnswer(question, index) }}</p>
        </div>
      </template>
      <template #footer>
        <div class="form-group row">
          <div class="col-sm-12">
            <button type="button" class="btn btn-dc btn-block mb0" @click="hideModal">Close</button>
          </div>
        </div>
      </template>
    </dcModal>
  </div>
</template>
  
  <script>
import dcModal from '../../vue-directives/modal/Modal.vue'

export default {
  name: "dcOnlineDriverseEdFinal",
  components: {dcModal},
  props: {
    final: { type: Object, default: {} },
  },
  data() {
    return {
        modalShown: false,
        questions: [],
    }
  },
  methods: {
    viewFinalResults(questions){
        this.questions = questions;
        this.modalShown = true;
    },
    hideModal(){
        this.questions = [];
        this.modalShown = false;
    },
    isCorrectAnswer(question, index){
        return this.final.final_results.finalAnswers[(index + 1).toString()] ? 'Yes' : 'No'
    }
  }
};
</script>
