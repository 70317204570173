import axios from "axios";
import {Config} from "../../Config";

const HttpClient = axios.create({
  baseURL: Config.APPURL + '/api/v1',
  withCredentials: true,
  headers: {
   'Accept': 'application/json',
   'Content-Type': 'application/json',
  }
 })

const getAuthToken = () => localStorage.getItem('ds-token');

const authInterceptor = (config) => {
    if (getAuthToken() !== null) {
        config.headers['Authorization'] = 'Bearer ' + getAuthToken();
    }
    return config;
}

HttpClient.interceptors.request.use(authInterceptor);

export { HttpClient };