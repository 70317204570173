import angular from 'angular'
import template from './crm.html'
import CrmController from './crm.controller'
import Products from './products/admin-products.module'
import Coupons from './coupons/coupons.module'
import Leads from './leads/leads.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.crm', {
            url: '/crm',
            template: template,
            controller: CrmController,
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.crm', [
    Products.name,
    Coupons.name,
    Leads.name
]).config(config)
    .controller('CrmController', CrmController)
;
