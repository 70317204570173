import angular from 'angular'
import template from './grade-generic-report-card.html'
import controller from './grade-generic-report-card.controller'

function getStudent($stateParams, UsersModel ) {
    return UsersModel.getStudentByID($stateParams.studentId).then(response => {
        return response;
    });
}
getStudent.$inject = ["$stateParams", "UsersModel"];
function getTasks(TasksModel){
    return TasksModel.get().then(response => {
        return response.tasks;
    }) 
}
getTasks.$inject = ["TasksModel"];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.gradegenericreportcard', {
            'url' : '/:driveId/:studentId/grade-generic-report-card',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                tasks: getTasks,
                student: getStudent
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.gradegenericreportcard', [] )
    .config( config )
    .controller( 'GradeGenericReportCardController', controller)
;
