import angular from 'angular'
import VehicleAPI from './services/vehicles-api-service.service'
import AdminCreateDriveService from './services/admin-create-drive-service'
import LoginService from './services/login-service'
import FlashService from './services/flash-service'
import UserService from './services/user-service'
import AuthService from './services/auth-service.service'
import CalendarService from './services/calendar-service'
import DrivesFilterService from './services/drives-filter-service'
import SchoolService from './services/school-service'
import BatchCreateService from './services/batch-create-drive-service'
//import DriveCoachMap from './services/drivecoach-map-service'
import ExceptionHandler from './services/exception-handler.factory'
import AuthInterceptor from './interceptors/auth-interceptor.service'
import AdminTasksService from './services/admin-tasks-service'
import PayPalService from './services/paypal-service'
import store from "../../store";
import CreateOrderService from "../../app/admin/services/create-order-serivce"
import OrderDetailsService from './services/order-details-service';
import PolicyService from './services/policy-service';
import OpenReplayService from './services/open-replay-service'
// import { OpenReplayService } from './services/open-replay-service'

export const Services = angular.module( 'services', [] )
    .service( 'VehicleAPI', VehicleAPI )
    .service( 'AdminCreateDriveService', AdminCreateDriveService )
    .service( 'LoginService', LoginService)
    .service( 'FlashService', FlashService )
    .service( 'UserService', UserService )
    .service( 'AuthService', AuthService )
    .service( 'CalendarService', CalendarService )
    .service( 'DrivesFilterService', DrivesFilterService )
    .service( 'SchoolService', SchoolService )
    .service( 'BatchCreateService', BatchCreateService )
//    .service( 'DriveCoachMap', DriveCoachMap )
    // .factory('$exceptionHandler', ExceptionHandler)

    .service('CreateOrderService', CreateOrderService)
    .service( 'AdminTasksService', AdminTasksService)
    // .service('PusherService', PusherService)
    .service( 'PayPalService', PayPalService)
    .service( 'AuthInterceptor', AuthInterceptor )
    .service( 'VueStoreFactory', [() => store])
    .service( 'OrderDetailsService', OrderDetailsService )
    .service( 'PolicyService', PolicyService )
    .service( 'OpenReplayService', OpenReplayService);
