import angular from 'angular'

import template from './instructor-courses-index.html'
import InstructorCoursesIndexController from './instructor-courses-index.controller'

function getCourseTypes(CourseTypesModel){
  return CourseTypesModel.get().then(function(response){
    return response.courseTypes
  })
}
getCourseTypes.$inject = ['CourseTypesModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.courses', {
            url : '/courses',
            template : template,
            controller : InstructorCoursesIndexController,
            controllerAs : 'vm',
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            },
            resolve: { courseTypes: getCourseTypes }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.courses', [] )
    .config(config)
    .controller( 'InstructorCoursesIndexController', InstructorCoursesIndexController );
