import angular from 'angular'
import template from './admin-products.html'
import AdminProductsController from './admin-products.controller'
import Create from './create-product/create-product.module'
import Edit from './edit-product/edit-product.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.products', {
            url: '/products',
            template: template,
            controller: AdminProductsController,
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.crm.products', [
    Create.name,
    Edit.name,
]).config(config)
    .controller('AdminProductsController', AdminProductsController)
;
