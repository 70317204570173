import filter from 'lodash/filter'
class CompleteAccountModalInstanceController {

    constructor($scope, $uibModalInstance, student, $rootScope){
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        if (typeof student.guardian === 'undefined') {
            let guardian = filter(student.usermeta, (value, key) => {
                return key === 'guardian';
            });
            student.guardian = guardian[0];
        }
        this.student = student;
        this.close = this.close.bind(this);
        $rootScope.$on('STUDENT_SETUP_COMPLETE', this.close);
    }

    ok() {
        this.$uibModalInstance.close(this.$scope.selected.item);
    }

    close() {
        this.$uibModalInstance.close();
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
CompleteAccountModalInstanceController.$inject = ['$scope', '$uibModalInstance', 'student', '$rootScope'];

export default CompleteAccountModalInstanceController;