import template from './home.html'
import store from '../store'

function config( $stateProvider, $compileProvider, $urlRouterProvider, $httpProvider, $locationProvider, ENV, HTML_FIVE_MODE, $ngVueProvider ) {
    
    $ngVueProvider.setRootVueInstanceProps({
        store: store
    })

    $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    //$httpProvider.defaults.headers.post["Content-Type"] = 'application/x-www-form-urlencoded';

    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|file|sms|tel|mailto):/);

    $httpProvider.interceptors.push([
        '$injector',
        function ($injector) {
            return $injector.get('AuthInterceptor');
        }
    ]);
    if (ENV !== 'local' || HTML_FIVE_MODE === 'on') {
      $locationProvider.html5Mode(true);
    }
    $urlRouterProvider.when("", "/login");
    $urlRouterProvider.when("/", "/login");
    $stateProvider
        .state('drivecoach', {
            url: '',
            abstract: true,
            template: template,
            controller: 'MainController',
            controllerAs: 'vm'
        })
    ;
}
config.$inject = ['$stateProvider', '$compileProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider','ENV', 'HTML_FIVE_MODE', '$ngVueProvider'];
export default config;
