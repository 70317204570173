function AdminSchoolLocationViewVehiclesIndexController($state, $stateParams, LocationsModel, VehiclesModel) {

    let vm = this;
    vm.vehicles = [];

    LocationsModel.getLocationByID($stateParams.locationId)
        .then(function (response) {
            vm.location = response;
            vm.vehicles = response.vehicles;
        })
    ;

}
AdminSchoolLocationViewVehiclesIndexController.$inject = ['$state', '$stateParams', 'LocationsModel', 'VehiclesModel'];
export default AdminSchoolLocationViewVehiclesIndexController;