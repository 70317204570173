import { toQueryString } from "../helpers/queryString"
import {isJson} from "../helpers/json";
function ReportcardsModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/reportcards',
            UPDATE : APPURL + '/api/v1/reportcards',
            CREATE : APPURL + '/api/v1/reportcards'

        },
        reportcards;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        reportcards = extract( result );
        return reportcards;
    }

    model.getReportcardByID = (id) => {
        return $http.get(URLS.FETCH + '/' + id).then(cacheResults);
    };

    model.get = (data = null) => {
        if (data != null){
            return $http.get(URLS.FETCH + '?' + toQueryString(data)).then(cacheResults);
        }
        return $http.get(URLS.FETCH).then(cacheResults);
    };

    model.update = (request) => {
        return $http.put(URLS.FETCH + '/' + request.id, request).then(cacheResults);
    };

    model.create = (request) => {
        return $http.post(URLS.FETCH, request).then(cacheResults);
    };


    model.submitGrades = (data) => {
        return $http.post(URLS.FETCH + '/submit-grades', data).then(cacheResults);
    };

    model.submitPassFailGrade = (data) => {
        let payload = {'grade' : data.grade, 'drive_id' : data.drive_id, 'reportcard_id' : data.reportcard_id};
        return $http.post(URLS.FETCH + '/submit-grades', payload).then(cacheResults);
    };

    model.submitGenericGrade = (data) => {
        return $http.post(URLS.FETCH + '/submit-grades', data).then(cacheResults);
    };

    model.unlockReportCard = (reportcard_id) => {
        return $http.put(URLS.FETCH + '/' + reportcard_id + '/unlock').then(cacheResults);
    };

    model.destroy = (card) => {
        return $http.delete(URLS.FETCH + '/' + card.id).then(cacheResults);
    }

    model.destroyCollection = (reportcards) => {
        return $http.post(URLS.FETCH + '/delete-batch', reportcards).then(cacheResults);
    }

}
ReportcardsModel.$inject = ['$http', 'APPURL'];
export default ReportcardsModel;
