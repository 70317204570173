import angular from 'angular'
import template from './drive-session-types.html'
import dcDriveSessionTypesController from './drive-session-types.controller'
function dcDriveSessionTypes() {
    return {
        restrict : 'E',
        scope : {
            'canEdit' : '=',
            'canAdd' : '=',
            'driveSessionTypes' : '='
        },
        template : template,
        link :  (scope, element, attrs ) => {

        },
        controller: dcDriveSessionTypesController,
        controllerAs: 'vm',
        bindToController: true
    }
}
dcDriveSessionTypes.$inject = [];

export default angular.module( 'admin.directives.drivesessiontypes', [] )
    .controller( 'dcDriveSessionTypesController', dcDriveSessionTypesController )
    .directive( 'dcDriveSessionTypes', dcDriveSessionTypes )
;