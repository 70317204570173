import angular from 'angular'
import CalendarModel from './models/calendar-model'
import UsersModel from './models/users-model'
import UsermetaModel from './models/usermeta-model'
import TimeoffRequestsModel from './models/time-off-requests-model'
import SchoolsModel from './models/schools-model'
import LocationsModel from './models/locations-model'
import ClassroomsModel from './models/classrooms-model'
import CourseProductsModel from './models/course-products-model'
import CourseTypesModel from './models/course-types-model'
import CoursesModel from './models/courses-model'
import OrdersModel from './models/orders-model'
import LeadsModel from './models/leads-model'
import DriveSessionTypesModel from './models/drive-session-types-model'
import DrivesModel from './models/drives-model'
import EntitiesModel from './models/entities-model'
import EventsModel from './models/events-model'
import ProductsModel from './models/products-model'
import ProductCategoriesModel from './models/product-categories-model'
import FutureEntitiesRepository from './models/future-entities-model'
import HoursLogModel from './models/hourslog-model'
import ImportsModel from './models/imports-model'
import PayRatiosModel from './models/pay-ratios-model'
import PayrollModel from './models/payroll-model'
import PoliciesModel from './models/policies-model'
import ReportcardsTypesModel from './models/report-card-type-model'
import ReportcardsModel from './models/reportcards-model'
import ReportsModel from './models/reports-model'
import UsageReportModel from './models/usage-report-model'
import TasksModel from './models/tasks-model'
import InvitationsModel from './models/invitations-model'
import CouponsModel from './models/coupons-model'
import CrmTasksModel from './models/crm-tasks-model'
import VehiclesModel from './models/vehicles-model'
import ZonesModel from './models/zones-model'
import EmailTemplates from './models/email-templates'
import ExportsModel from './models/exports-model'
import LessonPlanModel from './models/lesson-plan-model'
import IntegrationsModel from './models/integrations-model'
import PostOfficeModel from './models/post-office-model'
import StudentUsermetaModel from './models/student-usermeta-model'
import PermitModel from './models/permit-models'
import FilesModel from './models/files-model'
import ReportBuilder from './models/report-builder'
import NotificationsModel from './models/notification-model'
import CancelationModel from './models/cancelation-model'
import OnlineDriversEd from './models/online-drivers-ed'
import CertificateModel from './models/student-certificate-model'
import SchoolSetupModel from './models/school-setup-model'
import FeeModel from './models/fee-model'
import GradeOptionModel from './models/grade-option-model.ts'
import ZipCodeModel from './models/zipcode-model'

export const Models = angular.module( 'models', [])
    .service( 'UsersModel', UsersModel )
    .service( 'SchoolsModel', SchoolsModel )
    .service( 'LocationsModel', LocationsModel )
    .service( 'UsermetaModel', UsermetaModel )
    .service( 'StudentUsermetaModel', StudentUsermetaModel)
    .service( 'TimeoffRequestsModel', TimeoffRequestsModel )
    .service( 'CalendarModel', CalendarModel )
    .service( 'ClassroomsModel', ClassroomsModel )
    .service( 'CourseProductsModel', CourseProductsModel )
    .service( 'CourseTypesModel', CourseTypesModel )
    .service('CoursesModel', CoursesModel)
    .service( 'DriveSessionTypesModel', DriveSessionTypesModel )
    .service( 'DrivesModel', DrivesModel )
    .service( 'EntitiesModel', EntitiesModel )
    .service( 'EventsModel', EventsModel )
    .service( 'FutureEntitiesRepository', FutureEntitiesRepository )
    .service( 'HoursLogModel', HoursLogModel )
    .service( 'ImportsModel', ImportsModel )
    .service( 'PayRatiosModel', PayRatiosModel )
    .service( 'PayrollModel', PayrollModel )
    .service( 'PoliciesModel', PoliciesModel )
    .service( 'ReportcardsTypesModel', ReportcardsTypesModel )
    .service( 'ReportcardsModel', ReportcardsModel )
    .service( 'ReportsModel', ReportsModel )
    .service( 'TasksModel', TasksModel )
    .service( 'CrmTasksModel', CrmTasksModel )
    .service( 'UsageReportModel', UsageReportModel )
    .service( 'ProductsModel', ProductsModel)
    .service( 'ProductCategoriesModel', ProductCategoriesModel)
    .service( 'OrdersModel', OrdersModel)
    .service( 'VehiclesModel', VehiclesModel )
    .service( 'ZonesModel', ZonesModel )
    .service( 'InvitationsModel', InvitationsModel)
    .service( 'CouponsModel', CouponsModel)
    .service( 'LeadsModel', LeadsModel)
    .service( 'EmailTemplates', EmailTemplates)
    .service( 'ExportsModel', ExportsModel)
    .service( 'LessonPlanModel', LessonPlanModel)
    .service( 'IntegrationsModel', IntegrationsModel)
    .service( 'PostOfficeModel', PostOfficeModel)
    .service( 'PermitModel', PermitModel)
    .service( 'FilesModel', FilesModel)
    .service( 'ReportBuilder', ReportBuilder)
    .service( 'NotificationModel', NotificationsModel)
    .service( 'CancelationModel', CancelationModel)
    .service( 'OnlineDriversEd', OnlineDriversEd)
    .service( 'CertificateModel', CertificateModel)
    .service('SchoolSetupModel', SchoolSetupModel)
    .service('FeeModel', FeeModel)
    .service('GradeOptionModel', GradeOptionModel)
    .service('ZipCodeModel', ZipCodeModel)
