"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StudentBookClassController = /** @class */ (function () {
    function StudentBookClassController(classDate, student, UsersModel, CoursesModel, FlashService) {
        this.loading = false;
        this.classDate = classDate;
        this.student = student;
        this.UsersModel = UsersModel;
        this.CoursesModel = CoursesModel;
        this.FlashService = FlashService;
    }
    StudentBookClassController.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.UsersModel.scheduleClassDate(this.student.id, this.classDate.course_id, this.classDate.id)
            .then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.loading = false;
        })
            .catch(function (Error) {
            _this.loading = false;
            console.error(Error);
        });
    };
    return StudentBookClassController;
}());
StudentBookClassController.$inject = [
    "classDate",
    "student",
    "UsersModel",
    "CoursesModel",
    "FlashService",
];
exports.default = StudentBookClassController;
