import isNil from 'lodash/isNil'
import moment from 'moment'

function AdminInstructorsViewTimeOffRequestsViewController( $stateParams, UsersModel, TimeoffRequestsModel, FlashService ) {

    const vm = this;
    vm.request = {};
    vm.approveRequest = approveRequest;
    vm.rejectRequest = rejectRequest;

    init();

    function init(){
        UsersModel.getInstructorByID( $stateParams.instructorId ).then( function ( response ) {
            vm.instructor = response;
        } );

        TimeoffRequestsModel.getRequestByID( $stateParams.timeOffRequestId ).then( function ( response ) {
            vm.request = {
                'id' : response.id,
                'user_id' : response.user_id,
                'begin' : moment( response.begin ).format( 'MM/DD/YYYY hh:mm a' ),
                'end' : moment( response.end ).format( 'MM/DD/YYYY hh:mm a' ),
                'status' : response.status,
                'reason' : response.reason,
                'notes' : response.notes
            }
        } );
    }

    function approveRequest() {

        FlashService.clearFlash();

        const approvedRequest = {
            'id' : vm.request.id,
            'user_id' : vm.request.user_id,
            'status' : 'Approved',
            'reason' : vm.request.reason
        };
        TimeoffRequestsModel.update( approvedRequest ).then( function ( response ) {
            FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            let request = response.request
            vm.request = {
                'id' : request.id,
                'user_id' : request.user_id,
                'begin' : moment( request.begin ).format( 'MM/DD/YYYY hh:mm a' ),
                'end' : moment( request.end ).format( 'MM/DD/YYYY hh:mm a' ),
                'status' : request.status,
                'reason' : request.reason,
                'notes' : request.notes
            }
            // $state.go( 'drivecoach.admin.instructors.view.timeoffrequests', { 'instructorId' : vm.instructor.id }, { 'reload' : true } );
        } );
    }

    function rejectRequest() {

        FlashService.clearFlash();

        if( isNil(vm.request.notes) ){
            return FlashService.setMessage({
                'type' : 'danger',
                'message' : 'Notes are required if rejecting a request for time off.'
            });
        }
        const rejectedRequest = {
            'id' : vm.request.id,
            'user_id' : vm.request.user_id,
            'status' : 'Rejected',
            'reason' : vm.request.reason,
            'notes' : vm.request.notes
        };

        TimeoffRequestsModel.update( rejectedRequest ).then( function ( response ) {
            FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            let request = response.request
            vm.request = {
                'id' : request.id,
                'user_id' : request.user_id,
                'begin' : moment( request.begin ).format( 'MM/DD/YYYY hh:mm a' ),
                'end' : moment( request.end ).format( 'MM/DD/YYYY hh:mm a' ),
                'status' : request.status,
                'reason' : request.reason,
                'notes' : request.notes
            }
            // $state.go( 'drivecoach.admin.instructors.view.timeoffrequests', { 'instructorId' : vm.instructor.id }, { 'reload' : true } );
        } );
    }
}
AdminInstructorsViewTimeOffRequestsViewController.$inject = [ '$stateParams', 'UsersModel', 'TimeoffRequestsModel', 'FlashService'];
export default AdminInstructorsViewTimeOffRequestsViewController;