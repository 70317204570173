import angular from 'angular'
import template from './admin-footer-nav.html'
import dcAdminFooterNavController from './admin-footer-nav.controller'
function dcAdminFooterNav() {
    return {
        restrict: 'E',
        scope: {},
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: dcAdminFooterNavController,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.footernav', [])
    .controller('dcAdminFooterNavController', dcAdminFooterNavController)
    .directive('dcAdminFooterNav', dcAdminFooterNav);

