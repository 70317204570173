import template from './student-nav.html'
function StudentSidebarNavigation( UserService ) {
    return {
        restrict : 'E',
        scope : {},
        template : template,
        link : ( scope, element, attrs ) => {

        } ,
        controller : 'StudentSidebarNavigationController',
        controllerAs : 'vm',
        bindToController: true
    }
}
StudentSidebarNavigation.$inject = ['UserService'];
export default StudentSidebarNavigation;