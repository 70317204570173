import angular from 'angular'
import StudentUpcomingDrivesController from './student-upcoming-drives.controller'
import template from './student-upcoming-drives.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.upcoming', {
            url : '/upcoming-drives',
            template : template,
            controller : StudentUpcomingDrivesController,
            controllerAs : 'vm',
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.parent,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];
export default angular.module( 'student.drives.upcoming', [] )
    .config( config )
    .controller( 'StudentUpcomingDrivesController', StudentUpcomingDrivesController );


