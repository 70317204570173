import angular from 'angular'
import template from './order-form.html'
import controller from './order-form.controller'
function dsOrderForm() {
    return {
        restrict: 'E',
        scope: {
            'submit' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.orderform', [])
    .controller('dsOrderFormController', controller)
    .directive('dsOrderForm', dsOrderForm);

