class AdminSchoolViewController {
    constructor($scope, UserService, school) {
        this.user = UserService.getCurrentUser();
        this.school = school;
        this.locations = this.school.locations;
        this.center = {
            lat: this.locations[0].address.lat,
            lng: this.locations[0].address.lng,
            zoom: 2
        };
    }

}

AdminSchoolViewController.$inject = ['$scope', 'UserService', 'school'];
export default AdminSchoolViewController;