import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import TextEditor from './TextEditor.vue'

//import packages here

export default angular.module( 'directives.texteditor', [] )
  .directive('dcTextEditor', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcTextEditor', TextEditor))
  }])
