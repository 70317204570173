class AdminStorefrontSettingsController {

    constructor(ENV, $state, PoliciesModel, FlashService) {
        this.ENV = ENV;
        this.busy = true;
        this.loading = true;
        this.policies = {
            'storefront' : {}
        };
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;

        PoliciesModel.getByID('storefront').then(response => {

            this.policies = {};
            this.policies.storefront = {
                'policymeta' : {}
            };

            let policyMeta = {};
            response.policy.policymeta.forEach(meta => {
                policyMeta[meta.meta_key] = meta.meta_value;
            });
            this.policies.storefront.policymeta = policyMeta;
            this.policies.storefront.policymeta.id = response.policy.id;
            this.busy = false;
            this.loading = false;
        });
        this.handleTermsOfServiceChange = this.handleTermsOfServiceChange.bind(this);
    }

    handleTermsOfServiceChange(data)
    {
        this.policies.storefront.policymeta.terms_of_service = data.html;
    }

    processPolicy() {
        this.busy = true;

        this.PoliciesModel.update(this.policies.storefront.policymeta).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        });

    }

}
AdminStorefrontSettingsController.$inject = ['ENV','$state', 'PoliciesModel', 'FlashService'];

export default AdminStorefrontSettingsController;