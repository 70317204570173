"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminSettingsPoliciesStudentMetaDataController = /** @class */ (function () {
    function AdminSettingsPoliciesStudentMetaDataController($state, $stateParams, UsersModel, FlashService, UserService, PoliciesModel, studentmetadata) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.UserService = UserService;
        this.PoliciesModel = PoliciesModel;
        this.submitted = false;
        this.loading = true;
        this.user = UserService.getCurrentUser();
        this.policies = studentmetadata;
    }
    AdminSettingsPoliciesStudentMetaDataController.prototype.processPolicy = function () {
        var _this = this;
        this.submitted = true;
        var studentmetadata = this.policies.studentmetadata;
        var data = {
            "id": this.policies.id,
            "policymeta": studentmetadata
        };
        this.PoliciesModel.update(data).then(function (response) {
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            _this.submitted = false;
        }).catch(function (Error) {
            _this.submitted = false;
        });
    };
    return AdminSettingsPoliciesStudentMetaDataController;
}());
AdminSettingsPoliciesStudentMetaDataController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'UserService', 'PoliciesModel', 'studentmetadata'];
exports.default = AdminSettingsPoliciesStudentMetaDataController;
