import angular from 'angular'
import controller from './order-reports.controller'
import template from './order-reports.html'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.orders.reports', {
            url : '/reports',
            template : template,
            controller : controller,
            controllerAs : 'vm',
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.crm.orders.reports', [] )
    .config(config)
    .controller('OderReportsController', controller);