import template from './toaster.html'
import controller from './toaster.controller'
export default () => {
    return {
        template,
        controller,
        controllerAs: 'vm',
        restrict: 'E',
        replace: true,
        scope: {
            'isVisible' : '@',
            'message' : '@',
            'type' : '@'
        }
    }
}
