import cloneDeep from 'lodash/cloneDeep'

class ReviewProfileInstanceController {
    
    constructor($state, $uibModalInstance, student, UsersModel, FlashService, submit){
        this.$state = $state;
        this.$uibModalInstance = $uibModalInstance;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.student = cloneDeep(student);
        this.submit = submit;
    }

    cancel() {
        this.$uibModalInstance.dismiss( 'cancel' );
    };

}
ReviewProfileInstanceController.$inject = [ '$state', '$uibModalInstance', 'student', 'UsersModel', 'FlashService', 'submit'];
export default ReviewProfileInstanceController;
