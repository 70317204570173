import angular from 'angular'
import template from './admin-instructor-tasks.html'
import controller from './admin-instructor-tasks.controller'

function getInstructor( $stateParams, UsersModel ) {
    return UsersModel.getInstructorByID($stateParams.instructorId).then((instructor) => instructor);
}
getInstructor.$inject = [ '$stateParams', 'UsersModel' ]

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.instructors.tasks', {
            'url' : '/:instructorId/tasks',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                instructor: getInstructor
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.instructors.tasks', [] )
    .config(config)
    .controller('AdminInstructorTasksController', controller);
