import { CreditCard } from "./CreditCard";


class Payment {

    constructor(data = {}) {
        this.$originalData = data;
        const {id, payment_method, amount, check_number, notes, created_at, updated_at} = data;
        this.$id = id;
        this.$payment_method = payment_method || 'card';
        if (amount === null) {
            this.$amount = null;
        } else {
            this.$amount = amount;
        }
        this.$created_at = created_at;
        this.$updated_at = updated_at;
        this.$check_number = check_number;
        this.$void = this.$originalData.void;
        this.$card = new CreditCard();
        this.$add_billing_address_as_pickup_location = true;
        this.$use_existing_payment_method = false;
        this.$notes = notes;
    }

    useExistingPaymentMethod() {
        return this.$use_existing_payment_method;
    }

    paymentMethodID() {
        return this.$payment_method_id;
    }

    creditCard() {
        return this.$card;
    }

    id() {
        return this.$id;
    }

    checkNumber() {
        return this.$check_number;
    }

    paymentMethod() {
        return this.$payment_method;
    }

    amount() {
        return parseInt(this.$amount);
    }

    createdAt() {
        if (!this.$created_at){
            this.$created_at = new Date;
        }
        return this.$created_at;
    }

    updatedAt() {
        if (!this.$updated_at){
            this.$updated_at = new Date;
        }
        return this.$updated_at;
    }

    /**
     * Set the amount for this payment. Must be a whole number
     * denominated in cents
     *
     * @param {number} amount
     */
    setAmount(amount) {
        this.$amount = amount;
    }

    notes() {
        return this.$notes;
    }

    isVoid() {
        return this.void === true;
    }

}


export { Payment }
