class FeeDetailController {
    constructor(UsersModel, FlashService){
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.submitted = false;
    }

    formatPaymentAmount(amount){
        return parseFloat(amount / 100).toFixed(2).toString();
    }

    voidPayment(payment_id, index) {
        this.submitted = true;
        this.UsersModel.voidPayment(this.fee.user_id, payment_id).then(response => {
            this.submitted = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.fee.amount_owed += parseInt(response.payment.amount);
            this.payments[index] = response.payment;
            this.calculateAmountOwed(this.payments)
        });
    }

    calculateAmountOwed(payments){
        let amountPaid = 0;
        if(this.payments.length > 0) {
            amountPaid = payments.filter(payment => {
                return payment.void === false;
            }).reduce((carry, payment) => {
                return carry + parseInt(payment.amount);
            }, 0);
        }
        this.fee.amount_owed = parseInt(this.fee.amount) - amountPaid;
    }
}
FeeDetailController.$inject = ['UsersModel', 'FlashService'];
export default FeeDetailController;