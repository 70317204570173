import moment from 'moment'
import ReviewProfileInstanceController from '../../../../signup/student/review-profile.controller'
import reviewProfileModalTemplate from './../../../../signup/student/review-student-signup.html'
import { States } from '../../../../common/constants/states'
import { isJson } from "../../../../common/helpers/json";

class CreateStudentController {

    constructor(UsersModel, FlashService, SchoolsModel, $uibModal, UserService, $state, CreateStudentService)
    {
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.SchoolsModel = SchoolsModel;
        this.$uibModal = $uibModal;
        this.$state = $state;
        this.CreateStudentService = CreateStudentService;

        this.schoolCodeValid = false;
        this.submitted = false;
        this.create_order = false;
        this.loadingSchools = false;
        this.states = States;
      
        this.updateDOB = this.updateDOB.bind(this)

        this.currentUser = UserService.getCurrentUser();
        if (isJson(this.currentUser.school.address)) {
            this.currentUser.school.address= JSON.parse(this.currentUser.school.address);
        }

        let defaults = {
            'schoolcode' : this.currentUser.school.code,
            'school' : this.currentUser.school,
            'last_minute_text_reminders' : 'N',
            'guardian_last_minute_text_reminders' : 'N'
        };
        this.student = {...defaults, ...CreateStudentService.get()}

        this.submitStudent = this.submitStudent.bind(this)
    }

    /**
     * Return the student's age in days based on their DOB
     * @returns int
     */
    getStudentAge() {
        if( typeof this.student.dob === 'undefined' ){
            return 10000000;
        }
        let dob = moment(this.student.dob, 'MMDDYYYY' ).toDate();
        return moment( new Date() ).diff( moment( dob ), 'days' );
    }

    /**
     * Is the student under 18 years old?
     * @returns {boolean}
     */
    studentIsAMinor()
    {
        return this.getStudentAge() < 6570;
    }

    /**
     * Is the student under 21 years old?
     * @returns {boolean}
     */
    studentUnder21()
    {
        return this.getStudentAge() < 7670;
    }

    /**
     * Force the school code to be upper case and only allow alphanumerics
     */
    formatCode() {
        this.student.schoolcode = this.student.schoolcode.replace( /[^a-z0-9\s]/gi, '' ).toUpperCase();
    }

    updateDOB(newDate) {
      this.student.dob = newDate.selected[0];
    }

    submitStudent() {
        if ( this.submitted === true ) {
            return;
        }
        let student = Object.assign({}, this.student);
        this.modalInstance.submitted = true;

        const dob = moment(student.dob, 'MMDDYYYY').format('YYYY-MM-DD');

        const request = {
            firstname: student.firstname,
            lastname: student.lastname,
            username: student.username,
            dob: dob,
            email: student.email,
            schoolcode: student.schoolcode,
            phone: student.phone,
            driving_experience: student.driving_experience,
            pickuplocation: null,
            parent: null
        }

        if (student.hasOwnProperty('pickuplocation') && student.pickuplocation.street && student.pickuplocation.city && student.pickuplocation.state && student.pickuplocation.zip) {
            request.pickuplocation = student.pickuplocation;
        }

        if (student.hasOwnProperty('parent') && student.parent.firstname && student.parent.lastname && student.parent.email && student.parent.phone){
            request.parent = student.parent;
        }

        if (request.pickuplocation === null) {
            delete request.pickuplocation;
        }

        if (request.parent === null){
            delete request.parent;
        }

        this.UsersModel.adminCreateStudent(request).then(response => {
            this.modalInstance.submitted = false;
            this.modalInstance.dismiss('cancel');
            if (this.create_order === true) {
                this.$state.go('drivecoach.admin.students.view.createorder', {'userId': response.student.id})
            } else {
                this.$state.go( 'drivecoach.admin.students.verification' );
            }
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
        }).catch(Error => {
            this.modalInstance.submitted = false;
        });
    }

    reviewProfile(){
        this.modalInstance = this.$uibModal.open({
            animation : true,
            template : reviewProfileModalTemplate,
            controller : ReviewProfileInstanceController,
            controllerAs : 'vm',
            size : 'sm',
            resolve : {
                'student' : this.student,
                'submit' : () => this.submitStudent
            }
        });

    }

}

CreateStudentController.$inject = ['UsersModel', 'FlashService', 'SchoolsModel', '$uibModal', 'UserService', '$state', 'CreateStudentService'];
export default CreateStudentController;