import { States } from "../../../../common/constants/states";
import { isJson } from "../../../../common/helpers/json";

function AdminStaffViewController($stateParams, UsersModel) {

    let vm = this;
    let usermeta = {};
    vm.states = States;
    vm.formData = {};
    UsersModel.getUserByID($stateParams.staffId).then(function (response) {
        response.usermeta.forEach(function (meta) {
            usermeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
        });
        response.usermeta = usermeta;
        vm.user = response;
    });

}
AdminStaffViewController.$inject = ['$stateParams', 'UsersModel'];
export default AdminStaffViewController;