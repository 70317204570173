import angular from 'angular'
import template from './create-task.html'
import controller from './create-task.controller'
function createTask() {
    return {
        restrict: 'E',
        scope: {
            'assignedUser' : '=',
            'userType': '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.createtask', [])
    .controller('dsTasksController', controller)
    .directive('dsCreateTask', createTask);

