import angular from 'angular'
import template from './orders.html'
import controller from './orders.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.instructor.dashboard.orders', {
            'url' : '/orders',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        });
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('instructor.dashboard.orders', [])
    .config(config)
    .controller('InstructorOrdersController', controller)
;
