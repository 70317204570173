import angular from "angular";
import template from "./admin-create-drive.html";
import AdminDrivesCreateController from "./admin-create-drive.controller";

function getVehicles(VehiclesModel) {
  return VehiclesModel.getVehicles({ all: true, status: "available" }).then(
    (response) => {
      return [{'id' : 0, 'name' : 'None'}].concat(response.vehicles);
    }
  );
}
getVehicles.$inject = ["VehiclesModel"];

function getInstructors(UsersModel) {
  return UsersModel.getInstructors({ all: true, status: "Active" }).then(
    (response) => {
      return [{'id' : 0, 'fullname' : 'None'}].concat(response.instructors);
    }
  );
}
getInstructors.$inject = ["UsersModel"];

function getSesssionTypes(DriveSessionTypesModel) {
  return DriveSessionTypesModel.get().then((response) => {
    return response.sessionTypes.filter((type) => {
      return type.status === "Active";
    });
  });
}
getSesssionTypes.$inject = ["DriveSessionTypesModel"];


function getDrivingLesson(AdminCreateDriveService){
  
    return {
      date: AdminCreateDriveService.getDate(),
      time: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes(), 0, 0),
      length: {
        hours: 2,
        minutes: "00"
      },
      sectors: [],
      zones: [],
      private: "N",
      observation: false,
    };
}
getDrivingLesson.$inject = ["AdminCreateDriveService"];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.admin.drives.create", {
    url: "/create",
    controller: AdminDrivesCreateController,
    controllerAs: "vm",
    template: template,
    resolve: {
      driveSession: getDrivingLesson,
      sessionTypes: getSesssionTypes,
      vehicles: getVehicles,
      instructors: getInstructors,
    },
    data: {
      authorizedRoles: [
        USER_ROLES.admin,
        USER_ROLES.administrator,
        USER_ROLES.owner,
      ],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("admin.drives.create", [])
  .controller("AdminDrivesCreateController", AdminDrivesCreateController)
  .config(config);
