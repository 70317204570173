import angular from 'angular'
import template from './tasks.html'
import controller from './tasks.controller'
function dsTasks() {
    return {
        restrict: 'E',
        scope: {
            'tasks' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.tasks', [])
    .controller('dsTasksController', controller)
    .directive('dsTasks', dsTasks);

