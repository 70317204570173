import angular from 'angular'
import controller from './student-notes.controller'
import template from './student-notes.html'

function getStudent($stateParams, UsersModel) {
    return UsersModel.getStudentByID($stateParams.userId).then(response => {
        return response;
    });
}
getStudent.$inject = ['$stateParams', 'UsersModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.studentnotes', {
            'url' : '/{userId}/notes',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                student : getStudent
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.studentnotes', [] )
    .config(config)
    .controller( 'InstructorStudentNotesController', controller )
;
