import angular from 'angular'
import AdminInstructorsEditAvailabilityController from './admin-edit-instructor-availability.controller'
import template from './admin-edit-instructor-availability.html'
function config( $stateProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.instructors.edit.availability', {
            'url' : '/availability',
            'controller' : AdminInstructorsEditAvailabilityController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )

    ;
}
config.$inject = ['$stateProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.instructors.edit.availability', [] )
    .config(config)
    .controller( 'AdminInstructorsEditAvailabilityController', AdminInstructorsEditAvailabilityController )
;
