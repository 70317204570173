<template>
  <div class="container-fluid">
    <div class="row" style="margin-bottom: 1.5em">
      <div class="col-md-12">
        <h1>Email Template: {{ template.display_name }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        
        <div class="form-group" style="margin-bottom: 4em;">
          <h2>Send This Email</h2>
          <p>This controls whether or not this email will be sent.</p>

          <select title="send_this_email" id="send_this_email" v-model="send_this_email" class="form-control">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
          <div class="alert alert-danger inline" v-if="send_this_email === false" style="margin-top: 2em; margin-bottom: 2em;">
            <strong class="uppercase">This email will not be delivered.</strong>
          </div>
        </div>
        
        <!-- your component here! showElement tag should be moved to outermost tag of component its not this div  -->
        <div class="form-group" style="margin-bottom: 4em;">
          <h2>Subject</h2>
          <p>This is the email subject, it does not display in the template. You may also use variables here.</p>

          <input title="subject" id="subject" style="text-align: left;" v-model="subject" class="form-control" type="text">
        </div>

        <div class="form-group" style="margin-bottom: 4em;">
          <h2>Status</h2>
          <p>This controls whether or not this template will override the existing template. If this is set to DRAFT,
           <strong>it will not be used</strong>.</p>

          <select title="status" id="status" v-model="status" class="form-control">
            <option value="published">PUBLISHED</option>
            <option value="draft">DRAFT</option>
          </select>
          <div class="alert alert-warning inline" v-if="status === 'draft'" style="margin-top: 2em; margin-bottom: 2em;">
            This is currently a draft, if you save this, <strong>it will not override the existing template.</strong>
          </div>
        </div>

        <section style="margin-top: 4em;">
          <h2>Content</h2>

          <p>This is the content of your email template. This may only effect some parts of some email templates, depending on the type of email.</p>
          <p>For example, one of the cancelation email templates is only partially editable because whether or not a user sees the cancelation detail is set in the settings panel in Drive Scout.</p>

          <quill-editor v-model="editor.content"
                        ref="myQuillEditor"
                        :options="editor.options"
                        @blur="onEditorBlur($event)"
                        @focus="onEditorFocus($event)"
                        @change="onEditorChange($event)"
                        @ready="onEditorReady($event)">
            <div id="toolbar" slot="toolbar">
            <span class="ql-formats">
              <select class="ql-font"></select>
              <select class="ql-size"></select>
            </span>
              <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-strike"></button>
            </span>
              <span class="ql-formats">
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span>
              <span class="ql-formats">
              <button class="ql-script" value="sub"></button>
              <button class="ql-script" value="super"></button>
            </span>
              <span class="ql-formats">
              <button class="ql-header" value="1"></button>
              <button class="ql-header" value="2"></button>
              <button class="ql-blockquote"></button>
              <button class="ql-code-block"></button>
            </span>
              <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-indent" value="-1"></button>
              <button class="ql-indent" value="+1"></button>
            </span>
              <span class="ql-formats">
              <button class="ql-direction" value="rtl"></button>
              <select class="ql-align"></select>
            </span>
              <span class="ql-formats">
              <button class="ql-link"></button>
                <!--              <button class="ql-image"></button>-->
                <!--              <button class="ql-video"></button>-->
                <!--              <button class="ql-formula"></button>-->
            </span>
              <span class="ql-formats">
              <button class="ql-clean"></button>
            </span>
              <!--            <button class="ql-custom-button" @click="customButtonClick">[ Click me ]</button>-->
            </div>

          </quill-editor>

          <div class="text-right" style="margin-top: 1.4em">
            <button class="btn btn-dc btn-red" @click.prevent="deleteTemplate(template)">Reset Template</button>
            <button class="btn btn-dc" @click.prevent="previewEmail(template)">Update Preview</button>
            <button class="btn btn-dc" @click.prevent="publish(template)">Save</button>
          </div>

          <hr style="margin: 2em auto;" />

          <h2 style="margin-top: 1.5em">Variables</h2>

          <p>These are different variables you can use when composing this email template's content. For example,
            some emails may have a <strong><span v-pre>{{ school }}</span></strong> variable that displays the name of
            your school when the email is sent.</p>

          <div class="well" v-for="(value, name) in template.variables">
            <span class="badge badge-info">{{ transformName(name) }}</span>
            <!--<p>{{ value.type }}</p>-->
            <p style="margin-top:1em">{{ value.description }}</p>
          </div>
        </section>

      </div>
      <div class="col-md-6">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-6">
              <h2>Preview</h2>
            </div>
            <div class="col-xs-6">
              <span class="pull-right"><strong>Preview Last Updated On: {{ previewUpdatedOn }}</strong></span>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <p>This is a preview of the email as the recipient will see it. Click the preview button to update this.</p>
            </div>
          </div>
        </div>
        <EmailTemplate :template="template"></EmailTemplate>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

// import ChildComponent from 'path/to/child'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import EmailTemplate from "../email-template/EmailTemplate.vue";

let DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute,true);
let AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass,true);

let BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass,true);

let ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass,true);

let DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass,true);

let FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass,true);

let SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass,true);

let AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle,true);

let BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle,true);

let ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle,true);

let DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle,true);

let FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle,true);

let SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle,true);

export default {
  name: 'dcEmailEditor', // this becomes the ngvue directive markup aka <dc-component-name></dc-component-name>
  components: {EmailTemplate, quillEditor },
  props: {
    'template' : { type: Object, default: {}},
    'content' : { type: String, default: ''},
    'showElement': { type: Boolean, default: true },
    'handleContentChange' : { type: Function, default: () => {}}
  },
  data() {

    let toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['link'],

      ['clean']                                         // remove formatting button
    ];

    return {
      subject: '',
      status: 'draft',
      send_this_email: "0",
      editor: {
        // content : '',
        options: {
          modules: {
            toolbar: '#toolbar' //toolbarOptions
          },
        },
      },
    }
  },
  methods: {
    previewEmail(template) {
      this.$store.dispatch('PREVIEW_EMAIL_TEMPLATE', {
        'email_key' : template.name,
        'subject' : this.subject,
        'email_content' : this.editor.content,
        'recipient_data' : {},
        'email_data' : {},
      })
    },
    publish(template) {
      let data = {
        key: template.name,
        status: this.status
      }
      if (this.subject) {
        data.subject = this.subject;
      }
      if (this.editor.content) {
        data.email_content = this.editor.content
      }
      data.send_this_email = this.send_this_email ? "1" : "0";
      this.$store.dispatch('PUBLISH_EMAIL_TEMPLATE', data).then(response => {
        let data = { 'message': response.data.message, 'template': response.data.template};
        this.$emit('email-template-published', {'data' : data})
      })
    },
    deleteTemplate(template) {
      this.$store.dispatch('DELETE_EMAIL_TEMPLATE', template).then(response => {
        this.editor.content = response.data.template.template_html;
        this.status = response.data.template.status;
        this.subject = response.data.template.subject;
        this.$store.dispatch('SET_PREVIEW_EMAIL_TEMPLATE', response.data.template);
        this.$emit('email-template-reset', response)
      });
    },
    transformName(name) {
      return '{{ ' + name + ' }}';
    },
    onEditorBlur(quill) {
      // console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      // console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      // console.log('editor ready!', quill)
      this.subject = this.template.subject;
      this.status = this.template.status || 'draft';
      this.editor.content = this.template.template_html;
      this.$store.dispatch('SET_PREVIEW_EMAIL_TEMPLATE', this.template);
    },
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text);
      this.$emit('editor-content-change', {html:html, text:text, subject: this.subject, status: this.status})
      this.editor.content = html;
    },
    customButtonClick() {
      alert('test')
    }
  },
  mounted () {
    // console.log('mounted')
  },
  destroyed () {
    // console.log('destroyed')
  },
  created () {
    // console.log('created')
    // console.log('content')
    // console.log(this.content)
    this.send_this_email = this.template.send_this_email;
  },
  computed: {
    previewUpdatedOn: function() {
      return moment(this.$store.getters.emailPreviewUpdatedOn).format('h:mm:ss a')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>