export const Timezones = angular.module('constants.timezones', [])
    .constant('TIMEZONES', [
        {
            'name' : 'Eastern' ,
            'code' : 'America/New_York'
        },
        {
            'name' : 'Pacific',
            'code' : 'America/Los_Angeles'
        },
        {
            'name' : 'Mountain',
            'code' : 'America/Denver'
        },
        {
            'name' : 'Mountain (No Daylight Saving Time)',
            'code' : 'America/Phoenix'
        },
        {
            'name' : 'Central',
            'code' : 'America/Chicago'
        }
    ])
;