import moment from 'moment'
import isNull from 'lodash/isNull'
class InstructorReportCardsController {

    constructor(UsersModel, UserService, students , FlashService){
        this.UsersModel = UsersModel;
        this.UserService = UserService;
        this.students = students;
        this.FlashService = FlashService;

        this.date = new Date();
        this.instructor = UserService.getCurrentUser();
        this.students = students;
        this.studentFilterDateFrom = new Date();
        this.studentFilterDateTo = new Date(); // moment().add( '2', 'weeks' ).toDate();
        this.customRange = false;
      
        this.handleDateChange = this.handleDateChange.bind(this)
        
        this.loading = true;

        if(this.students.length > 0){
            this.student = this.students[0];
            this.getStudentReportcards();
        } else {
            this.loading = false;
        }
    }

    toggleCustomRange(){
        this.customRange = ! this.customRange;
    }

    clearData(){
        this.student = null;
        this.students = [];
        this.reportcard = null;
    }

    setRange(range){
        this.clearData();

        if( range === 'today' ){
            this.studentFilterDateFrom = new Date();
            this.getStudents();
        }
        if( range === 'tomorrow' ){
            this.studentFilterDateFrom = moment().add(1, 'day').toDate();
            this.getStudents();
        }
    }

    getStudents() {
        this.clearData();
        this.loading = true;

        if( isNull(this.studentFilterDateFrom) ){
            return this.FlashService.setMessage({
                'message' : 'A date is required',
                'type' : 'error'
            })
        }
        const filterData = {
            'id' : this.instructor.id,
            'from' : moment( this.studentFilterDateFrom ).format( 'YYYY-MM-DD' ),
            'to' : moment( this.studentFilterDateFrom ).format( 'YYYY-MM-DD' )
        };
        this.UsersModel.getInstructorsUpcomingStudents( filterData ).then( response => {
            if(response.students.length > 0 ) {
                response.students.filter(student => {
                    student.name = student.firstname + ' ' + student.lastname;
                    return student;
                } );
                this.students = response.students;
                this.student = this.students[0];
                this.getStudentReportcards();
            } else {
                this.loading = false;
            }
        } ).catch( error => {
            this.loading = false;
        });
    }

    changeStudent() {
        this.loading = true;
        this.UsersModel.getStudentReportcards( { 'id' : this.student.id } ).then( response => {
            this.reportcard = response.reportcards.data[0];
            this.totalItems = response.reportcards.total;
            this.loading = false;
        } );
    }

    pageChanged() {
        this.loading = true;
        this.UsersModel.getStudentReportcards( {
            'id' : this.student.id,
            'page' : this.currentPage
        } ).then( response => {
            this.reportcard = response.reportcards.data[0];
            this.loading = false;
        } );
    }

    getStudentReportcards() {
        this.UsersModel.getStudentReportcards( { 'id' : this.student.id } ).then( response => {
            this.reportcard = response.reportcards.data[0];
            this.totalItems = response.reportcards.total;
            this.loading = false;
        } );
    }
  
    handleDateChange(newDate) {
      this.studentFilterDateFrom = newDate.selected[0]
      this.getStudents();
    }

}
InstructorReportCardsController.$inject = [ 'UsersModel', 'UserService', 'students', 'FlashService'];
export default InstructorReportCardsController;