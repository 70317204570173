import angular from 'angular'
import Residence from './residence/student-add-residence.module'
import Highschool from './high-school/student-add-high-school.module'
import StudentLocationsCreateController from './student-add-location.controller'
import template from './student-add-location.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.dashboard.locations.create', {
            'url' : '/create',
            'controller' : StudentLocationsCreateController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.dashboard.locations.create', [
        Residence.name,
        Highschool.name
    ] )
    .config( config )
    .controller( 'StudentLocationsCreateController', StudentLocationsCreateController )
;
