import angular from 'angular'
import StudentDrivesViewController from './student-drive-view.controller'
import Reportcard from './report-card/student-drive-report-card.module'
import template from './student-drive-view.html'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { toHoursAndMinutes } from '../../../common/helpers/time'
import "moment-duration-format";

function getCancellationRules( UsersModel, $stateParams, UserService){
    return UsersModel.getStudentCancellationRules($stateParams.id, UserService.getCurrentUser().id).then(response => {
        return response;
    });
}
getCancellationRules.$inject = [ 'UsersModel', '$stateParams', 'UserService'];

function getPickupLocations($stateParams, UsersModel){
    return UsersModel.getStudentPickupLocationsByDriveSession($stateParams.id).then(response => {
        response.pickuplocations.map(location => {
            return location;
        });
        return response.pickuplocations;
    });
}
getPickupLocations.$inject = ['$stateParams', 'UsersModel'];

function getDriveSession( $stateParams, DrivesModel ) {
    return DrivesModel.getDriveByID( $stateParams.id ).then( response => {
        let drive = cloneDeep(response.drive);
        let duration = toHoursAndMinutes(
            parseInt(response.drive.session_length)
        ).split(":");

        drive.drive_date = moment( response.drive.drive_date + ' ' + response.drive.start_time, 'YYYY-MM-DD H:mm:ss' ).format('MM/DD/YYYY');
        drive.start_time = moment( response.drive.drive_date + ' ' + response.drive.start_time, 'YYYY-MM-DD H:mm:ss').format('h:mm A');

        drive.sessionLengthHours = duration[0];
        drive.sessionLengthMinutes = duration[1];
        return drive;
    } )
}
getDriveSession.$inject = ['$stateParams', 'DrivesModel'];

function getCurrentUser( UserService, UsersModel ) {
    let currentUser = UserService.getCurrentUser();
    return UsersModel.getStudentByID( currentUser.id ).then( response => {
        return response;
    } )
}
getCurrentUser.$inject = ['UserService', 'UsersModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.drives.view', {
            'url' : '/view/:id',
            'controller' : StudentDrivesViewController,
            'controllerAs' : 'vm',
            'template' : template,
            resolve : {
                'driveSession' : getDriveSession,
                'student' : getCurrentUser,
                'pickupLocations' : getPickupLocations,
                'cancellationRules' : getCancellationRules
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.student,
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.drives.view', [
    Reportcard.name
] )
    .config( config )
    .controller( 'StudentDrivesViewController', StudentDrivesViewController );
