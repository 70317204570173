import { createBillingGatewayFromPolicies } from "./billing/BillingGateway";
import { Policies } from "./Policies";

class School {

    constructor(data) {
        this.$originalData = data;
        const { id, name, organisation_id, policies } = data;
        this.$id = id;
        this.$name = name;
        this.$organisation_id = organisation_id;

        const policyData = policies || [];
        this.$policies = new Policies(policyData).setSchool(this);
    }

    name(){
        return this.$name;
    }

    id() {
        this.$id;
    }

    policies(){
        return this.$policies;
    }

    billingGatway(){
        return createBillingGatewayFromPolicies(this.policies())
    }

    setPolicies(policies) {
        this.$policies = policies;
    }
}

export { School }