import angular from 'angular'
import template from './emails.html'
import controller from './emails.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.emails', {
            url: '/emails',
            controller: controller,
            controllerAs: 'vm',
            template: template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.settings.emails', [])
    .config(config)

