export const Hours = [
    {
        "name" : "1 PM",
        "value" : "13"
    },
    {
        "name" : "2 PM",
        "value" : "14"
    },
    {
        "name" : "3 PM",
        "value" : "15"
    },
    {
        "name" : "4 PM",
        "value" : "16"
    },
    {
        "name" : "5 PM",
        "value" : "17"
    },
    {
        "name" : "6 PM",
        "value" : "18"
    },
    {
        "name" : "7 PM",
        "value" : "19"
    },
    {
        "name" : "8 PM",
        "value" : "20"
    },
    {
        "name" : "9 PM",
        "value" : "21"
    },
    {
        "name" : "10 PM",
        "value" : "22"
    },
    {
        "name" : "11 PM",
        "value" : "23"
    },
    {
        "name" : "12 AM",
        "value" : "24"
    },
    {
        "name" : "1 AM",
        "value" : "01"
    },
    {
        "name" : "2 AM",
        "value" : "02"
    },
    {
        "name" : "3 AM",
        "value" : "03"
    },
    {
        "name" : "4 AM",
        "value" : "04"
    },
    {
        "name" : "5 AM",
        "value" : "05"
    },
    {
        "name" : "6 AM",
        "value" : "06"
    },
    {
        "name" : "7 AM",
        "value" : "07"
    },
    {
        "name" : "8 AM",
        "value" : "08"
    },
    {
        "name" : "9 AM",
        "value" : "09"
    },
    {
        "name" : "10 AM",
        "value" : "10"
    },
    {
        "name" : "11 AM",
        "value" : "11"
    },
    {
        "name" : "12 AM",
        "value" : "12"
    }
]

export function Minutes() {
    let data = [];
    for (let i = 0; i <= 59; i++){
        let name = i;
        if( name < 10 ){
            name = '0' + name;
        }
        data.push({
            name,
            'value' : i
        })
    }
    return data;
}
