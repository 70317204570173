import cloneDeep from 'lodash/cloneDeep'

class AdminStudentsInvitationsController {

    constructor(InvitationsModel, FlashService) {
        this.InvitationsModel = InvitationsModel;
        this.FlashService = FlashService;
        this.invitiations = [];
        this.loading = true;
        this.submitting = false;
        this.invite = {
            'type': 'email'
        }

        InvitationsModel.get().then(response => {
            this.invitations = response.invitations;
            this.loading = false;
        });
    }

    sendInvitation() {
        let data = {
            'invite_type': this.invite.type
        }
        if (this.invite.type === 'email') {
            data.email = this.invite.email;
        }
        if (this.invite.type === 'phone') {
            data.phone = this.invite.phone;
        }
        this.submitting = true;
        this.InvitationsModel.sendInvitation(data).then(response => {
            let invite = cloneDeep(this.invite);
            invite.accepted = false;
            this.submitting = false;
            this.invitations.unshift(invite);
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            })
            delete this.invite.phone;
            delete this.invite.email;
        }).catch(response => {
            this.submitting = false;
        })
    }


}

AdminStudentsInvitationsController.$inject = ['InvitationsModel', 'FlashService'];

export default AdminStudentsInvitationsController;