import {Policy} from "./Policy"
import {PolicyMeta} from "./PolicyMeta"

class Policies {

    constructor(policyCollection = []) {
        this.initialize(policyCollection);
    }

    setSchool(school){
        this.$school = school;
    }

    numberOfPolicies()
    {
        return Object.keys(this.$policies).length;
    }

    toArray(){
        let data = [].concat(Object.values(this.$policies));
        return data;
    }

    policies(){
        return this.$policies;
    }

    getPolicyByName(name) {
        return this.$policies[name];
    }

    policyExists(name) {
        return !!this.$policies[name]
    }

    /**
     * Update a policy
     * @param {Policy} policy 
     */
    setPolicy(policy){
        // console.log('Setting policy', policy)
        this.$policies[policy.name()] = policy;
    }

    initialize(policyCollection){
        this.$policies = {};
        policyCollection.forEach(policy => {
            this.$policies[policy.name()] = policy;
        })
        return this;
    }

}

/**
 * This is middleware to help the Angular 1 controllers be less terrible.
 */
function createFromSinglePolicyAPIResponse(response){
    const metaCollection = [];
    response.policy.policymeta.forEach(m => {
        metaCollection.push(new PolicyMeta(m.id,m.policy_id,m.meta_key,m.meta_value))
    })
    const policy = new Policy(response.policy.id, response.policy.name);
    
    policy.setMetaData(metaCollection);
    const policies = new Policies([policy]);
    return policies;
}

function createFromPolicyAPIResponse(response){
    const policyCollection = [];
    response.policies.forEach(policy => {
        const metaCollection = [];
        policy.policymeta.forEach(meta => {
            metaCollection.push(new PolicyMeta(meta.id,meta.policy_id,meta.meta_key,meta.meta_value))
        })
        const $policy = new Policy(policy.id, policy.name);
        $policy.setMetaData(metaCollection);
        policyCollection.push($policy);
    })
        
    const policies = new Policies(policyCollection);
    return policies;
}


export { Policies, createFromSinglePolicyAPIResponse, createFromPolicyAPIResponse }