import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcVersionNumber from './VersionNumber.vue'

//import packages here

export default angular.module( 'directives.versionNumber', [] )
  .directive('dcVersionNumber', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcVersionNumber', dcVersionNumber))
  }])
