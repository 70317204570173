class AdminStaffIndexController {

    constructor(UserService, UsersModel, FlashService, $window) {
        this.UserService = UserService;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.currentPage = 1;
        this.page_by = 20;
        this.searchName = '';

        this.user = UserService.getCurrentUser();
        this.busy = true;
        this.statusOptions = [
            'All',
            'Active',
            'Disabled'
        ];
        this.searchStatus = 'All';
        this.processStaff = this.processStaff.bind(this);

        this.UsersModel.getStaff({ 'page': this.currentPage, 'page_by': this.page_by }).then(this.processStaff);
    }
    
    canBeEdited(user) {
        if (user.usertype.type === 'owner') {
            if (this.user.role.type !== 'owner') {
                return false;
            }
        }

        return true;
    }

    sendWelcomeEmail(id){
        const confirm = this.$window.confirm('Are you sure you want to send the welcome email? This will also reset this users password.');
        if (confirm === false) {
            return false;
        }
        this.busy = true;
        this.UsersModel.sendStaffWelcomeEmail(id).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        })
    }

    processStaff(response) {
        this.users = response.staff.data;
        this.totalItems = response.staff.total;
        this.busy = false;
    }

    search() {
        this.busy = true;
        let searchData = {
	    page: this.currentPage,
            page_by: this.page_by	
	};
        if (this.searchStatus !== 'All') {
            searchData.status = this.searchStatus;
        }
        if (this.searchName !== '') {
            searchData.name = this.searchName
        }
        this.UsersModel.getStaff(searchData)
            .then(this.processStaff)
            .catch(Error => this.busy = false);
    }

    pageChanged() {
        this.busy = true;
        let searchData = {
            'page': this.currentPage,
            'page_by': this.page_by
        };
        if (this.searchStatus !== 'All') {
            searchData.status = this.searchStatus;
        }
        if (this.searchName !== '') {
            searchData.name = this.searchName
        }
        this.UsersModel.getStaff(searchData)
            .then(this.processStaff)
            .catch(Error => this.busy = false);
    }

}

AdminStaffIndexController.$inject = ['UserService', 'UsersModel', 'FlashService', '$window'];
export default AdminStaffIndexController;
