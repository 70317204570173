function AdminSchoolsIndexController($state, SchoolsModel) {
    const vm = this;

    // this will be student info pulled from the server later
    SchoolsModel.getSchools().then(function (response) {
        vm.schools = response.filter(function (school) {
            school.address = angular.fromJson(school.address);
            return school;
        });
    });
}
AdminSchoolsIndexController.$inject = ['$state', 'SchoolsModel'];
export default AdminSchoolsIndexController;
