function GoBackModalInstanceController( $state, $uibModalInstance, student ) {

    let vm = this;
    vm.student = student;

    vm.ok = function () {
        goBack();
    };

    vm.cancel = function () {
        $uibModalInstance.dismiss( 'cancel' );
    };

    function goBack() {
        navigateBack($state.current.name);
    }

    function navigateBack(state){

        switch(state){
            case 'drivecoach.signup.student.studentinfo':
                delete vm.student;
                $uibModalInstance.close( { 'student' : vm.student, 'state' : 'drivecoach.login' } );
                break;
            case 'drivecoach.signup.student.permitinfo':
                delete vm.student.parent;
                delete vm.student.guardian_last_minute_email_reminders;
                delete vm.student.guardian_last_minute_text_reminders;
                delete vm.student.guardian_upcoming_text_reminders;
                delete vm.student.guardian_upcoming_email_reminders;
                delete vm.student.highschool;
                delete vm.student.permit;
                $uibModalInstance.close( {
                    'student' : vm.student,
                    'state' : 'drivecoach.signup.student.studentinfo',
                    'progress' : 1
                } );
                break;
            case 'drivecoach.signup.student.guardianinfo':
                // delete pickup location and guardian info
                delete vm.student.parent;
                delete vm.student.guardian_last_minute_email_reminders;
                delete vm.student.guardian_last_minute_text_reminders;
                delete vm.student.guardian_upcoming_text_reminders;
                delete vm.student.guardian_upcoming_email_reminders;
                delete vm.student.highschool;
                $uibModalInstance.close( {
                    'student' : vm.student,
                    'state' : 'drivecoach.signup.student.permitinfo'
                } );
                break;
            case 'drivecoach.signup.student.pickuplocationstart':
                delete vm.student.highschool;
                $uibModalInstance.close( {
                    'student' : vm.student,
                    'state' : 'drivecoach.signup.student.guardianinfo',
                    'progress' : 2
                } );
                break;
            case 'drivecoach.signup.student.pickuplocation':
                delete vm.student.highschool;
                $uibModalInstance.close( {
                    'student' : vm.student,
                    'state' : 'drivecoach.signup.student.guardianinfo',
                    'progress' : 2
                } );
                break;
            case 'drivecoach.signup.student.pickuplocationhighschool':
                delete vm.student.highschool;
                $uibModalInstance.close( {
                    'student' : vm.student,
                    'state' : 'drivecoach.signup.student.guardianinfo',
                    'progress' : 2
                } );
                break;
            case 'drivecoach.signup.student.pickuplocationconfirm':
                delete vm.student.highschool;
                $uibModalInstance.close( {
                    'student' : vm.student,
                    'state' : 'drivecoach.signup.student.guardianinfo',
                    'progress' : 2
                } );
                break;
            case 'drivecoach.signup.student.finish':
                delete vm.student.highschool;
                $uibModalInstance.close( {
                    'student' : vm.student,
                    'state' : 'drivecoach.signup.student.guardianinfo',
                    'progress' : 2
                } );
                break;
            default:
                //$state.go('');
                break;
        }
    }

}
GoBackModalInstanceController.$inject = [ '$state', '$uibModalInstance', 'student'];
export default GoBackModalInstanceController;