class dcFlashController {

    constructor($rootScope, $scope, FlashService, $timeout){
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.FlashService = FlashService;
        this.$timeout = $timeout;

        this.closeFlash = this.closeFlash.bind(this);
        this.newFlash = this.newFlash.bind(this);
        this.closeInfoFlash = this.closeInfoFlash.bind(this);

        $scope.$on('CLEAR_FLASH_MESSAGE', this.closeFlash);
        $scope.$on( 'NEW_FLASH_MESSAGE', this.newFlash );
    }

    newFlash() {
        this.message = this.FlashService.getMessage();
        this.type = this.FlashService.getType();
        this.isVisible = true;
        if ( this.type === 'success' ) {
            return this.$timeout( () => {
                this.isVisible = false;
                this.type = '';
                return this.message = '';
            }, 7000 );
        }
    }

    closeFlash() {
      this.isVisible = false;
      this.type = '';
      return this.message = '';
    }
  
    closeInfoFlash() {
      this.closeFlash()
      this.$rootScope.$broadcast('MESSAGE_CLEARED')
    }
}
dcFlashController.$inject = ['$rootScope', '$scope', 'FlashService', '$timeout'];
export default dcFlashController;
