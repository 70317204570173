function dcMiniCalendarAgendaController( $rootScope, UserService, $state, CalendarModel, EntitiesModel ) {
    const vm = this;

    vm.prev = agendaPrev;
    vm.next = agendaNext;
    vm.currentUser = UserService.getCurrentUser();
    vm.isDriveSession = EntitiesModel.isDriveSession;
    vm.isCourse = EntitiesModel.isCourse;
    vm.isEvent = EntitiesModel.isEvent;
    vm.isRTO = EntitiesModel.isRTO;
    vm.currentAgendaIndex = 0;
    vm.loading = true;
    vm.viewDriveSession = viewDriveSession;
    vm.shouldShowLabel = shouldShowLabel;

    init();

    function shouldShowLabel(item){
        if( vm.currentUser.role.type !== 'student' ){
            return true;
        }
        return item.label.name !== 'OPEN SESSION';
    }

    function viewDriveSession(item){

        if( vm.currentUser.role.type === 'student') {
            return $state.go('drivecoach.student.drives.create', { 'id' : item.id } );
        }

        return $state.go('drivecoach.admin.drives.edit', { 'id' : item.id } );
    }

    function setAgenda(){
        vm.loading = false;

        if( vm.paging === false ){
            vm.agendaItems = CalendarModel.getAgendaData().list;
        }else{
            vm.currentAgendaIndex = 0;
            vm.agendaItems = CalendarModel.getAgenda();
            vm.totalAgendaItems = CalendarModel.getAgendaData().total;
            vm.agendaCollection = CalendarModel.getAgendaData().all;
        }
    }

    function init(){
        $rootScope.$watch('CalendarModel.getAgenda', () => {
            setAgenda();
        });
        $rootScope.$on('ADMIN_CALENDAR_AGENDA_UPDATED', setAgenda );
        $rootScope.$on('STUDENT_CALENDAR_AGENDA_UPDATED', setAgenda );

        $rootScope.$on('CALENDAR_LOADING', () => {
            vm.loading = true;
        });
    }

    function agendaPrev(){
        if(vm.currentAgendaIndex === 0){
            return;
        }
        vm.agendaItems = vm.agendaCollection[vm.currentAgendaIndex - 1];
        vm.currentAgendaIndex = vm.currentAgendaIndex - 1;
    }

    function agendaNext(){
        //console.log(vm.currentAgendaIndex , vm.totalAgendaItems.length)
        if(vm.currentAgendaIndex === vm.agendaCollection.length){
            return;
        }
        vm.agendaItems = vm.agendaCollection[vm.currentAgendaIndex + 1];
        vm.currentAgendaIndex = vm.currentAgendaIndex + 1;
    }
}
dcMiniCalendarAgendaController.$inject = ['$rootScope', 'UserService', '$state', 'CalendarModel','EntitiesModel' ];
export default dcMiniCalendarAgendaController;