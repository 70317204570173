class AdminSettingsController {

  constructor($rootScope, UserService, OnlineDriversEd){
    this.user = UserService.getCurrentUser();
    this.ode_enabled = false;
    OnlineDriversEd.getFeatureDetails().then(response => {
      this.ode_enabled = response.enabled;
    });
  }

}
AdminSettingsController.$inject = ['$rootScope', 'UserService', 'OnlineDriversEd'];
export default AdminSettingsController;
