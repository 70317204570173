import controller from './order.controller'
import template from './order.html'
function Order() {
    return {
        restrict : 'E',
        scope : {
            'order' : '='
        },
        template : template,
        controller : controller,
        controllerAs : 'vm',
        bindToController : true
    }
}
export default angular.module( 'directives.order', [] )
    .directive( 'dsOrder', Order)
    .controller( 'OrderController', controller );
