"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function PostOfficeModel($http, APPURL) {
    var model = this, URLS = {
        FETCH: APPURL + '/api/v1/post-office',
        UPDATE: APPURL + '/api/v1/post-office',
        CREATE: APPURL + '/api/v1/post-office'
    }, zones;
    function extract(result) {
        return result.data;
    }
    function cacheResults(result) {
        zones = extract(result);
        return zones;
    }
    model.getSenders = function (email) {
        return $http.get(APPURL + '/api/v1/post-office/senders?sender=' + email)
            .then(cacheResults);
    };
}
PostOfficeModel.$inject = ['$http', 'APPURL'];
exports.default = PostOfficeModel;
