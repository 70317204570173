import angular from "angular";
import template from "./admin-settings.html";
import AdminSettingsController from "./admin-settings.controller";
import Policies from "./policies/admin-policies.module";
import Notifications from "./notifications/admin-settings-notifications.module";
import Billing from "./billing/admin-settings-billing.module";
import RunPayroll from "./run-payroll/run-payroll.module";
import ReportCardSettings from "./report-card-settings/report-card-settings.module";
import AdhocPickupLocations from "./adhoc-pickup-locations/adhoc-pickup-locations.module";
import AdminExports from "./exports/exports.module";
import Integrations from "./integrations/integrations.module";

let modules = [
  Policies.name,
  Notifications.name,
  Billing.name,
  RunPayroll.name,
  ReportCardSettings.name,
  AdhocPickupLocations.name,
  AdminExports.name,
  Integrations.name,
];
function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.admin.settings", {
    url: "/settings",
    template: template,
    controller: AdminSettingsController,
    controllerAs: "vm",
    data: {
      authorizedRoles: [
        USER_ROLES.admin,
        USER_ROLES.administrator,
        USER_ROLES.owner,
      ],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("admin.settings", modules)
  .controller("AdminSettingsController", AdminSettingsController)
  .config(config);
