function StudentDrivesViewReportCardController( UsersModel, $stateParams, UserService ) {

    const vm = this;
    vm.reportCard = null;
    vm.reportCardGraded = false;

    let data = {
        'student_id' : UserService.getCurrentUser().id,
        'drive_id' : $stateParams.id
    }

    UsersModel.getReportcardForDrive(data).then(response => {
        vm.reportCard = response.reportcard;
        vm.reportCardGraded = true;
    }).catch(Error => {
        vm.reportCardGraded = false;
    })

}
StudentDrivesViewReportCardController.$inject = [ 'UsersModel', '$stateParams', 'UserService' ];
export default StudentDrivesViewReportCardController;
