import angular from 'angular'
import template from './admin-students.html'
import AdminStudentsController from './admin-students.controller'
import Index from './index/admin-students-index.module'
import Verification from './verification/admin-verify-students.module'
import Edit from './edit/admin-edit-student.module'
import Create from './create-student/create-student.module'
import View from './view/admin-view-student.module'
import Drives from './drives/admin-student-drives.module'
import ReportCards from './view/report-cards/admin-student-report-cards.module'
import ReportCard from './view/report-card/admin-student-report-card.module'
import PickupLocations from './pickup-locations/admin-student-pick-up-locations.module'
import Invitations from './invitations/student-invitations.module'
import Permit from './view/permit/admin-edit-student-permit.module.'
import Files from './view/files/files.module'

let modules = [
    Index.name,
    Verification.name,
    Edit.name,
    View.name,
    Create.name,
    Drives.name,
    ReportCards.name,
    ReportCard.name,
    PickupLocations.name,
    Invitations.name,
    Permit.name,
    Files.name,
];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.students', {
            'url' : '/students',
            'abstract' : true,
            'controller' : AdminStudentsController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.students', modules )
    .config(config)
;