import angular from 'angular'
import StudentProgressReportController from './student-progress-report.controller'
import template from './student-progress-report.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.progressreport', {
            url : '/progress-report',
            controller : StudentProgressReportController,
            controllerAs: 'vm',
            template : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.progressreport', [] )
    .config( config )
    .controller( 'StudentProgressReportController', StudentProgressReportController );
