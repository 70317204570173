import angular from 'angular'
import StudentLocationsCreateHighschoolController from './student-add-high-school.controller'
import template from './student-add-high-school.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.dashboard.locations.create.highschool', {
            'url' : '/high-school',
            'controller' : StudentLocationsCreateHighschoolController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } );
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.dashboard.locations.create.highschool', [] )
    .config( config )
    .controller( 'StudentLocationsCreateHighschoolController', StudentLocationsCreateHighschoolController )
;
