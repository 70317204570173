import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcDatepicker from './Datepicker.vue'

//import packages here

export default angular.module( 'directives.datepicker', [] )
  .directive('dcDatepicker', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcDatepicker', dcDatepicker))
  }])
