import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import EmailActivity from './EmailActivity.vue'

export default angular.module( 'directives.emailactivity', [] )
  .directive('dsEmailActivity', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dsEmailActivity', EmailActivity))
  }])
