import '../../../assets/images/icon-01.svg'
import angular from 'angular'
import Vue from 'vue'
import template from './admin-dashboard.html'
import AdminDashboardController from './admin-dashboard.controller'
import AdminDashboardCalendar from './calendar/admin-dashboard-calendar.module'
import AdminDashboardAgenda from './agenda/admin-dashboard-agenda.module'
import AdminDashboardInstructors from './instructors/admin-instructors.module'
import AdminDashboardStaff from './staff/admin-staff.module'
import AdminDashboardReportCards from './reportcards/admin-reportcards.module'
import AdminDashboardFutureSessions from './future-sessions/admin-future-sessions.module'
import Schools from './schools/admin-schools.module'
import Students from './students/admin-students.module'
import AdminGradeGenericReportCards from './admin-grade-generic-report-card/grade-generic-report-card.module'
import AdminGradeGradedReportCards from './admin-grade-graded-report-card/grade-graded-report-card.module'
import AdminGradePassFailReportCard from './admin-grade-pass-fail-report-card/create-pass-fail-report-card.module'
import AdminCompleteDrive from './admin-complete-drive/admin-complete-drive.module'
import ReportCard from './report-card/report-card.module'
import SchoolSetup from '../directives/school-setup/SchoolSetup.vue'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.dashboard', {
            url: '/dashboard',
            template: template,
            controller: AdminDashboardController,
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard', [
    AdminDashboardCalendar.name,
    AdminDashboardAgenda.name,
    AdminDashboardInstructors.name,
    Students.name,
    AdminDashboardStaff.name,
    AdminDashboardReportCards.name,
    Schools.name,
    AdminGradeGradedReportCards.name,
    AdminGradeGenericReportCards.name,
    AdminGradePassFailReportCard.name,
    AdminCompleteDrive.name,
    ReportCard.name,
    AdminDashboardFutureSessions.name
]).config(config)
    .controller('AdminDashboardController', AdminDashboardController)
    .directive('dcSchoolSetup', ['createVueComponent', function (createVueComponent) {
        return createVueComponent(Vue.component('dcSchoolSetup', SchoolSetup))
      }])
;
