import moment from 'moment'
import { isJson } from '../../../../common/helpers/json'
function AdminSettingsPoliciesCoursesController(UserService, $state, UsersModel, PoliciesModel, FlashService, CourseTypesModel) {

    let vm = this;
    vm.formData = {};
    let currentUser = UserService.getCurrentUser();
    vm.formData.policymeta = {};
    vm.minSessionLength = {};
    vm.processPolicy = processPolicy;
    vm.updateDefaultTime = updateDefaultTime;

    init();

    function init() {
        PoliciesModel.get().then(response => {
            vm.school = currentUser.school;
            vm.policies = {};
            response.policies.forEach(policy => {
                let policyName = policy.name;
                vm.policies[policyName] = {'id': policy.id};
                let policyMeta = {};
                policy.policymeta.forEach(meta => {
                    policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
                });
                vm.policies[policyName].policymeta = policyMeta;
            });

            if (vm.policies.courses.hasOwnProperty('policymeta')) {
                if (vm.policies.courses.policymeta.hasOwnProperty('time')) {
                    vm.defaultSessionTime = moment(vm.policies.courses.policymeta.time, 'HH:mm:ss').toDate();
                }
                
                if (!vm.policies.courses.policymeta.hasOwnProperty('allow_add_student_to_course')) {
                    vm.policies.courses.policymeta.allow_add_student_to_course = "Y"
                }
            }

            if (vm.policies.courses.policymeta.hasOwnProperty('minimum_session_length')) {
                // convert from minutes to separate hours and minutes
                let _durMoment = moment.duration(vm.policies.courses.policymeta.minimum_session_length, 'minutes').format('H:mm').split(':');
                vm.minSessionLength.hours = _durMoment[0];
                vm.minSessionLength.minutes = _durMoment[1];
            }

            vm.policies.courses.policymeta.id = vm.policies.courses.id;

        });
        //getCourseTypes();
    }

    function processPolicy(isValid) {
        vm.policies.courses.policymeta.time = moment(vm.defaultSessionTime).format('H:mm');

        // convert minimum session length from separate hours and minutes to minutes
        vm.policies.courses.policymeta.minimum_session_length = ( parseInt(vm.minSessionLength.hours) * 60 ) + parseInt(vm.minSessionLength.minutes);

        if (isValid) {
            PoliciesModel.update(vm.policies.courses.policymeta).then(function (response) {
                FlashService.setMessage({'type': 'success', 'message': response.message});
            });
        } else {
            FlashService.setMessage({'type': 'error', 'message': 'All fields are required.'});
        }
    }
  
    function updateDefaultTime(newTime) {
      vm.defaultSessionTime = newTime.selected[0]
    }

}
AdminSettingsPoliciesCoursesController.$inject = ['UserService', '$state', 'UsersModel', 'PoliciesModel', 'FlashService', 'CourseTypesModel'];

export default AdminSettingsPoliciesCoursesController;