function AdminSchoolLocationViewVehiclesViewController($state, $stateParams, LocationsModel, VehiclesModel) {

    const vm = this;

    LocationsModel.getLocationByID($stateParams.locationId)
        .then(function (response) {
            vm.location = response;
        })
    ;

    VehiclesModel.getVehiclesByLocation($stateParams.locationId)
        .then(function (response) {
            vm.vehicles = response;
        });

}
AdminSchoolLocationViewVehiclesViewController.$inject = ['$state', '$stateParams', 'LocationsModel', 'VehiclesModel']

export default AdminSchoolLocationViewVehiclesViewController;