import template from './student-info-overview.html'
function dsStudentInfoOverview() {
    return {
        restrict : 'E',
        scope : {
            'student' : '=',
            'nextSession' : '=',
            'hasNextSession' : '=',
            'preferred_pronoun_enabled' : '='
        },
        template : template,
        link :  (scope, element, attrs ) => {
            if (typeof scope.hasNextSession === 'undefined') {
                scope.hasNextSession = false;
            }
        },
        controller: 'dsStudentInfoOverviewController',
        controllerAs: 'vm',
        bindToController: true
    }
}

export default dsStudentInfoOverview;
