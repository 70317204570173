import angular from 'angular'
import StudentLocationsController from './student-locations.controller'
import Index from './index/student-locations-index.module'
import Create from './create/student-add-location.module'

import template from './student-locations.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.dashboard.locations', {
            url : '/locations',
            template : template,
            controller : StudentLocationsController,
            controllerAs : 'vm',
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.parent,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.dashboard.locations', [
    Index.name,
    Create.name
] )
    .config( config )
    .controller( 'StudentLocationsController', StudentLocationsController )
;
