import angular from 'angular'
import controller from './report-card.controller'
import template from './reportcard.html'

function getReportcard(ReportcardsModel, $stateParams){
    return ReportcardsModel.getReportcardByID($stateParams.report_card_id).then(response => {
        return response.reportcard;
    });
}
getReportcard.$inject = ['ReportcardsModel', '$stateParams'];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.instructor.dashboard.reportcard', {
            'url' : '/reportcard/:report_card_id',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                'reportcard' : getReportcard,
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.parent,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.reportcard', [] )
    .config(config)
    .controller('InstructorReportCard', controller)
;
