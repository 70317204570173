import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcOnlineDriversEdFinal from './OnlineDriversEdFinal.vue'

export default angular.module( 'directives.onlinedriversedfinal', [] )
  .directive('dcOnlineDriversEdFinal', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcOnlineDriversEdFinal', dcOnlineDriversEdFinal))
  }])
