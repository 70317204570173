function InstructorRequestTimeOffController( UserService, $state, UsersModel ) {
    const vm = this;
    vm.date = new Date();
    vm.currentUser = UserService.getCurrentUser();
    vm.requestType = {};

    vm.toggleRequest = toggleRequestType;

    init();

    function init(){
        UsersModel.getInstructorByID( vm.currentUser.id ).then( response => {
            vm.instructor = response;
        } );
    }

    function toggleRequestType(type) {
        if (type === 'dayOff') {
            vm.requestType.moreThanDay = false;
            $state.go('drivecoach.instructor.dashboard.requesttimeoff.day');
        }
        if (type === 'moreThanDay') {
            vm.requestType.dayOff = false;
            $state.go('drivecoach.instructor.dashboard.requesttimeoff.range');
        }
    }
}
InstructorRequestTimeOffController.$inject = ['UserService', '$state', 'UsersModel'];
export default InstructorRequestTimeOffController;
