import angular from 'angular'
import template from './admin-student-report-card.html'
import controller from './admin-student-report-card.controller'

function getReportcard(ReportcardsModel, $stateParams){
    return ReportcardsModel.getReportcardByID($stateParams.report_card_id).then(response => {
        return response.reportcard;
    });
}
getReportcard.$inject = ['ReportcardsModel', '$stateParams'];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.students.reportcard', {
            'url' : '/report-card/:report_card_id',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                'reportcard' : getReportcard,
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.students.view.reportcard', [] )
    .config(config)
    .controller('AdminStudentReportCardController', controller )
;