import {States} from "../../../../../common/constants/states";
import { isJson } from "../../../../../common/helpers/json";

class AdminSchoolZoneCreateController {

    constructor($state, $stateParams, LocationsModel, SchoolsModel, ZonesModel, FlashService, ZipCodeModel) {
        this.$state = $state;
        this.states = States;
        this.$stateParams = $stateParams;
        this.LocationsModel = LocationsModel;
        this.SchoolsModel = SchoolsModel;
        this.ZonesModel = ZonesModel;
        this.FlashService = FlashService;
        this.ZipCodeModel = ZipCodeModel;
        this.custom_center = false;
        this.zone_center = {};
        this.zone = {'sectors': 0};
        this.zipCodeSearchResults = [];

        SchoolsModel.getSchoolByID($stateParams.schoolId).then(response => {
            this.school = response;
            this.locations = response.locations;
        })
    }

    toggleAllZipCodes(){
        this.zipCodeSearchResults = this.zipCodeSearchResults.map(zip => {
            zip.isSelected = !zip.isSelected;
            return zip;
        });
    }

    searchZipCodes() {
        let query = {state: this.getStateFromLocationID()}
        if (this.isNumeric(this.zipCity)) {
            query.number = this.zipCtiy;
        } else {
            query.city = this.zipCity;
        }
        this.ZipCodeModel.getZipCodes(query).then(response => {
            this.zipCodeSearchResults = response.zipcodes;
        })
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    getStateFromLocationID()
    {
        const location = this.locations.find(l => {
            return l.id == this.zone.location_id;
        });

        const address = isJson(location.address) ? JSON.parse(location.address) : location.address;
        return address.state;
    }


    createZone(isValid) {
        this.submitted = true;
        if (this.zone_center.street && this.zone_center.city && this.zone_center.state && this.zone_center.state) {
            this.zone.location = this.zone_center.street + ',' + this.zone_center.city + ',' + this.zone_center.state + ',' + this.zone_center.zip;
        }
        if (isValid) {
            
            let payload = Object.assign({}, this.zone);
            if (payload.type === 'POSTAL_CODE') {
                payload.zipcodes = this.zipCodeSearchResults.filter(z => {
                    return z.isSelected === true;
                }).map(z => {
                    return z.number;
                });
            }

            this.ZonesModel.createZone(payload).then(response=> {
                this.FlashService.setMessage({'type': 'success', 'message': response.message});
                this.zone = {'sectors': 0};
                this.$state.go('drivecoach.admin.schools.zones.index', {'schoolId': this.$stateParams.schoolId}, {'reload': true});
                this.submitted = false;
                this.zone_center = {};
                this.custom_center = false;
            }).catch(Error => {
                this.submitted = false;
            });
        } else {
            this.FlashService.setMessage({'type': 'error', 'message': 'Please fill the form.'});
        }
    }
}
AdminSchoolZoneCreateController.$inject = ['$state', '$stateParams', 'LocationsModel', 'SchoolsModel', 'ZonesModel', 'FlashService', 'ZipCodeModel'];
export default AdminSchoolZoneCreateController;