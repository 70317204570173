const EmailsController = function(FlashService, UserService)
{
    const vm = this;

    vm.template = {};
    vm.templateLoaded = false;
    vm.show_dns_instructions = false;
    vm.school = UserService.getCurrentUser().school;

    vm.createEmailTemplate = createEmailTemplate.bind(this);
    vm.handleEmailTemplateReset = handleEmailTemplateReset.bind(this)
    vm.handleEmailTemplatePublished = handleEmailTemplatePublished.bind(this)
    vm.handleEmailSamlpeSent = handleEmailSampleSent.bind(this)

    function handleEmailTemplatePublished(data) {
        FlashService.setMessage({
            'type' : 'success',
            'message' : data.data.message
        })
    }

    function handleEmailSampleSent(data) {
        FlashService.setMessage({
            'type' : 'success',
            'message' : data.data.message
        })
    }

    function createEmailTemplate(template){
        vm.template = template;
        vm.templateLoaded = true;
    }

    function handleEmailTemplateReset(response) {
        FlashService.setMessage({
            'type' : 'success',
            'message' : response.data.message
        })
    }

}
EmailsController.$inject = ['FlashService', 'UserService'];
export default EmailsController;