import { toYMD } from "../../../common/helpers/time";
import VehiclesModel from "../../../common/models/vehicles-model";
import viewCourseModule from "../../courses/view/view-course.module";

class AdminReportcardsController {
  constructor(
    $state,
    $scope,
    ReportcardsModel,
    FlashService,
    $window,
    instructors
  ) {
    this.$state = $state;
    this.$scope = $scope;
    this.ReportcardsModel = ReportcardsModel;
    this.FlashService = FlashService;
    this.$window = $window;
    this.selectAllReportCards = false;
    this.filterParams = {
      instructor: null,
      status: null,
      page_by: 50
    };
    this.instructors = [{ id: 0, fullname: "All" }, ...instructors];
    this.datepickers = [
      {
        open: ($event) => {
          this.datepickers[0].status.opened = true;
        },
        dateOptions: {
          formatYear: "yy",
          startingDay: 7,
          showWeeks: false,
        },
        format: "dd-MMMM-yyyy",
        status: {
          opened: false,
        },
      },
      {
        open: ($event) => {
          this.datepickers[1].status.opened = true;
        },
        dateOptions: {
          formatYear: "yy",
          startingDay: 7,
          showWeeks: false,
        },
        format: "dd-MMMM-yyyy",
        status: {
          opened: false,
        },
      },
    ];
    this.reportcards = [];
    this.selection = [];
    this.busy = false;
    this.currentPage = 1;
    this.page_by = 50;

    this.setup();

    this.$scope.$watch(
      "vm.reportcards|filter:{selected:true}",
      (nv) => {
        if (typeof nv !== "undefined") {
          this.selection = nv.map((entity) => {
            return entity;
          });
        }
      },
      true
    );
  }

  selectAll() {
    this.reportcards = this.reportcards.map(reportcard => {
      reportcard.selected = this.selectAllReportCards === true;
      return reportcard;
    });
  }

  deleteEntities() {
    const confirm = this.$window.confirm(
      "Are you sure you want to deleted the selected entities?"
    );
    if (!confirm) {
      return;
    }
    const payload = {
      reportcards: this.selection.map((card) => {
        return { id: card.id };
      }),
    };
    this.busy = true;
    this.ReportcardsModel.destroyCollection(payload)
      .then((response) => {
        this.FlashService.setMessage({
          type: "success",
          message: response.message,
        });
        this.search();
      })
      .catch((Error) => (this.busy = false));
  }

  setup() {
    this.busy = true;

    this.ReportcardsModel.get(this.queryParams())
      .then((response) => {
        response.reportcards.data = response.reportcards.data.map(
          this.formatReportCard
        );
        this.reportcards = response.reportcards.data;
        this.totalItems = response.reportcards.total;
        this.busy = false;
      })
      .catch((Error) => {
        this.busy = false;
      });
  }

  search() {
    this.busy = true;

    this.ReportcardsModel.get(this.queryParams())
      .then((response) => {
        response.reportcards.data = response.reportcards.data.map(
          this.formatReportCard
        );
        this.reportcards = response.reportcards.data;
        this.totalItems = response.reportcards.total;
        this.page_by = response.reportcards.per_page;
        this.busy = false;
      })
      .catch((Error) => {
        this.busy = false;
      });
  }

  gradeReportCard(reportcard) {
    if (reportcard.type.name === "Pass/Fail") {
      this.$state.go("drivecoach.admin.createpassfailreportcard", {
        driveId: reportcard.drive_id,
        studentId: reportcard.student_id,
      });
    }
    if (reportcard.type.name === "Graded") {
      this.$state.go("drivecoach.admin.createreportcard", {
        driveId: reportcard.drive_id,
        studentId: reportcard.student_id,
      });
    }
    if (reportcard.type.name === "Standard") {
      this.$state.go("drivecoach.admin.gradegenericreportcard", {
        driveId: reportcard.drive_id,
        studentId: reportcard.student_id,
      });
    }
  }

  formatReportCard(reportcard) {
    if (reportcard.drive) {
      reportcard.lesson_datetime =
        reportcard.drive.drive_date + " " + reportcard.drive.start_time;
    } else {
      reportcard.lesson_datetime = "N/A";
    }
    return reportcard;
  }

  queryParams() {
    let params = {
      page: this.currentPage,
      per_page: this.filterParams.page_by,
    };
    if (this.filterParams.instructor) {
      params.instructor_id = this.filterParams.instructor.id;
    }
    if (this.filterParams.start_date) {
      params.start_date = toYMD(this.filterParams.start_date);
    }
    if (this.filterParams.end_date) {
      params.end_date = toYMD(this.filterParams.end_date);
    }
    if (this.filterParams.status) {
      params.status = this.filterParams.status;
    }
    return params;
  }

  deleteReportCard(reportcard_id) {
    const confirm = this.$window.confirm(
      "Are you sure you want to delete this report card? THIS CANNOT BE UNDONE."
    );
    if (confirm === false) {
      return;
    }
    this.busy = true;
    this.ReportcardsModel.destroy({ id: reportcard_id })
      .then((response) => {
        this.busy = false;
        this.reportcards = this.reportcards.filter((reportcard) => {
          return reportcard.id !== reportcard_id;
        });
        this.FlashService.setMessage({
          type: "success",
          message: response.message,
        });
      })
      .catch((Error) => {
        this.busy = false;
      });
  }

  unlockReportCard(reportcard_id) {
    this.busy = true;
    this.ReportcardsModel.unlockReportCard(reportcard_id).then((response) => {
      this.busy = false;
      this.reportcards = this.reportcards.map((reportcard) => {
        if (reportcard.id === reportcard_id) {
          reportcard.status = "Unlocked";
        }
        return reportcard;
      });
      this.FlashService.setMessage({
        type: "success",
        message: response.message,
      });
    });
  }

  pageChanged() {
    this.busy = true;
    this.ReportcardsModel.get(this.queryParams())
      .then((response) => {
        this.busy = false;
        response.reportcards.data = response.reportcards.data.map(
          this.formatReportCard
        );
        this.reportcards = response.reportcards.data;
      })
      .catch((Error) => (this.busy = false));
  }
}

AdminReportcardsController.$inject = [
  "$state",
  "$scope",
  "ReportcardsModel",
  "FlashService",
  "$window",
  "instructors",
];

export default AdminReportcardsController;
