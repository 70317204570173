import angular from 'angular'
import controller from './adhoc-pickup-locations.controller'
import template from './adhoc-pickup-locations.html'
import {isJson} from "../../../common/helpers/json";
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.settings.adhocpickuplocations', {
            url : '/custom-pickup-locations',
            controller : controller,
            controllerAs: 'vm',
            template: template,
            resolve: {
                PolicyData: getPolicy
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

function getPolicy(PoliciesModel) {
    return PoliciesModel.getByID('drives').then(response => {
        let policy = response.policy;
        let policyMeta = {};
        policy.policymeta.forEach(meta => {
            policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
        });
        policy.policymeta = policyMeta;

        policy.policymeta.id = policy.id;
        if (!policyMeta.hasOwnProperty('allow_adhoc_pickup_locations')) {
            return {
                'id' : policy.id,
                'FeatureEnabled' : false
            };
        }
        return {
            'id' : policy.id,
            'FeatureEnabled' : policyMeta.allow_adhoc_pickup_locations === "Y",
        }
    })
}
getPolicy.$inject = ['PoliciesModel'];

export default angular.module('admin.settings.adhocpickuplocations', [])
    .config(config)
    .controller('AdhocPickupLocationsSettingsController', controller)