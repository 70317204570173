import angular from 'angular'
import template from './instructor-view-time-off-request-detail.html'
import controller from './instructor-view-time-off-request-detail.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.timeoffrequests.view', {
            'url' : '/:timeOffRequestId/view',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.timeoffrequests.view', [] )
    .config(config)
    .controller( 'InstructorsViewTimeOffRequestsViewController', controller )
;
