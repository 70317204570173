function LocationsModel( $http , APPURL) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/locations',
            UPDATE : APPURL + '/api/v1/locations',
            CREATE : APPURL + '/api/v1/locations'
        },
        locations,
        location;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        locations = extract( result );
        return locations;
    }

    function cacheResult( result ) {
        location = extract( result );
        if ( location.address ) {
            location.address = angular.fromJson( location.address );
        }
        return location;
    }

    model.getUserLocations = function ( user ) {
        var user_id;
        if ( typeof user !== 'object' ) {
            user_id = user;
        } else {
            user_id = user.id;
        }

        return $http.get( URLS.FETCH ).then( function ( response ) {
            return response.data.filter( function ( location ) {
                location.address = angular.fromJson( location.address );
                return location.user_id === user_id;
            } )
        } )
    };

    model.geocode = function ( data ) {
        return $http.post( URLS.FETCH + '/geocode', { 'data' : data } ).then( cacheResult );
    };

    model.getLocationByID = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResult );
    };

    model.getLocations = function () {
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.createLocation = function ( location ) {
        return $http.post( URLS.FETCH, location ).then( cacheResults );
    };

    model.editLocation = function ( location ) {
        return $http.put( URLS.FETCH + '/' + location.id, location ).then( cacheResults );
    };

    model.deleteLocation = function ( location ) {
        return $http.delete( URLS.FETCH + '/' + location ).then( cacheResults );
    };

    model.disableLocation = function ( location ) {
        return $http.patch( URLS.FETCH + '/' + location.id + '/disable', {'id' : location.id, 'status' : 'Disabled'} ).then( cacheResults );
    };

    model.activateLocation = function ( location ) {
        return $http.patch( URLS.FETCH + '/' + location.id + '/activate', {'id' : location.id, 'status' : 'Enabled'} ).then( cacheResults );
    };

    model.createClassroom = function ( data ) {
        return $http.post( URLS.FETCH + '/' + data.location_id + '/classrooms', data ).then( cacheResults );
    };

    model.editClassroom = function ( data ) {
        return $http.put( URLS.FETCH + '/' + data.location_id + '/classrooms/' + data.id, data ).then( cacheResults );
    };

    model.getTravelDistanceBetween = function ( data ) {
        return $http.post( URLS.FETCH + '/traveldistance', data ).then( cacheResults );
    };

    model.getZones = (location_id) => {
        return $http.get( URLS.FETCH + '/' + location_id + '/zones' ).then( cacheResults );
    };

    model.placePickupLocation = (data) => {
        return $http.get( APPURL + '/api/v1/pickuplocations/place?' + toQueryString(data))
            .then(function(response){
                return response.data;
            });
    }

}
LocationsModel.$inject = ['$http', 'APPURL'];
export default LocationsModel;
