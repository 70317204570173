import {States} from "../../../../../../../common/constants/states";

class AdminSchoolsLocationsZonesIndexController {

    constructor($state, LocationsModel, SchoolService, ZonesModel, $stateParams, FlashService)
    {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SchoolService = SchoolService;
        this.ZonesModel = ZonesModel;
        this.FlashService = FlashService;
        this.states = States;
        this.busy = false;

        LocationsModel.getLocationByID($stateParams.locationId).then(response => {
            this.location = response;
        });
        LocationsModel.getZones($stateParams.locationId).then(response => {
            this.zones = response.zones;
        })
    }

    updateZone(zone) {
        this.busy = true;
        this.ZonesModel.editFallbackLocation(zone).then(response => {
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'success'
            })
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        })
    }

}
AdminSchoolsLocationsZonesIndexController.$inject = ['$state', 'LocationsModel', 'SchoolService', 'ZonesModel', '$stateParams', 'FlashService']
export default AdminSchoolsLocationsZonesIndexController;