class PolicyMeta {
    constructor(id, policy_id, key, value) {
        this.$id = id;
        this.$policy_id = policy_id;
        this.$meta_key = key;
        this.$meta_value = value;
    }


    id() {
        return this.$id;
    }


    key() {
        return this.$meta_key;
    }

    value() {
        return this.$meta_value;
    }

    setValue(value){
        this.$meta_value = value;
    }
}
export { PolicyMeta }