import angular from 'angular'
import template from './campus-zones.html'
import AdminSchoolsLocationsZonesController from './campus-zones.controller'
import ZonesIndex from './index/campus-zones-index.module'
import ZonesCreate from './create/admin-campus-create-zone.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.schools.view.locations.zones', {
            'url': '/zones',
            'abstract': true,
            'controller': AdminSchoolsLocationsZonesController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


export default angular.module('admin.dashboard.schools.view.locations.zones', [
    ZonesIndex.name,
    ZonesCreate.name
]).config(config)
    .controller('AdminSchoolsLocationsZonesController', AdminSchoolsLocationsZonesController)
;
