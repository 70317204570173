import angular from 'angular'
import template from './agenda.html'
import AdminAgendaController from './admin-dashboard-agenda.controller'

function prepareFilters(CalendarModel, DrivesFilterService) {
    const availableServices = DrivesFilterService.getFilterOptions();
    if( availableServices.services.length > 0 ){
        return availableServices;
    }
    return CalendarModel.getFilterOptions().then(response => {
        DrivesFilterService.setFilterOptions(response.options);
        return response.options;
    });
}
prepareFilters.$inject = ['CalendarModel', 'DrivesFilterService'];


function config($stateProvider, $urlRouterProvider, USER_ROLES){
    $stateProvider
        .state('drivecoach.admin.agenda', {
            'url' : '/agenda/:date',
            'controller' : AdminAgendaController,
            'controllerAs' : 'vm',
            'resolve': {
                'filterOptions' : prepareFilters
            },
            'template' : template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator,
                    USER_ROLES.staff
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.calendar.agenda', [])
    .config(config)
    .controller('AdminAgendaController', AdminAgendaController)
;
