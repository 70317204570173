"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EditLessonPlanController = /** @class */ (function () {
    function EditLessonPlanController($state, $stateParams, LessonPlanModel, FlashService, courseTypes, sessionTypes, lessonPlan, $window) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$window = $window;
        this.LessonPlanModel = LessonPlanModel;
        this.FlashService = FlashService;
        this.loading = true;
        this.submitting = false;
        this.courseTypes = courseTypes;
        this.sessionTypes = sessionTypes;
        this.lessonPlan = lessonPlan;
        this.displayLessonPlanType = this.displayLessonPlanType.bind(this);
        this.requirementTypes = [
            { name: "Permit", value: "permit" },
            { name: "Course", value: "course_type" },
            { name: "Session Type", value: "session_type" },
        ];
        this.requirement = {
            type: "",
            sequence: 0,
            sessionType: { id: 0 },
            courseType: { id: 0 },
            days: 0,
            hours_required: 0,
        };
    }
    EditLessonPlanController.prototype.displayLessonPlanType = function (type) {
        if (type === "course_type") {
            return "Course Type";
        }
        if (type === "session_type") {
            return "Session Type";
        }
        if (type === "permit") {
            return "Permit";
        }
        return "";
    };
    EditLessonPlanController.prototype.addRequirement = function () {
        var _this = this;
        var payload = {
            type: this.requirement.type,
            session_type_id: this.requirement.sessionType.id,
            course_type_id: this.requirement.courseType.id,
            days: this.requirement.days,
            sequence: this.requirement.sequence,
            minutes_required: this.requirement.hours_required * 60,
        };
        this.LessonPlanModel.addRequirement(this.$stateParams.lessonPlanID, payload).then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.lessonPlan.requirements.push(response.requirement);
            _this.requirement = {
                type: "",
                sequence: 0,
                sessionType: { id: 0 },
                courseType: { id: 0 },
                days: 0,
                hours_required: 0,
            };
            _this.lessonPlan.requirements = _this.lessonPlan.requirements.sort(function (a, b) {
                return a.sequence > b.sequence ? 1 : -1;
            });
        });
    };
    EditLessonPlanController.prototype.removeFromLessonPlan = function (index, requirement) {
        var _this = this;
        var $confirm = this.$window.confirm("Are you sure you want to delete this rule?");
        if ($confirm === false) {
            return;
        }
        this.lessonPlan.requirements.splice(index, 1);
        this.LessonPlanModel.deleteRequirement(this.$stateParams.lessonPlanID, requirement.id).then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
        });
    };
    EditLessonPlanController.prototype.saveLessonPlan = function () {
        var _this = this;
        this.submitting = true;
        var plan = {
            name: this.lessonPlan.name,
        };
        this.LessonPlanModel.update(this.lessonPlan.id, plan)
            .then(function (response) {
            _this.submitting = false;
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
        })
            .catch(function (Error) {
            _this.submitting = false;
        });
    };
    return EditLessonPlanController;
}());
EditLessonPlanController.$inject = [
    "$state",
    "$stateParams",
    "LessonPlanModel",
    "FlashService",
    "courseTypes",
    "sessionTypes",
    "lessonPlan",
    "$window",
];
exports.default = EditLessonPlanController;
