function ExportsModel($http, APPURL) {
  let model = this,
    URLS = {
      FETCH: APPURL + "/api/v1/exports",
    },
    data;
  function extract(result) {
    return result.data;
  }

  function cacheResults(result) {
    data = extract(result);
    return data;
  }

  model.fetchReport = function(report_type) {
    return $http
      .get(URLS.FETCH + "?export_type=" + report_type)
      .then(cacheResults);
  };
}
ExportsModel.$inject = ['$http', 'APPURL'];
export default ExportsModel;