import template from './reportcard.html'
function dcReportcard() {
    return {
        restrict : 'E',
        scope : {
            'reportcard' : '='
        },
        template : template,
        link :  (scope, element, attrs ) => {

        },
        controller: 'dcReportcardController',
        controllerAs: 'vm',
        bindToController: true
    }
}
dcReportcard.$inject = [];
export default dcReportcard;
