import {HttpClient} from '../core/HttpClient'

const PreviewEmail = function(template){
    return HttpClient.post('/post-office/email-templates/preview', template);
}

const PublishEmailTemplate = function(templateData) {
    let payload = {...templateData};
    delete payload.key;
    return HttpClient.put('/post-office/email-templates/' + templateData.key, payload);
}

const GetEmailTemplates = function() {
    return HttpClient.get('/post-office/email-templates');
}

const DeleteEmailTemplates = function(template) {
    return HttpClient.delete('/post-office/email-templates/' + template.name);
}

const SendSampleEmail = function(sampleData){
    return HttpClient.post('/post-office/send-sample', {
        template_name: sampleData.name, recipient: sampleData.recipient
    })
}
export {
    PreviewEmail,
    PublishEmailTemplate,
    GetEmailTemplates,
    DeleteEmailTemplates,
    SendSampleEmail
}