import angular from 'angular'
import AdminCalendarController from './admin-dashboard-calendar.controller'
import template from './calendar.html'

function prepareCalendar(CalendarModel, DrivesFilterService) {
    return CalendarModel.getFilterOptions().then(response => {
        DrivesFilterService.setFilterOptions(response.options);
        return response.options;
    });
}
prepareCalendar.$inject = ['CalendarModel', 'DrivesFilterService'];


function getUser(UsersModel, UserService) {
    const currentUser = UserService.getCurrentUser();
    return UsersModel.getUserByID(currentUser.id).then(response => {
        response.role = currentUser.role;
        return response;
    });
}
getUser.$inject = ['UsersModel', 'UserService'];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.calendar', {
            'url': '/calendar',
            'controller': AdminCalendarController,
            'controllerAs': 'vm',
            'template': template,
            'resolve': {
                'user': getUser,
                'filterOptions' : prepareCalendar
            },
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.calendar', [])
    .config(config)
    .controller('AdminCalendarController', AdminCalendarController)
