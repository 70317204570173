import angular from 'angular'
import template from './admin-settings-policies-cancellations.html'
import AdminSettingsPoliciesCancellationsController from './admin-settings-policies-cancellations.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.settings.policies.cancellations', {
            url: '/cancellations',
            controller: AdminSettingsPoliciesCancellationsController,
            controllerAs: 'vm',
            template: template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.settings.policies.cancellations', [])
    .config(config)
    .controller('AdminSettingsPoliciesCancellationsController', AdminSettingsPoliciesCancellationsController)
