"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function ZonesModel($http, APPURL) {
    var model = this, URLS = {
        FETCH: APPURL + '/api/v1/zones',
        UPDATE: APPURL + '/api/v1/zones',
        CREATE: APPURL + '/api/v1/zones'
    }, zones;
    function extract(result) {
        return result.data;
    }
    function cacheResults(result) {
        zones = extract(result);
        return zones;
    }
    model.getZones = function () {
        return $http.get(URLS.FETCH).then(cacheResults);
    };
    model.createZone = function (zone) {
        return $http.post(URLS.CREATE, zone).then(cacheResults);
    };
    model.edit = function (zone) {
        return $http.post(URLS.FETCH + '/' + zone.id, zone).then(cacheResults);
    };
    model.editFallbackLocation = function (zone) {
        var data = {
            'location': {
                'name': zone.fallback_pickup_location.name,
                'address': zone.fallback_pickup_location.address
            }
        };
        return $http.put(URLS.FETCH + '/' + zone.id + '/fallback-location', data).then(cacheResults);
    };
    model.deleteZone = function (id) {
        return $http.delete(URLS.FETCH + '/' + id).then(cacheResults);
    };
    model.deleteSector = function (zone_id, sector_id) {
        return $http.delete(URLS.FETCH + '/' + zone_id + '/sectors/' + sector_id).then(cacheResults);
    };
}
ZonesModel.$inject = ['$http', 'APPURL'];
exports.default = ZonesModel;
