import moment from 'moment'
import { isJson } from '../../../common/helpers/json'
import StudentDetailsTemplate from './instructor-view-student-details-modal.html'
import ViewStudentDetailsModalController from './instructor-view-student-details-modal.controller'
import AddStudentModalInstanceCtrl from '../../../admin/drives/add-student-modal/add-student-modal.controller'
import AddStudentModalTemplate from '../../../admin/drives/add-student-modal/add-student-modal.html'
import { timeStringToDate } from '../../../common/helpers/time'

class InstructorDriveController {

    constructor($uibModal, $state, UserService, $q, $stateParams, UsersModel, DrivesModel, FlashService, policyData) {
        this.loading = true;
        this.submitting = true;
        this.$uibModal = $uibModal;
        this.UserService = UserService;
        this.$q = $q;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;
        this.policyData = policyData;

        this.drive = {};
        this.pickuplocation = {};
        this.dropofflocation = {};

        this.pickuplocations = [];
        this.dropofflocations = [];

        this.addingStudent = false;
        this.canViewStudentOrders = false;
        this.canBookDrivingLessons = false;
        this.instructor = UserService.getCurrentUser();
        this.canGrade = false;
        this.useReportCards = false;

        this.setup = this.setup.bind(this);
        this.removePassenger = this.removePassenger.bind(this);
        this.updateSession = this.updateSession.bind(this);
        this.sessionBooked = this.sessionBooked.bind(this);

        this.setup().then(response => {
            this.loading = false;
        }).catch(Error => {
            console.error(Error);
            this.loading = false;
        });
    }

    sessionBooked()
    {
        if (this.drive.observation === false) {
            return this.drive.student_id !== null;
        }

        return this.drive.students.length > 0;
    }

    resetAddingStudent()
    {
        this.pickuplocations = [];
        this.pickuplocation = {};
        this.dropofflocations = [];
        this.dropofflocation = {};
        this.addingStudent = false;
        delete this.drive.student;
        this.drive.student_id = null;
    }

    setpolicies(response) {
        this.school = response.school;
    }

    getStudentAge(dob) {
        let birthday = moment(dob, 'YYYY-MM-DD').toDate();
        return moment(new Date()).diff(moment(birthday), 'years');
    }

    setdrive(response) {

        this.drive = response.drive;

        if (this.drive.status !== 'Available') {
            if (this.drive.student_id !== null) {
                let hasGuardian = this.drive.student.usermeta.filter(meta => {
                    return meta.meta_key === 'guardian';
                });
                let parentMeta;
                if (hasGuardian.length === 1) {
                    parentMeta = hasGuardian[0].meta_value;
                    this.drive.student.guardian = JSON.parse(parentMeta);
                }
                this.drive.student.phone = this.drive.student.usermeta.filter(meta => {
                    return meta.meta_key === 'phone';
                })[0].meta_value;
                const dob_meta = this.drive.student.usermeta.find(meta => meta.meta_key === 'dob');
                if (dob_meta) {
                    this.drive.student.dob = dob_meta.meta_value;
                } else {
                    this.drive.student.dob = "";
                }
                const pronoun_meta = this.drive.student.usermeta.find(meta => meta.meta_key === 'preferred_pronoun');
                if (pronoun_meta) {
                    this.drive.student.preferred_pronoun = pronoun_meta.meta_value;
                } else {
                    this.drive.student.preferred_pronoun = "";
                }
            } else {
                if (this.drive.observation === true) {
                    this.drive.students = this.drive.students.map(student => {
                        let hasGuardian = student.usermeta.filter(meta => {
                            return meta.meta_key === 'guardian';
                        });
                        let parentMeta;
                        if (hasGuardian.length === 1) {
                            parentMeta = hasGuardian[0].meta_value;
                            student.guardian = JSON.parse(parentMeta);
                        }
                        student.phone = student.usermeta.filter(meta => {
                            return meta.meta_key === 'phone';
                        })[0].meta_value;
                        const dob_meta = student.usermeta.find(meta => meta.meta_key === 'dob');
                        if (dob_meta) {
                            student.dob = dob_meta.meta_value;
                        } else {
                            student.dob = "";
                        }
                        const pronoun_meta = student.usermeta.find(meta => meta.meta_key === 'preferred_pronoun');
                        if (pronoun_meta) {
                            student.preferred_pronoun = pronoun_meta.meta_value;
                        } else {
                            student.preferred_pronoun = "";
                        }
                        return student;
                    });
                }
            }
        }

        this.drive.startTime = moment(this.drive.drive_date + ' ' + this.drive.start_time, 'YYYY-MM-DD HH:mm:s').format('h:mm a');

        const minutes = moment.duration(this.drive.session_length, 'minutes');
        const endTimeMoment = moment(this.drive.drive_date + ' ' + this.drive.start_time, 'YYYY-MM-DD HH:mm:s').add(minutes, 'minutes');
        this.endTimeMoment = endTimeMoment;

        this.drive.endTime = endTimeMoment.format('h:mm a');

        this.drive.duration = moment.duration(this.drive.session_length, 'minutes').asHours();
        this.drive.duration = moment.duration(this.drive.duration, 'hours').humanize();

        this.driveDate = moment(this.drive.drive_date + ' ' + this.drive.start_time);
        this.untilDrive = this.driveDate.fromNow();

        // TODO: replace with drive.end
        const driveEnd = moment(this.drive.drive_date + ' ' + this.drive.start_time).add(minutes, 'minutes');
        this.drive.inProgress = moment().isBetween(this.driveDate, driveEnd);
        // "now" is after the drive date
        this.drive.inPast = moment().isAfter(this.driveDate);
    }

    /**
     * Set up the policies and drive data for the view
     * @param data the data resolved from $q.all(), [0] is the user/school data for policies and [1] is the drive
     */
    async setup() {
        const getSettings  = await this.PoliciesModel.getDriveByID('studentmetadata');
        const getuser = await this.UsersModel.getInstructorByID(this.instructor.id);
        const getdrive = await this.DrivesModel.getDriveByID(this.$stateParams.driveId);

        this.preferred_pronoun_enabled = false;
        // let policyMeta: {[key:string]:any} = {};
        let policyMeta = {};
        getSettings.policy.policymeta.forEach(meta => {
            policyMeta[meta.meta_key] = meta.meta_value;
        });
        if (policyMeta.preferred_pronoun) {
            this.preferred_pronoun_enabled = true;
        }

        this.setdrive(getdrive);

        if (this.policyData.InstructorCanViewStudentOrders.FeatureEnabled) {
            this.canViewStudentOrders = true;
        }
        if (this.policyData.InstructorCanBookDrivingLessons.FeatureEnabled) {
            this.canBookDrivingLessons = true;
        }
        if (this.policyData.UseReportCards.FeatureEnabled) {
            this.useReportCards = true;
        }

        if (this.policyData.AllowGradeDuringDrive.FeatureEnabled && this.drive.inProgress) {
            this.canGrade = true;
        } else {
            this.canGrade = false;
        }

        if (this.drive.status === 'Taken' && moment().isAfter(this.endTimeMoment)) {
            this.canGrade = true;
        } else {
            this.canGrade = false;
        }

        if (this.drive.status !== 'Complete' && this.policyData.AllowGradeDuringDrive.FeatureEnabled === true && this.driveDate.isBefore(moment())) {
            this.canGrade = true;
        } else {
            this.canGrade = false;
        }

        if (this.drive.status !== 'Complete' && this.policyData.AllowGradeDuringDrive.FeatureEnabled === false && this.endTimeMoment.isBefore(moment())) {
            this.canGrade = true;
        } else {
            this.canGrade = false;
        }
    }

    showGradeAndCancelOptions() {
        let hasStudent;
        if (this.drive.observation === false) {
            hasStudent = this.drive.student_id !== null;
        } else {
            hasStudent = this.drive.students.length > 0;
        }

        const inProgressOrUngradedInPast = this.drive.inProgress || (this.drive.status !== 'Complete' && this.drive.inPast);
        return hasStudent && inProgressOrUngradedInPast;
    }

    removePassenger(index) {
        this.drive.students.splice(index, 1);
    }

    viewStudentDetails() {

        let modalInstance = this.$uibModal.open({
            animation: true,
            template: StudentDetailsTemplate,
            controller: ViewStudentDetailsModalController,
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                'student': () => this.drive.student
            }
        });

        modalInstance.result.then((cancellation) => {
            // $scope.selected = selectedItem;
        }, () => { });
    }

    viewStudentOrders(studentId) {
        this.$state.go('drivecoach.instructor.dashboard.studentorders', { 'student_id': studentId })
    }

    viewStudentNotes(studentId) {
        this.$state.go('drivecoach.instructor.dashboard.studentnotes', { 'userId': studentId })
    }

    /**
     * Add student modal
     */
    addStudentModal() {
        let drive = Object.assign({}, this.drive);
        drive.start_time = timeStringToDate(drive.start_time);

        let modalInstance = this.$uibModal.open({
            animation: true,
            template: AddStudentModalTemplate,
            controller: AddStudentModalInstanceCtrl,
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                'drivesession': () => drive,
                'toggleStudentSchoolOverride': () => false
            }
        });

        modalInstance.result.then((data) => {

            data.drivesession.student.usermeta.forEach((usermeta, index) => {
                data.drivesession.student[usermeta.meta_key] = usermeta.meta_value;
            });
            this.drive = data.drivesession;
            if (this.drive.observation === false) {
                this.drive.student_id = data.drivesession.student.id;
                this.drive.status = 'Taken';
            } else {
                this.drive.student_id = null;
                this.drive.students = data.drivesession.students;
                if (this.drive.students.length === this.drive.vehicle.seats) {
                    this.drive.status = 'Taken';
                } else {
                    this.drive.status = 'Available';
                }
            }

            this.loading = true;
            this.UsersModel.getPickupLocations(this.drive.student_id).then(response => {
                this.pickuplocations = [].concat(response.pickuplocations);
                this.dropofflocations = [].concat(response.pickuplocations);
                this.loading = false;
                this.addingStudent = true;
            }).catch(Error => {
                this.loading = false;
                this.addingStudent = false;
            });

        });
    }


    updateSession() {

        this.submitting = true;
        const data = {
            'drive_id': this.drive.id,
            'student_id': this.drive.student.id,
            'pickuplocation': this.pickuplocation,
            'dropofflocation': this.dropofflocation
        };

        this.DrivesModel.claimDrive(data).then(response1 => {
            this.DrivesModel.getDriveByID(this.drive.id).then(response => {
                this.setdrive(response);
                this.FlashService.setMessage({ 'type': 'success', 'message': response1.message });
                this.addingStudent = false;
                this.submitting = false;
            }).catch(Error => {
                this.addingStudent = false;
                this.submitting = false;
            });
        });

    }

    cleanGeom(sector) {
        delete sector.geom;
        return sector;
    }
}
InstructorDriveController.$inject = ['$uibModal', '$state', 'UserService', '$q', '$stateParams', 'UsersModel', 'DrivesModel', 'FlashService', 'policyData'];
export default InstructorDriveController;
