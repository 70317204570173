import {toQueryString} from "../helpers/queryString";

function ProductCategoriesModel($http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/crm/product-categories',
            UPDATE : APPURL + '/api/v1/crm/product-categories',
            CREATE : APPURL + '/api/v1/crm/product-categories'
        },
        events;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        events = extract( result );
        return events;
    }

    model.show = (id) => {
        return $http.get(URLS.FETCH + '/' + id).then( cacheResults );
    };

    model.get = (data = null) => {
        let url = URLS.FETCH;
        if(data !== null){
            url = URLS.FETCH + '?' + toQueryString( data );
        }
        return $http.get(url).then( cacheResults );
    };

    model.update = ( request ) => {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.toggleData = (product) => {
        return $http.patch(URLS.FETCH + '/toggle/' + product.id, product).then(cacheResults)
    }

    model.create = ( request ) => {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.delete = ( data ) => {
        return $http.delete( URLS.FETCH + '/' + data.id ).then( cacheResults );
    };

    model.search = ( params ) => {
        return $http.post( URLS.FETCH + '/search', params ).then( cacheResults );
    };

}
ProductCategoriesModel.$inject = ['$http', 'APPURL'];
export default ProductCategoriesModel;