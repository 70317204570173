import angular from 'angular'
import template from './admin-edit-instructor.html'
import AdminInstructorsEditController from './admin-edit-instructor.controller'
import AdminInstructorEditContactInfo from './contact-info/admin-edit-instructor-contact-info.module'
import AdminInstructorEditCertifications from './certifications/admin-edit-instructor-certifications.module'
import AdminInstructorEditAvailability from './availability/admin-edit-instructor-availability.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.instructors.edit', {
            'url': '/:instructorId/edit',
            'controller': AdminInstructorsEditController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })

    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.instructors.edit', [
    AdminInstructorEditContactInfo.name,
    AdminInstructorEditAvailability.name,
    AdminInstructorEditCertifications.name
])
    .config(config)
    .controller('AdminInstructorsEditController', AdminInstructorsEditController)

