import angular from 'angular'
import template from './admin-events.html'
import AdminEventsController from './admin-events.controller'
import Create from './create/admin-create-event.module'
import Edit from './edit/admin-edit-event.module'
import Batch from './batchcreate/admin-batch-create-events.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.events', {
            url: '/events',
            template: template,
            controller: AdminEventsController,
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.events', [
    Create.name,
    Edit.name,
    Batch.name
]).config(config)
    .controller('AdminEventsController', AdminEventsController)
;
