import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

function dcStudentFooterNavController( $rootScope, $window, UserService ) {
    const vm = this;
    vm.student = UserService.getCurrentUser();
    vm.studentCanSchedule = true;
    filter( vm.student.school.policies , ( policy, key) => {
        return key === 'drives';
    }).forEach( drives => {
        if( drives.policymeta.hasOwnProperty('allow_students_to_schedule') && ! isEmpty( drives.policymeta.allow_students_to_schedule ) ){
            vm.studentCanSchedule = drives.policymeta.allow_students_to_schedule == 1;
        }
    });

    vm.columnClass = ((vm.student.status === 'Active' && vm.studentCanSchedule === true) ? 'col-xs-5' : 'col-xs-6');

    vm.navBack = () => {
        if ( $rootScope.getHistory().length > 1 ) {
            $window.history.back();
        }
    }
}
dcStudentFooterNavController.$inject = ['$rootScope', '$window', 'UserService'];
export default dcStudentFooterNavController;