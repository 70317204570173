import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcViewCourse from './ViewCourse.vue'
import AdminViewCourseController from './view-course.controller'
import template from './admin-view-course.html'

function getCourse( $stateParams, CoursesModel ) {
  return CoursesModel.show( $stateParams.courseId ).then( response => response.course );
}
getCourse.$inject = ['$stateParams', 'CoursesModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
  $stateProvider
    .state( 'drivecoach.admin.courses.view', {
    'url' : '/view/:courseId',
    'template' : template,
    'controller' : AdminViewCourseController,
    'controllerAs' : 'vm',
    resolve : {
      course : getCourse,
    },
    data : {
      authorizedRoles : [
        USER_ROLES.admin,
        USER_ROLES.administrator,
        USER_ROLES.owner
      ]
    }
  } )
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.courses.view', [] )
  .config(config)
  .controller( 'AdminViewCourseController', AdminViewCourseController )
  .directive('dcViewCourse', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcViewCourse', dcViewCourse))
  }])
;
