import moment from 'moment'

function DrivesFilterService( CalendarModel ) {
    const service = this;

    /**
     * The collection to be sent to the calendar/agenda
     * @type array
     */
    service.items = [];

    service.agenda = [];

    /**
     * Filter parameters object
     * @type {{}}
     */
    service.filterParams = {
        'status': 'all'
    };

    service.month = moment().format('M');

    service.year = moment().format('YYYY');

    service.clearFilterOptions = clearFilterOptions;
    service.setMonth = setMonth;
    service.setYear = setYear;
    service.setDate = setDate;
    service.setStartDate = setStartDate;
    service.setEndDate = setEndDate;
    service.getDate = getDate;
    service.getMonth = getMonth;
    service.getYear = getYear;
    service.getStartDate = getStartDate;
    service.getEndDate = getEndDate;
    service.getFilterOptions = getFilterOptions;
    service.setFilterOptions = setFilterOptions;
    service.setFilterParameters = setFilterParameters;
    service.getFilterParameters = getFilterParameters;
    service.clearFilterParameters = clearFilterParameters;
    service.setAgenda = setAgenda;
    service.getAgenda = getAgenda;

    /**
     * Object of filter option arrays
     * @type {{services: Array, courses: Array, instructors: Array, locations: Array, sectors: Array, classrooms: Array, vehicles: Array, filterLocations: Array}}
     */
    service.filterOptions = {
        'services' : [],
        'courses' : [],
        'instructors' : [],
        'locations' : [],
        'sectors' : [],
        'classrooms' : [],
        'vehicles' : [],
        'filterLocations' : [],
        'days' : 'all', // for the student filter
        'times' : 'all' // for the student filter
    };

    /**
     * Clear the filter options and reset them to the default empty object/arrays
     * @returns {{services: Array, courses: Array, instructors: Array, locations: Array, sectors: Array, classrooms: Array, vehicles: Array, filterLocations: Array}|*}
     */
    function clearFilterOptions() {
        service.filterOptions = {
            'services' : [],
            'courses' : [],
            'instructors' : [],
            'locations' : [],
            'sectors' : [],
            'classrooms' : [],
            'vehicles' : [],
            'filterLocations' : [],
            'days' : 'all', // for the student filter
            'times' : 'all' // for the student filter
        };
        service.month = moment().format('M');
        service.year = moment().format('YYYY');
        return service.filterOptions;
    }

    function setMonth(data){
        service.month = data;
    }

    function setYear(data){
        service.year = data;
    }

    function getMonth(){
        return service.month;
    }

    function getYear(){
        return service.year;
    }

    function setDate(date){
        service.filterParams.date = date;
    }

    function getDate(){
        return service.filterParams.date;
    }
  
    function setStartDate(data) {
      service.startDate = data
    }
  
    function getStartDate() {
      return moment(service.startDate).format('YYYY-MM-DD')
    }
  
    function setEndDate(data) {
      service.endDate = data
    }
  
    function getEndDate() {
      return moment(service.endDate).format('YYYY-MM-DD')
    }

    /**
     * Get the filter options
     * @returns {{services: Array, courses: Array, instructors: Array, locations: Array, sectors: Array, classrooms: Array, vehicles: Array, filterLocations: Array}|*}
     */
    function getFilterOptions() {
        return service.filterOptions;
    }

    /**
     * Prepares the filter options object used to determin what the admin can filter the calendar by
     * @param data
     * @returns {{services: Array, courses: Array, instructors: Array, locations: Array, sectors: Array, classrooms: Array, vehicles: Array, filterLocations: Array}}
     */
    function setFilterOptions( data ) {
        service.filterOptions = data;
        return service.filterOptions;
    }

    /**
     * Set the filter parameters
     * @param params
     */
    function setFilterParameters( params ) {
        service.filterParams = params;
    }

    /**
     * Return the currently set filter parameters
     * @returns {{}|*}
     */
    function getFilterParameters() {
        return service.filterParams;
    }

    /**
     * Clear the filter parameters
     */
    function clearFilterParameters() {
        service.filterParams = {};
        service.month = moment().format('M');
        service.year = moment().format('YYYY');
    }

    /**
     * Set agenda items for admin dashboard
     * @param data
     * @returns {*}
     */
    function setAgenda(data){
        service.agenda = data;
        CalendarModel.setAgenda(data);
        return service.agenda;
    }

    /**
     * Get agenda items for admin dashboard
     * @returns {Array|*}
     */
    function getAgenda(){
        return service.agenda;
    }

}
DrivesFilterService.$inject = ['CalendarModel'];
export default DrivesFilterService;