import { inDollars } from '../../../../DriveScout/Billing/Teller'

class ProductController {

    constructor(){
        this.inDollars = inDollars.bind(this);
    }

}

ProductController.$inject = [];
export default ProductController;