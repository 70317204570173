<template>
  <div v-html="content">
  </div>

</template>
<script>
export default {
  name: 'email-template',

  props: {
    'template': { type: Object, default: {}},
  },
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {
    content() {
      return this.$store.state.emailEditor.preview.html;
    }
  }
}
</script>