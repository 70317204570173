import angular from 'angular'
import template from './admin-settings-storefront.html'
import controller from './admin-settings-storefront.controller'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.settings.policies.storefront', {
            url : '/storefront',
            controller : controller,
            controllerAs : 'vm',
            template: template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.settings.policies.storefront', [] )
    .config(config)
    .controller( 'AdminSettingsPoliciesStorefrontController', controller)
;
