import angular from 'angular'
import InstructorRequestTimeOffDayController from './instructor-rto-day.controller'
import template from './instructor-rto-day.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.requesttimeoff.day', {
            'url' : '/day',
            'controller' : InstructorRequestTimeOffDayController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } );
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.requesttimeoff.day', [] )
    .config(config)
    .controller( 'InstructorRequestTimeOffDayController', InstructorRequestTimeOffDayController )

