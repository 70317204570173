import angular from 'angular'
import template from './admin-edit-school.html'
import AdminSchoolEditController from './admin-edit-school.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.schools.edit', {
            'url': '/edit/:schoolId',
            'controller': AdminSchoolEditController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.schools.edit', [])
    .config(config)
    .controller('AdminSchoolEditController', AdminSchoolEditController);
