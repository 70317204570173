import angular from 'angular'
import template from './admin-drives-view-report-card.html'
import AdminDrivesViewReportCardController from './admin-drives-view-report-card.controller'

function getSession( $stateParams, DrivesModel ) {
    return DrivesModel.getDriveByID( $stateParams.id ).then( function ( response ) {
        return response.drive;
    } );
}
getSession.$inject = ['$stateParams', 'DrivesModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.drives.reportcard', {
            'url' : '/:id/report-card',
            resolve : {
                session : getSession
            },
            'controller' : AdminDrivesViewReportCardController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.drives.reportcard', [] )
    .config(config)
    .controller('AdminDrivesViewReportCardController', AdminDrivesViewReportCardController);
