import moment from 'moment'
function InstructorCertificationsController( $scope, $state, UsersModel, FlashService, UsermetaModel ) {

    const vm = this;
    let usermeta;
    vm.today = moment().format( 'M/D/YYYY' );

    vm.editCertification = editCertification;
    vm.updateExpirationDate = updateExpirationDate;
    vm.updateIssuedDate = updateIssuedDate;

    init();

    function init(){
        UsersModel.getInstructorByID( $scope.currentUser.id ).then( response => {
            response.usermeta.license.expiration_date = moment( response.usermeta.license.expiration_date ).toDate();
            vm.instructor = response;
            vm.certificate = {
                'number' : vm.instructor.usermeta.certificate.number,
                'issue_date' : moment( vm.instructor.usermeta.certificate.issue_date ).toDate(),
                'expiration_date' : moment( vm.instructor.usermeta.certificate.expiration_date ).toDate()
            };
        } );
    }

    function editCertification( isValid ) {
        vm.submitted = true;

        if ( isValid ) {
            usermeta = {
                'user_id' : vm.instructor.id,
                'meta_key' : 'certificate',
                'meta_value' : {
                    'number' : vm.certificate.number,
                    'issue_date' : moment( vm.certificate.issue_date ).format( 'YYYY-MM-DD' ),
                    'expiration_date' : moment( vm.certificate.expiration_date ).format( 'YYYY-MM-DD' )
                }
            };

            UsermetaModel.update( usermeta ).then( response => {
                FlashService.setMessage( {
                    'type' : 'success',
                    'message' : response.message
                } );
                vm.submitted = false;
                $state.go( 'drivecoach.instructor.dashboard.info' );
            } );
        } else {
            FlashService.setMessage( { 'type' : 'error', 'message' : 'Please fill the form.' } );
        }
    }
    
    function updateIssuedDate(newDate) {
      vm.certificate.issue_date = newDate.selected[0]
    }
  
    function updateExpirationDate(newDate) {
      vm.certificate.expiration_date = newDate.selected[0]
    }

}
InstructorCertificationsController.$inject = ['$scope', '$state', 'UsersModel', 'FlashService', 'UsermetaModel'];
export default InstructorCertificationsController;