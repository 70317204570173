import angular from 'angular'
import template from './admin-instructor-view-time-off-requests.html'
import AdminInstructorsViewTimeOffRequestsController from './admin-instructor-view-time-off-requests.controller'
import TimeOffRequestView from './view/admin-instructor-view-time-off-request-detail.module'
import TimeOffRequestCreate from './create/admin-instructor-create-time-off-request.module'
function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.instructors.view.timeoffrequests', {
            'url': '/:instructorId/view/time-off-requests',
            'controller': AdminInstructorsViewTimeOffRequestsController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.instructors.view.timeoffrequests', [
    TimeOffRequestView.name,
    TimeOffRequestCreate.name
]).config(config)
    .controller('AdminInstructorsViewTimeOffRequestsController', AdminInstructorsViewTimeOffRequestsController)
