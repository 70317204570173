import angular from 'angular'
import template from './admin-school-edit-vehicle.html'
import AdminSchoolsVehiclesEditController from './admin-school-edit-vehicle.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.schools.vehicles.edit', {
            'url' : '/:schoolId/edit/:vehicleId',
            'controller' : AdminSchoolsVehiclesEditController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.schools.vehicles.edit', [] )
    .config(config)
    .controller( 'AdminSchoolsVehiclesEditController', AdminSchoolsVehiclesEditController )
;
