import angular from 'angular'
import Vue from 'vue';
import 'ngVue';
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

import dcTooltip from './Tooltip.vue'

export default angular.module( 'directives.tooltip', [] )
  .directive('dcTooltip', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcTooltip', dcTooltip))
  }])
