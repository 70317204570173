function dcGoBack($rootScope, $state) {
    return {
        restrict: 'A',
        scope: {},
        link: (scope, element, attrs) => {

            // root scope on change stuff was moved to the app.start file around line 27
            // original reference: http://stackoverflow.com/questions/16635381/angularjs-ui-route-get-previous-state
            // secondary reference: http://stackoverflow.com/questions/14070285/how-to-implement-history-back-in-angular-js
            element.on('click', function() {
                if($rootScope.previousState.params){
                    $state.go($rootScope.previousState.name, $rootScope.previousState.params);
                }else{
                    $state.go($rootScope.previousState.name);
                }
            });

        }
    }
}
dcGoBack.$inject = ['$rootScope', '$state' ];
export default dcGoBack;
