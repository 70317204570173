import angular from 'angular'
import template from './admin-school-vehicles.html'
import AdminVehiclesController from './admin-school-vehicles.controller'
import AdminDashboardVehiclesIndex from './index/admin-school-vehicles-index.module'
import AdminDashboardVehiclesView from './view/admin-school-view-vehicle.module'
import AdminDashboardVehiclesCreate from './create/admin-school-create-vehicle.module'
import AdminDashboardVehiclesEdit from './edit/admin-school-edit-vehicle.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.schools.vehicles', {
            'url': '/vehicles',
            'abstract': true,
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.schools.vehicles', [
    AdminDashboardVehiclesIndex.name,
    AdminDashboardVehiclesView.name,
    AdminDashboardVehiclesCreate.name,
    AdminDashboardVehiclesEdit.name,
]).config(config)
    .controller( 'AdminVehiclesController', AdminVehiclesController );