import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import SMSMessenger from './SMSMessenger.vue'

export default angular.module( 'directives.smsmessenger', [] )
  .directive('dcSmsMessenger', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcSMSMessenger', SMSMessenger))
  }])
