class AdminTasksService {
    constructor(UserService, $rootScope, CrmTasksModel){
        this.tasks = [];
        this.currentUser = UserService.getCurrentUser();
        this.$rootScope = $rootScope;
        this.CrmTasksModel = CrmTasksModel;
    }

    setTasks(data){
        this.tasks = data;
        //this.$rootScope.$broadcast('')
    }

    clearTasks(){
        this.tasks = []
    }
    
    getTasks(){
        return this.tasks;
    }

    sortByTime(){
        this.tasks.sort(function(x, y) {
            return x.timestamp - y.timestamp;
        });
    }

    addTask(task){
        if (task.$created_by === this.currentUser.id) {
            this.tasks.push(task);
            this.tasks.sort((x, y) => {
                return x.$timestamp - y.$timestamp;
            });
            // this.setTasks(this.tasks);
        }
    }

    updateTask(task) {
        let tasks = this.tasks.filter(_task => {
            if(_task.$id === task.$id){
                _task = Object.assign(_task, task);
            }
            return _task.$created_by === this.currentUser.id
        });
        tasks.sort((x, y) => {
            return x.$timestamp - y.$timestamp;
        });
        this.setTasks(tasks)
    }

    removeTask(index){
        this.tasks.splice()
    }

    loadTasks(params) {
        return this.CrmTasksModel.get(params)
    }

    filterTasks(filterBy = 'all', page = 1, userId) {
        let params = { 
            'page': page,
        };

        if(userId) {
            params.user_id = userId
        }

        if(filterBy === 'all'){
            //do nothing
        }

        if(filterBy === 'open'){
            params['filter_by'] = 'open';
        }
        if(filterBy === 'past_due'){
            params['filter_by'] = 'past_due';
        }
        if(filterBy === 'complete'){
            params.complete = true;
        }
        return this.loadTasks(params);
    }
}
AdminTasksService.$inject = ['UserService', '$rootScope', 'CrmTasksModel'];
export default AdminTasksService;