"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CreateLessonPlanController = /** @class */ (function () {
    function CreateLessonPlanController($rootScope, $state, LessonPlanModel, FlashService, courseTypes, sessionTypes) {
        this.$state = $state;
        this.LessonPlanModel = LessonPlanModel;
        this.FlashService = FlashService;
        this.loading = true;
        this.submitting = false;
        this.courseTypes = courseTypes;
        this.sessionTypes = sessionTypes;
        this.displayLessonPlanType = this.displayLessonPlanType.bind(this);
        this.requirementTypes = [
            { name: "Permit", value: "permit" },
            { name: "Course", value: "course_type" },
            { name: "Session Type", value: "session_type" },
        ];
        this.requirement = {
            type: "",
            sequence: 0,
            sessionType: {},
            courseType: {},
            days: 0,
            hours_required: 0,
        };
        this.lessonPlan = {
            name: "",
            requirements: [],
        };
    }
    CreateLessonPlanController.prototype.displayLessonPlanType = function (type) {
        if (type === "course_type") {
            return "Course Type";
        }
        if (type === "session_type") {
            return "Session Type";
        }
        if (type === "permit") {
            return "Permit";
        }
        return "";
    };
    CreateLessonPlanController.prototype.addRequirement = function () {
        this.lessonPlan.requirements.push(this.requirement);
        this.requirement = {
            type: "",
            sequence: 0,
            sessionType: {},
            courseType: {},
            days: 0,
            hours_required: 0,
        };
        this.lessonPlan.requirements = this.lessonPlan.requirements.sort(function (a, b) {
            return a.sequence > b.sequence ? 1 : -1;
        });
    };
    CreateLessonPlanController.prototype.removeFromLessonPlan = function (index) {
        this.lessonPlan.requirements.splice(index, 1);
    };
    CreateLessonPlanController.prototype.createLessonPlan = function () {
        var _this = this;
        this.submitting = true;
        this.LessonPlanModel.create({
            name: this.lessonPlan.name,
            requirements: this.lessonPlan.requirements.map(function (requirement) {
                if (requirement.type === "course_type") {
                    requirement.course_type_id = requirement.courseType.id;
                    return {
                        events: requirement.events,
                        sequence: requirement.sequence,
                        type: requirement.type,
                        course_type_id: requirement.course_type_id,
                    };
                }
                if (requirement.type === "session_type") {
                    requirement.minutes_required = requirement.hours_required * 60;
                    requirement.session_type_id = requirement.sessionType.id;
                    return {
                        events: requirement.events,
                        sequence: requirement.sequence,
                        type: requirement.type,
                        session_type_id: requirement.session_type_id,
                        minutes_required: requirement.minutes_required,
                    };
                }
                if (requirement.type === "permit") {
                    return {
                        events: requirement.events,
                        sequence: requirement.sequence,
                        type: requirement.type,
                        days: requirement.days,
                    };
                }
                return requirement;
            }),
        })
            .then(function (response) {
            _this.submitting = false;
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.lessonPlan = {
                name: "",
                requirements: [],
            };
            _this.$state.go("drivecoach.admin.lessonplans", {}, { reload: true });
        })
            .catch(function (Error) {
            _this.submitting = false;
        });
    };
    return CreateLessonPlanController;
}());
CreateLessonPlanController.$inject = [
    "$rootScope",
    "$state",
    "LessonPlanModel",
    "FlashService",
    "courseTypes",
    "sessionTypes",
];
exports.default = CreateLessonPlanController;
