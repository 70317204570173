"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CertificateModel = /** @class */ (function () {
    function CertificateModel($http, APPURL) {
        this.APPURL = APPURL;
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + '/api/v1/certificate',
        },
            this.data = [];
        this.endpoint = this.endpoint.bind(this);
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    CertificateModel.prototype.endpoint = function (id) {
        var url = this.APPURL + '/api/v1/users/students/' + id + '/certificate';
        return url;
    };
    CertificateModel.prototype.extract = function (result) {
        return result.data;
    };
    CertificateModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return result.data;
    };
    CertificateModel.prototype.get = function (student_id) {
        return this.$http.get(this.endpoint(student_id)).then(this.cacheResults);
    };
    ;
    CertificateModel.prototype.update = function (student_id, certificate_number, certificate_issue_date) {
        return this.$http.put(this.endpoint(student_id), { 'certificate_number': certificate_number, certificate_issue_date: certificate_issue_date }).then(this.cacheResults);
    };
    ;
    CertificateModel.prototype.destroy = function (student_id) {
        return this.$http.delete(this.endpoint(student_id)).then(this.cacheResults);
    };
    return CertificateModel;
}());
CertificateModel.$inject = ['$http', 'APPURL'];
exports.default = CertificateModel;
