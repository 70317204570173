"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var time_1 = require("../../../common/helpers/time");
var saveToDisk_1 = require("../../../common/helpers/saveToDisk");
var EditReportController = /** @class */ (function () {
    function EditReportController($window, $rootScope, $state, $stateParams, FlashService, ReportBuilder, ReportBuilderConfig, report) {
        this.$window = $window;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.FlashService = FlashService;
        this.loading = false;
        this.lessonPlans = [];
        this.page_by = 10;
        this.totalItems = 0;
        this.currentPage = 1;
        this.ReportBuilder = ReportBuilder;
        this.data = {};
        this.reports = [];
        this.previewData = {
            rows: [],
            columns: []
        };
        this.formConfig = {
            name: report.name,
            subject: report.model,
            startDate: null,
            endDate: null
        };
        this.formOperations = {
            'integers': [{
                    name: ">",
                    value: "gt"
                }, {
                    name: "<",
                    value: "lt"
                }, {
                    name: "=",
                    value: "eq"
                }],
            'strings': [{
                    name: '=',
                    value: 'Equals'
                }, {
                    name: 'Like',
                    value: 'like'
                }]
        };
        ReportBuilderConfig[report.model].columns = report.columns;
        ReportBuilderConfig[report.model].filters = report.filters;
        ReportBuilderConfig[report.model].groups = report.groups;
        this.ReportBuilderConfig = ReportBuilderConfig;
        this.report = report;
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.selectedColumns = this.selectedColumns.bind(this);
        this.selectedFilterColumns = this.selectedFilterColumns.bind(this);
        this.reportTypes = [{
                name: 'Driving Lessons',
                value: 'drives',
            }, {
                name: 'Orders',
                value: 'orders'
            }, {
                name: 'Students',
                value: 'students'
            }];
        this.setAvailableColumns = this.setAvailableColumns.bind(this);
        this.availableColumns = [];
        this.setAvailableColumns();
        this.setFilterStartDate = this.setFilterStartDate.bind(this);
        this.setFilterEndDate = this.setFilterEndDate.bind(this);
        this.transformFilterForRequest = this.transformFilterForRequest.bind(this);
        this.addFilter = {
            column: null,
            filter_type: '',
            startDate: '',
            endDate: '',
            time: '',
            operator: null,
            operand: null
        };
        this.submitAddFilter = this.submitAddFilter.bind(this);
        this.removeFilterFromColumn = this.removeFilterFromColumn.bind(this);
        this.submitPreview = this.submitPreview.bind(this);
        this.submitPreview();
    }
    EditReportController.prototype.removeFilterFromColumn = function (column, index) {
        this.ReportBuilderConfig[this.formConfig.subject].filters.splice(index, 1);
    };
    EditReportController.prototype.submitAddFilter = function () {
        var filterData = Object.assign({}, this.addFilter);
        this.ReportBuilderConfig[this.formConfig.subject].filters.push(filterData);
        this.addFilter = {
            column: {},
            filter_type: '',
            startDate: '',
            endDate: '',
            time: '',
            operator: null,
            operand: null
        };
    };
    EditReportController.prototype.transformFilterForRequest = function (filter) {
        if (filter.column.type === 'date') {
            filter.startDate = (0, time_1.toYMD)(filter.startDate);
        }
        if (filter.column.type === 'date') {
            filter.endDate = (0, time_1.toYMD)(filter.endDate);
        }
        return filter;
    };
    EditReportController.prototype.submitPreview = function () {
        var _this = this;
        var filterData = JSON.parse(JSON.stringify(this.ReportBuilderConfig[this.formConfig.subject].filters));
        var payload = {
            name: this.formConfig.name,
            subject: this.formConfig.subject,
            columns: this.ReportBuilderConfig[this.formConfig.subject].columns,
            filters: filterData.map(this.transformFilterForRequest),
        };
        this.ReportBuilder.getPreview(payload).then(function (response) {
            _this.previewData.rows = [].concat(response.rows);
            _this.previewData.columns = [].concat(response.columns);
        });
    };
    EditReportController.prototype.saveReport = function () {
        var _this = this;
        var filterData = JSON.parse(JSON.stringify(this.ReportBuilderConfig[this.formConfig.subject].filters));
        var payload = {
            name: this.formConfig.name,
            subject: this.formConfig.subject,
            columns: this.ReportBuilderConfig[this.formConfig.subject].columns,
            filters: filterData.map(this.transformFilterForRequest),
        };
        this.ReportBuilder.updateReport({ 'id': this.$stateParams.reportId }, payload).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    };
    EditReportController.prototype.downloadReport = function () {
        var _this = this;
        this.ReportBuilder.downloadReport({ 'id': this.$stateParams.reportId }).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            (0, saveToDisk_1.saveToDisk)(response.url);
        });
    };
    EditReportController.prototype.setFilterStartDate = function (newDate) {
        //used with vue flatpickr
        this.addFilter.startDate = newDate.selected[0];
    };
    EditReportController.prototype.setFilterEndDate = function (newDate) {
        //used with vue flatpickr
        this.addFilter.endDate = newDate.selected[0];
    };
    EditReportController.prototype.setStartDate = function (newDate) {
        //used with vue flatpickr
        this.formConfig.startDate = newDate.selected[0];
    };
    EditReportController.prototype.setEndDate = function (newDate) {
        //used with vue flatpickr
        this.formConfig.endDate = newDate.selected[0];
    };
    EditReportController.prototype.setAvailableColumns = function () {
        if (this.formConfig.subject === "") {
            return;
        }
        this.availableColumns = this.ReportBuilderConfig[this.formConfig.subject].columns;
    };
    EditReportController.prototype.selectedColumns = function () {
        return this.availableColumns.filter(function (column) {
            return column.selected === true;
        });
    };
    EditReportController.prototype.selectedFilterColumns = function () {
        return this.availableColumns.filter(function (column) {
            return column.selected === true && column.type !== 'relationship' && column.type !== 'metadata';
        });
    };
    return EditReportController;
}());
EditReportController.$inject = [
    "$window",
    "$rootScope",
    "$state",
    "$stateParams",
    "FlashService",
    "ReportBuilder",
    "ReportBuilderConfig",
    "report",
];
exports.default = EditReportController;
