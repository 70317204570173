"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var AdminBillingController = /** @class */ (function () {
    function AdminBillingController(ENV, $state, PoliciesModel, FlashService, CreateOrderService, PolicyService) {
        var _this = this;
        this.ENV = ENV;
        this.busy = true;
        this.loading = true;
        this.policies = {
            'billing': {
                id: 0,
                policymeta: {
                    "authorize_api_login_id": "",
                    "authorize_api_trans_key": "",
                    "authorize_client_key": "",
                    "billing_gateway": "",
                    "instructor_can_create_orders": "",
                    "paypal_rest_api_client_id": "",
                    "paypal_rest_api_secret_key": "",
                    "stripe_publishable_key": "",
                    "stripe_secret_key": "",
                    "surcharge_amount": 0,
                    "surcharge_detail": "",
                    "surcharge_on": ""
                }
            }
        };
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;
        this.CreateOrderService = CreateOrderService;
        this.PolicyService = PolicyService;
        this.$cashier = CreateOrderService.cashier();
        PoliciesModel.getByID('billing').then(function (response) {
            var policyMeta = {};
            response.policy.policymeta.forEach(function (meta) {
                policyMeta[meta.meta_key] = meta.meta_value;
            });
            _this.policies.billing.id = response.policy.id;
            // @ts-ignore
            _this.policies.billing.policymeta = policyMeta;
            _this.busy = false;
            _this.loading = false;
        });
        this.updateInstructorsTakingOrders = this.updateInstructorsTakingOrders.bind(this);
    }
    AdminBillingController.prototype.updateSurcharge = function () {
        var _this = this;
        this.busy = true;
        var payload = {
            id: this.policies.billing.id,
            surcharge_on: this.policies.billing.policymeta.surcharge_on,
            surcharge_amount: this.policies.billing.policymeta.surcharge_amount,
            surcharge_detail: this.policies.billing.policymeta.surcharge_detail
        };
        this.PoliciesModel.update(payload).then(function (response) {
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            if (_this.$cashier.policies().policyExists('billing')) {
                var billingPolicy = _this.$cashier.policies().getPolicyByName('billing');
                billingPolicy.setMetaValueByKey('surcharge_on', payload.surcharge_on);
                billingPolicy.setMetaValueByKey('surcharge_amount', payload.surcharge_amount);
                billingPolicy.setMetaValueByKey('surcharge_detail', payload.surcharge_detail);
                _this.CreateOrderService.$cashier.policies().setPolicy(billingPolicy);
                _this.CreateOrderService.$cashier.setupPolicies();
            }
            _this.busy = false;
        }).catch(function (Error) {
            _this.busy = false;
        });
    };
    AdminBillingController.prototype.updateInstructorsTakingOrders = function () {
        var _this = this;
        this.busy = true;
        var payload = {
            id: this.policies.billing.id,
            instructor_can_create_orders: this.policies.billing.policymeta.instructor_can_create_orders,
        };
        this.PoliciesModel.update(payload).then(function (response) {
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            if (_this.$cashier.policies().policyExists('billing')) {
                var billingPolicy = _this.$cashier.policies().getPolicyByName('billing');
                billingPolicy.setMetaValueByKey('instructor_can_create_orders', payload.instructor_can_create_orders);
                _this.CreateOrderService.$cashier.policies().setPolicy(billingPolicy);
                _this.CreateOrderService.$cashier.setupPolicies();
            }
            _this.busy = false;
        }).catch(function (Error) {
            _this.busy = false;
        });
    };
    AdminBillingController.prototype.processPolicy = function () {
        var _this = this;
        this.busy = true;
        var _a = this.policies.billing.policymeta, id = _a.id, requestData = __rest(_a, ["id"]);
        var request = __assign({ id: this.policies.billing.id }, requestData);
        this.PoliciesModel.update(request).then(function (response) {
            _this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            if (_this.$cashier.policies().policyExists('billing')) {
                var billingPolicy = _this.$cashier.policies().getPolicyByName('billing');
                billingPolicy.setMetaValueByKey('billing_gateway', request.billing_gateway);
                billingPolicy.setMetaValueByKey('authorize_client_key', request.authorize_client_key);
                billingPolicy.setMetaValueByKey('authorize_api_trans_key', request.authorize_api_trans_key);
                billingPolicy.setMetaValueByKey('authorize_api_login_id', request.authorize_api_login_id);
                billingPolicy.setMetaValueByKey('stripe_secret_key', request.stripe_secret_key);
                billingPolicy.setMetaValueByKey('stripe_publishable_key', request.stripe_publishable_key);
                _this.CreateOrderService.$cashier.policies().setPolicy(billingPolicy);
                _this.CreateOrderService.$cashier.setupPolicies();
                _this.PolicyService.setPolicies(_this.CreateOrderService.$cashier.policies());
            }
            _this.busy = false;
        }).catch(function (Error) {
            _this.busy = false;
        });
    };
    return AdminBillingController;
}());
AdminBillingController.$inject = ['ENV', '$state', 'PoliciesModel', 'FlashService', 'CreateOrderService', 'PolicyService'];
exports.default = AdminBillingController;
