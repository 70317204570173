function dsDriveSesionTimelineController(UserService) {

    const vm = this;
    vm.currentUser = UserService.getCurrentUser();

    init();

    function init() {
        vm.plots = vm.drive.plots;
    }

}
dsDriveSesionTimelineController.$inject = ['UserService'];
export default dsDriveSesionTimelineController;