import {isJson} from "../../../../../../common/helpers/json";

class AdminSchoolLocationViewController {

    constructor($stateParams, LocationsModel, school){
      this.school = school 
      this.center = {
        lat: this.school.locations[0].address.lat,
        lng: this.school.locations[0].address.lng
      }
      this.locations = []
      this.ready = false;
      
      LocationsModel.getLocationByID($stateParams.locationId).then(response => {
            response.address = isJson(response.address) ? JSON.parse(response.address) : response.address;
            this.location = response;
            this.locations = [this.location];

            this.center = {
                lat: this.location.address.lat,
                lng: this.location.address.lng,
                zoom: 2
            };
            this.ready = true;
        });
    }

}
AdminSchoolLocationViewController.$inject = ['$stateParams', 'LocationsModel', 'school'];
export default AdminSchoolLocationViewController;