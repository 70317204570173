<template>
  <section>
    <div class="container-fluid">
      <div class="row" style="margin-bottom: 2em">
        <div class="col-xs-12">
          <h4>Send Sample Email Template</h4>
          <form>
            <p>Send a sample of one of your emails to the provided email address. This is useful for testing your templates.</p>
            <div class="form-group">
              <label for="template_name">Email template</label>
              <select class="form-control" name="template_name" v-model="sampleEmail.template">
                <option v-bind:value="template.name" v-for="template in emailTemplates">
                  {{ template.display_name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="recipient">Email Address</label>
              <input name="recipient" type="email" v-model="sampleEmail.recipient" class="form-control text-left" />
            </div>
            <div class="form-group">
              <button class="btn btn-dc" @click.prevent="sendSampleEmail()">Send Sample Email</button>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <h4>Email Templates</h4>
          <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr>
              <th>Name</th>
              <th>When does this go out?</th>
              <th>Customized</th>
              <th>Send this email?</th>
              <th>Modified On</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-bind:key="template.id" v-for="template in emailTemplates">
              <td>{{ template.display_name}}</td>
              <td>{{ template.sent_when }}</td>
              <td>{{ template.customized ? 'Yes' : 'No' }}</td>
              <td>{{ template.send_this_email ? 'Yes' : 'No' }}</td>
              <td v-if="template.customized">{{ template.updated_at }}</td>
              <td v-if="!template.customized">N/A</td>
              <td>
                <button class="btn btn-dc" @click.prevent="selectTemplate(template)">Preview/Edit</button><br />
                <!-- <button class="btn btn-dc mt-2" @click.prevent="toggleSendTemplate(template)">{{ template.send_this_email ? 'Turn Off' : 'Turn On' }}</button> -->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import EmailEditor from '../email-editor/EmailEditor.vue'

  export default {
    name: 'dc-email-templates',
    components: {
      'dc-email-editor': EmailEditor
    },
    props: {
    },
    data() {
      return {
        sampleEmail: {
          recipient: '',
          template: ''
        }
      }
    },
    created () {
      const userString = localStorage.getItem('dc-user')
      const tokenString = localStorage.getItem('dc-auth-token')
      if (userString) {
        let payload = JSON.parse(userString);
        this.$store.commit('SET_USER', payload)
      }
      if (tokenString) {
        this.$store.commit('SET_TOKEN', tokenString.replace(/^"|"$/g, ''))
      }
      this.$store.dispatch('GET_EMAIL_TEMPLATES')
    },
    methods: {
      selectTemplate(template){
        this.$emit('select-template', template);
      },
      sendSampleEmail(){
        this.$store.dispatch('SEND_SAMPLE_EMAIL', {recipient: this.sampleEmail.recipient, name: this.sampleEmail.template}).then(response => {
          let data = { 'message': response.data.message};
          this.$emit('email-sample-sent', {'data' : data})
        })
      },
      toggleSendTemplate(template){
        const emailTemplate = Object.assign({}, template);
        emailTemplate.key = template.name;
        if (emailTemplate.send_this_email === true){
          emailTemplate.send_this_email = "0";
        } else {
          emailTemplate.send_this_email = "1";
        }
        this.$store.dispatch('PUBLISH_EMAIL_TEMPLATE', emailTemplate).then(response => {
          let data = { 'message': response.data.message, 'template': response.data.template};
          this.$emit('email-template-published', {'data' : data})
        })
      }
    },
    computed: {
      emailTemplates: function(){
        return this.$store.state.emailEditor.templates;
      }
    }
  }
</script>

<style scoped lang="scss">

</style>