export const hours_list = [
    {'n' : '0 Hours', 'v' : 0},
    {'n' : '1 Hour', 'v' : 1},
    {'n' : '2 Hours', 'v' : 2},
    {'n' : '3 Hours', 'v' : 3},
    {'n' : '4 Hours', 'v' : 4},
    {'n' : '4 Hours', 'v' : 4},
    {'n' : '5 Hours', 'v' : 5},
    {'n' : '6 Hours', 'v' : 6},
    {'n' : '7 Hours', 'v' : 7},
    {'n' : '8 Hours', 'v' : 8},
];
