import angular from 'angular'
import template from './admin-future-sessions.html'
import AdminFutureSessionsController from './admin-future-sessions.controller'
import UsersModel from '../../../common/models/users-model';

function prepareCalendar(CalendarModel, DrivesFilterService) {
    return CalendarModel.getFilterOptions().then(response => {
        DrivesFilterService.setFilterOptions(response.options);
        return response.options;
    });
}
prepareCalendar.$inject = ['CalendarModel', 'DrivesFilterService'];

function getSectors(UserService, SchoolsModel){
    let school = UserService.getCurrentUser().school;
    return SchoolsModel.getSchoolByID(school.id).then(response => {
        let sectors = response.locations.map(location => location.zoneparts);
        let zones = response.locations.map(location => location.zones);
        return {
            'zones' : [].concat.apply([], zones),
            'sectors' : [].concat.apply([], sectors)
        };
    });
}
getSectors.$inject = ['UserService', 'SchoolsModel'];

function getInstructors(UsersModel)
{
    return UsersModel.getInstructors({ all: true, status: "Active" }).then(
      (response) => {
        return response.instructors;
      }
    );
}
getInstructors.$inject = ['UsersModel'];

function getVehicles(VehiclesModel){
    return VehiclesModel.getVehicles({'all' : true, 'status' : 'available'}).then(function(response){
        return response.vehicles;
    })
}
getVehicles.$inject = ['VehiclesModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.futuresessions', {
            'url' : '/future-sessions',
            'controller' : AdminFutureSessionsController,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                'serviceArea' : getSectors,
                'instructors' : getInstructors,
                'filterOptions' : prepareCalendar,
                'vehicles' : getVehicles,
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.staff,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.futuresessions', [] )
    .config(config)
    .controller( 'AdminFutureSessionsController', AdminFutureSessionsController )
;
