import angular from 'angular'
import template from './admin-courses-index.html'
import AdminCoursesController from './admin-courses.controller'
import AdminCreateCourse from './create/create-course.module'
import AdminViewCourse from './view/view-course.module'
import AdminBatchCreateCourse from './batchcreate/admin-batch-create-courses.module'
import AdminEditCourse from './edit/edit-course.module'
import AdminCourseAttendance from './attendance/admin-course-attendance.module'

function getCourseTypes(CourseTypesModel){
  return CourseTypesModel.get().then(function(response){
    return response.courseTypes
  })
}
getCourseTypes.$inject = ['CourseTypesModel'];

function config($stateProvider, $urlRouterProvider, USER_ROLES){
    $stateProvider
        .state('drivecoach.admin.courses', {
            url: '/courses',
            template: template,
            controller: AdminCoursesController,
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            },
            resolve: { courseTypes: getCourseTypes }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];
export default angular.module('admin.courses', [
    AdminCreateCourse.name,
    AdminViewCourse.name,
    AdminEditCourse.name,
    AdminBatchCreateCourse.name,
    AdminCourseAttendance.name
])
.config(config)
.controller('AdminCoursesController', AdminCoursesController);
