import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcEditCourse from './EditCourse.vue'
import moment from 'moment'
import AdminCoursesEditController from './edit-course.controller'
import template from './admin-edit-course.html'

function getCourse( $stateParams, CoursesModel ) {
    return CoursesModel.show( $stateParams.courseId ).then( response => {
        response.course.date = moment( response.course.date ).toDate();
        response.course.time = moment( response.course.time, 'HH:mm:ss' ).toDate();
        // response.course.number_of_seats = 20;
        let _durMoment = moment.duration( response.course.duration, 'minutes' ).format( 'H:mm' ).split( ':' );
        response.course.duration = {
            'hours' : _durMoment[0],
            'minutes' : _durMoment[1]
        };
        return response.course;
    } );
}
getCourse.$inject = ['$stateParams', 'CoursesModel'];


function getCourseTypes(CourseTypesModel){
    return CourseTypesModel.get().then(function(response){
        return response.courseTypes.filter(type => type.status === 'Active');
    })
}
getCourseTypes.$inject = ['CourseTypesModel'];


function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.courses.edit', {
            url : '/edit/:courseId',
            template : template,
            controller : AdminCoursesEditController,
            controllerAs : 'vm',
            resolve : {
                course : getCourse,
                courseTypes : getCourseTypes
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.courses.edit', [] )
    .config(config)
    .controller( 'AdminCoursesEditController', AdminCoursesEditController )
    .directive('dcEditCourse', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcEditCourse', dcEditCourse))
  }])
;
