import angular from 'angular'
import StudentSignupController from './student-signup.controller'
import ReviewProfileInstanceController from './review-profile.controller'
import GoBackModalInstanceController from './go-back.controller'
import template from './student-signup.html'
import '../../../assets/images/logo-for-wht-bg.svg'
function config( $stateProvider, $urlRouterProvider ) {
    $stateProvider
        .state( 'drivecoach.signup.student', {
            'url' : '/student',
            'controller' : StudentSignupController,
            'controllerAs' : 'vm',
            'template' : template
        } )
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

export default angular.module( 'signup.student', [] )
    .config(config)
    .controller( 'StudentSignupController', StudentSignupController )
    .controller( 'GoBackModalInstanceController', GoBackModalInstanceController )
    .controller( 'ReviewProfileInstanceController', ReviewProfileInstanceController );
