import angular from 'angular'
import template from './school-zones.html'
import AdminSchoolsZonesController from './school-zones.controller'
import ZonesIndex from './index/school-zones-index.module'
import ZonesCreate from './create/school-create-zone.module'
let modules = [
    ZonesIndex.name,
    ZonesCreate.name,
];
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.schools.zones', {
            'url' : '/zones',
            'abstract' : true,
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.schools.zones', modules)
    .config(config)
    .controller( 'AdminSchoolsZonesController', AdminSchoolsZonesController )
;
