import { toQueryString } from "../helpers/queryString"
function ReportsModel( $q, $http, UserService, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/reports',
            UPDATE : APPURL + '/api/v1/reports',
            CREATE : APPURL + '/api/v1/reports'
        },
        report;

    function extract( result ) {
        return result.data;
    }

    function cacheResponse( result ) {
        return extract( result );
    }

    model.getAdminStudentIndexStats = function( data ){
        if ( data ) {
            return $http.get( URLS.FETCH + '/adminStudentIndex?' + toQueryString( data ) ).then( cacheResponse );
        }
        return $http.get(URLS.FETCH + '/adminStudentIndex' ).then(cacheResponse);
    };

    model.getAdminDashboard = function(){
        return $http.get(URLS.FETCH + '/admin/dashboard' ).then(cacheResponse);
    };

    model.getPickupLocations = function(){
        return $http.get(URLS.FETCH + '/pickuplocations' ).then(cacheResponse);
    }


}
ReportsModel.$inject = ['$q', '$http', 'UserService', 'APPURL'];
export default ReportsModel;
