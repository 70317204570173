import template from './calendar-filter.html'
function dcCalendarFilters() {
    return {
        restrict : 'E',
        scope: {
            'context' : '@'
        },
        template : template,
        link : ( scope, element, attrs ) => {
        },
        controller : 'dcCalendarFilterController',
        controllerAs : 'vm',
        bindToController : true
    }
}
dcCalendarFilters.$inject = [];
export default dcCalendarFilters;