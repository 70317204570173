"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PolicyService = /** @class */ (function () {
    function PolicyService($rootScope) {
        this.$rootScope = $rootScope;
    }
    PolicyService.prototype.getPolicies = function () {
        return this.policies;
    };
    PolicyService.prototype.setPolicies = function (policies) {
        this.policies = policies;
        this.$rootScope.$broadcast('POLICY_SERVICE_POLICY_SET');
    };
    return PolicyService;
}());
PolicyService.$inject = ['$rootScope'];
exports.default = PolicyService;
