import moment from 'moment'
class InstructorGradeGenericReportCardController {
    constructor($state, $stateParams, DrivesModel, FlashService, ReportcardsModel, student){
        this.reportcard = {
            student: {
                firstname: student.firstname,
                lastname: student.lastname
            },
        };

        this.drive = [];
        this.tasks = [];
        this.grades = [];
        this.gradeIncomplete = true;

        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ReportcardsModel = ReportcardsModel;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;

        DrivesModel.getDriveByID($stateParams.driveId).then(response => {
            this.drive = response.drive;
            this.driveDate = moment(this.drive.drive_date + ' ' + this.drive.start_time);
            this.untilDrive = this.driveDate.fromNow();
        });
    }

    submitGrade() {
        const data = {
            'drive_id' : this.$stateParams.driveId,
            'internal_note' : this.internal_note,
            'external_note' : this.external_note
        };

        this.ReportcardsModel.submitGenericGrade(data).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            });
            if (response.redirect === 'dashboard') {
                this.$state.go( 'drivecoach.instructor.dashboard' );
            }
            if (response.redirect === 'drive') {
                this.$state.go('drivecoach.instructor.dashboard.drive', { 'driveId' : this.drive.id });
            }
        });
    }

}
InstructorGradeGenericReportCardController.$inject = ['$state', '$stateParams', 'DrivesModel', 'FlashService', 'ReportcardsModel', 'student'];
export default InstructorGradeGenericReportCardController;
