import angular from 'angular'
import dcCourseTypesController from './course-types.controller'
import template from './course-types.html'

function dcCourseTypes() {
    return {
        restrict : 'E',
        scope : {
            'canEdit' : '=',
            'canAdd' : '=',
            'courseTypes' : '='
        },
        template : template,
        link :  (scope, element, attrs ) => {

        },
        controller: dcCourseTypesController,
        controllerAs: 'vm',
        bindToController: true
    }
}
dcCourseTypes.$inject = [];

export default angular.module( 'admin.directives.coursetypes', [] )
    .controller( 'dcCourseTypesController', dcCourseTypesController )
    .directive('dcCourseTypes', dcCourseTypes)
;
