"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCourseFiles = void 0;
var queryString_1 = require("../../../app/common/helpers/queryString");
var HttpClient_1 = require("../core/HttpClient");
var URLS = {
    FETCH: '/courses',
    UPDATE: '/courses',
    CREATE: '/courses'
};
function extract(result) {
    return result.data;
}
function cacheResults(result) {
    var data = extract(result);
    return data;
}
var show = function (id) {
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + id).then(cacheResults);
};
var get = function (data) {
    if (data) {
        return HttpClient_1.HttpClient.get(URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(cacheResults);
    }
    return HttpClient_1.HttpClient.get(URLS.FETCH).then(cacheResults);
};
var update = function (request) {
    return HttpClient_1.HttpClient.put(URLS.FETCH + '/' + request.id, request).then(cacheResults);
};
var create = function (request) {
    return HttpClient_1.HttpClient.post(URLS.FETCH, request).then(cacheResults);
};
var deleteCourse = function (data) {
    return HttpClient_1.HttpClient.delete(URLS.FETCH + '/' + data.id).then(cacheResults);
};
var search = function (params) {
    return HttpClient_1.HttpClient.post(URLS.FETCH + '/search', params).then(cacheResults);
};
var batchCreate = function (courses) {
    return HttpClient_1.HttpClient.post(URLS.CREATE + '/batch', courses).then(cacheResults);
};
var getCourseFiles = function (courseId) {
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + courseId + '/files').then(cacheResults);
};
exports.getCourseFiles = getCourseFiles;
var getCourseStudents = function (courseId) {
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + courseId + '/students').then(cacheResults);
};
var getCourseClassDateStudents = function (course_id, class_date_id) {
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + course_id + '/classes/' + class_date_id + '/students').then(cacheResults);
};
var addStudentToCourse = function (courseId, studentId, classDates) {
    return HttpClient_1.HttpClient.post(URLS.CREATE + '/' + courseId + '/students', { student_id: studentId, class_dates: classDates }).then(cacheResults);
};
var removeStudentFromCourse = function (courseId, studentId) {
    return HttpClient_1.HttpClient.delete(URLS.FETCH + '/' + courseId + '/students/' + studentId).then(cacheResults);
};
var printRoster = function (data) {
    if (data.class_id) {
        return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + data.id + '/print?class_id=' + data.class_id).then(cacheResults);
    }
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + data.id + '/print').then(cacheResults);
};
var notifyStudents = function (data) {
    return HttpClient_1.HttpClient.post(URLS.FETCH + '/' + data.course_id + '/notify-students', {
        subject: data.subject,
        body: data.body,
        to: data.to
    }).then(cacheResults);
};
var updateRosterRow = function (student_id, course_id, received_contract) {
    var req = {
        "student_id": student_id,
        "course_id": course_id,
        "received_contract": received_contract === 1 ? "1" : "0"
    };
    return HttpClient_1.HttpClient.patch("".concat(URLS.FETCH, "/").concat(course_id, "/students/").concat(student_id), req).then(cacheResults);
};
var getCourseAttendance = function (courseId) {
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + courseId + '/attendance').then(cacheResults);
};
var getCourseClassAttendance = function (course_id, class_id) {
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + course_id + '/attendance/' + class_id).then(cacheResults);
};
var updateCourseAttendance = function (data) {
    return HttpClient_1.HttpClient.put(URLS.UPDATE + '/' + data.courseId + '/attendance', data.students).then(cacheResults);
};
var updateCourseClassAttendance = function (data) {
    return HttpClient_1.HttpClient.put(URLS.UPDATE + '/' + data.courseId + '/attendance/' + data.class_id, data.students).then(cacheResults);
};
var getClassDates = function (course_id) {
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + course_id + '/classes').then(cacheResults);
};
var getClassDate = function (course_id, class_id) {
    return HttpClient_1.HttpClient.get(URLS.FETCH + '/' + course_id + '/classes/' + class_id).then(cacheResults);
};
