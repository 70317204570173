import angular from 'angular'
import template from './product-course-types.html'
import controller from './product-course-types.controller'
function dsProductCourseTypes() {
    return {
        restrict: 'E',
        scope: {
            'product' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.productcoursetypes', [])
    .controller('dcProductCourseTypes', controller)
    .directive('dsProductCourseTypes', dsProductCourseTypes);

