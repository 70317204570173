import moment from 'moment'
function StudentUpcomingDrivesController(UsersModel, UserService ) {

    const vm = this;
    vm.date = new Date();
    vm.student = UserService.getCurrentUser();

    vm.classes = [];
    vm.loading = true;

    const options = {
        'id' : vm.student.id,
        'drivesAfter' : moment().format('YYYY-MM-DD'),
        'order' : 'asc',
        'page_results' : true
    };

    vm.pageChanged = pageChanged;

    init();

    function init(){
        UsersModel.getStudentDrives(options)
            .then( handleUpcomingStudentDrives );
    }

    function handleUpcomingStudentDrives( response ) {
        // vm.totalItems = response.drives.total;
        vm.drives = response.drives.map(formatDrive);
        vm.loading = false;
    }

    function formatDrive(drive){
        const minutes = moment.duration(drive.session_length, 'minutes');
        drive.startTime = moment(drive.start_time, 'HH:mm:s').format('h:mm a');
        drive.endTime = moment(drive.start_time, 'HH:mm:s').add( minutes, 'minutes').format('h:mm a');
        drive.duration = moment.duration(drive.session_length, 'minutes').asHours();
        drive.duration = moment.duration(drive.duration, 'hours').humanize();
        if( drive.observation === true ){
            drive.type.name = drive.type.name + ' (Observation)';
        }
        return drive;
    }

    function pageChanged() {
        options.page = vm.currentPage;
        UsersModel.getStudentDrives(options).then( handleUpcomingStudentDrives );
    }

}
StudentUpcomingDrivesController.$inject = ['UsersModel', 'UserService'];
export default StudentUpcomingDrivesController;
