import { toQueryString } from "../helpers/queryString";

function LeadsModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/crm/leads',
            UPDATE : APPURL + '/api/v1/crm/leads',
            CREATE : APPURL + '/api/v1/crm/leads'
        },
        events;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        events = extract( result );
        return events;
    }

    model.show = ( id ) => {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.get = (data=null) => {
        if(data !== null){
            if (data.hasOwnProperty('status') && data.status === 'all') {
                delete data.status;
            }
            return $http.get( URLS.FETCH + '?' + toQueryString(data)).then( cacheResults );
        }
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = ( request ) => {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.create = ( request ) => {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.delete = ( data ) => {
        return $http.delete( URLS.FETCH + '/' + data.id ).then( cacheResults );
    };

    model.search = ( params ) => {
        return $http.post( URLS.FETCH + '/search', params ).then( cacheResults );
    };

}
LeadsModel.$inject = ['$http', 'APPURL'];
export default LeadsModel;