function ReportcardsTypesModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/report-card-types',
            UPDATE : APPURL + '/api/v1/report-card-types',
            CREATE : APPURL + '/api/v1/report-card-types'
        },
        reportcards;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        reportcards = extract( result );
        return reportcards;
    }

    model.get = function () {
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = function ( request ) {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.create = function ( request ) {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.destroy = function ( card ) {
        return $http.delete( URLS.FETCH + '/' + card.id ).then( cacheResults );
    }
}
ReportcardsTypesModel.$inject = ['$http', 'APPURL'];
export default ReportcardsTypesModel;
