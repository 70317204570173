import moment from 'moment'
import { isJson } from "../../../common/helpers/json";

class AdminCompleteDriveController {

    constructor(
        $rootScope,
        $scope,
        $state,
        UserService,
        UsersModel,
        VehiclesModel,
        DrivesModel,
        SchoolsModel,
        FlashService,
        driveSession,
        PoliciesModel,
        $q,
    ) {

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.UserService = UserService;
        this.UsersModel = UsersModel;
        this.VehiclesModel = VehiclesModel;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;
        this.driveSession = driveSession;

        const minutes = moment.duration(this.driveSession.session_length, 'minutes');
        const endTimeMoment = moment(this.driveSession.start_time, 'HH:mm:s').add(minutes, 'minutes');
        this.endTimeMoment = endTimeMoment;

        this.driveSession.endTime = endTimeMoment.format('h:mm a');

        this.driveSession.duration = moment.duration(this.driveSession.session_length, 'minutes').asHours();
        this.driveSession.duration = moment.duration(this.driveSession.duration, 'hours').humanize();

        this.driveDate = moment(this.driveSession.drive_date + ' ' + this.driveSession.start_time);

        this.policies = {};
        this.currentUser = UserService.getCurrentUser();
        this.loading = true;

        // load all of the things!

        const getSchool = SchoolsModel.getSchoolByID(this.currentUser.school_id);
        const getPolicies = PoliciesModel.get();
        const getDriveMeta = DrivesModel.getDriveMeta(this.driveSession.id);

        $q.all([getSchool, getPolicies, getDriveMeta]).then(responses => {
            this.formatSchoolResponse(responses[0]);
            this.formatPoliciesResponse(responses[1]);
            this.driveSession = this.formatDriveSession(responses[2]);
            this.loading = false;
        });

    }

    formatDriveSession(response){
        let hasGuardian = response.drive.student.usermeta.filter(meta => {
          return meta.meta_key === 'guardian';
        }).length > 0;
        let parentMeta;
        if (hasGuardian) {
          parentMeta = response.drive.student.usermeta.find(meta => meta.meta_key === 'guardian').meta_value;
          response.drive.student.guardian = JSON.parse(parentMeta);
        }
        response.drive.student.phone = response.drive.student.usermeta.filter(meta => {
          return meta.meta_key === 'phone';
        })[0].meta_value;
        const dob_meta = response.drive.student.usermeta.find(meta => meta.meta_key === 'dob');
        if (dob_meta) {
          response.drive.student.dob = dob_meta.meta_value;
        } else {
          response.drive.student.dob = "";
        }

        const drive = Object.assign(this.driveSession, response.drive);
        return drive;
    }

    formatPoliciesResponse(response) {
        response.policies.forEach(policy => {
            let policyName = policy.name;
            this.policies[policyName] = { 'id': policy.id };
            let policyMeta = {};
            policy.policymeta.forEach(meta => {
                policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
            });
            this.policies[policyName].policymeta = policyMeta;
        });
    }

    formatSchoolResponse(response) {
        response.locations = response.locations.filter(location => {
            return location.zoneparts.length > 0;
        });
        this.school = response;
    }

    submit() {
        this.submitted = true;

        this.DrivesModel.complete(this.driveSession).then(response => {
            this.FlashService.setMessage({ 'type': 'success', 'message': response.message });
            this.submitted = false;
            this.$state.go('drivecoach.admin.dashboard', {}, { reload: true });
        }).catch(Error => {
            this.submitted = false;
        });

    }

}

AdminCompleteDriveController.$inject = ['$rootScope', '$scope', '$state', 'UserService', 'UsersModel', 'VehiclesModel', 'DrivesModel', 'SchoolsModel', 'FlashService', 'driveSession', 'PoliciesModel', '$q'];
export default AdminCompleteDriveController;
