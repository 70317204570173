import angular from 'angular'
import moment from 'moment'
import InstructorReportCardsController from './instructor-upcoming-students-report-cards.controller'
import template from './instructor-upcoming-students-report-cards.html'

getInstructorsUpcomingStudents.$inject = ['UserService', 'UsersModel'];
function getInstructorsUpcomingStudents( UserService, UsersModel ) {
    const instructor = UserService.getCurrentUser();
    const initialFilterData = {
        'id' : instructor.id,
        'from' : moment().format( 'YYYY-MM-DD' ),
        'to' : moment().format( 'YYYY-MM-DD' )
    };
    return UsersModel.getInstructorsUpcomingStudents( initialFilterData ).then( response => {
        return response.students.filter( student => {
            student.name = student.firstname + ' ' + student.lastname;
            return student;
        } );
    } );
}

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.reportcards', {
            url : '/reportcards',
            template : template,
            controller : InstructorReportCardsController,
            controllerAs : 'vm',
            resolve : {
                'students' : getInstructorsUpcomingStudents
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.parent,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.reportcards', [] )
    .config(config)
    .controller( 'InstructorReportCardsController', InstructorReportCardsController )
;
