"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BillingGateway_1 = require("../../../../../classes/billing/BillingGateway");
var months_and_years_1 = require("../../../../../common/constants/months_and_years");
var states_1 = require("../../../../../common/constants/states");
var StudentPaymentMethodsController = /** @class */ (function () {
    function StudentPaymentMethodsController($stateParams, UsersModel, PoliciesModel, PolicyService, FlashService, $location) {
        var _this = this;
        this.$stateParams = $stateParams;
        this.loading = true;
        this.UsersModel = UsersModel;
        this.PoliciesModel = PoliciesModel;
        this.PolicyService = PolicyService;
        this.FlashService = FlashService;
        this.paymentMethods = [];
        this.gateway = 'none';
        this.gateway_config = {};
        this.years = months_and_years_1.Years;
        this.months = months_and_years_1.Months;
        this.states = states_1.States;
        this.returnUrl = $location.absUrl();
        this.handleAuthorizeCard = this.handleAuthorizeCard.bind(this);
        this.getSetupIntent = this.getSetupIntent.bind(this);
        this.removePaymentMethod = this.removePaymentMethod.bind(this);
        this.intent = {};
        this.billing_address = {
            street: '',
            city: '',
            state: '',
            zip: ''
        };
        UsersModel.getStudentByID($stateParams.userId).then(function (response) {
            _this.student = response;
        });
        this.tokenizationSupported = this.tokenizationSupported.bind(this);
        var policies = PolicyService.getPolicies();
        var billingPolicy = policies.getPolicyByName('billing');
        if (billingPolicy.metaExistsByKey('billing_gateway')) {
            this.gateway = billingPolicy.getMetaValueByKey('billing_gateway');
            this.gateway_config = (0, BillingGateway_1.createBillingGatewayFromPolicies)(policies).config();
        }
        UsersModel.getStudentPaymentMethods($stateParams.userId).then(function (response) {
            _this.paymentMethods = response.payment_methods;
            _this.loading = false;
        });
        if (this.gateway === 'stripe') {
            this.getSetupIntent();
        }
    }
    StudentPaymentMethodsController.prototype.tokenizationSupported = function () {
        if (this.gateway === 'stripe') {
            return true;
        }
        if (this.gateway === 'authorize') {
            // @ts-ignore
            return this.gateway === 'authorize' && !!this.gateway_config.authorize_api_client_key;
        }
        return false;
    };
    StudentPaymentMethodsController.prototype.getSetupIntent = function () {
        var _this = this;
        this.UsersModel.getStudentPaymentSetupIntent(this.$stateParams.userId, 'setup').then(function (response) {
            _this.intent = response.intent;
        });
    };
    StudentPaymentMethodsController.prototype.removePaymentMethod = function (paymentMethodID) {
        var _this = this;
        this.UsersModel.removeStudentPaymentMethod(this.$stateParams.userId, paymentMethodID).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.UsersModel.getStudentPaymentMethods(_this.$stateParams.userId).then(function (response) {
                _this.paymentMethods = response.payment_methods;
                _this.loading = false;
            });
        }).catch(function (response) {
            _this.FlashService.setMessage({
                'type': 'error',
                'message': response.message
            });
            _this.paymentMethods = _this.paymentMethods.filter(function (paymentMethod) {
                return paymentMethod.id !== paymentMethodID;
            });
        });
    };
    StudentPaymentMethodsController.prototype.handleAuthorizeCard = function (opaqueData) {
        var _this = this;
        this.UsersModel.addStudentPaymentMethod(this.$stateParams.userId, {
            opaqueDataValue: opaqueData.dataValue,
            opaqueDataDescriptor: opaqueData.dataDescriptor,
            update_card: false,
            address: {
                street: this.billing_address.street,
                city: this.billing_address.city,
                state: this.billing_address.state,
                zip: this.billing_address.zip
            }
        }).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    };
    return StudentPaymentMethodsController;
}());
StudentPaymentMethodsController.$inject = ['$stateParams', 'UsersModel', 'PoliciesModel', 'PolicyService', 'FlashService', '$location'];
exports.default = StudentPaymentMethodsController;
