import CreateShiftModalInstanceController from './create-shift.controller'
import CreateShiftTemplate from './create-shift.html'
function AdminBatchCreateController($scope, $state, BatchCreateService, FlashService, $uibModal) {

    const vm = this;

    vm.endDate = null;

    vm.drive = BatchCreateService.getDrive();
    BatchCreateService.setContext(vm.context);
    vm.displayTitle = vm.context === 'drive' ? 'session' : vm.context;
    vm.drives = [];
    vm.creatingShift = false;
    vm.duplicatingSession = false;
    vm.today = new Date;

    vm.duplicateSession = duplicateSession;
    vm.toggleCreateBatchSessionForm = toggleCreateBatchSessionForm;
    vm.toggleCreateShift = toggleCreateShift;
    vm.openCreateShiftModal = openCreateShiftModal;

    $scope.$watch(BatchCreateService.getDrive, (newValue, oldValue) => {
        vm.drive = BatchCreateService.getDrive();
        setDatePicker();
    });

    setDatePicker();

    function setDatePicker() {
        vm.datepicker = {
            'open': $event => {
                vm.datepicker.status.opened = true;
            },
            dateOptions: {
                formatYear: 'yy',
                startingDay: 7,
                showWeeks: false,
                'minDate': vm.drive !== null ? vm.drive.date : vm.today,
            },
            'format': 'dd-MMMM-yyyy',
            'status': {
                opened: false
            }
        };
    }

    function openCreateShiftModal() {

        vm.creatingShift = true;

        const modalInstance = $uibModal.open({
            animation: true,
            template: CreateShiftTemplate,
            controller: CreateShiftModalInstanceController,
            controllerAs: 'vm',
            size: 'lg'
        });

        modalInstance.result.then(function (selectedItem) {
            //$scope.selected = selectedItem;
            vm.creatingShift = false;
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    }

    function toggleCreateShift() {
        vm.creatingShift = !vm.creatingShift;
    }

    function duplicateSession() {
        const dupeOptions = {
            'startDate': vm.drive.date,
            'endDate': vm.endDate,
            'days': vm.days
        };
        delete vm.drive.notes; // remove notes
        const collection = BatchCreateService.momentIterate(dupeOptions, vm.drive);

        BatchCreateService.getBatchMethod(collection, 'drive').then(response => {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            if (response.conflicts !== 0) {
                FlashService.setMessage({
                    'type': 'error',
                    'message': 'There were scheduling conflicts, visit the scheduling conflicts page to review them.'
                })
            }
            BatchCreateService.setDrive(null);
            $state.go('drivecoach.admin.drives.createanother');
        })

    }

    function toggleCreateBatchSessionForm() {
        vm.duplicatingSession = !vm.duplicatingSession;
    }

}
AdminBatchCreateController.$inject = ['$scope', '$state', 'BatchCreateService', 'FlashService', '$uibModal'];
export default AdminBatchCreateController;