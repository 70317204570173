import angular from 'angular'
import template from './admin-nav.html'
import dcAdminNavController from './admin-nav.controller'
function dcAdminNav() {
    return {
        restrict: 'E',
        scope: {
            'currentUser': '@'
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: dcAdminNavController,
        controllerAs: 'vm',
        bindToController: true
    }
}
dcAdminNav.$inject = [];


export default angular.module('admin.directives.nav', [])
    .controller('dcAdminNavController', dcAdminNavController)
    .directive('dcAdminNav', dcAdminNav);

