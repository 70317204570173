function PayrollModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/payroll',
            UPDATE : APPURL + '/api/v1/payroll',
            CREATE : APPURL + '/api/v1/payroll'
        },
        policies;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        policies = extract( result );
        return policies;
    }

    model.getByID = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.get = function () {
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = function ( request ) {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.updateCollection = function ( request ) {
        return $http.put( URLS.FETCH + '/6/collection', request ).then( cacheResults );
    };

    model.runPayroll = function(request){
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    }

    model.insert = function ( request ) {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.remove = function ( user ) {
        return $http.delete( URLS.FETCH + '/' + user.id ).then( cacheResults );
    }
}
PayrollModel.$inject = ['$http', 'APPURL'];
export default PayrollModel;

