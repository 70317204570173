import angular from 'angular'
import template from './admin-school-campus-index.html'
import AdminSchoolsLocationsIndexController from './admin-school-campus-index.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.schools.view.locations.index', {
            'url' : '/index',
            'controller' : AdminSchoolsLocationsIndexController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.schools.view.locations.index', [] )
    .config(config)
    .controller( 'AdminSchoolsLocationsIndexController', AdminSchoolsLocationsIndexController )
;
