<template>
  <portal>
    <transition name="fade">
      <div class="modal-bg" v-if="modalShown" :class="{'modal-open': modalShown, 'modal-center': modalCenter}">
        <div class="modal-container" role="dialog" :class="modalClasses">
          <div class="modal-content">
            <div v-if="modalHeader" class="modal-header">
              <slot name="header"></slot>
            </div>
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div v-if="modalFooter" class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
  import { Portal } from '@linusborg/vue-simple-portal'

  export default {
    name: 'dcModal',
    components: { Portal },
    props: {
      //use this prop to trigger modal open/close
      'modalShown': { type: Boolean, required: true },
      //set either of these to false to hide elements (& border)
      'modalHeader': { type: Boolean, default: true },
      'modalFooter': { type: Boolean, default: true },
      'modalClasses': { type: Array, default: () => []},
      'modalCenter': { type: Boolean, default: false } //controls if modal is positioned in the center or at the top of the screen
    },
    data() {
      return {

      }
    },
    methods: {
      emitEvent: function(eventName, dataObject) {
        this.$emit(eventName, dataObject)
      },
      closeModal: function() {
        this.emitEvent('close-modal')
      },
      handleEsc: function(event) {
        if(document.querySelector('.modal-open') && event.key === 'Escape') {
          this.closeModal()
        } else { return false; }
      },
      handleClickOutside: function(event) {
        if(document.querySelector('.modal-open') && (event.target.matches(".close-modal") || (!event.target.closest(".modal-container") && !event.target.closest('.alert')))) {
            this.closeModal()
        } else { return false; }
      },
      handleCloseButtonClick: function(event) {
        this.closeModal();
      }
    },
    mounted() {
      console.log('mounted')
      if(this.modalShown === true) {
        let el = document.body;
        el.classList.add('no-scroll')
      }
      //set up close events
      document.addEventListener( "click", this.handleClickOutside)
      document.addEventListener('keyup', this.handleEsc)
      let closeButtons = [...document.querySelectorAll('.close-modal')]
      closeButtons.map(el => {
        el.addEventListener('click', this.handleCloseButtonClick)
      })
    },
    beforeDestroy() {
      let el = document.body;
      el.classList.remove('no-scroll');
      //remove close events
      document.removeEventListener( "click", this.handleClickOutside)
      document.removeEventListener('keyup', this.handleEsc)
      let closeButtons = [...document.querySelectorAll('.close-modal')]
      closeButtons.map(el => {
        el.removeEventListener('click', this.handleCloseButtonClick)
      })
    },
    watch: {
      modalShown: function(val) {
        if(val === true ) {
          let el = document.body;
          el.classList.add('no-scroll')
        } else {
          let el = document.body;
          el.classList.remove('no-scroll');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../../../assets/css/partials/variables.scss';

  .modal-bg {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    background: #333333C2;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: auto;

    &.modal-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal-container {
      position: relative;
      margin: 40px auto;
      float: none !important;

      .modal-content {
        border-radius: 8px;
        background: $white;
        padding: 5px;
        background-clip: padding-box;
      }
    }
    
    .modal-body p {
      font-size: 16px;
    }
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>