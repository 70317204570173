import angular from 'angular'
import CurrencyMask from './directives/currency-mask/currency-mask.module'
import StudentOverview from './directives/students/student-info-overview/student-info-overview.module'
import Flash from './directives/flash/flash.module'
import Agenda from './directives/calendar-agenda/mini-calendar-agenda.module'
import CalendarFilter from './directives/calendar-filter/calendar-filter.module'
import GoBack from './directives/goback/goback.module'
import ReportCard from './directives/reportcard/reportcard.module'
import StudentUsageDetail from './directives/students/student-usage-detail/student-usage-detail.module'
import StringToNumber from './directives/string-to-number/string-to-number.module'
import Order from './directives/order/order.module'
import FeeDetail from './directives/fee-detail/fee-detail.module'

//Vue components
import Map from './directives/vue-directives/map/map.module';
import Datepicker from './directives/vue-directives/datepicker/datepicker.module';
import CalendarVue from './directives/vue-directives/calendar/calendar.module';
import MiniCalendarAgendaVue from './directives/vue-directives/calendar-agenda/mini-calendar-agenda.module';
import TextEditor from './directives/vue-directives/text-editor/text-editor.module';
import Modal from './directives/vue-directives/modal/modal.module';
import EmailTemplates from './directives/vue-directives/email-templates/email-templates.module';
import EmailEditor from './directives/vue-directives/email-editor/email-editor.module';
import EmailActivity from './directives/vue-directives/email-activity/email-activity.module';
import StudentProgressOverview from './directives/vue-directives/students/student-progress-overview.module';
import CourseAttendance from './directives/vue-directives/course-attendance/course-attendance.module';
import VersionNumber from './directives/vue-directives/version-number/version-number.module';
import ModalNgvue from './directives/vue-directives/modal-ngvue/modal-ngvue.module';    
import Tooltip from './directives/vue-directives/tooltip/tooltip.module';
import Loading from './directives/vue-directives/loading/loading.module';
import OrderDetail from './directives/vue-directives/order-detail/order-detail.module';
import BarChartModule from '../admin/dashboard/instructors/view/scheduling-history/BarChart.module'
import FileUpload from './directives/vue-directives/file-upload/file-upload.module'
import SMSMessenger from './directives/vue-directives/sms-messenger/sms-messenger.module'
import StripeCard from './directives/stripe-card/stripe-card.module'
import OnlineDriversEdFinal from './directives/vue-directives/online-drivers-ed-final/online-drivers-ed-final.module'
import AuthorizeCard from './directives/authorize-card/authorize-card.module'
import ColorPicker from './directives/vue-directives/color-picker/color-picker.module'
import FlashMessage from './directives/vue-directives/flash/flash.module'

export default angular.module( 'directives', [
    CurrencyMask.name,
    Agenda.name,
    GoBack.name,
    ReportCard.name,
    Flash.name,
    StudentOverview.name,
    StudentUsageDetail.name,
    StringToNumber.name,
    CalendarFilter.name,
    Order.name,
    FeeDetail.name,
    //vue components
    CalendarVue.name,
    MiniCalendarAgendaVue.name,
    Map.name,
    TextEditor.name,
    EmailTemplates.name,
    EmailEditor.name,
    Datepicker.name,
    EmailActivity.name,
    Modal.name,
    StudentProgressOverview.name,
    CourseAttendance.name,
    VersionNumber.name,
    Tooltip.name,
    Loading.name,
    ModalNgvue.name,
    OrderDetail.name,
    BarChartModule.name,
    FileUpload.name,
    SMSMessenger.name,
    StripeCard.name,
    OnlineDriversEdFinal.name,
    AuthorizeCard.name,
    ColorPicker.name,
    FlashMessage.name,
]);
