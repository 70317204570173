import moment from 'moment'
function formatEvents(event) {
    event.location = angular.fromJson(event.location);
    event.time = moment(event.time, 'HH:mm:s').format('h:mm a');
    event.duration = moment.duration(event.duration, 'minutes').asHours();
    event.duration = moment.duration(event.duration, 'hours').humanize();
    return event;
}

function AdminEventsController($scope, $state, UsersModel, EventsModel) {

    let vm = this;
    vm.maxSize = 5;
    vm.currentPage = 1;

    vm.pageChanged = function () {
        EventsModel.get({'page': vm.currentPage}).then(function (response) {
            vm.events = response.events.data.filter(formatEvents);
        });
    };

    EventsModel.get().then(function (response) {
        vm.events = response.events.data.filter(formatEvents);
        vm.totalItems = response.events.total;
    });
}


AdminEventsController.$inject = ['$scope', '$state', 'UsersModel', 'EventsModel'];
export default AdminEventsController;