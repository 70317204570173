import angular from 'angular'
import template from './grade-generic-report-card.html'
import InstructorGradeGenericReportCardController from './grade-generic-report-card.controller'

function getStudent($stateParams, UsersModel ) {
    return UsersModel.getStudentByID($stateParams.studentId).then(response => {
        return response;
    });
}
getStudent.$inject = ["$stateParams", "UsersModel"];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.gradegenericreportcard', {
            'url' : '/:driveId/:studentId/grade-generic-report-card',
            'controller' : InstructorGradeGenericReportCardController,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                student: getStudent
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.gradegenericreportcard', [] )
    .config( config )
    .controller( 'InstructorGradeGenericReportCardController', InstructorGradeGenericReportCardController )
;
