import angular from 'angular'
import template from './batch-create-courses.html'
import AdminCoursesBatchCreateController from './admin-batch-create-courses.controller'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.courses.batch', {
            'url' : '/batch-create',
            'controller' : AdminCoursesBatchCreateController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.courses.batch', [] )
    .config( config )
    .controller( 'AdminCoursesBatchCreateController', AdminCoursesBatchCreateController );