import moment from 'moment'
import {isJson} from "../../../common/helpers/json";

class AdminCoursesCreateController {

    constructor($state, UsersModel, CoursesModel, FlashService, ClassroomsModel, BatchCreateService, courseTypes, PoliciesModel, UserService, LocationsModel){
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.LocationsModel = LocationsModel;
        this.PoliciesModel = PoliciesModel;
        this.CoursesModel = CoursesModel;
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;
        this.ClassroomsModel = ClassroomsModel;
        this.BatchCreateService = BatchCreateService;
        this.courseTypes = courseTypes;
        this.locations = [];
        this.classrooms = [];
        this.instructors = [];
        this.submitted = false;
        this.submitSuccess = false;
        this.loading = true;
        this.busy = false;
        this.courseId = null;
        this.user = UserService.getCurrentUser();

        this.overrideInstructorSettings = 'N';
        this.formData = {
            'duration' : {},
            'number_of_seats' : 20
        };

        this.handleLocationChange = this.handleLocationChange.bind(this)
        this.handleLinkClick = this.handleLinkClick.bind(this)
        this.submit = this.submit.bind(this)

        this.setup().then(response => {
            this.loading = false;
            this.busy = false;
        }).catch(Error => {
            console.error(Error);
            this.loading = false;
        });
      
    }

    async setup(){
        const locations = await this.LocationsModel.getLocations();
        const policyResponse = await this.PoliciesModel.getByID('courses');
        const policy = policyResponse.policy;

        this.locations = locations;
        this.policies = {};
        let policyMeta = [];
        this.policies['courses'] = { 'id' : policy.id };
        policy.policymeta.forEach(meta => {
            policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
        } );
        this.policies['courses'].policymeta = policyMeta;

        this.formData.school_id = this.user.school.id;

        if (this.policies.courses.policymeta.date === 'today') {
            this.formData.date = new Date();
        }

        if (this.policies.courses.policymeta.date === '+3 days') {
            this.formData.date = moment().add('3', 'days').toDate();
        }

        if (this.policies.courses.policymeta.date === '+7 days') {
            this.formData.date = moment().add('7', 'days').toDate();
        }

        this.formData.time = moment(this.policies.courses.policymeta.time, 'H:mm').toDate();

        if (this.policies.courses.policymeta.minimum_session_length) {
            let _durMoment = moment.duration(this.policies.courses.policymeta.minimum_session_length, 'minutes').format('H:mm').split(':');
            this.formData.duration.hours = _durMoment[0];
            this.formData.duration.minutes = _durMoment[1];
        }

        const classrooms = await this.getClassrooms(null, true, locations[0])
        const instructors = await this.getInstructors(null, true, locations[0])
        this.loading = false;
    }

    batchCreate() {
        this.BatchCreateService.setCourse(this.formData);
        this.$state.go('drivecoach.admin.courses.batch');
    }

    getInstructors(name = null, all = false, location = null)  {
        if (name === null && all === false) {
            return;
        }

        this.busy = true;
        let params = {
            'location' : { id: location.id },
            'overrideInstructorSettings' : this.overrideInstructorSettings
        };

        return this.UsersModel.searchInstructors(params).then(response => {
            this.instructors = response.instructors;
            this.busy = false;
            return response.instructors;
        }).catch(error => {
            this.busy = false;
            return error;
        });
    }

    getClassrooms(name = null, all = false, location = null ){
        this.busy = true;
        let params = {
            'location' : { id: location.id }
        };

        return this.ClassroomsModel.search(params).then(response => {
            this.classrooms = response.classrooms;
            this.busy = false;
            return response.classrooms;
        } ).catch(error => {
            this.busy = false;
            return error;
        });
    }
  
    handleLocationChange(location_id) {
      let location = this.locations.find((location) => location.id == location_id);
      this.getClassrooms(null, true, location)
      this.getInstructors(null, true, location)
    }
  
    handleLinkClick(data) {
      this.$state.go(data.destination, data.params);
    }
  
    submit(data) {
        this.submitSuccess = false;
        this.submitted = true;

        this.CoursesModel.create(data).then(response => {
            this.FlashService.setMessage({ 'type' : 'success', 'message' : response.message });
            this.submitted = false;
            this.courseId = response.course.id;
            this.$state.go('drivecoach.admin.courses.view', {'courseId' : this.courseId});
      } ).catch(error => {
          this.submitted = false;
      });
    }

}
AdminCoursesCreateController.$inject = ['$state', 'UsersModel', 'CoursesModel', 'FlashService', 'ClassroomsModel', 'BatchCreateService', 'courseTypes', 'PoliciesModel', 'UserService', 'LocationsModel'];
export default AdminCoursesCreateController;