<template>
  <div class="well p0 table-responsive">
    <table v-if="studentAttendance.length > 0" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th v-if="showEmailForm">Select</th>
        <th>Student</th>
        <th>{{ $store.state.viewingCourse.date }} From {{ $store.state.viewingCourse.start }} to {{ $store.state.viewingCourse.end }}</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="student in studentAttendance" :key="student.studentId">
          <td v-if="showEmailForm"><input style="margin-top: 0;" type="checkbox" v-model="selectedStudents" :value="student" /></td>
          <td v-if="context === 'admin'"><a :style="{cursor: 'pointer'}" @click="emitEvent('link-click', { params: {userId: student.studentId}, destination: 'drivecoach.admin.students.view' })">{{student.fullname}}</a></td>
          <td v-if="context === 'instructor'">{{student.fullname}}</td>
          <td v-for="classDate in student.classDates" :key="classDate.id">
            <div class="radio">
              <label>
                <input type="radio" :value="true" v-model="classDate.present"> Present
              </label>
            </div>
            <div class="radio">
              <label>
                <input type="radio" :value="false" v-model="classDate.present"> Absent
              </label>
            </div>
            <hr>
            <div class="checkbox">
              <label>
                <input type="checkbox" :value="true" v-model="classDate.tardy"> Tardy
              </label>
            </div>
          </td>
          <td>
            <button type="button" class="btn btn-dc" @click="emitEvent('update-attendance', {students: [student], courseId: course.id})">Save Student</button>
          </td>
        </tr>
        <tr>
          <td v-if="showEmailForm"><input style="margin-top: 0;" type="checkbox" v-model="allStudentsSelected"/><p style="white-space: break-spaces;">Email All Students</p></td>
          <td></td>
          <td></td>
          <td><button type="button" class="btn btn-dc" @click="emitEvent('update-attendance', {students: studentAttendance, courseId: course.id})">Save All Students</button></td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <p>There are no students in this course yet.</p>
    </div>
    <div v-if="showEmailForm && studentAttendance.length > 0" class="col-md-6">
      <div class="well-header" style="margin-top:2em;">
        <h2 class="well-heading mb-1">Email Students</h2>
        <p>Use this to send emails to students in this course. <strong>Select students to email above.</strong></p>
      </div>
      <div class="well-content">
        <div style="padding: 1em;">
          <dcSendEmail
            :courseId="course.id"
            :emailSent="emailSent"
            :selectedUsers="selectedStudents"
            :placeholder="'Type your message here! Send Zoom link, reminder to bring permits, etc.'"
            @emit-event="emitEvent($event.eventName, $event.dataObject)"
          ></dcSendEmail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  import dcSendEmail from '../send-email/SendEmail.vue'

  export default {
    name: 'dcCourseAttendance', //this becomes the ngvue directive markup aka <dc-component-name></dc-component-name>
    components: { dcSendEmail },
    props: {
      'context': { type: String, default: '' },
      'course': { type: Object, default: (()=> {})},
      'emailSent': { tyep: Boolean, default: false },
      'studentAttendance': { type: Array, required: true },
      'showElement': { type: Boolean, default: true }
    },
    data() {
      return {
        email: {
          subject: '',
          body: ''
        },
        selectedStudents: []
      }
    },
    methods: {
      emitEvent: function(eventName, dataObject) {
        this.$emit(eventName, dataObject)
      },
      moment: function(datetime) {
        return moment(datetime)
      },
    },
    mounted () {
      console.log('mounted')
    },
    computed: {
      allStudentsSelected: {
        get() {
          if (this.studentAttendance && this.studentAttendance.length > 0) { // A users array exists with at least one item
            let allSelected = true;
            for (const student of this.studentAttendance) {
              if (!this.selectedStudents.includes(student)) {
                allSelected = false; // If even one is not included in array
              }
              // Break out of loop if mismatch already found
              if(!allSelected) break;
            }
            return allSelected;
          }
          return false;
        },
        set(value) {
          const selected = [];
          this.studentAttendance.forEach((student) => {
            if(value === true) {
              selected.push(student);
            }
          });
          this.selectedStudents = selected;
        }
      },
      showEmailForm: function() {
        return this.context === 'admin'
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../../../assets/css/partials/variables.scss';

  input[type="radio"], input[type="checkbox"] {
      margin-top: 1px;
  }
  hr {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    border-top-color: $light-grey;
  }
  .well-header {
    padding: 15px;
  }
</style>