import moment from 'moment'
import sortBy from 'lodash/sortBy'

class StudentAgendaController {
    
    constructor(UserService, $state, $stateParams, CalendarModel){
        this.$state = $state;

        this.date = $stateParams.date;
        this.agendaData = {
            'student_id' : UserService.getCurrentUser().id,
            'date' : $stateParams.date
        };

        CalendarModel.getStudentAgenda( this.agendaData ).then( response => {
            this.drives = response.drives.filter( drive => {
                const minutes = moment.duration(drive.session_length, 'minutes');
                drive.date = drive.drive_date;
                drive.type = drive.type.name;
                drive.startTime = moment(drive.start_time, 'HH:mm:s').format('h:mm a');
                drive.timestamp = moment(drive.drive_date + ' ' + drive.start_time ).unix();
                drive.endTime = moment(drive.start_time, 'HH:mm:s').add( minutes, 'minutes').format('h:mm a');
                drive.duration = moment.duration(drive.session_length, 'minutes').asHours();
                drive.duration = moment.duration(drive.duration, 'hours').humanize();
                return drive.status === 'Available' && drive.type === 'Drive';
            } );

            this.courses = response.courses.filter( course => {
                course.type = 'Course';
                course.timestamp = moment(moment(course.date).format('YYYY-MM-DD') + ' ' + course.time , 'YYYY-MM-DD HH:mm:ss').unix();
                return course;
            } );

            this.items = this.drives.concat( this.courses );

            this.items = sortBy(this.items, item => {
                return item.timestamp;
            } );
        } );
    }
    
    next(){
        this.$state.go('drivecoach.student.drives.agenda', {
            'date' : moment(this.date).add(1, 'days').format('YYYY-MM-DD')
        } )
    }

    prev(){
        this.$state.go('drivecoach.student.drives.agenda', {
            'date' : moment(this.date).subtract(1, 'days').format('YYYY-MM-DD')
        } )
    }

}
StudentAgendaController.$inject = ['UserService', '$state', '$stateParams', 'CalendarModel'];
export default StudentAgendaController