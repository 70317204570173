function ExceptionHandler($log, AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY, ENV, UserService) {
  return function(exception, cause) {
    $log.error(exception);
    if (ENV === "production" || ENV === "development" || ENV === "staging" || ENV === "dev") {
      
    }
  };
}

ExceptionHandler.$inject = ['$log', 'AIRBRAKE_PROJECT_ID', 'AIRBRAKE_PROJECT_KEY', 'ENV', 'UserService'];
export default ExceptionHandler;