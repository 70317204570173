import { isJson } from "../../../common/helpers/json";

class StudentSettingsPoliciesCancellationController {

    constructor($scope, $state, UsersModel, FlashService){
        this.formData = {};
        this.$state = $state;
        this.FlashService = FlashService;
        this.UsersModel = UsersModel;

        UsersModel.getStudentByID( $scope.currentUser.id ).then( response => {
            const metadata = {};
            response.usermeta.forEach(meta => {
                metadata[meta.meta_key] = isJson( meta.meta_value ) ? JSON.parse( meta.meta_value ) : meta.meta_value;
            });
            response.usermeta = metadata;
            if (! response.usermeta.hasOwnProperty('communication')) {
                response.usermeta.communication = {};
            }
            this.user = response;
        } ); 
    }

    submit() {
        this.UsersModel.updateStudentSettings(this.user).then(response => {
            this.$state.go('drivecoach.student.settings');
            this.FlashService.setMessage({ 'type' : 'success', 'message' : response.message });
        });
    }

}
StudentSettingsPoliciesCancellationController.$inject = ['$scope', '$state', 'UsersModel', 'FlashService'];
export default StudentSettingsPoliciesCancellationController
