import angular from 'angular'
import AdminSettingsRunPayrollController from './run-payroll.controller'
import template from './run-payroll.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.settings.runpayroll', {
            url : '/run-payroll',
            controller : AdminSettingsRunPayrollController,
            controllerAs: 'vm',
            template: template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.settings.runpayroll', [] )
    .config(config)
    .controller('AdminSettingsRunPayrollController', AdminSettingsRunPayrollController);

