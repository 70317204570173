import moment from 'moment';

function AdminCoursesBatchCreateController( $state, BatchCreateService, CoursesModel, FlashService ) {

    const vm = this;

    vm.endDate = null;
    vm.submitted = false;

    vm.course = BatchCreateService.getCourse();
    vm.courses = [];

    vm.duplicateSession = () => {
        vm.submitted = true;
        let dupeOptions = {
            courses: [{
                name: vm.course.type.name,
                date: moment(vm.course.date).format('YYYY-MM-DD'),
                time: moment(vm.course.time).format('HH:mm:ss'),
                type: vm.course.type
            }],
            start_date : moment(vm.course.date).format('YYYY-MM-DD'),
            start_time : moment(vm.course.time).format('HH:mm:ss'),
            end_date : moment(vm.endDate).format('YYYY-MM-DD'),
            days : vm.days,
            duration: (parseInt(vm.course.duration.hours) * 60) + parseInt(vm.course.duration.minutes),
            instructor: {
                id: vm.course.instructor.id,
            },
            type: vm.course.type,
            location: {
                id : vm.course.location.id
            },
            classroom: {
                id : vm.course.classroom.id
            },
            number_of_seats: vm.course.number_of_seats,
            description : vm.course.description,
        };

        if (!dupeOptions.description || dupeOptions.description === "") {
            delete dupeOptions.description;
        }

        CoursesModel.batchCreate(dupeOptions).then( response => {
            FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            if ( response.conflicts.length !== 0 ) {
                FlashService.setMessage( {
                    'type' : 'error',
                    'message' : 'There were scheduling conflicts, visit the scheduling conflicts page to review them.'
                } )
            }
            BatchCreateService.setCourse( null );
            $state.go( 'drivecoach.admin.calendar', {}, { 'reload' : true } );
        } ).catch( response => {
            vm.submitted = false;
        });

    };

    vm.toggleCreateBatchSessionForm = () => {
        vm.showCreateBatchSessionForm = 'yes';
    }
    
    vm.updateEndDate = (newDate) => {
      vm.endDate = newDate.selected[0]
    }

}

AdminCoursesBatchCreateController.$inject = ['$state', 'BatchCreateService', 'CoursesModel', 'FlashService'];
export default AdminCoursesBatchCreateController;
