"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LoginController = /** @class */ (function () {
    function LoginController($rootScope, $state, LoginService, FlashService, AUTH_EVENTS, APPURL, UserService) {
        var _this = this;
        this.LoginService = LoginService;
        this.FlashService = FlashService;
        this.UserService = UserService;
        this.submitted = this.LoginService.submitted;
        this.APPURL = APPURL;
        this.$state = $state;
        this.currentUser = UserService.getCurrentUser();
        this.errors = [];
        $rootScope.$watch(function () { return _this.LoginService.submitted; }, function () {
            _this.submitted = _this.LoginService.submitted;
        });
        $rootScope.$on(AUTH_EVENTS.loginFailed, function () {
            _this.LoginService.setSubmitted(false);
        });
    }
    LoginController.prototype.login = function (credentials) {
        this.LoginService.setSubmitted(true);
        this.LoginService.login(credentials);
    };
    return LoginController;
}());
LoginController.$inject = ['$rootScope', '$state', 'LoginService', 'FlashService', 'AUTH_EVENTS', 'APPURL', 'UserService'];
exports.default = LoginController;
