import angular from 'angular'
import template from './instructor-log-hours.html'
import InstructorLogHoursController from './instructor-log-hours.controller'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.loghours', {
            'url' : '/log-hours',
            'controller' : InstructorLogHoursController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.loghours', [] )
    .config(config)
    .controller( 'InstructorLogHoursController', InstructorLogHoursController )
;
