import angular from 'angular'
import AdminStaffController from './admin-staff.controller'
import template from './admin-staff.html'
import AdminDashboardStaffIndex from './index/admin-staff-index.module'
import AdminDashboardStaffCreate from './create/admin-create-staff.module'
import AdminDashboardStaffView from './view/admin-view-staff.module'
import AdminDashboardStaffEdit from './edit/admin-edit-staff.module'
import AdminDashboardStaffTasks from './tasks/admin-staff-tasks.module'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.staff', {
            'url' : '/staff',
            'abstract' : true,
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.staff', [
    AdminDashboardStaffIndex.name,
    AdminDashboardStaffCreate.name,
    AdminDashboardStaffView.name,
    AdminDashboardStaffEdit.name,
    AdminDashboardStaffTasks.name
]).config(config)
    .controller( 'AdminStaffController', AdminStaffController )
;
