import Vue from 'vue'
import Vuex from 'vuex'
import {HttpClient} from "./DriveScout/API/core/HttpClient";
import {
    DeleteEmailTemplates,
    GetEmailTemplates,
    PreviewEmail,
    PublishEmailTemplate,
    SendSampleEmail,
} from "./DriveScout/API/models/EmailTemplates";
import {Login} from './DriveScout/API/models/Auth';
import { SendSMS } from './DriveScout/API/models/NotificationModel';
import { UploadFile, DeleteFile, GetFileByID } from './DriveScout/API/models/FileModel'
import { getCourseFiles } from './DriveScout/API/models/CourseModel'
import { saveToDisk } from './app/common/helpers/saveToDisk';

Vue.use(Vuex);

const DS_USER_KEY = 'ds-user';
const DS_TOKEN_KEY = 'ds-token';

const store = new Vuex.Store({
    state: {
        token: '',
        user: null,
        emailEditor: {
            templates: [],
            preview: {
                html: null,
                updated_at: null
            }
        },
        flashMessage: {
            type: "",
            message: "",
        },
        school : {

        },
        policies: {

        },

        //
        viewingCourse: {
            id: 0,
            class_id: 0,
            date: null,
            start: null,
            end: null,
            instructor: {},
            instructor_id: 0
        }
    },
    mutations: {
        SET_SCHOOL (state, school) {
            state.school = school;
        },
        SET_POLICIES (state, policies) {
            state.policies = policies;
        },
        SET_USER (state, user) {
            state.user = user;
            localStorage.setItem(DS_USER_KEY, JSON.stringify(user))
        },
        LOGOUT (state) {
            localStorage.removeItem(DS_TOKEN_KEY);
            localStorage.removeItem(DS_USER_KEY);
        },
        SET_TOKEN (state, token) {
            localStorage.setItem(DS_TOKEN_KEY, token);
            state.token = token;
            HttpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        REMOVE_TOKEN(state){
            localStorage.removeItem(DS_TOKEN_KEY);
            delete state.token;
        },
        // TODO: Put these in an email editor vuex module
        PREVIEW_EMAIL_TEMPLATE(state, template) {
            state.emailEditor.preview.html = template;
        },
        SET_PREVIEW_EMAIL_TEMPLATE(state,template) {
            state.emailEditor.preview.html = template.template;
        },
        SET_EMAIL_PREVIEW_UPDATED_AT(state, date) {
            state.emailEditor.preview.updated_at = date;
        },
        PUBLISH_EMAIL_TEMPLATE(state, templateData) {

        },
        SET_EMAIL_TEMPLATES(state, templates) {
            state.emailEditor.templates = templates;
        },
        UPDATE_EMAIL_TEMPLATE(state, template) {
            state.emailEditor.templates = state.emailEditor.templates.map(emailTemplate => {
                if (emailTemplate.name === template.name) {
                    return template;
                }
                return emailTemplate;
            });
        },

        SET_VIEW_COURSE(state, course){
            state.viewingCourse.id = course.id;
            state.viewingCourse.class_id = course.class_id;
            state.viewingCourse.date = course.date;
            state.viewingCourse.start = course.start;
            state.viewingCourse.end = course.end;
            state.viewingCourse.instructor = course.instructor;
            state.viewingCourse.instructor_id = course.instructor_id;
        },

        /**
         * @param {string} data.message
         * @param {string} data.type 
         */
        ADD_FLASH_MESSAGE(state, data) {
            state.flashMessage.message = data.message;
            state.flashMessage.type = data.type;
        }
        
    },
    actions: {
        LOGOUT ({commit}) {
            commit('LOGOUT')
        },
        SET_TOKEN ({commit}, token) {
            if (token === null) {
                commit('REMOVE_TOKEN');
            } else {
                commit('SET_TOKEN', token);
            }
        },
        SET_USER ({commit}, user){
            commit('SET_USER', user)
        },
        SET_SCHOOL ({commit}, school){
            commit('SET_SCHOOL', school)
        },
        SET_POLICIES ({commit}, policies){
            commit('SET_POLICIES', policies)
        },
        PREVIEW_EMAIL_TEMPLATE ({commit}, template) {
            PreviewEmail(template).then(response => {
                commit('PREVIEW_EMAIL_TEMPLATE', response.data.template)
                commit('SET_EMAIL_PREVIEW_UPDATED_AT', new Date)
            })
        },
        SET_PREVIEW_EMAIL_TEMPLATE ({commit}, template) {
            commit('SET_PREVIEW_EMAIL_TEMPLATE', template)
            commit('SET_EMAIL_PREVIEW_UPDATED_AT', new Date)
        },
        SET_EMAIL_PREVIEW_UPDATED_AT ({commit}, date) {
            commit('SET_EMAIL_PREVIEW_UPDATED_AT', date)
        },
        PUBLISH_EMAIL_TEMPLATE ({commit}, templateData) {
            return PublishEmailTemplate(templateData).then(response => {
                commit('PUBLISH_EMAIL_TEMPLATE', templateData)
                commit('SET_EMAIL_PREVIEW_UPDATED_AT', new Date)
                commit('SET_PREVIEW_EMAIL_TEMPLATE', response.data.template)
                commit('UPDATE_EMAIL_TEMPLATE', response.data.template)
                return response
            });
        },
        SEND_SAMPLE_EMAIL ({commit}, sampleData){
            return SendSampleEmail(sampleData).then(response => {
                // commit('ADD_FLASH_MESSAGE', response.data)
                return response;
            })
        },
        SET_EMAIL_TEMPLATES({commit}, templates) {
            commit('SET_EMAIL_TEMPLATES', templates)
        },
        GET_EMAIL_TEMPLATES({commit}) {
            return GetEmailTemplates().then(response => {
                commit('SET_EMAIL_TEMPLATES', response.data)
            })
        },
        UPDATE_EMAIL_TEMPLATE({commit}, template) {
            commit('UPDATE_EMAIL_TEMPLATE', template)
        },
        DELETE_EMAIL_TEMPLATE({commit}, template) {
            return DeleteEmailTemplates(template).then(response => {
                commit('UPDATE_EMAIL_TEMPLATE', response.data.template)
                return response;
            })
        },

        SEND_SMS ({commit}, smsData){
            return SendSMS(smsData).then(response => {
                // commit('ADD_FLASH_MESSAGE', response.data)
                return response;
            })
        },

        /**
         * 
         * @param {*} param0 
         * @param {string} data.fileType
         * @param {string} data.fileName
         * @param {string} data.formData 
         * @returns 
         */
        UPLOAD_FILE({commit}, data) {
            return UploadFile(data.fileType, data.fileName, data.formData).then(response => {
                commit('ADD_FLASH_MESSAGE', {type: 'success', message: response.data.message})
                return response.data.file;
            })
        },

        DELETE_FILE({commit}, fileId) {
            return DeleteFile(fileId).then(response => {
                commit('ADD_FLASH_MESSAGE', {type: 'success', message: response.data.message})
                return response.data;
            })
        },

        GET_FILE({commit}, fileId) {
            return GetFileByID(fileId).then(response => {
                return response.data.file;
            })
        },

        DOWNLOAD_FILE({commit}, fileId){
            GetFileByID(fileId).then(response => {
                commit('ADD_FLASH_MESSAGE', {
                    'type' : 'success',
                    'message' : response.data.message
                });
                saveToDisk(response.data.temporary_url);
            }).catch(Error => {
    
            });
        },

        GET_COURSE_FILES({commit}, id){
            return getCourseFiles(id).then(response => {
                return response.files;
            })
        },

        // SET_VIEW_COURSE
        SET_VIEW_COURSE({commit}, course){
            commit('SET_VIEW_COURSE', course);
        }
    },
    getters: {
        emailPreviewUpdatedOn: state => {
            return state.emailEditor.preview.updated_at
        }
    }
})

export default store;