import angular from 'angular'
import controller from './grade-graded-report-card.controller'
import template from './grade-graded-report-card.html'

function getStudent($stateParams, UsersModel) {
    return UsersModel.getStudentByID($stateParams.studentId).then(response => {
        return response;
    });
}
getStudent.$inject = ["$stateParams", "UsersModel"];
function getTasks(TasksModel){
    return TasksModel.get().then(response => {
        return response.tasks;
    }) 
}
getTasks.$inject = ["TasksModel"];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state( 'drivecoach.admin.createreportcard', {
            'url' : '/:driveId/:studentId/create-report-card',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                tasks: getTasks,
                student: getStudent
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.createreportcard', [])
    .config(config)
    .controller('CreateReportCardController', controller)
;
