<template>
  <section>
    <section style="margin-bottom: 4em">
      <h1 class="text-center" style="font-size: 22px">School Setup</h1>
    </section>
    <section v-if="step == 'step1'">
      <form>
        <div class="col-xs-12">
          <p class="text-center" style="font-size: 18px; margin-bottom: 2em">
            This is the first name, lastname and phone number of your Owner level
            account.
          </p>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_firstname">Owner First Name</label>
            <input
              class="form-control"
              id="school_setup_firstname"
              type="text"
              v-model="step1.firstname"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">Owner Last Name</label>
            <input
              class="form-control"
              id="school_setup_lastname"
              type="text"
              v-model="step1.lastname"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">Owner Phone Number</label>
            <input
              class="form-control"
              id="school_setup_lastname"
              type="text"
              v-model="step1.phone"
            />
          </div>
          <div class="text-center" style="margin-top: 2em; margin-bottom: 2em">
            <button
              class="btn btn-dc"
              @click.prevent="setStep('step1', 'step2')"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </section>
    <section v-if="step == 'step2'">
      <form>
        <div class="col-xs-12">
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_firstname">School Name</label>
            <input
              class="form-control"
              id="school_setup_firstname"
              type="text"
              v-model="step2.school_name"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">School Phone</label>
            <input
              class="form-control"
              id="school_setup_lastname"
              type="text"
              v-model="step2.school_phone"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">School Email</label>
            <input
              class="form-control"
              id="school_setup_lastname"
              type="text"
              v-model="step2.school_email"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">School Timezone</label>
            <select class="form-control" v-model="step2.school_timezone">
              <option value="America/New_York">Eastern</option>
              <option value="America/Los_Angeles">Pacific</option>
              <option value="America/Chicago">Central</option>
              <option value="America/Phoenix">Mountain Time (No DST)</option>
              <option value="America/Denver">Mountain Time</option>
            </select>
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">School Street</label>
            <input
              class="form-control"
              id="school_setup_lastname"
              type="text"
              v-model="step2.address.street"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">School City</label>
            <input
              class="form-control"
              id="school_setup_lastname"
              type="text"
              v-model="step2.address.city"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">School State</label>
            <input
              class="form-control"
              id="school_setup_lastname"
              type="text"
              v-model="step2.address.state"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2em">
            <label for="school_setup_lastname">School Zip Code</label>
            <input
              class="form-control"
              id="school_setup_lastname"
              type="text"
              v-model="step2.address.zip"
            />
          </div>
          <div class="text-center" style="margin-top: 2em; margin-bottom: 2em">
            <button
              class="btn btn-dc"
              @click.prevent="setStep('step2', 'step1')"
            >
              Prev
            </button>
            <button class="btn btn-dc" @click.prevent="completeSetup()">
              Finish
            </button>
          </div>
        </div>
      </form>
    </section>
  </section>
</template>
<script>
export default {
  props: {
    user: {},
    step: "",
    schoolData: {},
    address: {},
  },
  mounted() {
    this.step1.firstname = this.user.firstname;
    this.step1.lastname = this.user.lastname;
    this.step1.phone = this.user.phone;

    this.step2.school_name = this.schoolData.school_name;
    this.step2.school_phone = this.schoolData.school_phone;
    this.step2.school_email = this.schoolData.school_email;
    this.step2.school_timezone = this.schoolData.school_timezone;

    this.step2.address.street = this.address.street;
    this.step2.address.city = this.address.city;
    this.step2.address.state = this.address.state;
    this.step2.address.zip = this.address.zip;
  },
  data() {
    return {
      step1: {
        firstname: "",
        lastname: "",
        phone: "",
      },
      step2: {
        school_name: "",
        school_phone: "",
        school_email: "",
        school_timezone: "",

        address: {
          street: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    };
  },
  methods: {
    setStep(from, to) {
      // if going backwards, go back
      if (from === "step4" && to === "step3") {
        this.$emit("set-step", "step3");
        return;
      }
      if (from === "step3" && to === "step2") {
        this.$emit("set-step", "step2");
        return;
      }
      if (from === "step2" && to === "step1") {
        this.$emit("set-step", "step1");
        return;
      }

      // step 1 - owner info
      if (from === "step1" && to === "step2") {
        // to step 2

        // save step 1;
        this.$emit("save-step", {
          step: 1,
          data: { user_data: { ...this.step1 } },
        });
      }

      // step 2 - school info and address
      if (from === "step2" && to === "step3") {
        let data = {
          school_data: {
            name: this.step2.school_name,
            email: this.step2.school_email,
            phone: this.step2.school_phone,
            school_timezone: this.step2.school_timezone,
          },
          address: this.step2.address,
        };
        this.$emit("save-step", { step: 2, data: data });
      }

    },
    completeSetup() {
      let payload = {
        user_data: {
          firstname: this.step1.firstname,
          lastname: this.step1.lastname,
          phone: this.step1.phone,
        },
        school_data: {
          name: this.step2.school_name,
          email: this.step2.school_email,
          phone: this.step2.school_phone,
          school_timezone: this.step2.school_timezone,
        },
        address: {
          street: this.step2.address.street,
          city: this.step2.address.city,
          state: this.step2.address.state,
          zip: this.step2.address.zip,
        },
      };

      this.$emit("new-school-setup-complete", payload);
    },
  },
};
</script>
