"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FilesModel = /** @class */ (function () {
    function FilesModel($http, APPURL) {
        this.URLS = {
            FETCH: APPURL + '/api/v1/files',
            UPDATE: APPURL + '/api/v1/files',
            CREATE: APPURL + '/api/v1/files'
        };
        this.$http = $http;
        this.files;
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    FilesModel.prototype.extract = function (result) {
        return result.data;
    };
    FilesModel.prototype.cacheResults = function (result) {
        this.files = this.extract(result);
        return this.files;
    };
    FilesModel.prototype.get = function (file_id) {
        return this.$http.get(this.URLS.FETCH + '/' + file_id).then(this.cacheResults);
    };
    ;
    FilesModel.prototype.uploadFile = function (file_type, name, formData) {
        return this.$http.post(this.URLS.FETCH + '?file_type=' + file_type + '&name=' + name, formData, {
            headers: {
                "Content-Type": undefined
            }
        }).then(this.cacheResults);
    };
    ;
    FilesModel.prototype.destroy = function (id) {
        return this.$http.delete(this.URLS.FETCH + '/' + id).then(this.cacheResults);
    };
    return FilesModel;
}());
FilesModel.$inject = ['$http', 'APPURL'];
exports.default = FilesModel;
