import moment from 'moment'

class InstructorCoursesIndexController {
    constructor(UserService, CoursesModel, courseTypes, LocationsModel, ClassroomsModel) {   
        this.courses = [];
        this.loading = true;
        this.currentPage = 1;
        this.CoursesModel = CoursesModel;
        this.LocationsModel = LocationsModel
        this.ClassroomsModel = ClassroomsModel
        this.pageChanged = this.pageChanged.bind(this);
        this.search = this.search.bind(this);
        this.getClassrooms = this.getClassrooms.bind(this);
        this.getCourses = this.getCourses.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);

        this.user = UserService.getCurrentUser();
        this.courseTypeOptions = courseTypes;
        this.courseTypeOptions.unshift({'name': 'All'});
        this.classrooms = []
        this.locations = []
        this.currentPage = 1;

        this.searchData = {
            paginate: true,
            instructor: this.user
        }

        this.setup().then(res => {
            this.loading = false;
        });
    }

    async setup() {
        const courses = await this.getCourses()
        const locations = await this.LocationsModel.getLocations();
        this.locations = locations;
        this.locations.unshift({'name': 'All'});
        const classrooms = await this.getClassrooms(null, true)
    }

    getCourses() {
        return this.CoursesModel.get({'instructor_id': this.user.id}).then((response) => {
            this.courses = response.courses.data.map(this.formatCourses);
            this.totalItems = response.courses.total;
        });
    }

    pageChanged(){
        this.searchData.page = this.currentPage
        return this.CoursesModel.search(this.searchData).then(response => {
            this.courses = response.courses.data.map(this.formatCourses);
            this.totalItems = response.courses.total;
        })
    }

    formatCourses(course){
        let timeMoment = moment(course.time, 'H:mm:ss');
        course.time = timeMoment.format('h:mm A');
        course.end = timeMoment
            .add(parseInt(course.duration), 'm')
            .format('h:mm A');
        return course;
    }

    search() {
        this.loading = true;
        this.searchData.page = 1
        return this.CoursesModel.search(this.searchData).then(response => {
            this.courses = response.courses.data.map(this.formatCourses);
            this.totalItems = response.courses.total;
            this.currentPage = 1
            this.loading = false;
        })
    }

    getClassrooms(name = null, all = false, location = null ){
        this.busy = true;
        return this.ClassroomsModel.search({}).then(response => {
            this.classrooms = response.classrooms;
            this.classrooms.unshift({'name': 'All'});
            this.busy = false;
            return response.classrooms;
        } ).catch(error => {
            this.busy = false;
            return error;
        });
    }

    updateEndDate(newDate) {
      this.searchData.end_date = newDate.selected[0]
    }
    
    updateStartDate(newDate) {
      this.searchData.start_date = newDate.selected[0]
    }
} 
InstructorCoursesIndexController.$inject = ['UserService', 'CoursesModel', 'courseTypes', 'LocationsModel', 'ClassroomsModel'];
export default InstructorCoursesIndexController;