"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserService = /** @class */ (function () {
    function UserService(store, $cookies, SITEURL, VueStoreFactory) {
        this.DS_USER_TOKEN = 'dc-user';
        this.currentUser = null;
        this.store = store;
        this.$cookies = $cookies;
        this.SITEURL = SITEURL;
        this.VueStoreFactory = VueStoreFactory;
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.removeCurrentUser = this.removeCurrentUser.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.setCurrentUserAttribute = this.setCurrentUserAttribute.bind(this);
    }
    UserService.prototype.setCurrentUser = function (user) {
        if (user === void 0) { user = null; }
        if (user === null) {
            this.currentUser = user;
            this.store.remove(this.DS_USER_TOKEN);
        }
        else {
            this.currentUser = user;
            this.store.set(this.DS_USER_TOKEN, user);
        }
        this.VueStoreFactory.dispatch('SET_USER', this.currentUser);
        return this.currentUser;
    };
    UserService.prototype.removeCurrentUser = function () {
        this.currentUser = null;
        this.store.remove(this.DS_USER_TOKEN);
        var domain = this.SITEURL.replace('http://', '.').replace('https://', '.').replace('app.', '');
        this.$cookies.remove(this.DS_USER_TOKEN, { path: '/', domain: domain });
    };
    /**
     * Retrieve the current user from either the store or local storage
     */
    UserService.prototype.getCurrentUser = function () {
        return this.currentUser;
    };
    UserService.prototype.setCurrentUserAttribute = function (key, value) {
        this.currentUser[key] = value;
    };
    return UserService;
}());
UserService.$inject = ['store', '$cookies', 'SITEURL', 'VueStoreFactory'];
exports.default = UserService;
