import template from './flash.html'
function dcFlash() {
    return {
        restrict : 'E',
        scope : {
            'isVisible' : '@',
            'message' : '@',
            'type' : '@'
        },
        template : template,
        controller : 'dcFlashController',
        controllerAs : 'vm',
        bindToController : true
    }
}
export default dcFlash;