import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcModal from './Modal.vue'

export default angular.module( 'directives.modal', [] )
  .directive('dcModal', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcModal', dcModal))
  }])
