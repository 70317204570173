import angular from 'angular'
import template from './product-categories.html'
import controller from './product-categories.controller'
function dsProductCategories() {
    return {
        restrict: 'E',
        scope: {
            'product' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.productcategories', [])
    .controller('dcProductCategoriesController', controller)
    .directive('dsProductCategories', dsProductCategories);

