class dsProductFormController {
  constructor(UserService, OnlineDriversEd) {
    this.loading = true;
    this.user = UserService.getCurrentUser();
    this.school = this.user.school;
    this.handlePurchaseInstructionsChange = this.handlePurchaseInstructionsChange.bind(this)
    this.handleProductDescriptionChange = this.handleProductDescriptionChange.bind(this)
    this.onlineDriversEdConfig = {
      available: false,
      enabled: false
    }
    OnlineDriversEd.getFeatureDetails().then(response => {
      this.loading = false;
      this.onlineDriversEdConfig.available = response.available;
      this.onlineDriversEdConfig.enabled = response.enabled;
    }).catch(Error => {
      this.loading = false;
    });
  }

  handlePurchaseInstructionsChange(data) {
    this.product['purchase_instructions'] = data.html;
  }

  handleProductDescriptionChange(data) {
    this.product['description'] = data.html;
  }
}
dsProductFormController.$inject = ['UserService', 'OnlineDriversEd'];
export default dsProductFormController;