import angular from 'angular'
import template from './student-orders.html'
import controller from './student-orders.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.instructor.dashboard.studentorders', {
            'url' : '/student-orders/:student_id',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            resolve: {
                student : getStudent
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        });
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


function getStudent($stateParams, UsersModel){
    return UsersModel.getStudentByID($stateParams.student_id).then(response => {
        return response;
    })
}
getStudent.$inject = ['$stateParams', 'UsersModel'];

export default angular.module('instructor.dashboard.studentorders', [])
    .config(config)
    .controller('InstructorsStudentOrdersController', controller)
;
