import moment from 'moment'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import { dateStringToDate } from '../../../common/helpers/time';
function AdminDrivesSchedulingConflictsController($state, $q, DrivesModel, CoursesModel, EventsModel, drives, courses, events, FlashService) {

    const vm = this;
    vm.entities = [];
    vm.goTo = goTo;
    vm.delete = deleteEntity;
    vm.page_by = 25;
    vm.currentPage = 1;
    vm.pageChanged = pageChanged.bind(this);

    vm.schedulingConflicts = {
        drives : {
            currentPage: 1,
            totalItems: drives.drives.total,
            pageBy: 25,
            data: drives.drives.data,
        },
        courses : {
            currentPage: 1,
            pageBy: 25,
            data: courses.courses.data,
            totalItems: courses.courses.total,
        },
        events : {
            currentPage: 1,
            pageBy: 25,
            data: events.events.data,
            totalItems: events.events.total,
        }
    }

    init();

    /**
     * Build entities
     */
    function init() {
        vm.schedulingConflicts.drives.data = drives.drives.data.map((drive) => {
            const _tmp = {};
            _tmp.created_at = drive.created_at;
            _tmp.id = drive.id;
            _tmp.date = dateStringToDate(drive.drive_date).toLocaleDateString('en-US');
            _tmp.instructor = {'name': drive.instructor.fullname};
            _tmp.type = {'name': drive.type.name};
            _tmp.timestamp = moment(_tmp.created_at, 'YYYY-MM-DD H:mm:ss').unix();
            _tmp.start_time = moment(drive.start_time, 'H:mm:ss').format('h:mm');
            _tmp.duration = moment.duration(drive.session_length, 'minutes').asHours();
            _tmp.notes = drive.notes;
            return _tmp;
        });

        vm.schedulingConflicts.courses.data = courses.courses.data.map((course) => {
            const _tmp = {};
            _tmp.created_at = course.created_at;
            _tmp.id = course.id;
            _tmp.date = course.date;
            _tmp.instructor = {'name': course.instructor.fullname};
            _tmp.type = {'name': 'Course'};
            _tmp.start_time = moment(course.time, 'H:mm:ss').format('h:mm');
            _tmp.duration = moment.duration(course.duration, 'minutes').asHours();
            _tmp.notes = course.notes;
            _tmp.timestamp = moment(_tmp.created_at, 'YYYY-MM-DD H:mm:ss').unix();
            return _tmp;
        });

        vm.schedulingConflicts.events.data = events.events.data.map((event) => {
            const _tmp = {};
            _tmp.created_at = event.created_at;
            _tmp.id = event.id;
            _tmp.date = event.date;
            _tmp.instructor = {'name': event.instructor.fullname};
            _tmp.type = {'name': 'Event'};
            _tmp.start_time = moment(event.time, 'H:mm:ss').format('h:mm');
            _tmp.duration = moment.duration(event.duration, 'minutes').asHours();
            _tmp.notes = event.notes;
            _tmp.timestamp = moment(_tmp.created_at, 'YYYY-MM-DD H:mm:ss').unix();
            return _tmp;
        });

    }

    function goTo(id, type, $index) {
        if (type === 'Course') {
            return $state.go('drivecoach.admin.courses.edit', {'courseId': id});
        }

        if (type === 'Event') {
            return $state.go('drivecoach.admin.events.edit', {'eventId': id});
        }

        return $state.go('drivecoach.admin.drives.edit', {'id': id});

    }

    function deleteEntity(entity, type, $index) {
        if (type === 'Course') {
            CoursesModel.delete(entity).then(function (response) {
                FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                vm.schedulingConflicts.courses.data.splice($index, 1);
            })
        } else if (type === 'Event') {
            EventsModel.delete(entity).then(function (response) {
                FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                vm.schedulingConflicts.events.data.splice($index, 1);
            });
        } else {
            DrivesModel.deleteDrive(entity.id).then(function (response) {
                FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                vm.schedulingConflicts.drives.data.splice($index, 1);
            });
        }

    }

    function pageChanged(type)
    {
        if (type === 'drive') {
            const query = {'paginate' : "1", 'status' : 'Inactive - Scheduling Conflict', 'page_by' : vm.schedulingConflicts.drives.pageBy, 'page' : vm.schedulingConflicts.drives.currentPage};
            DrivesModel.search(query).then(response => {
                vm.schedulingConflicts.drives.data = response.drives.data.map((drive) => {
                    const _tmp = {};
                    _tmp.created_at = drive.created_at;
                    _tmp.id = drive.id;
                    _tmp.date = dateStringToDate(drive.drive_date).toLocaleDateString('en-US');
                    _tmp.instructor = {'name': drive.instructor.fullname};
                    _tmp.type = {'name': drive.type.name};
                    _tmp.timestamp = moment(_tmp.created_at, 'YYYY-MM-DD H:mm:ss').unix();
                    _tmp.start_time = moment(drive.start_time, 'H:mm:ss').format('h:mm');
                    _tmp.duration = moment.duration(drive.session_length, 'minutes').asHours();
                    _tmp.notes = drive.notes;
                    return _tmp;
                });
                vm.schedulingConflicts.drives.currentPage = response.drives.current_page;
                vm.schedulingConflicts.drives.totalItems = response.drives.total;
            })
        }

        if (type === 'courses') {
            const query = {'paginate' : "1", 'status' : 'Inactive - Scheduling Conflict', 'page_by' : vm.schedulingConflicts.courses.pageBy, 'page' : vm.schedulingConflicts.courses.currentPage};
            CoursesModel.search(query).then(response => {
                vm.schedulingConflicts.courses.data = response.courses.data.map((course) => {
                    const _tmp = {};
                    _tmp.created_at = course.created_at;
                    _tmp.id = course.id;
                    _tmp.date = course.date;
                    _tmp.instructor = {'name': course.instructor.fullname};
                    _tmp.type = {'name': 'Course'};
                    _tmp.start_time = moment(course.time, 'H:mm:ss').format('h:mm');
                    _tmp.duration = moment.duration(course.duration, 'minutes').asHours();
                    _tmp.notes = course.notes;
                    _tmp.timestamp = moment(_tmp.created_at, 'YYYY-MM-DD H:mm:ss').unix();
                    return _tmp;
                });
                vm.schedulingConflicts.courses.currentPage = response.courses.current_page;
                vm.schedulingConflicts.courses.totalItems = response.courses.total;
            })
        }

        if (type === 'events') {
            const query = {'paginate' : "1", 'status' : 'Inactive - Scheduling Conflict', 'page_by' : vm.schedulingConflicts.events.pageBy, 'page' : vm.schedulingConflicts.events.currentPage};
            EventsModel.search(query).then(response => {
                vm.schedulingConflicts.events.data = response.events.data.map((event) => {
                    const _tmp = {};
                    _tmp.created_at = event.created_at;
                    _tmp.id = event.id;
                    _tmp.date = event.date;
                    _tmp.instructor = {'name': event.instructor.fullname};
                    _tmp.type = {'name': 'Event'};
                    _tmp.start_time = moment(event.time, 'H:mm:ss').format('h:mm');
                    _tmp.duration = moment.duration(event.duration, 'minutes').asHours();
                    _tmp.notes = event.notes;
                    _tmp.timestamp = moment(_tmp.created_at, 'YYYY-MM-DD H:mm:ss').unix();
                    return _tmp;
                });
                vm.schedulingConflicts.events.currentPage = response.events.current_page;
                vm.schedulingConflicts.events.totalItems = response.events.total;
            });
        }
        
    }
}
AdminDrivesSchedulingConflictsController.$inject = ['$state', '$q', 'DrivesModel', 'CoursesModel', 'EventsModel', 'drives', 'courses', 'events', 'FlashService'];
export default AdminDrivesSchedulingConflictsController;