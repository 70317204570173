import angular from 'angular'
import template from './admin-view-instructor.html'
import AdminInstructorsViewController from './admin-view-instructor.controller'
import AdminDashboardInstructorViewTimeOffRequests from './time-off-requests/admin-instructor-view-time-off-requests.module'
import AdminDashboardInstructorViewSchedule from './schedule/admin-view-instructor-schedule.module'
import AdminDashboardInstructorViewSchedulingHistory from './scheduling-history/scheduling-history.module'
import { telFormat } from '../../../../common/filters/telFormat';

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.instructors.view', {
            'url': '/:instructorId/view',
            'controller': AdminInstructorsViewController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.instructors.view', [
    AdminDashboardInstructorViewTimeOffRequests.name,
    AdminDashboardInstructorViewSchedule.name,
    AdminDashboardInstructorViewSchedulingHistory.name,
]).config(config)
    .controller('AdminInstructorsViewController', AdminInstructorsViewController)
    .filter('telFormat', telFormat);  

