<!--//this component is used to send emails on view course and course roster page currently -->
<template>
  <form>
    <div style="margin-bottom: 1em;" v-if="selectedUsers.length > 0">
      <p style="margin-bottom: 5px;">To:</p>
      <div><span v-for="user in selectedUsers" :key="user.id" style="display: inline-block; padding: 5px; margin-bottom: 5px; margin-right: 5px; background: #eee;">{{ user.fullname }}</span></div>
    </div>
    <div>
      Subject:<br /> <input type="text" v-model="email.subject" class="form-control">
    </div>
    <div style="margin-top: 1em;">
      <dcTextEditor :content="email.body"
              @editor-content-change="handleEditorContentChange($event, 'body')"  
              :placeholder="placeholder"></dcTextEditor>
    </div>
    <div>
      <button style="margin-top: 2em;" data-cy="sendCourseNotification" @click.prevent="sendCourseEmail()" class="btn btn-blue">Send Email</button>
    </div>
  </form>
</template>

<script>
  import dcTextEditor from '../text-editor/TextEditor.vue'

  export default {
    name: 'dcSendEmail',
    components: { dcTextEditor },
    props: {
      'courseId': { type: Number },
      'emailSent': { type: Boolean, default: false },
      'selectedUsers': { type: Array, default: () => [] },
      'placeholder': { type: String }
    },
    data() {
      return {
        email: {
          body: "",
          subject: ""
        }
      }
    },
    methods: {
      emitEvent: function(eventName, dataObject) {
        this.$emit('emit-event', {eventName: eventName, dataObject: dataObject})
      },
      handleEditorContentChange: function(value, key) {
        this.email[key] = value.html;
      },
      sendCourseEmail: function() {
        //USER MUST HAVE ID KEY FOR THIS TO SEND
        const data = {
          course_id: this.courseId,
          to: this.selectedUsers.map(user => {
            return user.id;
          }),
          subject: this.email.subject,
          body: this.email.body
        }
        this.emitEvent('send-course-email', data)
      }
    },
    watch: {
      emailSent(newVal){
        if(newVal === true) {
          this.email.subject = "";
          this.email.body = "";
        }
      },
    }
  }

</script>

<style lang="scss" scoped>
.form-control {
  text-align: left;
}
</style>
