import moment from 'moment'
class GradeGenericReportCardController {
    constructor($state, $stateParams, DrivesModel, FlashService, ReportcardsModel, student, $window) {
        this.$window = $window;
        this.reportcard = {
            student: {
                firstname: student.firstname,
                lastname: student.lastname
            },
        };

        this.drive = [];
        this.tasks = [];
        this.grades = [];
        this.gradeIncomplete = true;

        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ReportcardsModel = ReportcardsModel;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;

        DrivesModel.getDriveByID($stateParams.driveId).then(response => {
            this.drive = response.drive;
            this.driveDate = moment(this.drive.drive_date + ' ' + this.drive.start_time);
            this.untilDrive = this.driveDate.fromNow();
        });
    }

    submitGrade() {

        const data = {
            'drive_id': this.$stateParams.driveId,
            'internal_note': this.internal_note,
            'external_note': this.external_note
        };

        this.ReportcardsModel.submitGenericGrade(data).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.$state.go('drivecoach.admin.reportcards');
        });

    }

    deleteReportCard(reportcard_id) {
        const confirm = this.$window.confirm(
            "Are you sure you want to delete this report card? THIS CANNOT BE UNDONE."
        );
        if (confirm === false) {
            return;
        }
        this.busy = true;
        this.ReportcardsModel.destroy({ id: reportcard_id })
            .then((response) => {
                this.busy = false;
                this.$state.go('drivecoach.admin.reportcards');
                this.FlashService.setMessage({
                    type: "success",
                    message: response.message,
                });
            })
            .catch((Error) => {
                this.busy = false;
            });
    }

}
GradeGenericReportCardController.$inject = ['$state', '$stateParams', 'DrivesModel', 'FlashService', 'ReportcardsModel', 'student', '$window'];
export default GradeGenericReportCardController;
