"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LogoutController = /** @class */ (function () {
    function LogoutController($rootScope, LoginService, UserService, AUTH_EVENTS, DrivesFilterService) {
        LoginService.logout().then(function (response) {
            UserService.removeCurrentUser();
            DrivesFilterService.clearFilterOptions();
            DrivesFilterService.clearFilterOptions();
            $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);
        }, function (error) {
            console.log(error);
        });
    }
    return LogoutController;
}());
LogoutController.$inject = ['$rootScope', 'LoginService', 'UserService', 'AUTH_EVENTS', 'DrivesFilterService'];
exports.default = LogoutController;
