import {Task} from "../../classes/Task";

class AdminTasksController {

    constructor(UserService, $rootScope, AdminTasksService) {
        this.AdminTasksService = AdminTasksService;
        this.$rootScope = $rootScope;
        this.user = UserService.getCurrentUser();
        this.tasks = [];

        $rootScope.$on('TASKS_UPDATED', () => {
            this.filterTasks(this.filteringBy, this.currentPage);
        })
        
        $rootScope.$on('ADMIN_TASK_CREATED', (data) => {
            this.filterTasks(this.filteringBy, this.currentPage);
        })

        this.AdminTasksService.loadTasks({}).then(response => {
            this.handleResponse(response);
        });

    }

    filterTasks(filterBy = 'all', page = 1) {
        this.filteringBy = filterBy;

        this.AdminTasksService.filterTasks(filterBy, page).then(response => {
            this.handleResponse(response);
        })
    }

    pageChanged(){
        this.filterTasks(this.filteringBy, this.currentPage);
    }

    handleResponse(response) {
        this.tasks_last_page = response.data.last_page;
        this.currentPage = response.data.current_page;
        let tasks = response.data.data.map(task => new Task(task))             
        this.totalItems = response.data.total;
        this.AdminTasksService.setTasks(tasks);
        this.tasks = this.AdminTasksService.getTasks();
    }

}

AdminTasksController .$inject = ['UserService', '$rootScope', 'AdminTasksService'];
export default AdminTasksController;