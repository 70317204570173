import angular from 'angular'
import template from './admin-settings-billing.html'
import AdminBillingController from './admin-settings-billing.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.settings.billing', {
            url: '/billing',
            controller: AdminBillingController,
            controllerAs: 'vm',
            template: template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.settings.billing', [])
    .config(config)
    .controller('AdminBillingController', AdminBillingController)
