import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcInstructorViewCourse from './InstructorViewCourse.vue'
import template from './instructor-view-course.html'
import InstructorViewCourseController from './instructor-view-course.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.course.view', {
            url : '/view',
            template : template,
            controller : InstructorViewCourseController,
            controllerAs : 'vm',
            resolve : {
                course : getCourse
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

function getCourse( $stateParams, CoursesModel ) {
    return CoursesModel.show( $stateParams.courseId ).then( response => {
        // const _durMoment = moment.duration( response.course.duration, 'minutes' ).format( 'H:mm' ).split( ':' );
        // response.course.location = angular.fromJson( response.course.location );
        // response.course.date = moment( response.course.date ).toDate();
        // response.course.time = moment( response.course.time, 'HH:mm:ss' ).toDate();
        // response.course.duration = {
        //     'hours' : _durMoment[0],
        //     'minutes' : _durMoment[1]
        // };
        return response.course;
    } );
}
getCourse.$inject = ['$stateParams', 'CoursesModel'];

export default angular.module( 'instructor.dashboard.course.view', [] )
    .config(config)
    .controller( 'InstructorViewCourseController', InstructorViewCourseController )
    .directive('dcInstructorViewCourse', ['createVueComponent', function (createVueComponent) {
        return createVueComponent(Vue.component('dcInstructorViewCourse', dcInstructorViewCourse))
    }]);
