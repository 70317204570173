function HoursLogModel( $http , APPURL) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/hourslog',
            UPDATE : APPURL + '/api/v1/hourslog',
            CREATE : APPURL + '/api/v1/hourslog'

        },
        hourslog,
        user,
        metadata;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        hourslog = extract( result );
        return hourslog;
    }

    model.getRequestByID = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.get = function () {
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = function ( request ) {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.create = function ( request ) {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.delete = function ( user ) {
        return $http.delete( URLS.FETCH + '/' + user.id ).then( cacheResults );
    }

}
HoursLogModel.$inject = ['$http', 'APPURL'];
export default HoursLogModel;
