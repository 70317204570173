import angular from 'angular'
import StudentSettingsPoliciesCancellationController from './student-edit-communication-settings.controller'

import template from './student-edit-communication-settings.module.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {

    $stateProvider
        .state( 'drivecoach.student.settings.communication', {
            url : '/communication',
            controller : StudentSettingsPoliciesCancellationController,
            controllerAs : 'vm',
            template : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.settings.communication', [] )
    .config( config )
    .controller( 'StudentSettingsPoliciesCancellationController', StudentSettingsPoliciesCancellationController )

