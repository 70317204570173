import angular from 'angular'
import controller from './instructor-create-order.controller'
import template from './instructor-create-order.html'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.instructor.dashboard.createorder', {
            url: '/create-order',
            template: template,
            controller: controller,
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        })
        ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('instructor.dashboard.createorder', [])
    .config(config)
    .controller('InstructorCreateOrderController', controller);