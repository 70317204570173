class AdminSchoolsVehiclesCreateController {

    constructor($state, VehicleAPI, FlashService, VehiclesModel, school){
      this.locations = school.locations;
      this.school = school;
      this.vehicles = {
        makes: [],
        models: [],
        years: VehicleAPI.getYears()
      };
      this.vehicle = {
        name: "",
        year: null,
        make: null,
        model: null
      };

      this.VehiclesModel = VehiclesModel;
      this.VehicleAPI = VehicleAPI;
      this.FlashService = FlashService;
      this.$state = $state;
      this.submitting = false;

      VehicleAPI.getMakes().then(response => {
        this.vehicles.makes = response;
      });
    }
  
    getModelsByYear(vehicle) {
      if(vehicle.year && vehicle.make) {
        this.vehicles.models = [];
        this.noModelsError = '';
        this.VehicleAPI.getModelsByYear(vehicle.make, vehicle.year).then(result => {
          if(result.error) {
            this.noModelsError = result.error;
          } else {
            this.vehicles.models = result;
          }
        });
      }
    }

    addVehicle() {
        this.submitted = true;
        let vehicle = {
            'location_id' : this.vehicle.location_id,
            'make' : this.vehicle.make,
            'model' : this.vehicle.model,
            'year' : this.vehicle.year,
            'license' : this.vehicle.license,
            'status' : this.vehicle.status,
            'vin' : this.vehicle.vin,
            'seats' : this.vehicle.seats
        }
   
        this.submitting = true;
        this.VehiclesModel.createVehicle(vehicle).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            this.vehicle = {
              name: "",
              year: null,
              make: null,
              model: null,
              license: "",
              status: "",
              vin: "",
              seats: null
            };
            this.submitting = false;
            this.$state.go('drivecoach.admin.schools.vehicles.index', {'schoolId': this.school.id}, {'reload': true});
        }).catch(error => {
            this.submitting = false;
        });
        this.submitting = false;
    }

}
AdminSchoolsVehiclesCreateController.$inject = ['$state', 'VehicleAPI', 'FlashService', 'VehiclesModel', 'school'];
export default AdminSchoolsVehiclesCreateController;
