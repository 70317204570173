import moment from "moment";

class dsStudentInfoOverviewController {
    constructor($state, UserService, UsersModel) {
        this.$state = $state;
        this.currentUser = UserService.getCurrentUser();
        this.UsersModel = UsersModel;
    }

    toggleNotebook()
    {
        if (this.currentUser.usertype_id === 3) {
            this.$state.go();
        } else {
            this.$state.go('drivecoach.admin.students.view.notes', {'userId' : this.student.id});
        }
    }

    closeModal()
    {
        this.modalShown = false;
    }

    formatNoteContent(note){
        return this.$sce.trustAsHtml(note);
    }

    handleAddNoteChange(data){
        this.note.content = data.html;
    }

    getRole(role)
    {
        if (role === 1) {
            return 'Students';
        }

        if (role === 3) {
            return 'Instructors';
        }

        if (role === 4) {
            return 'Owners';
        }

        if (role === 5) {
            return 'Administrators';
        }

        if (role === 9) {
            return 'Staff';
        }

        return 'Owner';
    }

    addNote()
    {
        this.submitting = true;
        this.UsersModel.addStudentNote(this.student.id, this.note).then(
        (response) => {
            this.FlashService.setMessage({type: 'success', message: response.message});
            this.studentNotes.data.push(response.note);
            this.submitting = false;
            this.note = {
                content : "",
                allowed_roles: {
                    students: false,
                    instructors: false,
                    administrators: true,
                    staff: true
                }
            }
        }
        ).catch(Error => {
            this.submitting = false;
        });
    }

    studentNotesPageChanged()
    {
        this.submitting = true;
        this.UsersModel.getStudentNotes(this.student.id, {page: this.studentNotes.currentPage}).then(
        (response) => {
            this.studentNotes.data = response.notes.data;
            this.studentNotes.total = response.notes.total;
            this.studentNotes.currentPage = response.notes.current_page;
            this.submitting = false;
        }
        ).catch(Error => {
            this.submitting = false;
        });
    }

    getStudentAge() {
        if (this.student === null) {
            return 100;
        }
        if (typeof this.student.usermeta === 'undefined') {
            return 100;
        }
        if( typeof this.student.usermeta.dob === 'undefined' ){
            return 100;
        }
        let dob = moment(this.student.usermeta.dob, 'YYYY-MM-DD' ).toDate();
        return moment( new Date() ).diff( moment( dob ), 'years' );
    }

}
dsStudentInfoOverviewController.$inject = ['$state', 'UserService', 'UsersModel'];
export default dsStudentInfoOverviewController;
