import angular from 'angular'
import StudentAgendaController from './student-agenda.controller'
import template from './student-agenda.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.drives.agenda', {
            'url' : '/agenda/:date',
            'controller' : StudentAgendaController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } );
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.drives.agenda', [] )
    .config( config )
    .controller( 'StudentAgendaController', StudentAgendaController );


