import { nav } from '../../../common/helpers/nav'
function dcAdminNavController(UserService, FlashService, UsersModel, $state, $rootScope, DrivesFilterService) {

    let vm = this;
    vm.currentUser = UserService.getCurrentUser();
    vm.switchToSchool = switchToSchool;

    function reloadCurrentState() {
        // if( $rootScope.currentState ){
        //     $state.go($rootScope.currentState.name, $rootScope.currentState.params, {'reload' : true});
        // }else{
        $state.go('drivecoach.admin.dashboard', {}, {'reload': true});
        // }
    }

    function switchToSchool(schoolID) {
        UsersModel.switchToSchool(schoolID).then(function (response) {
            response.user.access_token = UserService.getCurrentUser().access_token;
            UserService.setCurrentUser(response.user);
            DrivesFilterService.clearFilterOptions();
            DrivesFilterService.clearFilterOptions();
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            reloadCurrentState();
        });
    }

    nav(); 

}
dcAdminNavController.$inject = ['UserService', 'FlashService', 'UsersModel', '$state', '$rootScope', 'DrivesFilterService'];

export default dcAdminNavController;