function AdminSchoolsLocationsClassroomsCreateController($state, $stateParams, LocationsModel, FlashService) {

    const vm = this;
    vm.formData = {};
    vm.formData.location_id = $stateParams.locationId;

    // this will be student info pulled from the server later
    LocationsModel.getLocationByID($stateParams.locationId).then(function (response) {
        vm.location = response;
    });

    vm.createClassroom = function (isValid) {

        if (isValid) {
            LocationsModel.createClassroom(vm.formData).then(function (response) {
                FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                $state.go('drivecoach.admin.schools.view.locations.view.classrooms', {}, {'reload': true});
            })
        } else {
            FlashService.setMessage({
                'type': 'error',
                'message': 'The name is required'
            });
        }
    }
}
AdminSchoolsLocationsClassroomsCreateController.$inject = ['$state', '$stateParams', 'LocationsModel', 'FlashService']
export default AdminSchoolsLocationsClassroomsCreateController;