import angular from 'angular'
import controller from './admin-reportcards.controller'
import template from './admin-reportcards.html'

function getInstructors(UsersModel)
{
    return UsersModel.getInstructors({'all' : true}).then(response => {
        return response.instructors;
    });
}
getInstructors.$inject = ['UsersModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.reportcards', {
            'url' : '/reportcards',
            'controller' : 'AdminReportcardsController',
            'controllerAs' : 'vm',
            'template' : template,
            resolve: {
                instructors: getInstructors
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.reportcards', [])
    .config(config)
    .controller( 'AdminReportcardsController', controller )
;