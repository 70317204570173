"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var LessonPlanModel = /** @class */ (function () {
    function LessonPlanModel($http, APPURL) {
        var _this = this;
        this.show = function (id) {
            return _this.$http.get(_this.URLS.FETCH + "/" + id).then(_this.cacheResults);
        };
        this.get = function (data) {
            if (data === void 0) { data = null; }
            if (data !== null) {
                return _this.$http
                    .get(_this.URLS.FETCH + "?" + (0, queryString_1.toQueryString)(data))
                    .then(_this.cacheResults);
            }
            return _this.$http.get(_this.URLS.FETCH).then(_this.cacheResults);
        };
        this.update = function (id, request) {
            return _this.$http
                .put(_this.URLS.FETCH + "/" + id, request)
                .then(_this.cacheResults);
        };
        this.create = function (request) {
            return _this.$http.post(_this.URLS.FETCH, request).then(_this.cacheResults);
        };
        this.delete = function (data) {
            return _this.$http
                .delete(_this.URLS.FETCH + "/" + data.id)
                .then(_this.cacheResults);
        };
        this.updateRequirement = function (id, requirement_id, data) {
            return _this.$http
                .put(_this.URLS.FETCH + "/" + id + "/requirements/" + requirement_id, data)
                .then(_this.cacheResults);
        };
        this.addRequirement = function (id, data) {
            return _this.$http
                .post(_this.URLS.FETCH + "/" + id + "/requirements", data)
                .then(_this.cacheResults);
        };
        this.deleteRequirement = function (id, requirement_id) {
            return _this.$http
                .delete(_this.URLS.FETCH + "/" + id + "/requirements/" + requirement_id)
                .then(_this.cacheResults);
        };
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + "/api/v1/lesson-plans",
            UPDATE: APPURL + "/api/v1/lesson-plans",
            CREATE: APPURL + "/api/v1/lesson-plans",
        };
        this.lesson_plans = [];
        this.cacheResults = this.cacheResults.bind(this);
        this.extract = this.extract.bind(this);
    }
    LessonPlanModel.prototype.extract = function (result) {
        return result.data;
    };
    LessonPlanModel.prototype.cacheResults = function (result) {
        this.lesson_plans = this.extract(result);
        return this.lesson_plans;
    };
    return LessonPlanModel;
}());
LessonPlanModel.$inject = ["$http", "APPURL"];
exports.default = LessonPlanModel;
