"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
function VehiclesModel($http, APPURL) {
    var model = this, URLS = {
        FETCH: APPURL + '/api/v1/vehicles',
        UPDATE: APPURL + '/api/v1/vehicles',
        CREATE: APPURL + '/api/v1/vehicles'
    }, data, vehicles;
    function extract(result) {
        return result.data;
    }
    function cacheResults(result) {
        vehicles = extract(result);
        return vehicles;
    }
    function cacheResult(result) {
        data = extract(result);
        return data;
    }
    model.getVehiclesByLocation = function (location) {
        var location_id;
        if (typeof location === 'object') {
            location_id = location.id;
        }
        else {
            location_id = location;
        }
        return $http.get(URLS.FETCH + '/location/' + location_id).then(cacheResult);
    };
    model.getVehicleByID = function (id) {
        return $http.get(URLS.FETCH + '/' + id).then(cacheResult);
    };
    model.getVehicles = function (data) {
        if (data && data.hasOwnProperty('all') && data.all === true) {
            return $http.get(URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(cacheResults);
        }
        if (data && (data.hasOwnProperty('page') ||
            data.hasOwnProperty('name') ||
            data.hasOwnProperty('status'))) {
            return $http.get(URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(cacheResults);
        }
        return $http.get(URLS.FETCH).then(cacheResults);
    };
    model.searchVehicles = function (data) {
        return $http.post(URLS.FETCH + '/search', data).then(cacheResults);
    };
    model.createVehicle = function (vehicle) {
        return $http.post(URLS.CREATE, vehicle).then(cacheResults);
    };
    model.editVehicle = function (vehicle) {
        return $http.put(URLS.CREATE + '/' + vehicle.id, vehicle).then(cacheResults);
    };
}
VehiclesModel.$inject = ['$http', 'APPURL'];
exports.default = VehiclesModel;
