import {States} from "../../../../../common/constants/states";
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

class AdminInstructorsEditContactInfoController {
    
    constructor($state, $stateParams, UsersModel, FlashService){
        this.editPassword = false;
        this.states = States;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.$state = $state;
        UsersModel.getInstructorByID( $stateParams.instructorId ).then( response => {
            this.instructor = response;
        } );
    }

    submit(isValid) {
        this.submitted = true;

        if (isValid) {
            let payload = cloneDeep(this.instructor);
            if (payload.usermeta.license) {
                payload.usermeta.license.expiration_date = moment(payload.usermeta.license.expiration_date, 'MMDDYYYY').format('YYYY-MM-DD');
            } else {
                delete payload.usermeta.license;
            }
            delete payload.zones;
            delete payload.locations;
            delete payload.zoneparts;
            delete payload.sectors;

            this.UsersModel.updateInstructor(payload).then(response => {
                this.FlashService.setMessage( {
                    'type' : 'success',
                    'message' : response.message
                } );
                this.$state.go('drivecoach.admin.instructors.view', { 'instructorId' : response.instructor.id });
                this.submitted = false;
            } ).catch(Error => {
                this.submitted = false;
            });
        } else {
            this.submitted = false;
            this.FlashService.setMessage( { 'type' : 'error', 'message' : 'Please fill the form.' } );
        }
    }

}
AdminInstructorsEditContactInfoController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService'];
export default AdminInstructorsEditContactInfoController;
