import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import FlashMessage from './FlashMessage.vue';

export default angular.module( 'directives.flashmessage', [] )
  .directive('dcFlashMessage', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcFlashMessage', FlashMessage))
  }])
