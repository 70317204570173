import angular from 'angular'
import template from './order-form.html'
import controller from './order-form.controller'
function dsStudentOrderForm() {
    return {
        restrict: 'E',
        scope: {
            'submit' : '=',
            'order' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('student.directives.orderform', [])
    .controller('dsStudentOrderFormController', controller)
    .directive('dsStudentOrderForm', dsStudentOrderForm);

