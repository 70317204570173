import angular from 'angular'
import template from './drive-session-timeline.html'
import dsDriveSessionTimelineController from './drive-session-timeline.controller'

function dsDriveSessionTimeline() {
    return {
        restrict: 'E',
        scope: {
            'drive': '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: dsDriveSessionTimelineController,
        controllerAs: 'vm',
        bindToController: true
    }
}
dsDriveSessionTimeline.$inject = [];

export default angular.module('admin.directives.drivesessiontimeline', [])
    .controller('dsDriveSessionTimelineController', dsDriveSessionTimelineController)
    .directive('dsDriveSessionTimeline', dsDriveSessionTimeline)
;
