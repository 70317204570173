class AdminInstructorsViewController {

    constructor($state, $stateParams, UsersModel, FlashService){
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.loading = true;

        UsersModel.getInstructorByID($stateParams.instructorId).then(response => {
            this.instructor = response;
            this.loading = false;
        });
    }

    disableInstructor() {
        this.UsersModel.disableInstructor({'user': {'id' : this.instructor.id}}).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.$state.go('drivecoach.admin.instructors.index');
        });
    }

    activateInstructor() {
        this.UsersModel.activateInstructor({'user': {'id' : this.instructor.id}}).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.$state.go('drivecoach.admin.instructors.index');
        });
    }

}
AdminInstructorsViewController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService'];
export default AdminInstructorsViewController;