import angular from 'angular'
import StudentAccountEditController from './student-edit-account.controller'
import template from './student-edit-account.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.account.edit', {
            'url' : '/edit',
            'controller' : StudentAccountEditController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];
export default angular.module( 'student.account.edit', [] )
    .config( config )
    .controller( 'StudentAccountEditController', StudentAccountEditController )
;
