
class AdminStudentReportCardController
{

    constructor($stateParams, UsersModel, reportcard){
        this.reportcard = reportcard;
    }

}
AdminStudentReportCardController.$inject = ['$stateParams', 'UsersModel', 'reportcard'];
export default AdminStudentReportCardController;