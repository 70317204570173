import angular from 'angular'
import template from './admin-school-create-vehicle.html'
import controller from './admin-school-create-vehicle.controller'

function getSchool($stateParams, SchoolsModel){
    return SchoolsModel.getSchoolByID($stateParams.schoolId).then(response => {
        return response;
    });
}
getSchool.$inject = ['$stateParams', 'SchoolsModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES,  ) {
    $stateProvider
        .state( 'drivecoach.admin.schools.vehicles.create', {
            'url' : '/add-vehicle/:schoolId',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            resolve: {
                school: getSchool
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.schools.vehicles.create', [] )
    .config(config)
    .controller( 'AdminSchoolsVehiclesCreateController', controller )
;
