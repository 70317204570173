import angular from 'angular'
import template from './admin-campus-classrooms.html'
import AdminSchoolsLocationsClassroomsController from './admin-campus-classrooms.controller'
import AdminCampusCreateClassroom from './create/admin-campus-create-classroom.module'
function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.schools.view.locations.view.classrooms', {
            'url': '/classrooms/:locationId',
            'controller': AdminSchoolsLocationsClassroomsController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.schools.view.locations.view.classrooms', [
    AdminCampusCreateClassroom.name
])
    .config(config)
    .controller('AdminSchoolsLocationsClassroomsController', AdminSchoolsLocationsClassroomsController)
;
