import angular from 'angular'
import controller from './pending-report-cards.controller.js'
import template from './pending-report-cards.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.pendingreportcards', {
            'url' : '/pending-report-cards',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )

    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('instructor.dashboard.pendingreportcards', [])
    .config(config)
    .controller('PendingReportCards', controller)
;
