class AdminSettingsPoliciesCalendarController {

    constructor(UserService, $state, UsersModel, PoliciesModel, FlashService){
        this.UserService = UserService;
        this.$state =  $state;
        this.UsersModel = UsersModel;
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;
        this.loading = true;
        this.busy = true;
        this.policies = {
            'calendar' : {}
        };

        this.processPolicy = this.processPolicy.bind(this);
        PoliciesModel.getByID('calendar').then(response => {
            let policy = response.policy;
            let policies = {
                'calendar' : policy
            };
            let policyName = policy.name;
            let policyMeta = {};
            // This is for the back end right now, the endpoint expects and ID parameter
            // that is the Policy primary key
            policyMeta['id'] = policy.id;
            policy.policymeta.forEach(meta => {
                policyMeta[meta.meta_key] = meta.meta_value;
            });
            policies[policyName].policymeta = policyMeta;
            this.policies[policyName] = policy;
            this.loading = false;
            this.busy = false;
        });
    }

    processPolicy(isValid){
        if (isValid) {
            this.busy = true;
            this.PoliciesModel.update(this.policies.calendar.policymeta).then(response => {
                this.busy = false;
                this.FlashService.setMessage({'type': 'success', 'message': response.message});
            });
        } else {
            this.FlashService.setMessage({'type': 'error', 'message': 'All fields are required.'});
        }
    }

}
AdminSettingsPoliciesCalendarController.$inject = ['UserService', '$state', 'UsersModel', 'PoliciesModel', 'FlashService'];
export default AdminSettingsPoliciesCalendarController;