import angular from 'angular'
import AdminSchoolBillingController from './billing.controller'
import template from './billing.html'

function getODEConfig(OnlineDriversEd) {
    return OnlineDriversEd.getFeatureDetails().then(response => {
        return response;
    });
}
getODEConfig.$inject = ['OnlineDriversEd'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.schools.billing', {
            'url' : '/billing',
            'controller' : AdminSchoolBillingController,
            'resolve' : {
                OnlineDriversEdConfig: getODEConfig,
            },
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.schools.billing', [] )
    .config(config)
    .controller( 'AdminSchoolBillingController', AdminSchoolBillingController );
