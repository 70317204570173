import angular from 'angular'
import AdminEventsEditController from './admin-edit-event.controller'
import template from './admin-edit-event.html'
import moment from 'moment'
function getEvent( EventsModel, $stateParams ) {
    return EventsModel.show( $stateParams.eventId ).then( function ( response ) {
        const durMoment = moment.duration( response.event.duration, 'minutes' ).format( 'H:mm' ).split( ':' );
        response.event.date = moment( response.event.date ).toDate();
        response.event.time = moment( response.event.time, 'HH:mm:ss' ).toDate();
        response.event.duration = {};
        response.event.duration.hours = durMoment[0];
        response.event.duration.minutes = durMoment[1];

        return response.event;
    } );
}
getEvent.$inject = ['EventsModel', '$stateParams'];

function getCampuses(LocationsModel){
    return LocationsModel.getLocations().then(response => {
        return response;
    });
}
getCampuses.$inject = ['LocationsModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.events.edit', {
            url : '/edit/:eventId',
            template : template,
            controller : AdminEventsEditController,
            controllerAs : 'vm',
            resolve : {
                event : getEvent,
                campuses: getCampuses
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.events.edit', [] )
    .config(config)
    .controller('AdminEventsEditController', AdminEventsEditController);