import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import {Hours, Minutes} from "../../../common/constants/times";
import {Task} from "../../../classes/Task";

class CreateTaskController {

    constructor(CrmTasksModel, FlashService, AdminTasksService, UserService, $rootScope) {
        this.minutes = Minutes();
        this.hours = Hours;
        this.CrmTasksModel = CrmTasksModel;
        this.FlashService = FlashService;
        this.AdminTasksService = AdminTasksService;
        this.$rootScope = $rootScope;
        this.submitted = false;
        this.today = new Date();
        this.user = UserService.getCurrentUser();

        this.task = {
            'owner' : this.user
        }
        this.showUserSearch = typeof this.assignedUser === 'undefined';
        this.assign_user_label = null;
        if(!this.showUserSearch){
            this.task.assign_user = cloneDeep(this.assignedUser);
            this.assign_user_label = this.task.assign_user.fullname;
        }
      
        this.updateDueDate = this.updateDueDate.bind(this)

    }
  
    updateDueDate(newDate) {
      this.task.due_date = newDate.selected[0]

        this.datepicker = {
            'open': ($event) => {
                this.datepicker.status.opened = true;
            },
            dateOptions: {
                formatYear: 'yy',
                startingDay: 7,
                showWeeks: false
            },
            'format': 'dd-MMMM-yyyy',
            'status': {
                opened: false
            }
        };
    }

    submit() {
        this.submitted = true;
        let formdata = cloneDeep(this.task)
        formdata.due_date = moment(this.task.due_date).format('MM/DD/YYYY')
        formdata.due_time = moment(this.due_on_hours + ' ' + this.due_on_minutes, 'HH:mm').format('HH:mm');
        this.CrmTasksModel.create(formdata).then(response => {
            this.submitted = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            })
            this.resetTask();
            this.$rootScope.$broadcast(`${this.userType}_TASK_CREATED`);
        }).catch(response => {
            this.submitted = false;
        })
    }

    resetTask() {
        this.task = {
            'owner' : this.user
        }
        if(!this.showUserSearch){
            this.task.assign_user = cloneDeep(this.assignedUser);
            this.assign_user_label = this.task.assign_user.fullname;
        }
    }
}

CreateTaskController.$inject = ['CrmTasksModel', 'FlashService', 'AdminTasksService', 'UserService', '$rootScope'];
export default CreateTaskController;