import {PaymentMethods} from "../../common/constants/payment_methods";
import {Months} from "../../common/constants/months_and_years";
import {Years} from "../../common/constants/months_and_years";
import {States} from "../../common/constants/states";
import cloneDeep from 'lodash/cloneDeep'
import { Order } from "../../classes/Order";
import { Coupon } from "../../classes/Coupon";
import { Payment } from "../../classes/Payment";
import { Product } from "../../classes/Product";
import { Student } from "../../classes/Student";
import { LineItem } from "../../classes/LineItem";
import Dinero from 'dinero.js'
import {inDollars, Teller} from "../../../DriveScout/Billing/Teller"

class StudentOrderController {

    constructor(OrdersModel, FlashService, UsersModel, $stateParams, UserService, CreateOrderService){
        this.loading = true;
        this.amount_owed = 0;
        this.$stateParams = $stateParams;
        this.months = Months;
        this.years = Years;
        this.states = States;
        this.payment = {}
        this.fee = null;
        this.fees = []
        this.payment_methods = PaymentMethods;
        this.OrdersModel = OrdersModel;
        this.FlashService = FlashService;
        this.UsersModel = UsersModel;
        this.currentUser = UserService.getCurrentUser();

        this.policies = {};
        this.policies.billing = {
            'policymeta' : {}
        };
        
        this.surchargeAmountBilled = 0;
        
        this.$cashier = CreateOrderService.cashier();

        // set surcharge data
        this.surchargeBilledSeparately = this.$cashier.surchargeBilledSeparately();
        this.surchargeAmount = this.$cashier.surchargeAmount();
        this.surchargeDetail = this.$cashier.surchargeDetail();

        // set payment methods
        this.payment.payment_method = 'card'

        // Get the order details and load this order into memory if it isn't already
        OrdersModel.getDetails({'id' : $stateParams.orderID}).then(response => {
            const $order = new Order();
            const $payments = response.data.payments.map(payment => {
                return new Payment(payment);
            });
            const $lineitems =   response.data.order.lineitems.map($lineitem => {
                return new LineItem($lineitem);
            })
            const $products = $lineitems.map($lineitem => {
                return $lineitem.product();
            });
            const $student = new Student(this.currentUser)

            if (response.data.order.coupons.length > 0) {
                const $coupon = new Coupon(response.data.order.coupons[0])
                $order.setCoupon($coupon);
            }

            $order.setNotes(response.data.order.notes);
            $order.setStatus(response.data.order.status);
            $order.setPayments($payments);
            $order.setLineItems($lineitems);
            $order.setStudent($student)
            $order.setPaid(response.data.order.paid);
            $order.$id = response.data.order.id;
            $order.$created_at = response.data.order.created_at;
            $order.setProducts($products);

            $order.setTotal(response.data.order.total);

            this.$cashier.setOrder($order);

            this.calculateAmountOwed($payments);
            this.loading = false;
        })
    }

    /**
     * Calculate the surcharge in cents for a given value in cents
     * 
     * @param {Number} totalDue 
     * @return {Number} The surcharge for the given amount of money in cents
     */
    calculateSurcharge(totalDue)
    {
        if (this.$cashier.surchargeBilledSeparately() === false) {
            return 0;
        }

        if (this.$cashier.surchargeBilledSeparately()) {
            return Dinero({ amount: totalDue, currency: 'USD' })
                .percentage(this.$cashier.surchargeAmount())
                .getAmount()
        }

        return 0;
    }

    paymentWithSurcharge(amount)
    {
        return amount + this.calculateSurcharge(amount)        
    }

    /**
     * 
     */
    totalBilled()
    {
        // return with the surcharge amount applied to whatever the payment amount is.
        // the payment amount should be in cents.
        if (this.payment.hasOwnProperty('payment_amount') && this.payment.payment_amount > 0) {
            return this.paymentWithSurcharge(this.payment.payment_amount);
        }

        // if no payment is being made, return 0 cents
        return 0;
    }

    /**
     * Because students can only make payments electronically, if the surcharge setting
     * is on, it always applies to students.
     * 
     * @return {Boolean}
     */
    checkSurchargeRequired() {
        return this.surchargeBilledSeparately;
    }

    /**
     * This sets the amount the student owes on the controller instance.
     * 
     * @param {[]Payments]} payments A collection of Payment objects
     */
    calculateAmountOwed(payments){
        if (this.$cashier.order().status() === 'VOID') {
            this.amount_owed = 0;
        } else {
            let amountPaid = 0;
            if (payments.length > 0) {
                amountPaid = payments.filter(payment => {
                    return payment.isVoid() === false;
                }).reduce((carry, payment) => {
                    return carry + payment.amount();
                }, 0);
            }
            
            this.amount_owed = this.$cashier.order().total() - amountPaid;
        }
    }

    addBillingAddress(index, address){
        if (! this.payment.hasOwnProperty('card')) {
            this.payment.card = {}
        }
        this.payment.card.billing_address = address;
    }

    selectFee(feeId, index){
        this.fee = this.fees[index];
    }

    formatPaymentAmount(amount){
        return inDollars(amount);
    }

    submitPayment(){
        this.submitted = true;
        let payment = cloneDeep(this.payment);
        if (this.currentUser.role.type === 'student') {
            payment.payment_method = 'card'
        }
        this.OrdersModel.submitPayment({
            'order_id' : this.$cashier.order().id(), 
            'payment': payment
        }).then(response => {
            this.submitted = false;
            this.amount_owed -= payment.payment_amount;
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            const $payments = [...this.$cashier.order().payments(), new Payment(response.payment)];
            this.$cashier.order().setPayments($payments);
            this.$cashier.order().setPaid(response.order.paid);
            this.$cashier.order().setNotes(response.order.notes);
            this.calculateAmountOwed(this.$cashier.order().payments());
            this.payment = {};
        }).catch(Error => {
            this.submitted = false;
        })
    }
}
StudentOrderController.$inject = ['OrdersModel', 'FlashService', 'UsersModel', '$stateParams', 'UserService', 'CreateOrderService'];
export default StudentOrderController;