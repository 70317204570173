import angular from 'angular'
import template from './admin-school-view.html'
import AdminSchoolViewController from './admin-school-view.controller'
import Locations from './locations/admin-school-locations.module'

getSchool.$inject = ['SchoolsModel', 'UserService'];
function getSchool(SchoolsModel, UserService) {
    let user = UserService.getCurrentUser();
    return SchoolsModel.getSchoolByID(user.school.id).then(response => {
        return response;
    })
}

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.schools.view', {
            'url': '/view/:schoolId',
            'controller': AdminSchoolViewController,
            'controllerAs': 'vm',
            'template': template,
            'resolve': {
                'school': getSchool
            },
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.schools.view', [
    Locations.name
])
    .config(config)
    .controller('AdminSchoolViewController', AdminSchoolViewController);
