"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminNotificationsController = /** @class */ (function () {
    function AdminNotificationsController($window, $rootScope, $state, FlashService, NotificationModel) {
        var _this = this;
        this.$window = $window;
        this.$state = $state;
        this.FlashService = FlashService;
        this.loading = true;
        this.page_by = 10;
        this.totalItems = 0;
        this.currentPage = 1;
        this.NotificationModel = NotificationModel;
        this.data = {};
        this.notifications = [];
        this.formConfig = {
            to: "",
            submitting: false
        };
        this.submit = this.submit.bind(this);
        this.NotificationModel.getNotifications({ type: 'sms' }).then(function (response) {
            _this.notifications = response.notifications.data;
            _this.currentPage = response.notifications.current_page;
            _this.totalItems = response.notifications.total;
            _this.loading = false;
        });
    }
    AdminNotificationsController.prototype.submit = function () {
        var _this = this;
        this.formConfig.submitting = true;
        var request = { type: 'sms', page: this.currentPage, to: "" };
        if (this.formConfig.to) {
            request.to = this.formConfig.to;
        }
        if (request.to === "") {
            delete request.to;
        }
        this.NotificationModel.getNotifications(request).then(function (response) {
            _this.notifications = response.notifications.data;
            _this.currentPage = response.notifications.current_page;
            _this.totalItems = response.notifications.total;
            _this.loading = false;
            _this.formConfig.submitting = false;
        }).catch(function (Error) {
            _this.formConfig.submitting = false;
            _this.loading = false;
        });
    };
    AdminNotificationsController.prototype.pageChange = function () {
        var _this = this;
        var request = { type: 'sms', page: this.currentPage, to: "" };
        if (this.formConfig.to) {
            request.to = this.formConfig.to;
        }
        if (request.to === "") {
            delete request.to;
        }
        this.NotificationModel.getNotifications(request).then(function (response) {
            _this.notifications = response.notifications.data;
            _this.currentPage = response.notifications.current_page;
            _this.totalItems = response.notifications.total;
            _this.loading = false;
        }).catch(function (Error) {
            _this.formConfig.submitting = false;
            _this.loading = false;
        });
    };
    return AdminNotificationsController;
}());
AdminNotificationsController.$inject = [
    "$window",
    "$rootScope",
    "$state",
    "FlashService",
    "NotificationModel",
];
exports.default = AdminNotificationsController;
