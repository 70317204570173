class dcDriveSessionTypesController {

    constructor(UserService, DriveSessionTypesModel, ReportcardsTypesModel, FlashService){
        this.UserService = UserService;
        this.DriveSessionTypesModel = DriveSessionTypesModel;
        this.ReportcardsTypesModel = ReportcardsTypesModel;
        this.FlashService = FlashService;

        this.currentUser = UserService.getCurrentUser();
        this.initialTab = 0;

        this.getSessionTypes();
        this.getReportCardTypes();
        this.displayHelp();
    }
    

    disable(sessiontype) {
        let data = Object.assign({}, sessiontype);
        data.status = 'Disabled';
        this.DriveSessionTypesModel.update(data).then(response => {
            this.sessionTypes = response.sessionTypes;
        }).catch(response => {
            this.updating = false;
        });
    }

    displayHelp(subject){
        let helpText;
        switch (subject) {
            case 'allow_custom_pickup_locations':
                helpText = 'This will allow a student to be picked up at an address of their choosing. Set this to "No" if you would only like students to be picked up at the school for this session type.'
                break;
            case 'allow_custom_dropoff_locations':
                helpText = 'This will allow a student to be dropped off at an address of their choosing. Set this to "No" if you would only like students to be dropped off at the school for this session type.'
                break;
            case 'send_instructor_change_notification':
                helpText = 'If the instructor on this session is changed, a notification email will be sent to the student on the session and their guardian if this setting is set to "Yes"';
                break;
            case 'send_last_minute_availability_alert':
                helpText = 'If a student cancels a session within the window specified in the Cancellations screen, a notification (either a text message, an email, or both) will be sent to ' +
                    'every student and their guardian who are in the same zone as the cancelled session if this setting is set to "Yes".'
                break;
            case 'disable_notifications_after_completion_of_curriculum':
                helpText = 'When a student completes all of the hours they have purchased for this session type they will no longer receive text message or email alerts of ANY newly available sessions if this setting is set to "Yes". If they wish to continue receiving notifications they (or an admin) will have to update the setting in their profile.'
                break;
            case 'auto_verify_on_purchase':
                helpText = 'In DriveScout, verification means adding the hours for a session type a student has purchased to their account. When this setting is set to "Yes" when a student or their guardian purchases a product with this session type in it, their hours will automatically be updated to reflect their purchase.';
                break;
            case 'allow_observations':
                helpText = 'This will allow this session type to be booked as an Observation, which allows more than one student to be on one drive session at a time.';
                break;
            default:
                helpText = '';
                break;
        }
        return helpText;
    }

    getSessionTypes() {
        this.DriveSessionTypesModel.get().then(response => {
            this.sessionTypes = response.sessionTypes;
            this.tabs = this.sessionTypes.map(sessionType => {
                let type = Object.assign({}, sessionType);
                type.title = sessionType.name;
                return type;
            })
        });
    }

    getReportCardTypes() {
        this.ReportcardsTypesModel.get().then(response => {
            this.reportCardTypes = response.data;
        })
    }

    addProduct() {
        const productData = Object.assign({}, this.newProduct);
        this.DriveSessionTypesModel.insert(productData).then(response => {
            this.sessionTypes = this.sessionTypes.concat(response.sessionType);
            this.newProduct = {};
        });
    }

    updatePayRatio(sessionType) {
        this.updating = true;
        this.DriveSessionTypesModel.update(sessionType).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            this.updating = false;
        }).catch(response => {
            this.updating = false;
        });
    }

}

dcDriveSessionTypesController.$inject = ['UserService', 'DriveSessionTypesModel', 'ReportcardsTypesModel', 'FlashService'];
export default dcDriveSessionTypesController;