import {Months} from "../../../common/constants/months_and_years";
import {Years} from "../../../common/constants/months_and_years";
import cloneDeep from 'lodash/cloneDeep'
import {States} from "../../../common/constants/states";
import {inDollars} from '../../../../DriveScout/Billing/Teller'
import {isJson} from '../../../common/helpers/json'
import moment from 'moment'

class InstructorStudentOrdersController {

    constructor($stateParams, UsersModel, FlashService, student){
        this.$stateParams = $stateParams;
        this.student = student;
        this.loading = true;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.payment = {}
        this.orders = [];
        this.addresses = [];
        this.order = null;
        this.months = Months;
        this.years = Years;
        this.states = States;

        this.UsersModel.getPickupLocations(this.student.id).then(response => {
            this.addresses = response.pickuplocations.map(location => {
                return location.address;
            });
        })

        this.getOrders();

        this.inDollars = inDollars.bind(this);
    }

    getOrders(){
        this.submitted = true;
        let payload = {student_id:this.student.id};
        this.UsersModel.getStudentOrders(payload).then(response => {
            this.orders = response.orders.data.filter(order => {
                order.created_at = moment(order.created_at).format('MM/DD/YYYY h:mm a')
                order.lineitems = order.lineitems.filter(item => {
                    if (item.product_details) {
                        item.product_details = isJson(item.product_details) ? JSON.parse(item.product_details) : item.product_details;
                    }
                    return item;
                })
                return order
            });
            this.submitted = false;
            this.loading = false;
        }).catch(Error => {
          this.loading = false;
        });

    }

    addBillingAddress(index, address){
        if( ! this.payment.hasOwnProperty('card') ){
            this.payment.card = {}
        }
        this.payment.card.billing_address = address;
    }

    submitPayment(){
        this.submitted = true;
        let payment = cloneDeep(this.payment);
        this.UsersModel.submitPayment(this.student.id, this.fee.id, {'payment': payment}).then(response => {
           this.submitted = false;
           this.FlashService.setMessage({
               'type' : 'success',
               'message' : response.message
           })
           // replace fee
           this.orders.forEach((_order, index) => {
               if( _order.id === response.order.id ){
                   this.orders[index] = response.order;
               }
           })
            this.payment = {
               'payment_method' : 'card'
            };
            this.fee = null;
        }).catch(Error => {
            this.submitted = false;
        })
    }

}

InstructorStudentOrdersController.$inject = ['$stateParams', 'UsersModel', 'FlashService', 'student'];
export default InstructorStudentOrdersController;
