class ProductCategoriesController {
    constructor(ProductCategoriesModel, FlashService){
        this.categories = [];
        this.category = {};
        this.ProductCategoriesModel = ProductCategoriesModel;
        this.FlashService = FlashService;
        ProductCategoriesModel.get().then( response => {
            this.categories = response.categories;
            if (this.categories.length > 0) {
            	this.category = this.categories[0];
            }
        })
    }

    add(){
        this.product.categories.push(Object.assign({}, this.category));
    }

    remove(index){
        this.product.categories.splice(index, 1);
    }

    createProductCategory(){
        this.ProductCategoriesModel.create({'category_name' : this.new_category}).then(response => {
            this.categories.push(response.category);
            this.new_category = null;
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
        })
    }
}

ProductCategoriesController.$inject = ['ProductCategoriesModel', 'FlashService'];
export default ProductCategoriesController;
