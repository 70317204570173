import {isJson} from "../../../common/helpers/json";

class PickupLocationModalInstanceController {

    constructor($uibModalInstance, data){
        this.$uibModalInstance = $uibModalInstance;
        data.pickuplocation.address = isJson(data.pickuplocation.address) ? JSON.parse(data.pickuplocation.address) : data.pickuplocation.address;
        this.pickuplocation = data.pickuplocation;
        this.title = data.title;

        this.ok = this.close.bind(this);
    }

    close() {
        this.$uibModalInstance.close();
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
PickupLocationModalInstanceController.$inject = ['$uibModalInstance', 'data'];
export default PickupLocationModalInstanceController;