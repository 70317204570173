import flatten from "lodash/flatten";
import map from "lodash/map";
import pickBy from "lodash/pickBy";

class AdminStudentsIndexController {
  constructor(UsersModel, ReportsModel, LocationsModel) {
    this.UsersModel = UsersModel;
    this.ReportsModel = ReportsModel;
    this.maxSize = 5;
    this.last_page = 0;
    this.page_by = 20;
    this.currentPage = 1;
    this.studentName = null;
    this.submitting = false;
    this.start_date = "";
    this.end_date = "";

    this.status = { name: "Active" };

    this.filterOptions = [
      { name: "All" },
      { name: "Inactive" },
      { name: "Unverified" },
      { name: "Active" },
      { name: "Unpaid Fees" },
      { name: "Archived" },
    ];

    this.sortOptions = [
      {
        name: "Alphabetical A-Z",
        type: "lastname",
        direction: "asc",
      },
      {
        name: "Alphabetical Z-A",
        type: "lastname",
        direction: "desc",
      },
      {
        name: "Start Date (oldest first)",
        type: "start_date",
        direction: "asc",
      },
      {
        name: "Start Date (newest first)",
        type: "start_date",
        direction: "desc",
      },
    ];

    this.stats = {
      Active: 0,
      Unverified: 0,
      Archived: 0,
      Inactive: 0,
      "Unpaid Fees": 0,
    };

    this.getSearchParams = this.getSearchParams.bind(this);
    this.processStudents = this.processStudents.bind(this);
    this.handleStartDateDatepickerChange =
      this.handleStartDateDatepickerChange.bind(this);
    this.handleEndDateDatepickerChange =
      this.handleEndDateDatepickerChange.bind(this);

    // Build the filter options
    LocationsModel.getLocations().then((response) => {
      this.locations = response;
      this.locations.unshift({ name: "All" });
      this.sectors = flatten(map(response, "zoneparts"));
      this.sectors.unshift({ name: "All" });
    });

    UsersModel.getStudents({
      page_by: this.page_by,
      status: "Active",
      withStats: true,
    }).then((result) => {
      this.students = result.students.data.filter(this.processStudents);
      this.last_page = result.students.last_page;
      this.totalItems = result.students.total;
      this.stats = result.stats;
    });
  }

  handleStartDateDatepickerChange(newDatetime) {
    if (newDatetime.selected.length === 0) {
      this.start_date = null;
    } else {
      this.start_date = newDatetime.selected[0];
    }
  }

  handleEndDateDatepickerChange(newDatetime) {
    if (newDatetime.selected.length === 0) {
        this.end_date = null;
    } else {
        this.end_date = newDatetime.selected[0];
    }
  }

  processStudents(student) {
    if (student.default_school_location.length !== 0) {
      student.location = student.default_school_location[0].name;
    }
    if (student.default_sector.length !== 0) {
      student.zone = student.default_sector[0].name;
    }
    let params = this.getSearchParams();

    if (params.sector !== null && this.sector) {
      student.zone = this.sector.name;
    }
    return student;
  }

  getSearchParams(getStats, removeNulls = true) {
    let params = {
      page_by: this.page_by,
      name: this.studentName,
      location: !this.location ? null : this.location.id,
      sector: !this.sector ? null : this.sector.id,
      status: !this.status ? null : this.status.name,
      page: this.currentPage,
      sort_type: !this.sort ? null : this.sort.type,
      sort_direction: !this.sort ? null : this.sort.direction,
    };

    if (getStats === true) {
      params.withStats = true;
    }

    if (removeNulls === true) {
      params = pickBy(params);
    }

    if (!this.start_date) {
      delete params.start_date;
    } else {
      params.start_date = this.start_date.toLocaleDateString("en-US");
    }
    if (!this.end_date) {
      delete params.end_date;
    } else {
      params.end_date = this.end_date.toLocaleDateString("en-US");
    }

    return params;
  }

  pageChanged() {
    this.submitting = true;
    this.UsersModel.getStudents(this.getSearchParams(true, true)).then(
      (result) => {
        this.students = result.students.data.filter(this.processStudents);
        this.stats = result.stats;
        this.submitting = false;
      }
    ).catch(Error => {
      this.submitting = false;
    });
  }

  applyFilter() {
    this.submitting = true;
    this.UsersModel.getStudents(this.getSearchParams(true, true)).then(
      (result) => {
        this.students = result.students.data.filter(this.processStudents);
        this.totalItems = result.students.total;
        this.stats = result.stats;
        this.submitting = false;
      }
    ).catch(Error => {
      this.submitting = false;
    });
  }
}

AdminStudentsIndexController.$inject = [
  "UsersModel",
  "ReportsModel",
  "LocationsModel",
];
export default AdminStudentsIndexController;
