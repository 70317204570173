<template>
  <transition name="fade">
    <div class="modal-bg" v-if="modalShown" :class="{'modal-open': modalShown, 'modal-center': modalCenter}">
      <div class="modal-container" role="dialog" :class="modalClasses">
        <div class="modal-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  // RECOMMENDED HTML MARKUP TO BE PUT IN SLOT
  // <div class="modal-header"></div>
  // <div class="modal-body"></div>
  // <div class="modal-footer"></div>

  export default {
    // THIS MODAL IS USED TO PLAY NICE WITH NGVUE. CAN BE DECRECATED WHEN WE GET OFF ANGULAR
    name: 'dcModalNgvue',
    props: {
      //use this prop to trigger modal open/close
      'modalShown': { type: Boolean, required: true },
      'modalClasses': { type: Array, default: () => []},
      'modalCenter': { type: Boolean, default: false } //controls if modal is positioned in the center or at the top of the screen
    },
    data() {
      return {

      }
    },
    methods: {
      emitEvent: function(eventName, dataObject) {
        this.$emit(eventName, dataObject)
      },
      closeModal: function() {
        this.emitEvent('close-modal')
      },
      handleCloseButtonClick: function(event) {
        this.closeModal();
      }
    },
    mounted() {
      if(this.modalShown === true) {
          let el = document.body;
          el.classList.add('no-scroll')
      }
      // set up close events
      let closeButtons = [...document.querySelectorAll('.close-modal')]
      closeButtons.map(el => {
        el.addEventListener('click', this.handleCloseButtonClick)
      })
    },
    beforeDestroy() {
      let el = document.body;
      el.classList.remove('no-scroll');
      //remove close events
      let closeButtons = [...document.querySelectorAll('.close-modal')]
      closeButtons.map(el => {
        el.removeEventListener('click', this.handleCloseButtonClick)
      })
    },
    watch: {
      modalShown: function(val) {
        if(val === true ) {
          let el = document.body;
          el.classList.add('no-scroll');
        } else {
          let el = document.body;
          el.classList.remove('no-scroll');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../../../assets/css/partials/variables.scss';

  .modal-bg {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    background: #333333C2;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;

    &.modal-center {
      justify-content: center;
      align-items: center;
    }

    .modal-container {
      position: relative;
      margin: 60px auto 40px;
      float: none !important;

      .modal-content {
        border-radius: 8px;
        background: $white;
        padding: 5px;
        background-clip: padding-box;
      }
    }
    
    .modal-body p {
      font-size: 16px;
    }
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>