"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PermitModel = /** @class */ (function () {
    function PermitModel($http, APPURL) {
        this.APPURl = APPURL;
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + '/api/v1/permits',
            UPDATE: APPURL + '/api/v1/permits',
            CREATE: APPURL + '/api/v1/permits'
        },
            this.permits = [];
        this.getPermit = this.getPermit.bind(this);
        this.update = this.update.bind(this);
        this.destroy = this.destroy.bind(this);
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    PermitModel.prototype.extract = function (result) {
        return result.data;
    };
    PermitModel.prototype.cacheResults = function (result) {
        this.permits = this.extract(result);
        return this.permits;
    };
    PermitModel.prototype.getPermit = function (student_id) {
        return this.$http.get(this.URLS.FETCH + '/' + student_id).then(this.cacheResults);
    };
    ;
    PermitModel.prototype.update = function (student_id, permit) {
        return this.$http.put(this.URLS.FETCH + '/' + student_id, permit).then(this.cacheResults);
    };
    ;
    PermitModel.prototype.destroy = function (student_id) {
        return this.$http.delete(this.URLS.FETCH + '/' + student_id).then(this.cacheResults);
    };
    return PermitModel;
}());
PermitModel.$inject = ['$http', 'APPURL'];
exports.default = PermitModel;
