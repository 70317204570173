import angular from 'angular'
import template from './admin-drives.html'
import AdminDrivesController from './admin-drives.controller'
import Create from './create/admin-create-drive.module'
import BatchCreate from './batchcreate/batch-create-drive.module'
import Edit from './edit/admin-edit-drive.module'
import ReportCard from './report-card/admin-drives-view-report-card.module'
import SchedulingConflicts from './scheduling-conflicts/admin-session-scheduling-conflicts.module'
import RequiringAction from './requiring-action/requiring-action.module'

let modules = [
    Create.name,
    BatchCreate.name,
    Edit.name,
    ReportCard.name,
    SchedulingConflicts.name,
    RequiringAction.name,
];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.drives', {
            'url': '/drives',
            'abstract': true,
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.drives', modules )
    .config(config)
    .controller( 'AdminDrivesController', AdminDrivesController );
