"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ResetPasswordController = /** @class */ (function () {
    function ResetPasswordController($stateParams, LoginService, FlashService, AuthService) {
        this.$stateParams = $stateParams;
        this.LoginService = LoginService;
        this.FlashService = FlashService;
        this.AuthService = AuthService;
        this.formData = {
            'token': $stateParams.token,
            'username': ""
        };
        try {
            var queryString = new URLSearchParams(window.location.search);
            if (queryString.has("username")) {
                this.formData.username = queryString.get("username");
            }
        }
        catch (Error) {
            this.formData.username = "";
        }
        this.errors = [];
    }
    ResetPasswordController.prototype.submit = function () {
        this.AuthService.removeToken();
        this.LoginService.resetPassword(this.formData);
    };
    return ResetPasswordController;
}());
ResetPasswordController.$inject = ['$stateParams', 'LoginService', 'FlashService', 'AuthService'];
exports.default = ResetPasswordController;
