import moment from 'moment'

class InstructorTeamAgendaController {

  constructor($rootScope, UserService, $state, $stateParams, CalendarModel, DrivesFilterService, CalendarService, sessionTypes) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.CalendarModel = CalendarModel;
    this.DrivesFilterService = DrivesFilterService;
    this.CalendarService = CalendarService;

    this.currentUser = UserService.getCurrentUser();
    this.date = $stateParams.date;
    this.DrivesFilterService.setDate(this.date);

    // View drives by date
    this.agendaData = {
      'instructor_id': this.currentUser.id,
      'date': $stateParams.date,
      'statusIsnt': 'Inactive - Scheduling Conflict'
    };
    
    this.cardLabels = [
      {'class': 'attn-text', 'name': 'ACTION REQUIRED'},
      {'class': 'open-text', 'name': 'OPEN SESSION'},
      {'class': 'rto-text', 'name': 'RTO'},
      {'class': 'event-text', 'name': 'EVENT'},
      {'class': 'course-text', 'name': 'COURSE'},
    ];

    sessionTypes.forEach(type => {
      this.cardLabels.push({
        'name': type.name.toUpperCase(),
        'class': type.name.replace(/\s/g, '-').toLowerCase() + '-text'
      })
    });

    this.items = [];

    this.sessionsUpdated = this.sessionsUpdated.bind(this);
    this.handleAgendaResults = this.handleAgendaResults.bind(this);
    this.CalendarModel.getInstructorTeamAgenda(this.agendaData).then(this.handleAgendaResults);
    $rootScope.$on('UPDATE_AGENDA', this.sessionsUpdated);
  }

  setSectorPopover(zone, sectors){
    this.zone = zone;
    this.sectors = sectors;
  }

  next() {
    this.$state.go('drivecoach.instructor.dashboard.teamagenda', {
      'date': moment(this.date).add(1, 'days').format('YYYY-MM-DD')
    })
  }

  prev() {
    this.$state.go('drivecoach.instructor.dashboard.teamagenda', {
      'date': moment(this.date).subtract(1, 'days').format('YYYY-MM-DD')
    })
  }

  isDriveSession(item) {
    return item.type === 'Drive Session';
  }

  isCourse(item) {
    return item.type === 'Course';
  }

  isEvent(item) {
    return item.type === 'Event';
  }

  isRTO(item) {
    return item.typeDisplay === 'RTO';
  }

  sessionsUpdated() {
    this.items = this.CalendarService.getAgendaItems();
  }

  handleAgendaResults(response) {
    this.DrivesFilterService.setDate(this.date);
    this.items = this.CalendarService.processAgenda(response);
  }

}

InstructorTeamAgendaController.$inject = ['$rootScope', 'UserService', '$state', '$stateParams', 'CalendarModel', 'DrivesFilterService', 'CalendarService', 'sessionTypes'];

export default InstructorTeamAgendaController;
