import angular from 'angular'
import AdminStaffIndexController from './admin-staff-index.controller'
import template from './admin-staff-index.html'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.staff.index', {
            'url': '/index',
            'controller': AdminStaffIndexController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.staff.index', [])
    .config(config)
    .controller('AdminStaffIndexController', AdminStaffIndexController)
