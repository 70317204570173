import angular from 'angular'
import template from './admin-edit-instructor-locations.html'
import AdminInstructorEditLocations from './admin-edit-instructor-locations.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.instructors.editlocations', {
            'url' : '/:instructorId/edit-locations',
            'controller' : AdminInstructorEditLocations,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                instructor : ['UsersModel', '$stateParams', function ( UsersModel, $stateParams ) {
                    return UsersModel.getInstructorByID( $stateParams.instructorId ).then( response => {
                        return response;
                    } );
                }]
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.instructors.editlocations', [] )
    .config(config)
    .controller( 'AdminInstructorEditLocations', AdminInstructorEditLocations )
;
