import angular from 'angular'
import InstructorRequestTimeOffRangeController from './instructor-rto-range.controller'
import template from './instructor-rto-range.html'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.requesttimeoff.range', {
            'url' : '/range',
            'controller' : InstructorRequestTimeOffRangeController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.requesttimeoff.range', [] )
    .config(config)
    .controller('InstructorRequestTimeOffRangeController', InstructorRequestTimeOffRangeController);

