import moment from 'moment'
class InstructorRequestTimeOffRangeController {
    constructor( UserService, $state, UsersModel, TimeoffRequestsModel, FlashService ){
        this.UserService = UserService;
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.TimeoffRequestsModel = TimeoffRequestsModel;
        this.FlashService = FlashService;
        this.currentUser = UserService.getCurrentUser();
        this.today = new Date();
      
        this.dateRangeInvalid = false;
        this.dateRangeInvalidMessage = '';

        this.request = {
            'request_begin' : null,
            'request_end' : null
        };
        this.handleDateChange = this.handleDateChange.bind(this)

    }
    
    handleDateChange(newDate) {
      if(newDate.name === 'request_begin') {
        this.request.request_begin = newDate.selected[0]
      } else if(newDate.name === 'request_end') {
        this.request.request_end = newDate.selected[0]
      }
      this.checkForInvalidDate()
    }
  
    checkForInvalidDate(){
        if(this.request.request_begin === null || this.request.request_end === null){
            this.dateRangeInvalid = true;
            this.dateRangeInvalidMessage = 'A begin and end date is required.';
            return true;
        }
        let begin = moment(this.request.request_begin);
        let end = moment(this.request.request_end);
        if( end.isBefore(begin) ){
            this.dateRangeInvalid = true;
            this.dateRangeInvalidMessage = 'The end date is before the beginning date.';
            return true;
        }
        if( begin.isAfter(end) ){
            this.dateRangeInvalid = true;
            this.dateRangeInvalidMessage = 'The beginning date is before the ending date.';
            return true;
        }

        this.dateRangeInvalid = false;
        this.dateRangeInvalidMessage = '';
        return false;

    }

    submitRangeRequest( isValid ) {
        this.submitted = true;

        if ( isValid ) {
            const request = {
                'user_id' : this.currentUser.id,
                'begin' : moment( this.request.request_begin ).format( 'YYYY-MM-DD HH:mm:ss' ),
                'end' : moment( this.request.request_end ).hours(23).minutes(59).format( 'YYYY-MM-DD HH:mm:ss' ),
                'type' : 'range',
                'status' : 'Pending',
                'reason' : this.request.reason
            };

            this.TimeoffRequestsModel.create( request ).then( response => {
                this.FlashService.setMessage( {
                    'type' : 'success',
                    'message' : response.message
                } );
                this.submitted = false;
                this.$state.go( 'drivecoach.instructor.dashboard' );
            } );
        } else {
            this.FlashService.setMessage( { 'type' : 'error', 'message' : 'The form is invalid.' } );
        }

    }

}

InstructorRequestTimeOffRangeController.$inject = ['UserService', '$state', 'UsersModel', 'TimeoffRequestsModel', 'FlashService'];
export default InstructorRequestTimeOffRangeController;
