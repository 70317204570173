import {States} from "../../../common/constants/states";
import {isJson} from "../../../common/helpers/json"

class AdhocPickupLocations {

    constructor($rootScope, $state, $stateParams, SchoolsModel, UsersModel, FlashService, UserService, PoliciesModel, PolicyData) {
        this.loading = true;
        this.busy = true;
        this.currentUser = UserService.getCurrentUser();
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.PoliciesModel = PoliciesModel;
        this.SchoolsModel = SchoolsModel;
        this.FlashService = FlashService;
        this.states = States;

        this.hasPickupLocation = false;
        this.pickuplocations = [];
        this.pickuplocation = {};
        this.submitted = false;

        this.PolicyData = PolicyData;
        this.allow_adhoc_pickup_locations = PolicyData.FeatureEnabled;

        this.parseAddressJson = this.parseAddressJson.bind(this);

        SchoolsModel.getAdhocPickupLocations(this.currentUser.school_id).then(response => {
            this.pickuplocations = response.pickuplocations.filter(this.parseAddressJson);
            this.loading = false;
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
            this.loading =false;
        });

        $rootScope.$on('ADHOC_PICKUP_LOCATION_CREATED', (event, args) => {
            this.pickuplocations.push(args.data);
        });
    }

    updateSetting() {
        this.busy = true;
        this.PoliciesModel.update({
            'id' : this.PolicyData.id,
            'allow_adhoc_pickup_locations' : this.allow_adhoc_pickup_locations ? "Y" : "N"
        }).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            this.busy = false;
        }).catch(error => {
            this.busy = false;
        });
    }

    parseAddressJson(location) {
        location.address = isJson(location.address) ? JSON.parse(location.address) : location.address;
        return location;
    }

    removePickupLocation(id) {
        this.busy = true;

        this.SchoolsModel.removeAddhocPickupLocation({
            'school_id' : this.currentUser.school_id,
            'location_id' : id
        }).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.pickuplocations = this.pickuplocations.filter(location => {
                return location.id !== id;
            });
            this.busy = false;
        }).catch(error => {
            this.busy = false;
        });
    }

    toggleHiddenPickUpLocation(location){
        this.busy = true;
        let data = Object.assign({}, location);
        data.hidden = !data.hidden;
        this.SchoolsModel.updateAdhocPickupLocation(this.currentUser.school_id, location.id, data).then(response => {
            this.busy = false;
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            });
            location.hidden = data.hidden;
        }).catch(Error => {
            this.busy = false;
        })
    }
}

AdhocPickupLocations.$inject = ['$rootScope', '$state', '$stateParams', 'SchoolsModel', 'UsersModel', 'FlashService',
    'UserService', 'PoliciesModel', 'PolicyData'];
export default AdhocPickupLocations;