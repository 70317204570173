"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
function ZipCodeModel($http, APPURL) {
    var model = this, URLS = {
        FETCH: APPURL + '/api/v1/zipcodes',
        UPDATE: APPURL + '/api/v1/zipcodes',
        CREATE: APPURL + '/api/v1/zipcodes'
    }, zones;
    function extract(result) {
        return result.data;
    }
    function cacheResults(result) {
        zones = extract(result);
        return zones;
    }
    model.getZipCodes = function (query) {
        return $http.get(URLS.FETCH + '?' + (0, queryString_1.toQueryString)(query)).then(cacheResults);
    };
}
ZipCodeModel.$inject = ['$http', 'APPURL'];
exports.default = ZipCodeModel;
