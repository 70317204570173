import TaskModalController from './task-modal.controller'
import TaskModalTemplate from './task-modal.html'
import { taskRequestTransformer } from '../../../classes/Task'

class dsTasks {
    constructor($uibModal, CrmTasksModel, FlashService, AdminTasksService){
        this.AdminTasksService = AdminTasksService;
        this.$uibModal = $uibModal;
        this.CrmTasksModel = CrmTasksModel;
        this.FlashService = FlashService;
    }

    deleteTask(task){
        let confirm = window.confirm('Are you sure you want to delete this task?')
        if(!confirm){
            return false;
        } 
        const transformedTask = taskRequestTransformer(task)
        this.CrmTasksModel.destroy(transformedTask).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.tasks = this.tasks.filter(list_task => {
                return transformedTask.id !== list_task.$id
            })
        })
    }

    viewTask(task){
        this.AdminTasksService.task = task;
        let modalInstance = this.$uibModal.open({
            animation: true,
            template: TaskModalTemplate,
            controller: TaskModalController,
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                'task' : () => task
            }
        });
        modalInstance.result.then( event => {
            if( event.name === 'deleted' ){
                this.tasks = this.tasks.filter(task => {
                    return task.$id !== event.task.$id
                })
            }
            if(event.name === 'updated'){
                this.tasks = this.tasks.map(task => {
                    if(task.$id === event.task.$id){
                        task = event.task;
                    }
                    return task;
                })
            }
        }, function () {
            //$log.info('Modal dismissed at: ' + new Date());
        });
    }



}
dsTasks.$inject = ['$uibModal', 'CrmTasksModel', 'FlashService', 'AdminTasksService'];
export default dsTasks;