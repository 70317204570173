import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import moment from 'moment'
import { createFromPolicyAPIResponse } from '../../classes/Policies';
class InstructorDashboardController {

    constructor($state, UsersModel, UserService, PoliciesModel, store, $sce, VueStoreFactory)
    {
        this.loading = true;
        this.$sce = $sce;
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.PoliciesModel = PoliciesModel;
        this.VueStoreFactory = VueStoreFactory;
        this.currentUser = UserService.getCurrentUser();
        this.store = store;
        this.goToSession = this.goToSession.bind(this);
        this.setup().then(loading => {
            // setup complete
        });
    }

    async setup()
    {
        this.nextsessiondata = await this.getNextSession();
        if (isEmpty(this.nextsessiondata)) {
            this.nextDrive = {};
        }else{
            this.nextDrive = this.nextsessiondata.nextDrive;
            this.nextDriveDate = this.nextsessiondata.nextDriveDate;
            this.untilDrive = this.nextsessiondata.untilDrive;
        }

        let response = await this.UsersModel.getInstructorByID(this.currentUser.id);
        this.currentUser = response;

        const notifications = filter( this.currentUser.school.policies , ( policy, key) => {
            return key === 'notifications';
        }).forEach( notification => {
            if( notification.policymeta.hasOwnProperty('globalinstructornotification') && ! isEmpty( notification.policymeta.globalinstructornotification ) ){
                this.hasAdminMessage = true;
                this.adminMessage = this.$sce.trustAsHtml(notification.policymeta.globalinstructornotification);
            }
        });

        this.dsConfig = {
            'nav' : {
                'use_team_calendar' : false,
                'instructor_can_create_lessons' : false
            }
        }

        this.PoliciesModel.get().then(response => {
            response.policies = response.policies.filter(policy => {
                return policy.name === 'calendar' || policy.name === 'drives';
            })
            const policies = createFromPolicyAPIResponse(response);

            try {
                this.dsConfig.nav.use_team_calendar = policies.getPolicyByName('calendar').getMetaValueByKey('use_team_calendar');
            } catch (Error) {
                this.dsConfig.nav.use_team_calendar = false;
            }

            try {
                this.dsConfig.nav.instructor_can_create_lessons = policies.getPolicyByName('drives').getMetaValueByKey('instructor_can_create_lessons');
            } catch (Error) {
                this.dsConfig.nav.instructor_can_create_lessons = false;
            }
        });
        this.loading = false;
        return this.loading;
    }

    goToSession(){
        if(this.nextDrive.entity_type === 'drive'){
            this.$state.go('drivecoach.instructor.dashboard.drive', {'driveId' : this.nextDrive.id});
        }

        if (this.nextDrive.entity_type === 'drivescoutclass') {
            let data = {
                id: this.nextDrive.course_id,
                date: this.nextDrive.date,
                class_id: this.nextDrive.id,
                start: new Date(this.nextDrive.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
                end: new Date(this.nextDrive.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
                instructor: this.nextDrive.instructor,
                instructor_id: this.nextDrive.instructor_id,
            };
            data.date = new Date(this.nextDrive.start).toLocaleDateString();
            this.VueStoreFactory.dispatch('SET_VIEW_COURSE', data);
            this.$state.go('drivecoach.instructor.dashboard.course.view', {'courseId' : data.id});
        }

        if(this.nextDrive.entity_type === 'event'){
            this.$state.go('drivecoach.instructor.dashboard.event', {'eventId' : this.nextDrive.id});
        }
    }

    async getNextSession() {
        try {
            const response = await this.UsersModel.getNextInstructorEntity({'id': this.currentUser.id});
            if (isEmpty(response.entity)) {
                return {};
            }
            const date = moment(response.entity.date).format('YYYY-MM-DD');
            return {
                'nextDrive': response.entity,
                'nextDriveDate': moment(date + ' ' + response.entity.time),
                'untilDrive': moment(date + ' ' + response.entity.time).format('h:mm a')
            }
        } catch (Error) {
            console.error(Error);
            // throw Error;
            return {};
        }
    }
}

InstructorDashboardController.$inject = [ '$state', 'UsersModel', 'UserService', 'PoliciesModel', 'store', '$sce', 'VueStoreFactory'];
export default InstructorDashboardController;