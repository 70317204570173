import template from './instructor-nav.html'
import { nav } from '../../../common/helpers/nav'
import controller from './instructor-nav.controller';
function linkFunc( scope, element, attrs ) {
    nav();
}
function dcInstructorNav() {
    return {
        restrict : 'E',
        bindToController: {
            'options' : '='
        },
        controllerAs: 'vm',
        controller: controller,
        template : template,
        link : linkFunc,
        scope: {}
    }
}

export default dcInstructorNav;