import angular from 'angular'
import template from './admin-session-scheduling-conflicts.html'
import AdminDrivesSchedulingConflictsController from './admin-session-scheduling-conflicts.controller'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider.state('drivecoach.admin.drives.schedulingconflicts', {
        'url': '/scheduling-conflicts',
        'controller': AdminDrivesSchedulingConflictsController,
        'controllerAs': 'vm',
        'template': template,
        'resolve' : {
            'drives' : getDrives,
            'courses' : getCourses,
            'events' : getEvents
        },
        data: {
            authorizedRoles: [
                USER_ROLES.admin,
                USER_ROLES.administrator,
                USER_ROLES.owner
            ]
        }
    });
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


function getDrives(DrivesModel){
    return DrivesModel.search({'paginate' : "1", 'status' : 'Inactive - Scheduling Conflict', 'page_by' : 25}).then(function(response){
        return response;
    });
}
getDrives.$inject = ['DrivesModel'];

function getCourses(CoursesModel){
    return CoursesModel.search({'paginate' : "1", 'status' : 'Inactive - Scheduling Conflict', 'page_by' : 25}).then(function(response){
        return response;
    });
}
getCourses.$inject = ['CoursesModel'];

function getEvents(EventsModel){
    return EventsModel.search({'paginate' : "1", 'status' : 'Inactive - Scheduling Conflict', 'page_by' : 25}).then(function(response){
        return response;
    });
}
getEvents.$inject = ['EventsModel'];

export default angular.module('admin.drives.schedulingconflicts', [])
    .config(config)
    .controller('AdminDrivesSchedulingConflictsController', AdminDrivesSchedulingConflictsController);
