import { Task } from "../../../../classes/Task";

class AdminStaffTasksController {

    constructor($rootScope, $stateParams, UserService, AdminTasksService, UsersModel, staffMember){
        this.AdminTasksService = AdminTasksService;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.staffMember = staffMember;

        this.AdminTasksService.clearTasks();

        this.handleResponse = this.handleResponse.bind(this);

        this.user = UserService.getCurrentUser();
        
        this.AdminTasksService.loadTasks({'user_id':this.$stateParams.staffId}).then(response => {
            this.handleResponse(response);
        });

        $rootScope.$on('STAFF_TASK_CREATED', () => {
            this.filterTasks(this.filteringBy, this.currentPage, this.$stateParams.staffId);
        })

        $rootScope.$on('TASKS_UPDATED', () => {
            this.filterTasks(this.filteringBy, this.currentPage, this.$stateParams.staffId);
        })
    }

    filterTasks(filterBy = 'all', page = 1, userId = this.$stateParams.staffId) {
        this.filteringBy = filterBy;

        this.AdminTasksService.filterTasks(filterBy, page, userId).then(response => {
            this.handleResponse(response);
        })
    }

    pageChanged(){
        this.filterTasks(this.filteringBy, this.currentPage, this.$stateParams.staffId);
    }

    handleResponse(response){
        this.tasks_last_page = response.data.last_page;
        this.currentPage = response.data.current_page;
        let tasks = response.data.data.map(task => new Task(task));
        this.totalItems = response.data.total;
        this.AdminTasksService.setTasks(tasks);
        this.tasks = this.AdminTasksService.getTasks();
          
    }
}

AdminStaffTasksController.$inject = ['$rootScope', '$stateParams', 'UserService', 'AdminTasksService', 'UsersModel', 'staffMember'];
export default AdminStaffTasksController;