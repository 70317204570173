"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var states_1 = require("../../../../../common/constants/states");
var AdminStudentInstructorsController = /** @class */ (function () {
    function AdminStudentInstructorsController($state, $stateParams, UsersModel, FlashService, instructors, drivesPolicy, PoliciesModel) {
        var _this = this;
        this.initialLoad = true;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.states = states_1.States;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.submitted = false;
        this.form = { 'instructors': instructors };
        this.instructors = [];
        this.selectedInstructor = {};
        this.drivesPolicy = drivesPolicy;
        this.featureEnabled = drivesPolicy.getPolicyByName('drives').getMetaValueByKey('enable_instructor_pairing') === "Y";
        this.PoliciesModel = PoliciesModel;
        UsersModel.getStudentInstructors(this.$stateParams.userId).then(function (response) {
            _this.instructors = response.instructors;
        });
    }
    AdminStudentInstructorsController.prototype.enableFeature = function () {
        var _this = this;
        this.PoliciesModel.update({
            "id": this.drivesPolicy.getPolicyByName('drives').id(),
            "enable_instructor_pairing": "Y",
        }).then(function (response) {
            _this.FlashService.setMessage({
                "type": "success",
                "message": "Feature Enabled."
            });
            _this.featureEnabled = true;
        }).catch(function (Error) {
        });
    };
    AdminStudentInstructorsController.prototype.addInstructor = function () {
        var _this = this;
        var instructor = Object.assign({}, this.selectedInstructor);
        this.UsersModel.addInstructorToStudent(this.$stateParams.userId, { instructor_id: instructor.id }).then(function (response) {
            _this.instructors = [].concat(__spreadArray([instructor], _this.instructors, true));
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    };
    AdminStudentInstructorsController.prototype.removeInstructor = function (instructor) {
        var _this = this;
        this.UsersModel.removeInstructorFromStudent(this.$stateParams.userId, instructor.id).then(function (response) {
            _this.instructors = _this.instructors.filter(function (instruct) {
                return instruct.id !== instructor.id;
            });
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    };
    return AdminStudentInstructorsController;
}());
AdminStudentInstructorsController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', "instructors", "drivesPolicy", "PoliciesModel"];
exports.default = AdminStudentInstructorsController;
