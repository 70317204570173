import angular from "angular";
import controller from "./student-report-card.controller";
import template from "./student-report-card.html";
function getReportcard(UsersModel, UserService) {
  const user = UserService.getCurrentUser();
  return UsersModel.getStudentReportcards({ id: user.id }).then((response) => {
    return response;
  });
}
getReportcard.$inject = ["UsersModel", "UserService"];

function getStudent(UserService) {
  return UserService.getCurrentUser();
}
getStudent.$inject = ["UserService"];
function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.student.dashboard.reportcard", {
    url: "/report-card",
    controller: controller,
    controllerAs: "vm",
    template: template,
    resolve: {
      reportData: getReportcard,
      user: getStudent,
    },
    data: {
      authorizedRoles: [USER_ROLES.admin, USER_ROLES.owner, USER_ROLES.student],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("student.dashboard.reportcard", [])
  .config(config)
  .controller("StudentReportCardController", controller);
