"use strict";
// import 'ui-select';
// import 'angular-ui-utils';
// import 'angular-ui-bootstrap';
// import 'angular-ui-mask';
// import 'angular-sanitize';
// import 'angular-storage';
// import 'angular-animate';
// import 'angular-moment';
Object.defineProperty(exports, "__esModule", { value: true });
require("./assets/images/shortcut-icon.png");
require("./assets/images/apple-icon-57.png");
require("./assets/images/apple-icon-72.png");
require("./assets/images/apple-icon-114.png");
require("./assets/images/apple-icon-144.png");
require("./app/drivecoach.module");
