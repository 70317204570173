
class Student {

    constructor(data) {
        this.$originalData = Object.assign({}, data);
        const { id, firstname, lastname, email, fullname, school_id, organisation_id } = data;
        this.guardians = [];
        this.$id = id;
        this.$firstname =  firstname;
        this.$lastname = lastname;
        this.$email = email;
        this.$fullname = fullname;
        this.$school_id = school_id;
        this.$organisation_id = organisation_id;

    }

    id() {
        return this.$id;
    }

    email() {
        return this.$email;
    }

    fullname() {
        if (this.$fullname) {
            return this.$fullname;
        }
        this.fullname = `${this.$firstname} ${this.$lastname}`;
        return this.$fullname;
    }

    firstname() {
        return this.$firstname;
    }

    lastname() {
        return this.$lastname;
    }

    guardians() {
        return this.$guardians || [];
    }

}

export {Student}