import angular from 'angular'
import template from './product.html'
import controller from './product.controller'

function dsProduct() {
    return {
        restrict: 'E',
        scope: {
            'product' : '=',
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.product', [])
    .directive('product', dsProduct);

