"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CancelDriveModalInstanceCtrl = /** @class */ (function () {
    function CancelDriveModalInstanceCtrl($state, $uibModalInstance, drivesession, student_id, DrivesModel, FlashService, PolicyService, rules) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;
        this.rules = rules;
        this.policies = PolicyService.getPolicies();
        this.drivesession = drivesession;
        this.show_last_minute_availability_alert_control = rules.showFeeWarning;
        this.send_explanation_to_student = false;
        this.cancelation_reason_explanation = null;
        this.student_id = student_id;
        this.canceling = false;
        this.canceledByStudentWithFeeAmount = 0;
        try {
            this.canceledByStudentWithFeeAmount = parseInt(this.policies.getPolicyByName('cancellations').getMetaValueByKey('canceled_by_student_with_fee_amount'));
        }
        catch (Error) {
            this.canceledByStudentWithFeeAmount = 0;
        }
        this.cancelData = {
            'id': this.drivesession.id,
            'code': '',
            'cancelation_reason': this.cancelation_reason_explanation,
            'student_id': this.student_id,
            'send_explanation_to_student': this.send_explanation_to_student,
            'send_last_minute_availability_alert': 'yes',
            'send_last_minute_cancelation_to_student': 'yes',
            'send_last_minute_cancelation_to_instructor': 'yes',
        };
    }
    /**
     * Cancel the drive session
     */
    CancelDriveModalInstanceCtrl.prototype.cancelDriveSession = function () {
        var _this = this;
        this.canceling = true;
        var payload = Object.assign({}, this.cancelData);
        this.DrivesModel.cancelDriveSession(payload).then(function (response) {
            _this.canceling = false;
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.$state.go('drivecoach.admin.calendar', {}, { 'reload': true });
            _this.$uibModalInstance.close();
        }).catch(function (Error) {
            _this.canceling = false;
        });
    };
    CancelDriveModalInstanceCtrl.prototype.close = function () {
        this.$uibModalInstance.dismiss('cancel');
    };
    return CancelDriveModalInstanceCtrl;
}());
CancelDriveModalInstanceCtrl.$inject = ['$state', '$uibModalInstance', 'drivesession', 'student_id', 'DrivesModel', 'FlashService', 'PolicyService', 'rules'];
exports.default = CancelDriveModalInstanceCtrl;
