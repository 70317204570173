class AdminSchoolLocationViewVehiclesCreateController {

    constructor($state, LocationsModel, $stateParams, VehicleAPI, FlashService, VehiclesModel){
        this.VehicleAPI = VehicleAPI;
        this.FlashService = FlashService;
        this.VehiclesModel = VehiclesModel;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.submitting = false;

        this.vehicles = null;
        this.makes = null;
        this.models = null;
        this.years = null;
        this.vehicle = {
            'location_id': $stateParams.locationId
        };

        LocationsModel.getLocationByID($stateParams.locationId).then((response) => {
            this.location = response;
        });

        VehicleAPI.getMakes()
            .then((result) => {
                this.vehicles = result;
            })
        ;
    }

    selectMake(make) {
        let selectedMake = this.vehicles.makes.filter(function (m) {
            return m.name === make.trim();
        });
        this.models = selectedMake[0].models;
    };

    selectModel(model) {
        let selectedModel = this.models.filter(function (m) {
            return m.name === model.trim();
        });
        this.years = selectedModel[0].years;
    };

    addVehicle (){
        this.submitting = true;
        this.VehiclesModel.createVehicle(this.vehicle).then(function (response) {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            this.vehicle = {
                'location_id': this.$stateParams.locationId
            };
            this.submitting = false;
        }).catch(error => {
            this.submitting = false;
        });
    }

}

AdminSchoolLocationViewVehiclesCreateController.$inject = ['$state', 'LocationsModel', '$stateParams', 'VehicleAPI', 'FlashService', 'VehiclesModel'];
export default AdminSchoolLocationViewVehiclesCreateController;