function AdminSchoolLocationEditController($state, $stateParams, LocationsModel, FlashService) {

    const vm = this;
    vm.location = {};

    LocationsModel.getLocationByID($stateParams.locationId).then(function (response) {
        vm.location = response;
        vm.location.classrooms = angular.fromJson(vm.location.classrooms);
    });

    vm.editLocation = function (isValid) {
        vm.submitted = true;

        if (isValid) {
            LocationsModel.editLocation(vm.location).then(function (response) {
                FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                $state.go('drivecoach.admin.schools.view', {'schoolId': $stateParams.schoolId}, {'reload': true});
                vm.location = {
                    'school_id': $stateParams.schoolId
                };
                vm.submitted = false;
            });
        } else {
            FlashService.setMessage({'type': 'error', 'message': 'Please fill the form.'});
        }
    }

}
AdminSchoolLocationEditController.$inject = ['$state', '$stateParams', 'LocationsModel', 'FlashService'];
export default AdminSchoolLocationEditController;