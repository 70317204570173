"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CreateStudentService = /** @class */ (function () {
    function CreateStudentService() {
        this.student = {};
    }
    CreateStudentService.prototype.get = function (key) {
        if (key === void 0) { key = null; }
        if (key !== null) {
            return this.student[key];
        }
        return this.student;
    };
    CreateStudentService.prototype.set = function (key, value) {
        if (key === void 0) { key = 'student'; }
        if (value === void 0) { value = null; }
        if (key === 'student') {
            this.student = value;
        }
        else {
            this.student[key] = value;
        }
    };
    CreateStudentService.prototype.clear = function () {
        this.student = {};
    };
    return CreateStudentService;
}());
exports.default = CreateStudentService;
