import angular from 'angular'
import template from './admin-settings-notifications.html'
import AdminSettingsNotificationsController from './admin-settings-notifications.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.settings.notifications', {
            url : '/notifications',
            controller : AdminSettingsNotificationsController,
            controllerAs: 'vm',
            template: template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.settings.notifications', [] )
    .config(config)
    .controller( 'AdminSettingsNotificationsController', AdminSettingsNotificationsController )
;
