import moment from 'moment'
import { isJson } from "../../../../common/helpers/json";
import cloneDeep from 'lodash/cloneDeep'
import {hours_list} from "../../../../common/constants/hours_list";
import {minutes_list} from "../../../../common/constants/minutes_list";

class AdminSettingsPoliciesDrivesController {

    constructor(PoliciesModel, FlashService, UserService){
        this.hoursList = hours_list;
        this.minutesList = minutes_list;
        this.busy = true;
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;
        this.UserService = UserService;

        this.updateDefaultTime = this.updateDefaultTime.bind(this);

        this.policies = {'drives': {}}
        this.formData = {};
        this.formData.policymeta = {};
        this.minSessionLength = {};
        this.currentUser = UserService.getCurrentUser();
        this.daysBetweenDrivesOptions = [];
        for (let i = 0; i <= 90; i++) {
            let day = 'days';
            if (i === 1) {
                day = 'day';
            }
            this.daysBetweenDrivesOptions.push({
                'name' : i + ' ' + day,
                'value' : i + ' ' + day
            });
        }

        this.scheduleBufferOptions = [];
        for (let i=0; i <= 120; i++) {
            this.scheduleBufferOptions.push({
                'n' : i + ' Hours',
                'v' : i * 60
            });
        }

        PoliciesModel.getByID('drives').then(response => {
            let policy = response.policy;
            let policyMeta = {};
            policy.policymeta.forEach((meta) => {
                policyMeta[meta.meta_key] = isJson(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
            });
            policy.policymeta = policyMeta;
            if (policy.policymeta.hasOwnProperty('minimum_session_length')) {
                // convert from minutes to separate hours and minutes
                if (parseInt(policy.policymeta.minimum_session_length) < 60) {
                    this.minSessionLength = {
                        'hours' : 0,
                        'minutes' : policy.policymeta.minimum_session_length.toString()
                    }
                } else {
                    const _durMoment = moment.duration(policy.policymeta.minimum_session_length, 'minutes').format('H:mm').split(':');
                    this.minSessionLength.hours = parseInt(_durMoment[0]);
                    this.minSessionLength.minutes = _durMoment[1];
                }

            }

            if (policy.policymeta.hasOwnProperty('session_reminder_buffer')) {
                // convert to string to match option tag value
                policy.policymeta.session_reminder_buffer = policy.policymeta.session_reminder_buffer.toString();
            } else {
                policy.policymeta.session_reminder_buffer = "72";
            }

            if (policy.policymeta.hasOwnProperty('use_driving_distance')) {
                policy.policymeta.use_driving_distance = policy.policymeta.use_driving_distance == 1;
            }

            policy.policymeta.id = policy.id;
            this.policies.drives = policy;
            this.busy = false;
        });

    }

    processPolicy(isValid) {
        if (isValid) {
            this.busy = true;
            let policy_meta = cloneDeep(this.policies.drives.policymeta);
            policy_meta.defaults.time = moment(this.defaultSessionTime).format('H:mm');
            // convert minimum session length from separate hours and minutes to minutes
            policy_meta.minimum_session_length = ( parseInt(this.minSessionLength.hours) * 60 ) + parseInt(this.minSessionLength.minutes);
            this.PoliciesModel.update(policy_meta).then(response => {
                this.FlashService.setMessage({'type': 'success', 'message': response.message});
                this.busy = false;
            }).catch(error => {
                this.busy = false;
            });
        } else {
            this.FlashService.setMessage({'type': 'error', 'message': 'All fields are required.'});
        }
    }

    updateDefaultTime(newTime) {
      this.defaultSessionTime = newTime.selected[0]
    }

}
AdminSettingsPoliciesDrivesController.$inject = ['PoliciesModel', 'FlashService', 'UserService'];
export default AdminSettingsPoliciesDrivesController;
