class StudentDrivesClaimController {

    constructor($rootScope, UserService, $state, $stateParams, DrivesModel, FlashService, drive, locations){

        this.DrivesModel = DrivesModel;
        this.$state = $state;
        this.FlashService = FlashService;
        this.$stateParams = $stateParams;

        this.pickupLocation = {};
        this.locations = locations.pickuplocations;
        this.dropofflocations = locations.dropofflocations;
        this.drive = drive;
        this.currentUser = UserService.getCurrentUser();
	
        // this is the location students see if their school's settings do not allow for custom pick-up and
        // drop-off locations
        this.schoolAddress = this.currentUser.school.address;

        this.claimingDrive = false;

        $rootScope.$on('MESSAGE_CLEARED', () => {
            this.claimingDrive = false;
        });

        if (this.drive.type.allow_custom_dropoff_locations === false) {
            this.dropofflocation = this.drive.location;
        }

        if(this.drive.type.allow_custom_pickup_locations === false) {
            this.pickuplocation = this.drive.location;
        }
    }
    
    bookDrive() {
        this.claimingDrive = true;
        const data = {
            'drive_id' : this.$stateParams.id,
            'student_id' : this.currentUser.id,
            'pickuplocation' : this.pickuplocation,
            'dropofflocation' : this.dropofflocation
        };

        this.DrivesModel.claimDrive(data).then(response => {
            this.$state.go('drivecoach.student.dashboard', this.$stateParams, { reload : true });
            this.claimingDrive = false;
            this.FlashService.setMessage({'type' : 'success', 'message' : response.message });
        }).catch(Error => {
            this.claimingDrive = false;
        });

    }

}
StudentDrivesClaimController.$inject = ['$rootScope', 'UserService', '$state', '$stateParams', 'DrivesModel', 'FlashService', 'drive', 'locations'];
export default StudentDrivesClaimController
