import angular from 'angular'
import template from './student-invitations.html'
import AdminStudentsInvitationsController from './student-invitations.controller'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.students.invitations', {
            'url' : '/invitations',
            'controller' : AdminStudentsInvitationsController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.students.invitations', [] )
    .config(config)
    .controller( 'AdminStudentsInvitationsController', AdminStudentsInvitationsController)
;