"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toHoursAndMinutes = toHoursAndMinutes;
exports.padTo2Digits = padTo2Digits;
exports.dateStringToDate = dateStringToDate;
exports.timeStringToDate = timeStringToDate;
exports.dateTimeStringToDate = dateTimeStringToDate;
exports.toYMD = toYMD;
exports.dateOrEmptyString = dateOrEmptyString;
function toHoursAndMinutes(totalMinutes) {
    var minutes = totalMinutes % 60;
    var hours = Math.floor(totalMinutes / 60);
    return "".concat(padTo2Digits(hours), ":").concat(padTo2Digits(minutes));
}
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
function validateDateString(datestring) {
    var arr = datestring.split("-");
    var year = arr[0];
    var month = arr[1];
    var day = arr[2];
    if (year.length !== 4) {
        throw new Error("The year must be 4 digits long.");
    }
    if (month.length !== 2) {
        throw new Error("The month must be 2 digits long.");
    }
    if (day.length !== 2) {
        throw new Error("The day must be 2 digits long.");
    }
}
function validateTimeString(timestring) {
    var arr = timestring.split(":");
    if (arr[0].length !== 2) {
        throw new Error("The hours must be 2 digits long.");
    }
    if (arr[1].length !== 2) {
        throw new Error("The minutes must be 2 digits long.");
    }
    if (arr[2].length !== 2) {
        throw new Error("The seconds must be 2 digits long.");
    }
}
function dateStringToDate(datestring) {
    // throw an error if the  datestring is not YYYYY-MM-DD format
    validateDateString(datestring);
    var arr = datestring.split("-");
    var year = parseInt(arr[0]);
    var month = parseInt(arr[1]) - 1;
    var day = parseInt(arr[2]);
    var date = new Date(year, month, day);
    return date;
}
function timeStringToDate(timestring) {
    // throw an error if the  datestring is not HH:mm:ss format
    validateTimeString(timestring);
    var arr = timestring.split(":");
    var hours = parseInt(arr[0]);
    var minutes = parseInt(arr[1]);
    var seconds = parseInt(arr[2]);
    var date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), hours, minutes, seconds);
    return date;
}
function dateTimeStringToDate(datetimestring) {
    // throw an error if the  datestring is not YYYYY-MM-DD HH:mm:ss format
    var arr1 = datetimestring.split(" ");
    if (arr1.length !== 2) {
        throw new Error("Datetime string must be format YYYY-MM-DD HH:mm:ss. It was " + datetimestring);
    }
    validateDateString(arr1[0]);
    var datestring = arr1[0].split("-");
    if (datestring.length !== 3) {
        throw new Error("Datetime string must be format YYYY-MM-DD HH:mm:ss");
    }
    var year = parseInt(datestring[0]);
    var month = parseInt(datestring[1]) - 1;
    var day = parseInt(datestring[2]);
    var timestring = arr1[1];
    validateTimeString(arr1[1]);
    var arr = timestring.split(":");
    if (arr.length !== 3) {
        throw new Error("Datetime string must be format YYYY-MM-DD HH:mm:ss");
    }
    var hours = parseInt(arr[0]);
    var minutes = parseInt(arr[1]);
    var seconds = parseInt(arr[2]);
    var date = new Date(year, month, day, hours, minutes, seconds);
    return date;
}
function toYMD(date) {
    var d = new Date(date), month = "" + (d.getMonth() + 1), day = "" + d.getDate(), year = d.getFullYear();
    if (month.length < 2)
        month = "0" + month;
    if (day.length < 2)
        day = "0" + day;
    return [year, month, day].join("-");
}
function dateOrEmptyString(datestring) {
    try {
        return dateStringToDate(datestring);
    }
    catch (Error) {
        return "";
    }
}
