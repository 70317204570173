import angular from "angular";
import template from "./exports.html";
import ExportsController from "./exports.controller";

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.admin.settings.exports", {
    url: "/exports",
    controller: ExportsController,
    controllerAs: "vm",
    template: template,
    data: {
      authorizedRoles: [
        USER_ROLES.admin,
        USER_ROLES.owner,
        USER_ROLES.administrator,
      ],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("admin.settings.exports", [])
  .config(config)
  .controller("ExportsController", ExportsController);
