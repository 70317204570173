import StudentAccountController from './student-account.controller'
import EditAccount from './edit/student-edit-account.module'
import EditPermit from './edit-permit/student-edit-permit.module'
import template from './student-account.html'
function config( $stateProvider, $urlRouterProvider ) {
    $stateProvider
        .state( 'drivecoach.student.account', {
            url : '/account',
            template : template,
            controller : StudentAccountController,
            controllerAs : 'vm'
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

export default angular.module( 'student.account', [
    EditAccount.name,
    EditPermit.name
] )
    .config( config )
    .controller( 'StudentAccountController', StudentAccountController );
