
class StudentController {
    constructor(currentUser, ENV, PoliciesModel, VueStoreFactory){
        this.ENV = ENV;
        this.currentUser = currentUser;
        this.school = this.currentUser.school;

        PoliciesModel.get().then(response => {
            VueStoreFactory.dispatch('SET_POLICIES', response);
        }).catch(Error => {

        });
    }
}
StudentController.$inject = ['currentUser', 'ENV', 'PoliciesModel', 'VueStoreFactory'];
export default StudentController;
