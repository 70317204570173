class StudentLocationsIndexController {

    constructor(UsersModel, UserService, FlashService){
        this.UsersModel = UsersModel;
        this.UserService = UserService;
        this.FlashService = FlashService;

        this.student = UserService.getCurrentUser();
        this.pickupLocations = [];
        this.center = {};
        
        UsersModel.getPickupLocations( this.student.id ).then( response => {
            if(response.pickuplocations.length > 0) {
                this.pickupLocations = response.pickuplocations.filter( location => {
                    return location;
                } );
                this.pickupLocation = this.pickupLocations[0];
                this.center = {
                    lat: this.pickupLocations[0].address.lat,
                    lng: this.pickupLocations[0].address.lng,
                    zoom: 2
                };
            }
        } );
    }

    removePickupLocation( id ) {
        const data = {
            'user_id' : this.UserService.getCurrentUser().id,
            'location_id' : id
        };
        this.UsersModel.unattachStudentPickupLocation( data.user_id, data.location_id ).then( response => {
            this.FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            this.pickupLocations = response.user.pickuplocations.filter( location => {
                return location;
            } );
            this.pickupLocation = this.pickupLocations[0];
        } );
    }

}
StudentLocationsIndexController.$inject = ['UsersModel', 'UserService', 'FlashService'];
export default StudentLocationsIndexController;
