import angular from 'angular'
import template from './admin-staff-tasks.html'
import controller from './admin-staff-tasks.controller'

function getStaffMember( $stateParams, UsersModel ) {
    return UsersModel.getUserByID($stateParams.staffId).then((staffMember) => staffMember);
}
getStaffMember.$inject = [ '$stateParams', 'UsersModel' ]

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.staff.tasks', {
            'url' : '/:staffId/tasks',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                staffMember : getStaffMember
            },
            'data' : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.staff.tasks', [] )
    .config(config)
    .controller('AdminStaffTasksController', controller);
