import angular from 'angular'
import AdminEventsCreateController from './admin-create-event.controller'
import template from './admin-create-event.html'

function getCampuses(LocationsModel){
    return LocationsModel.getLocations().then(response => {
        return response;
    });
}
getCampuses.$inject = ['LocationsModel'];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.events.create', {
            url: '/create',
            template: template,
            controller: AdminEventsCreateController,
            controllerAs: 'vm',
            resolve: {
                campuses: getCampuses
            },
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];
export default angular.module('admin.events.create', [])
    .config(config)
    .controller( 'AdminEventsCreateController', AdminEventsCreateController )
;
