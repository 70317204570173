import moment from 'moment'
class StudentPastDrivesController {
    
    constructor(UsersModel , UserService){
        this.UsersModel = UsersModel;
        this.currentPage = 1;
        this.classes = []
        this.attendance = [];
        this.date = new Date();
        this.student = UserService.getCurrentUser();
        this.options = {
            'id' : this.student.id,
            'drivesBefore' : moment().format('YYYY-MM-DD'),
            'page_results' : true
        };
        
        this.handleStudentDrives = this.handleStudentDrives.bind(this);
        this.handlePastClasses = this.handlePastClasses.bind(this);
        UsersModel.getStudentDrives( this.options ).then( this.handleStudentDrives );
        UsersModel.getStudentPastClasses({id: this.student.id}).then(this.handlePastClasses);
    }

    pageChanged(){
        this.options.page = this.currentPage;
        this.UsersModel.getStudentDrives( this.options ).then( this.handleStudentDrives );
        this.UsersModel.getStudentPastClasses({id: this.student.id}).then(this.handlePastClasses);
    }

    handleStudentDrives( response ) {
        this.totalItems = response.drives.total;
        this.drives = response.drives.filter( drive => {
            let minutes = moment.duration(drive.session_length, 'minutes');
            drive.startTime = moment(drive.start_time, 'HH:mm:s').format('h:mm a');
            drive.endTime = moment(drive.start_time, 'HH:mm:s').add( minutes, 'minutes').format('h:mm a');
            drive.duration = moment.duration(drive.session_length, 'minutes').asHours();
            drive.duration = moment.duration(drive.duration, 'hours').humanize();
            if( drive.observation === true ){
                drive.type.name = drive.type.name + ' (Observation)';
            }
            return drive;
        } );
    }

    handlePastClasses( response ) {
        
        this.classes = response.classes.map((classDate) => {
            //matches classes with attendance... this could probably be moved to the back end 
            classDate.attendance = response.attendance.find( (el) => el.class_id === classDate.id)
            return classDate;
        })

    }

}
StudentPastDrivesController.$inject = [ 'UsersModel', 'UserService'];
export default StudentPastDrivesController;
