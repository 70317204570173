import moment from 'moment'
import {debounce} from 'lodash'
class dsSearchStudentsController {
    constructor(UsersModel, FlashService){
        this.students = [];

        this.UsersModel = UsersModel;
        this.FlashService = FlashService;

        this.searchingStudents = false;
        this.drivesession = this.drive;

        if( ! this.change ){
            this.change = () => {}
        }

        this.searchStudents = debounce(this.searchStudents.bind(this), 500);
    }

    /**
     * Search for students by name
     * @param search
     * @param all
     */
    searchStudentsByName(search, all) {

        let searchParams = {};
        this.searchingStudents = false;
        this.FlashService.clearFlash();

        if (search && search.length >= 3 && !this.searchingStudents) {
            searchParams.name = search;
        }

        if (typeof searchParams.name === 'undefined') {
            return;
        }

        this.searchingStudents = true;
        this.UsersModel.searchStudentsByName(searchParams).then(response => {
            response.students.filter(student => {
                if( student.hasOwnProperty('guardian') ){
                    if(!student.guardian.hasOwnProperty('firstname')){
                        delete student.guardian;
                    }
                }
                return student;
            });
            this.students = response.students;
            this.searchingStudents = false;
        });
    }
    
    /**
     * Search for students by name/date 
     * (THIS FUNCTION IS DECPRECATED, THIS DIRECTIVE IS NO LONGER USED ON THE CREATE SESSION PAGE JUST THE CREATE ORDER PAGE)
     * @param search
     * @param all
     */
    searchStudents(search, all) {

        let searchParams = {};
        this.searchingStudents = false;
        this.FlashService.clearFlash();

        if (all === false && typeof this.drivesession === 'object') {
            searchParams = {
                'hours': ( parseInt(this.drivesession.sessionLengthHours) * 60 ) + parseInt(this.drivesession.sessionLengthMinutes),
                'date': {
                    'date': moment(this.drivesession.drive_date).format('YYYY-MM-DD'),
                    'time': moment(this.drivesession.start_time).format('HH:mm:ss'),
                    'duration': ( parseInt(this.drivesession.sessionLengthHours) * 60 ) + parseInt(this.drivesession.sessionLengthMinutes)
                },
                'exclude' : {
                    'drives': [{'id': this.drivesession.id}]
                }
            };
        }

        if (search && search.length >= 3 && !this.searchingStudents) {
            searchParams.name = search;
        }

        if (typeof searchParams.name === 'undefined') {
            return;
        }

        searchParams.toggleStudentSchoolOverride = this.toggleStudentSchoolOverride;

        this.searchingStudents = true;
        this.UsersModel.searchStudents(searchParams).then(response => {
            response.students.filter(student => {
                if( student.hasOwnProperty('guardian') ){
                    if(!student.guardian.hasOwnProperty('firstname')){
                        delete student.guardian;
                    }
                }
                return student;
            });
            this.students = response.students;
            this.searchingStudents = false;
        });
    }
}
dsSearchStudentsController.$inject = ['UsersModel', 'FlashService'];
export default dsSearchStudentsController;