"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function AdminDrivesBatchCreateController($state, BatchCreateService, DrivesModel, FlashService) {
    var vm = this;
    vm.endDate = null;
    vm.showCreateBatchSessionForm = 'yes'; // yes for testing, change to no for production
    vm.drive = BatchCreateService.getDrive();
    vm.drives = [];
    vm.duplicateSession = duplicateSession;
    vm.toggleCreateBatchSessionForm = toggleCreateBatchSessionForm;
    function duplicateSession() {
        var dupeOptions = {
            'startDate': vm.drive.date,
            'endDate': vm.endDate,
            'days': vm.days
        };
        var appointment = Object.assign({}, vm.drive);
        var drives = BatchCreateService.momentIterate(dupeOptions, appointment);
        DrivesModel.batchCreate(drives).then(function (response) {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            if (response.conflicts !== 0) {
                FlashService.setMessage({
                    'type': 'error',
                    'message': 'There were scheduling conflicts, visit the scheduling conflicts page to review them.'
                });
            }
            BatchCreateService.setDrive(null);
            $state.go('drivecoach.admin.drives.createanother');
        });
    }
    function toggleCreateBatchSessionForm() {
        vm.showCreateBatchSessionForm = 'yes';
    }
}
AdminDrivesBatchCreateController.$inject = ['$state', 'BatchCreateService', 'DrivesModel', 'FlashService'];
exports.default = AdminDrivesBatchCreateController;
