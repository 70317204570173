import { debounce, cloneDeep } from 'lodash'

class dsSearchUsersController {
    constructor(UsersModel, FlashService, $timeout){
        this.users = [];

        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.$timeout = $timeout;

        this.searching = false;
        this.oldPlaceolder = this.placeholder;

        this.search = debounce(this.search, 1000)
        this.focusOnSearchField = this.focusOnSearchField.bind(this);
    }

    focusOnSearchField(isOpen) {
        if (isOpen) {
            this.placeholder = 'Search by name, email or phone.'
            let el = document.getElementById('admin-header-user-search-wrapper')
                    .querySelectorAll('input[type=search]')[0];

            this.$timeout(() => {
                el.focus();
            }, 200)

        } else {
            this.placeholder = this.oldPlaceolder;
        }
    }

    /**
     * Search for students by name
     * @param search
     */
    search(search) {

        if (!search){
            return;
        }

        let searchParams = {};
        //this.searching = false;
        this.FlashService.clearFlash();

        searchParams.query = search;

        if (typeof searchParams.query === 'undefined') {
            return;
        }

        if(typeof this.userType !== 'undefined') {
            searchParams.user_type = encodeURI(this.userType);
        }

        searchParams.toggleStudentSchoolOverride = this.schooloverride;

        this.searching = true;
        const placeholder = cloneDeep(this.placeholder);
        this.placeholder = '';
        this.UsersModel.searchUsers(searchParams).then(response => {
            this.users = response.data;
            this.searching = false;
            this.placeholder = placeholder;
        });
    }
}
dsSearchUsersController.$inject = ['UsersModel', 'FlashService', '$timeout'];
export default dsSearchUsersController;