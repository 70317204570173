import moment from 'moment'

class InstructorCalendarController{
  
  constructor($rootScope, CalendarModel, UserService, DrivesFilterService, CalendarService, $state ) {

    this.entities = [];
    this.$rootScope = $rootScope;
    this.CalendarModel = CalendarModel;
    this.UserService = UserService;
    this.CalendarService = CalendarService;
    this.DrivesFilterService = DrivesFilterService;
    this.$state = $state;
    
    this.updateEntities = this.updateEntities.bind(this);
    this.handleAgendaClick = this.handleAgendaClick.bind(this);

    // check cal service for view
    if (!this.CalendarService.getCalendarView()) {
      this.CalendarService.setCalendarView('dayGridMonth')
    }
    
    this.loading = false;
    
    $rootScope.$on('UPDATE_CALENDAR', this.updateEntities);
    $rootScope.$on('CALENDAR_LOADING', () => {
      this.loading = true;
    });
    
    if(this.calendarMode === 'old') {
      let data = {
        startDate: moment().startOf('month').valueOf(),
        endDate: moment().endOf('month').valueOf(),
        context: 'instructor'
      }
      this.CalendarService.handleDateChange(data)
    }
  }
  
  updateEntities() {
    const entities = this.CalendarService.getCalendarItems();
    this.entities = entities
    this.loading = false;
  }
  
  handleAgendaClick(data) {
    this.$state.go( 'drivecoach.instructor.dashboard.agenda', { 'date' : data.dateStr } );
  }
}
InstructorCalendarController.$inject = ['$rootScope', 'CalendarModel', 'UserService', 'DrivesFilterService', 'CalendarService', '$state'];
export default InstructorCalendarController;
