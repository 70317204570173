import angular from 'angular'
import template from './admin-tasks.html'
import AdminTasksController from './admin-tasks.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.tasks', {
            url : '/tasks',
            controller : AdminTasksController,
            controllerAs: 'vm',
            template: template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.tasks', [] )
    .config(config)
    .controller( 'AdminTasksController', AdminTasksController )
;
