<template>
  <dcModal :modalShown="modalShown" :modalClasses="['col-sm-10']" v-on:close-modal="handleClose()">
    <template #header>
      <h3 class="modal-title text-center">View Order {{ order.id }}</h3>
    </template>
    <template #body>
      <dcLoading v-if="loading"></dcLoading>
      <div class="row">
        <div :class="{'col-sm-12' : canTakePayment === false, 'col-sm-8' : canTakePayment === true}">
          <table class="table">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Purchased On</th>
                <th>Source</th>
                <th>Created By</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"><span v-if="order.hasOwnProperty('student')">{{ order.student.firstname }} {{ order.student.lastname }}</span></th>
                 <td>{{ formatDate(order.created_at) }}</td> 
                <td>{{ order.source === null ? 'N/A' : order.source }}</td>
                <td v-if="order.created_by !== null">{{ order.created_by.firstname }}
                  {{ order.created_by.lastname }}</td>
                <td v-else>N/A</td>
                <td>{{ formatAmount(orderTotal) }}</td>
              </tr>
            </tbody>
          </table>
          <h4>Notes</h4>
          <div :key="note.id" v-for="(note, index) in order.notes" class="well">
            <p v-html="note.content"></p>
          </div>
          <h4>Line Items</h4>
          <div v-for="(item, index) in order.lineitems" :key="index" class="well">
            <p style="font-size: 1.2em; font-weight: bolder">{{ item.product_name }}</p>
            <p style="margin-bottom: 2em;">{{ formatAmount(item.price) }}</p>
            <h5>Details: </h5>
            <p v-if="item.product_name === 'Surcharge/Processing Fee'">
              {{ item.description }}
            </p>
            <template v-if="item.product_details !== null">
              <p v-if="!item.product_details.sessiontypes">
                {{ item.product_details }}
              </p>
              <table class="table" style="margin-top: 1em;"
                v-else-if="item.product_details.sessiontypes.length > 0">
                <thead>
                  <tr>
                    <th>Session Type</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sessiontype, index) in item.product_details.sessiontypes" :key="index">
                    <th scope="row">{{ sessiontype.name }}</th>
                    <td>{{ sessiontype.pivot.hours / 60 }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>

          <div style="margin-bottom: 2em;">
            <h3 class="text-center">Coupons</h3>
            <div v-if="order.coupons.length > 0">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-condensed">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr class="text-left">
                    <td>
                      <strong>{{ order.coupons[0].code() }}</strong>
                    </td>
                    <td>
                      {{ order.coupons[0].label() }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="well" v-if="order.coupons.length == 0">
              There are no applied coupons.
            </div>
          </div>
          
          <p class="well" v-if="order.payments.length === 0">There have been no payments made on this order.</p>
            <div v-if="order.payments.length > 0">
                <h3 class="text-center">Payment History</h3>
                <div class="well p0 table-responsive">
                  <table class="table table-bordered table-striped payment-history-table">
                      <thead>
                        <tr>
                            <th>Paid On</th>
                            <th>Payment Method</th>
                            <th>Amount</th>
                            <th>Void</th>
                            <th>Check Number</th>
                            <th>Notes</th>
                            <th v-if="context !== 'student'">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(payment, index) in payments" :key="index">
                            <td>{{ formatDate(payment.charged_on) }}</td>
                            <td>{{ payment.payment_method}}</td>
                            <td>{{formatAmount(payment.amount)}}</td>
                            <td>{{ payment.void ? 'yes' : 'no' }}</td>
                            <td>{{ payment.payment_method === 'check' ? payment.check_number : 'N/A' }}</td>
                            <td>{{payment.notes}}</td>
                            <td v-if="context !== 'student'"><button
                                :disabled="payment.void === true || order.status === 'VOID'"
                                class="btn btn-light"
                                @click="emitEvent('void-payment', {payment_id: payment.id, index: index})">Void</button></td>
                        </tr>
                      </tbody>
                  </table>
                </div>
            </div>
            <hr>
            <h4>Amount Owed: <strong>{{ formatAmount(amountOwed) }}</strong></h4>
            <hr>
            <div v-if="context !== 'student'">
              <button class="btn btn-red btn-block" :disabled="submitted || order.status === 'VOID'" @click="emitEvent('void-order', {order_id: order.id})">Void Order</button>
              <p class="help-block">Note: If over 24 Hours has passed since the Order and/or Credit Card Payment date and time, please log in to Authorize.net's Merchant Center to verify or authorize the appropriate refund.</p>
            </div>

        </div>
        <div class="col-sm-4">
          <div>
            <div class="form-group" v-if="context !== 'student'">
              <label for="receipt_email">Reciept Email: </label>
              <input type="text" class="form-control" id="receipt_email" v-model="receipt_email" />
            </div>
            <button v-if="context !== 'student'" class="btn btn btn-dc btn-block" :disabled="submitted" @click.prevent="emitEvent('send-receipt', {order_id: order.id, receipt_email: receipt_email})">Send Reciept</button>
            <hr v-if="context !== 'student'" />
            <button class="btn btn btn-dc btn-block" :disabled="submitted" @click.prevent="emitEvent('print-order', {order_id: order.id})">Print Order</button>
            <p class="help-block"></p>
          </div>

          <hr>

          <h3 class="text-center" v-if="canTakePayment">Make Payment</h3>
          <div class="well" v-if="canTakePayment">
            <p v-if="order.paid === true">This order is paid in full.</p>
            <p v-if="order.status === 'VOID'">This order has been voided and cannot be paid.</p>
            <form v-if="order.paid === false && order.status !== 'VOID'" @submit.prevent="submitPayment(payment)" name="paymentForm" novalidate>
              <fieldset>
                <div>
                  <div class="form-group" v-if="context !== 'student'" :class="{'form-group--error': $v.payment.payment_method.$error }">
                    <label for="payment_method">Payment Method</label>
                    <select id="payment_method" class="form-control" v-model="payment.payment_method" required @blur="validateInput('payment_method')">
                      <option v-for="(option, index) in payment_methods" :value="option.value" :key="index">
                        {{ option.name }}
                      </option>
                    </select> 
                  </div>
                  <div class="form-group" :class="{'form-group--error': $v.payment.payment_amount.$error }">
                    <label for="payment_amount">Payment Amount</label>
                    <input id="payment_amount" class="form-control" type="text" placeholder="$0.00" v-model.lazy="payment.payment_amount" v-money="money" required @input="validateInput('payment_amount')"/>
                  </div>
                  <div class="alert inline alert-info" style="margin-top: 1.5em"
                    v-if="surchargeRequired && payment.payment_method === 'card' && paymentInCents > 0">
                    <p>Because you are paying with a card you must pay an additional {{ surchargeAmount }}%.</p>
                    <p style="font-size: 1.5em"><strong>The amount charged to this card will be
                        {{ formatAmount(paymentTotal) }}</strong></p>
                  </div>
                </div>
              </fieldset>
              <section v-if="payment.payment_method === 'check'">
                <fieldset>
                  <div class="form-group row">
                    <div class="col-xs-12">
                      <label>Check Number (optional)</label>
                      <input id="check_number" title="Check Number" class="form-control"
                        v-model="payment.check_number" />
                    </div>
                  </div>
                </fieldset>
              </section>
              <section v-if="payment.payment_method === 'card'">
                <fieldset v-if="billingConfig.gateway !== 'paypal'">
                  <div class="form-group row" :class="{'form-group--error': $v.payment.card.name_on_card.$error }">
                    <div class="col-xs-12">
                      <label>Name on Card</label>
                      <input id="name_on_card" title="Name on Card" class="form-control"
                        :required="payment.payment_method === 'card'" @input="validateInput('card', 'name_on_card')" v-model="payment.card.name_on_card" />
                    </div>
                  </div>
                </fieldset>
                <h6>Billing Address</h6>
                <div class="row">
                  <div class="col-xs-12">
                    <fieldset>
                      <div class="form-group row" :class="{'form-group--error': $v.payment.card.billing_address.street.$error }">
                        <div class="col-xs-12">
                          <label for="billing_address_street">Street</label>
                          <input class="form-control" v-model="payment.card.billing_address.street" @input="validateInput('card', 'billing_address', 'street')" :required="payment.payment_method === 'card'" id="billing_address_street">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-xs-12" :class="{'form-group--error': $v.payment.card.billing_address.city.$error }">
                          <label for="billing_address_city">City</label>
                          <input class="form-control" v-model="payment.card.billing_address.city" @input="validateInput('card', 'billing_address', 'city')" :required="payment.payment_method === 'card'" id="billing_address_city">
                        </div>
                        <div class="col-xs-12" :class="{'form-group--error': $v.payment.card.billing_address.state.$error }">
                          <label for="billing_address_state">State</label>
                          <select class="form-control" v-model="payment.card.billing_address.state"
                            @blur="validateInput('card', 'billing_address', 'state')" :required="payment.payment_method === 'card'" id="billing_address_state">
                            <option v-for="(state, index) in states" :value="state.value" :key="index">{{state.name}}</option>
                          </select>
                        </div>
                        <div class="col-xs-12" :class="{'form-group--error': $v.payment.card.billing_address.zip.$error }">
                          <label for="zip">Zip/Postal Code</label>
                          <input class="form-control" v-model="payment.card.billing_address.zip"
                            @input="validateInput('card', 'billing_address', 'zip')" :required="payment.payment_method === 'card'" id="zip">
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div> 

                <section v-if="billingConfig.gateway === 'paypal'">
                  <div id="paypal-button-container" ref="paypalButtonContainer"></div>
                </section>

                <fieldset v-else>
                  <div class="form-group" :class="{'form-group--error': cardErrors.number }">
                    <label for="card_number">Card Number</label>
                    <input class="form-control" v-model="payment.card.number"
                      :required="payment.payment_method === 'card'" id="card_number" @input="validateCCInput('number')" v-cardformat:formatCardNumber>
                  </div>
                  <div class="form-group row">
                    <div class="col-xs-12" :class="{'form-group--error': $v.payment.card.expiration_month.$error }">
                      <label for="expiration_month">Expiration Month</label>
                        <select class="form-control" id="expiration_month"
                                v-model="payment.card.expiration_month"
                                :required="payment.payment_method === 'card'"
                                @blur="validateInput('card', 'expiration_month')" >
                          <option v-for="(month, index) in months" :value="month.value" :key="index">{{ month.name }}</option>
                        </select>
                    </div>
                    <div class="col-xs-12" :class="{'form-group--error': $v.payment.card.expiration_year.$error }">
                        <label for="expiration_year">Expiration Year</label>
                        <select class="form-control"
                                v-model="payment.card.expiration_year"
                                id="expiration_year"
                                :required="payment.payment_method === 'card'"
                                @blur="validateInput('card', 'expiration_year')">
                          <option v-for="(year, index) in years" :value="year.value" :key="index">{{ year.name }}</option>
                        </select>
                    </div>
                    <div class="col-xs-12" :class="{'form-group--error': cardErrors.cvc }">
                      <label for="cvc">CVC</label>
                      <input class="form-control" :required="payment.payment_method === 'card'"
                        v-model="payment.card.cvc" id="cvc" @input="validateCCInput('cvc')" v-cardformat:formatCardCVC>
                    </div>
                  </div>
                </fieldset> 

              </section>
              <fieldset v-if="billingConfig.gateway !== 'paypal'">
                <div class="form-group">
                  <button class="btn btn-block btn-dc" :disabled="paymentFormInvalid || submitted === true" 
                    type="submit">Submit <i class="fa fa-circle-o-notch fa-spin fa-fw" v-if="submitted"></i></button>
                </div>
              </fieldset> 
            </form>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
       <button class="btn btn-light" type="button" @click="handleClose">Close</button>
    </template>
  </dcModal>
</template>

<script>
  import moment from 'moment';
  import { cloneDeep } from 'lodash';
  import { VMoney } from 'v-money';
  //form validation
  import { validationMixin } from 'vuelidate'
  import { required, requiredIf, minLength, numeric } from 'vuelidate/lib/validators'

  import dcModal from '../modal/Modal.vue';
  import dcLoading from '../loading/Loading.vue';

  import { inDollars, percentageOfXInCents } from "../../../../../DriveScout/Billing/Teller";
  import { PaymentMethods } from "../../../constants/payment_methods";
  import {States} from "../../../constants/states";
  import  { Months, Years } from "../../../constants/months_and_years";
  import Dinero from 'dinero.js'

  const paymentInitData = { payment_amount: '$0.00', payment_method: '', card: { name_on_card: '', billing_address: { street: '', city: '', state: '', zip: '' }, number: '', expiration: '', cvc: '' }} //used to reset form
  const mustBeGreaterThanZero = (amount) => parseInt(amount.replace(/\D/g,'')) > 0 //used to validate payment amount
  
  export default {
    name: 'dcOrderDetail',
    components: { dcModal, dcLoading },
    directives: { money: VMoney },
    mixins: [validationMixin],
    props: {
      'billingConfig': { type: Object, default: () => {} },
      'order': { type: Object, required: true },
      //use this prop to trigger modal open/close
      'modalShown': { type: Boolean, required: true },
      'loading': { type: Boolean, default: false },
      'context': { type: String, required: true },
      'clearPaymentForm': { type: Boolean, default: false }, //change this value to true from angular after form submit to clear form
      'submitted': { type: Boolean, default: false },
      'loading': { type: Boolean, default: false }
    },
    data() {
      return {
        cardErrors: {
          number: false,
          expiration_month: false,
          expiration_year: false, 
          cvc: false,
          receipt_email: '',
        },
        money: {
          decimal: '.',
          thousands: ',',
          prefix: '$',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        months: Months,
        payment: {
          payment_amount: '$0.00',
          payment_method: '',
          card: {
            name_on_card: '',
            billing_address: {
              street: '',
              city: '',
              state: '',
              zip: ''
            },
            number: '',
            expiration_month: '',
            expiration_year: '',
            cvc: ''
          }
        },
        payment_methods: PaymentMethods, //array
        paymentInitData: paymentInitData,
        states: States,
        years: Years,
        receipt_email: ''
      }
    },
    validations: {
      payment: {
        payment_amount: { required, mustBeGreaterThanZero },
        payment_method: { required },
        card: {
          name_on_card: { required: requiredIf(function() {return this.isCardPayment}), minLength: minLength(3) },
          billing_address: {
            street: { required: requiredIf(function() {return this.isCardPayment}),  minLength: minLength(3) },
            city: { required: requiredIf(function() {return this.isCardPayment}), minLength: minLength(2) },
            state: { required: requiredIf(function() {return this.isCardPayment}), minLength: minLength(2) },
            zip: { required: requiredIf(function() {return this.isCardPayment}), minLength: minLength(5), numeric }
          },
          number: { required: requiredIf(function() {return this.isCardPayment}) },
          expiration_month: { required: requiredIf(function() {return this.isCardPayment}) },
          expiration_year: { required: requiredIf(function() {return this.isCardPayment}) },
          cvc: { required: requiredIf(function() {return this.isCardPayment}) }
        }
      }
    },
    methods: {
      emitEvent: function(eventName, dataObject) {
        this.$emit(eventName, dataObject)
      },
      formatAmount: function(amount) {
        return inDollars(amount)
      },
      formatDate(date, formatStr = 'MM/DD/YYYY h:mm a') {
        return moment(date).format(formatStr);
      },
      handleClose() {
        this.receipt_email = '';
        this.emitEvent('close-modal');
      },
      handlePayPalPayment(details) {
        this.payment.charge_id = details.id;
        this.submitPayment();
      },
      resetPayment() {
        this.payment = JSON.parse(JSON.stringify(this.paymentInitData));
      },
      submitPayment(payment) {
        if (this.paymentFormInvalid) {
          return false;
        }
        let _payment = cloneDeep(payment)
        _payment.payment_amount = this.paymentInCents
        _payment.order_id = this.order.id
        if(this.context === 'student') {
          _payment.payment_method = 'card'
        }
        this.emitEvent('submit', {payment: _payment})
      },
      validateInput(key, nestedKey, nested2) {
        //im sure there's a better way to do this
        if(nested2) {
          return this.$v.payment[key][nestedKey][nested2].$touch()
        }
        else if(nestedKey) {
          return this.$v.payment[key][nestedKey].$touch()
        } else {
          return this.$v.payment[key].$touch()
        }
      },
      validateCCInput(key) {
        if(key === 'number') {
          //this.cardErrors.number = !this.$cardFormat.validateCardNumber(this.payment.card.number) //validate function returns true if valid;
        }
        else if(key === 'cvc') {
          //this.cardErrors.cvc = !this.$cardFormat.validateCardCVC(this.payment.card.cvc);
        }
      },
      applyProvidedCouponTo(amount)
      {
        // apply coupon if provided, otherwise proceed
        if (this.order.coupons.length > 0){
            if (this.order.coupons[0].type() === 'fixed') {
                return amount - this.order.coupons[0].amount();
            }

            if (this.order.coupons[0].type() === 'percentage') {
                const subAmount = Dinero({ amount: amount, currency: 'USD' }).percentage(this.order.coupons[0].amount());
                const dinero = Dinero({amount: amount, currency: 'USD'}).subtract(subAmount)
                return dinero.getAmount();
            }
        } 

        return amount;
      },
    },
    created() {},
    mounted() {},
    computed: { 
      amountOwed: function() {
        if (this.order.status === 'VOID') {
          return 0;
        } else {
          let amountPaid = 0;
          if (this.order.payments.length > 0) {
            amountPaid = this.order.payments.filter(payment => {
                return payment.void !== true;
            }).reduce((carry, payment) => {
                return carry + parseInt(payment.amount);
            }, 0);
          }
          return this.applyProvidedCouponTo(parseInt(this.orderTotal)) - amountPaid;
        }
      },
      isCardPayment() {
        return this.payment.payment_method === 'card';
      },
      paymentFormInvalid: function() {
        return this.$v.payment.$invalid || ( this.payment.payment_method === 'card' && Object.values(this.cardErrors).some(val => val === true));
      },
      paymentInCents: function() {
        return parseInt(this.payment.payment_amount.replace(/\D/g,''));
      },
      payments: function() {
        return this.order.payments;
      },
      surchargeAmount: function() {
        if (this.billingConfig.policyMeta.hasOwnProperty('surcharge_amount')) {
          return parseFloat(this.billingConfig.policyMeta.surcharge_amount);
        } else return 0;
      },
      surchargeAmountBilled: function() {
        if (this.surchargeBilledSeparately && this.payment.payment_method === 'card') {
            return inDollars(percentageOfXInCents(this.paymentInCents, this.surchargeAmount));
        }
      },
      surchargeBilledSeparately: function() {
        if (this.billingConfig.policyMeta.hasOwnProperty('surcharge_amount')) {
          return parseFloat(this.billingConfig.policyMeta.surcharge_amount) > 0;
        } else return false;
      },
      surchargeRequired: function() {
        return this.surchargeBilledSeparately && this.payment.payment_method === 'card';
      },
      orderTotal: function() {
          let orderTotalInCents = cloneDeep(this.order.lineitems).reduce((price, product) => {
              return price + parseInt(product.price);
          }, 0);
          return orderTotalInCents;
      },
      paymentTotal: function() {
        if(this.surchargeBilledSeparately && this.payment.payment_method === 'card') {
          return this.paymentInCents + percentageOfXInCents(this.paymentInCents, this.surchargeAmount); 
        } else {
          return this.paymentInCents;
        }
      },
      canTakePayment()
      {
        return this.context === 'administrator' || this.context === 'owner' || this.context === 'staff' || this.context === 'student' || (this.context === 'instructor' && this.billingConfig.policyMeta.instructor_can_take_payment === 'Y');
      }
    },
    watch: {
      // whenever clearPaymentForm prop changes, this function will run
      clearPaymentForm: function (newValue, oldValue) {
        if(newValue === true) {
          this.resetPayment()
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.form-group--error {
  label {
    color: red;
  }
  input, select, .ql-container.ql-snow {
    border: 1px solid red !important;
  }
}
</style>