import angular from 'angular'
import template from './admin-orders.html'
import AdminOrdersController from './admin-orders.controller'
import Create from './create-order/create-order.module'
import Edit from './create-order/create-order.module'
import Reports from './order-reports/order-reports.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.orders', {
            url: '/orders',
            template: template,
            controller: AdminOrdersController,
            controllerAs: 'vm',
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.crm.orders', [
    Create.name,
    Edit.name,
    Reports.name
]).config(config)
    .controller('AdminOrdersController', AdminOrdersController )
;
