import angular from 'angular'
import CreateOrderController from './create-order.controller'
import template from './create-order.html'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.orders.create', {
            url : '/create',
            template : template,
            controller : CreateOrderController,
            controllerAs : 'vm',
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.crm.orders.create', [] )
    .config(config)
    .controller('CreateOrderController', CreateOrderController);