import angular from 'angular'
import template from './product-session-types.html'
import controller from './product-session-types.controller'
function dsProductSessionTypes() {
    return {
        restrict: 'E',
        scope: {
            'product' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.productsessiontypes', [])
    .controller('dcProductSessionTypes', controller)
    .directive('dsProductSessionTypes', dsProductSessionTypes);

