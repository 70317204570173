function AdminSettingsPoliciesCancellationsController(UserService, PoliciesModel, FlashService) {

    const vm = this;
    vm.currentUser = UserService.getCurrentUser();
    vm.processPolicy = processPolicy;
    vm.addCancellationRule = addCancellationRule;
    vm.removeCancellationRule = removeCancellationRule;
    vm.school = vm.currentUser.school;
    vm.cancellationRules = [];
    vm.cancellation_rule = {
        hours: "12 Hours",
        fee: null
    }

    init();

    function init() {
        PoliciesModel.get().then(response => {
            vm.policies = {};
            response.policies.forEach(policy => {
                let policyName = policy.name;
                vm.policies[policyName] = { 'id': policy.id };
                let policyMeta = {};
                policy.policymeta.forEach(meta => {
                    policyMeta[meta.meta_key] = meta.meta_value;
                });
                vm.policies[policyName].policymeta = policyMeta;
            });
            vm.policies.cancellations.policymeta.fee = parseInt(vm.policies.cancellations.policymeta.fee);
            vm.policies.cancellations.policymeta.id = vm.policies.cancellations.id;
            if (typeof vm.policies.cancellations.policymeta.cancellation_rules !== "undefined") {
                vm.policies.cancellations.policymeta.cancellation_rules = JSON.parse(vm.policies.cancellations.policymeta.cancellation_rules);
                vm.cancellationRules = vm.policies.cancellations.policymeta.cancellation_rules;
            }
        });
    }

    function addCancellationRule() {
        if (vm.cancellation_rule.fee && vm.cancellation_rule.hours) {
            vm.cancellationRules.push({
                fee: vm.cancellation_rule.fee,
                hours: vm.cancellation_rule.hours,
            });

            vm.cancellation_rule = {
                fee: null,
                hours: "12 Hours"
            }
        }
    }

    function removeCancellationRule(index) {
        vm.cancellationRules.splice(index, 1);
    }

    function processPolicy(isValid) {
        if (isValid) {
            let payload = Object.assign({}, vm.policies.cancellations.policymeta);
            if (vm.cancellationRules.length > 0) {
                payload.cancellation_rules = vm.cancellationRules;
            }

            PoliciesModel.update(payload).then(response => {
                FlashService.setMessage({ 'type': 'success', 'message': response.message });
            });
        } else {
            FlashService.setMessage({ 'type': 'error', 'message': 'All fields are required.' });
        }
    }

}
AdminSettingsPoliciesCancellationsController.$inject = ['UserService', 'PoliciesModel', 'FlashService'];

export default AdminSettingsPoliciesCancellationsController;
