

class Address {
    constructor(id = 0, name = '', street = '', city = '', state = '', zip = '', lat = 0, lng = 0) {
        this.$id = id;
        this.$name = name;
        this.$street = street;
        this.$city = city;
        this.$state = state;
        this.$zip = zip;
        this.$lat = lat;
        this.$lng = lng;
    }

    name() {
        return this.$name;
    }

    toString() {
        return `${this.$street} ${this.$city} ${this.$state} ${this.$zip}`
    }

    setStreet(street) {
        this.$street = street;
    }

    setStreet(city) {
        this.$city = city;
    }

    setRegion(state) {
        this.$state = state;
    }

    setPostalCode(zip) {
        this.$zip = zip;
    }

    setLatittude(lat) {
        this.$lat = lat;
    }

    setLongitude(lng) {
        this.$lng = lng;
    }

    latitude() {
        return this.$lat;
    }

    longitude() {
        return this.$lng;
    }

    street() {
        return this.$street;
    }

    city() {
        return this.$city;
    }

    region() {
        return this.$state;
    }

    postalCode() {
        return this.$zip;
    }


}

export { Address }