"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ForgotPasswordController = /** @class */ (function () {
    function ForgotPasswordController(LoginService, FlashService, AuthService) {
        this.AuthService = AuthService;
        this.LoginService = LoginService;
        this.FlashService = FlashService;
        this.loading = false;
        this.username = "";
        this.errors = [];
    }
    ForgotPasswordController.prototype.submit = function () {
        var _this = this;
        this.AuthService.removeToken();
        if (!this.username) {
            this.FlashService.setMessage({
                type: 'error',
                message: 'Your username is required.',
            });
        }
        else {
            this.LoginService.remindPassword(this.username).catch(function (response) {
                _this.loading = false;
            }).catch(function (Error) {
                _this.loading = false;
            });
        }
    };
    return ForgotPasswordController;
}());
ForgotPasswordController.$inject = ['LoginService', 'FlashService', 'AuthService'];
exports.default = ForgotPasswordController;
