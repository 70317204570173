class ToasterService {

    constructor($rootScope){
        this.$rootScope = $rootScope;
        this.message = '';
        this.type = '';
    }

    getMessage () {
        return this.message;
    }
    addToast( newMessage ) {
        this.message = newMessage.message;
        this.type = newMessage.type;
        this.$rootScope.$broadcast('NEW_TOAST');
    }
    getType(){
        return this.type;
    }

    setType( newType ) {
        this.type = newType;
    }
    clear() {
        this.$rootScope.$broadcast( 'CLEAR_FLASH_MESSAGE' );
    }
}
ToasterService.$inject = ['$rootScope'];
export default ToasterService;