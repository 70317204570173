"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var time_1 = require("../../../common/helpers/time");
var DrivesRequiringActionController = /** @class */ (function () {
    function DrivesRequiringActionController($scope, $state, DrivesModel, FlashService) {
        var _this = this;
        this.pageBy = 20;
        this.totalItems = 0;
        this.currentPage = 1;
        this.drives = [];
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;
        this.$scope = $scope;
        this.selection = [];
        this.pageChanged = this.pageChanged.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.completeDrivingLessons = this.completeDrivingLessons.bind(this);
        this.getDrivingLessons = this.getDrivingLessons.bind(this);
        this.DrivesModel.getDrivesRequiringAction(this.requestParams()).then(function (response) {
            _this.drives = response.drives.data.map(function (drive) {
                drive.drive_date = (0, time_1.dateStringToDate)(drive.drive_date).toLocaleDateString('en-US', { year: "numeric", month: "2-digit", day: "2-digit" });
                drive.start_time = (0, time_1.timeStringToDate)(drive.start_time).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
                return drive;
            });
            _this.totalItems = response.drives.total;
            _this.currentPage = response.drives.current_page;
        });
        this.$scope.$watch("vm.drives|filter:{selected:true}", function (nv) {
            if (typeof nv !== "undefined") {
                // @ts-ignore
                _this.selection = nv.map(function (entity) {
                    return entity;
                });
            }
        }, true);
    }
    DrivesRequiringActionController.prototype.requestParams = function () {
        return {
            page_by: this.pageBy,
            page: this.currentPage,
        };
    };
    DrivesRequiringActionController.prototype.selectAll = function () {
        var _this = this;
        this.drives.forEach(function (drive, index) {
            _this.drives[index].selected = !_this.drives[index].selected;
        });
    };
    DrivesRequiringActionController.prototype.completeDrivingLessons = function () {
        var _this = this;
        var payload = this.drives.filter(function (drive) {
            return drive.selected === true;
        }).map(function (drive) {
            return drive.id;
        });
        this.DrivesModel.completeDrivingLessons({ 'drives': payload }).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        }).then(this.getDrivingLessons);
    };
    DrivesRequiringActionController.prototype.getDrivingLessons = function () {
        var _this = this;
        this.DrivesModel.getDrivesRequiringAction(this.requestParams()).then(function (response) {
            _this.drives = response.drives.data.map(function (drive) {
                drive.drive_date = (0, time_1.dateStringToDate)(drive.drive_date).toLocaleDateString('en-US', { year: "numeric", month: "2-digit", day: "2-digit" });
                drive.start_time = (0, time_1.timeStringToDate)(drive.start_time).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
                return drive;
            });
            _this.totalItems = response.drives.total;
            _this.currentPage = response.drives.current_page;
        });
    };
    DrivesRequiringActionController.prototype.pageChanged = function () {
        var _this = this;
        this.DrivesModel.getDrivesRequiringAction(this.requestParams()).then(function (response) {
            _this.drives = response.drives.data.map(function (drive) {
                drive.drive_date = (0, time_1.dateStringToDate)(drive.drive_date).toLocaleDateString('en-US', { year: "numeric", month: "2-digit", day: "2-digit" });
                drive.start_time = (0, time_1.timeStringToDate)(drive.start_time).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
                return drive;
            });
            _this.totalItems = response.drives.total;
            _this.currentPage = response.drives.current_page;
        });
    };
    return DrivesRequiringActionController;
}());
DrivesRequiringActionController.$inject = ["$scope", "$state", "DrivesModel", "FlashService"];
exports.default = DrivesRequiringActionController;
