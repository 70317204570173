import angular from 'angular'
import Vue from 'vue';
import 'ngVue';
  
import dcCalendar from './Calendar.vue'

export default angular.module( 'directives.calendarvue', [] )
  .directive('dcCalendar', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcCalendar', dcCalendar))
  }])
