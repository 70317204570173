"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StudentPastDrivesController = /** @class */ (function () {
    function StudentPastDrivesController(UsersModel, UserService) {
        this.UsersModel = UsersModel;
        this.currentPage = 1;
        this.classes = [];
        this.attendance = [];
        this.date = new Date();
        this.student = UserService.getCurrentUser();
        this.handlePastClasses = this.handlePastClasses.bind(this);
        UsersModel.getStudentPastClasses({ id: this.student.id }).then(this.handlePastClasses);
    }
    StudentPastDrivesController.prototype.pageChanged = function () {
        this.UsersModel.getStudentPastClasses({ id: this.student.id }).then(this.handlePastClasses);
    };
    StudentPastDrivesController.prototype.handlePastClasses = function (response) {
        this.classes = response.classes.map(function (classDate) {
            //matches classes with attendance... this could probably be moved to the back end 
            classDate.attendance = response.attendance.find(function (el) { return el.class_id === classDate.id; });
            return classDate;
        });
    };
    return StudentPastDrivesController;
}());
StudentPastDrivesController.$inject = ['UsersModel', 'UserService'];
exports.default = StudentPastDrivesController;
