"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Task_1 = require("../../../../../classes/Task");
var TasksController = /** @class */ (function () {
    function TasksController(AdminTasksService, $rootScope, $state, $stateParams, CrmTasksModel, FlashService, UsersModel, $q) {
        var _this = this;
        this.loading = true;
        this.filteringBy = "";
        this.tasks = [];
        this.totalItems = 0;
        this.tasks_last_page = 0;
        this.currentPage = 0;
        this.AdminTasksService = AdminTasksService;
        var getStudent = UsersModel.getStudentByID($stateParams.userId);
        var getTasks = CrmTasksModel.get({ user_id: $stateParams.userId });
        $q.all([getTasks, getStudent]).then(function (responses) {
            var response = responses[0];
            _this.tasks_last_page = response.data.last_page;
            var tasks = response.data.data.map(function (task) { return new Task_1.Task(task); });
            _this.AdminTasksService.setTasks(tasks);
            _this.totalItems = response.data.total;
            _this.tasks = _this.AdminTasksService.getTasks();
            $rootScope.$watch(function () { return AdminTasksService.tasks; }, function (newVal, oldVal) {
                _this.tasks = _this.AdminTasksService.getTasks();
            }, true);
            _this.student = responses[1];
            _this.loading = false;
        });
        $rootScope.$on("STUDENT_TASK_CREATED", function () {
            _this.filterTasks(_this.filteringBy, _this.currentPage, $stateParams.userId);
        });
        this.AdminTasksService.loadTasks({
            user_id: $stateParams.userId,
        }).then(function (response) {
            _this.handleTaskResponse(response);
        });
    }
    TasksController.prototype.handleTaskResponse = function (response) {
        this.tasks_last_page = response.data.last_page;
        this.currentPage = response.data.current_page;
        var tasks = response.data.data.map(function (task) { return new Task_1.Task(task); });
        this.totalItems = response.data.total;
        this.AdminTasksService.setTasks(tasks);
        this.tasks = this.AdminTasksService.getTasks();
    };
    TasksController.prototype.filterTasks = function (filterBy, page, userId) {
        var _this = this;
        if (filterBy === void 0) { filterBy = "all"; }
        if (page === void 0) { page = 1; }
        this.filteringBy = filterBy;
        this.AdminTasksService.filterTasks(filterBy, page, userId).then(function (response) {
            _this.handleTaskResponse(response);
        });
    };
    TasksController.prototype.pageChanged = function () {
        this.filterTasks(this.filteringBy, this.currentPage, this.$stateParams.userId);
    };
    return TasksController;
}());
TasksController.$inject = [
    "AdminTasksService",
    "$rootScope",
    "$state",
    "$stateParams",
    "CrmTasksModel",
    "FlashService",
    "UsersModel",
    "$q"
];
exports.default = TasksController;
