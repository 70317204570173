import {toQueryString} from '../../common/helpers/queryString'

function CouponsModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/coupons',
            UPDATE : APPURL + '/api/v1/coupons',
            CREATE : APPURL + '/api/v1/coupons'
        },
        tasks;

    function extract( result ) {
        return result.data;
    }

    function cacheResults(data) {
        data = extract(data);
        return data;
    }

    model.getByID = ( id ) => {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.get = (data) => {
        let url = URLS.FETCH;
        if(data !== null){
            url = URLS.FETCH + '?' + toQueryString(data);
        }
        return $http.get(url).then(cacheResults);
    };

    model.validateCoupon = (code) => {
        let url = URLS.FETCH;
        return $http.get(url + '/validate/' + code).then(cacheResults);
    };

    model.create = (coupon) => {
       return $http.post(URLS.FETCH, coupon).then(cacheResults)
    }

    model.update = ( request ) => {
        return $http.patch( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.destroy = ( coupon ) => {
        return $http.delete( URLS.FETCH + '/' + coupon.id ).then( cacheResults );
    }

    model.getProducts = function(coupon){
        return $http.get(URLS.FETCH + '/' + coupon.id + '/products').then(cacheResults);
    }

    model.addProduct = function(coupon, product){
        return $http.post(URLS.FETCH + '/' + coupon.id + '/products', {'product_id' : product.id}).then(cacheResults);
    }

    model.removeProduct = function(coupon, product){
        return $http.delete(URLS.FETCH + '/' + coupon.id + '/products/' + product.id).then(cacheResults);
    }

}
CouponsModel.$inject = ['$http', 'APPURL'];
export default CouponsModel;
