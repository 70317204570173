import moment from 'moment'

class AdminCalendarController {

  constructor($rootScope, $scope, CalendarService, DrivesFilterService, user, CalendarModel, $window, TimeoffRequestsModel, FlashService) {
      this.$rootScope = $rootScope;
      this.CalendarService = CalendarService;
      this.DrivesFilterService = DrivesFilterService;
      this.user = user;
      this.CalendarModel = CalendarModel;
      this.$window = $window;
      this.TimeoffRequestsModel = TimeoffRequestsModel;
      this.FlashService = FlashService;
      
      this.user = user;
      this.entities = [];
    
      this.loading = false
      this.calFullWidth = false;
      this.modalShown = false; //modal for RTOs
      this.rto = {}; //used for above modal

      this.updateEntities = this.updateEntities.bind(this);
      this.openRTOModal = this.openRTOModal.bind(this);
      this.closeRTOModal = this.closeRTOModal.bind(this);
      this.toggleCalFullWidth = this.toggleCalFullWidth.bind(this);
      this.deleteRTO = this.deleteRTO.bind(this)

      $rootScope.$on('UPDATE_CALENDAR', this.updateEntities);
      $scope.$on('OPEN_RTO_MODAL', this.openRTOModal);
      $rootScope.$on('CALENDAR_LOADING', () => {
          this.loading = true;
      });
  }

  updateEntities() {
    const entities = this.CalendarService.getCalendarItems();
    this.entities = entities
    this.loading = false;
  }

  openRTOModal(event, data) {
    this.modalShown = true;
    this.rto = data.event;
    this.rto.displayEnd = moment(this.rto.end).subtract(1, 'minute') //to show proper date in modal display
  }

  closeRTOModal() {
    this.modalShown = false;
    this.rto = {};
    this.CalendarService.getCalendarEvents({
      startDate: this.DrivesFilterService.getStartDate(),
      endDate: this.DrivesFilterService.getEndDate(),
      context: 'admin'
    })
  }

  deleteRTO(rto) { 
    const confirmDelete = this.$window.confirm(`Are you sure you want to delete this time off request? It cannot be undone.`);
    if (!confirmDelete) {
      return;
    }
    
    let request = {
      id: rto.id
    }
    
    this.TimeoffRequestsModel.delete(request).then(response => {
      this.FlashService.setMessage({
        'type': 'success',
        'message': response.message
      });
      this.closeRTOModal();
    })
  }

  toggleCalFullWidth() {
    this.calFullWidth = !this.calFullWidth
  }
  
}
AdminCalendarController.$inject = ['$rootScope', '$scope', 'CalendarService', 'DrivesFilterService', 'user', 'CalendarModel', '$window', 'TimeoffRequestsModel', 'FlashService'];
export default AdminCalendarController;
