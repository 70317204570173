import { toQueryString } from "../helpers/queryString"
import {isJson} from "../helpers/json";
function SchoolsModel( $http, APPURL ) {

    let model = this,
        URLS = {
            BASE : APPURL + '/api/v1',
            FETCH : APPURL + '/api/v1/schools',
            UPDATE : APPURL + '/api/v1/schools',
            CREATE : APPURL + '/api/v1/schools'

        },
        schools,
        school;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        schools = extract( result );
        if ( typeof schools === 'array' ) {
            schools.forEach( function ( school ) {
                school.address = isJson(school.address) ? JSON.parse( school.address ) : school.address;
            } );
        }
        return schools;
    }

    function cacheSchool( result ) {
        school = extract( result );
        school.address = isJson(school.address) ? JSON.parse( school.address ) : school.address;
        return school;
    }

    model.getSchoolByID = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheSchool );
    };

    model.getMap = function(id, data){
        if (data.student_id){
            return $http.get(URLS.FETCH + '/' + id + '/map?' + toQueryString(data)).then(response => {
                return response.data;
            });
        }
        return $http.get(URLS.FETCH + '/map').then(response => {
            return response.data;
        });
    }

    model.getSchools = function () {
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.createSchool = function ( school ) {
        return $http.post( URLS.CREATE, school ).then( cacheResults );
    };

    model.getSchoolByCode = function ( code ) {
        return $http.post( URLS.FETCH + '/code/' + code ).then( cacheResults );
    };

    model.getSchoolPlan = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id + '/plan' ).then( cacheResults );
    };
    model.getPaymentMethods = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id + '/payment-methods' ).then( cacheResults );
    };
    model.addPaymentMethod = function ( id, data ) {
        return $http.post( URLS.FETCH + '/' + id + '/payment-methods', data ).then( cacheResults );
    };
    model.getPaymentMethodIntent = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id + '/payment-methods/intent' ).then( cacheResults );
    };
    model.getSchoolCharges = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id + '/charges' ).then( cacheResults );
    };
    model.getSchoolInvoices = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id + '/invoices' ).then( cacheResults );
    };

    model.cancelSubscription = function ( id ) {
        return $http.delete( URLS.FETCH + '/' + id + '/subscriptions' ).then( cacheResults );
    };

    model.verifyUniqueCode = function ( code ) {
        return $http.post( URLS.FETCH + '/unique/code', { 'code' : code } ).then( cacheResults );
    };

    model.editSchool = function ( school ) {
        return $http.put( URLS.CREATE + '/' + school.id, school ).then( cacheResults );
    };

    model.getTasks = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id + '/tasks' ).then( cacheResults );
    };

    model.addTask = function ( data ) {
        return $http.post( URLS.FETCH + '/' + data.school_id + '/tasks', data ).then( cacheResults );
    };

    model.updateTasks = function ( data ) {
        return $http.put( URLS.FETCH + '/' + data.school_id + '/tasks', data ).then( cacheResults );
    };

    model.getHighSchools = function ( data ) {
        return $http.post( URLS.BASE + '/highschools/find', { 'location' : data } ).then( cacheResults );
    };

    model.previewServiceAreaForLocation = function(data){
        return $http.get(URLS.BASE + '/preview-service-area?' + toQueryString(data)).then(cacheResults);
    };

    model.addAdhocPickupLocation = (data) => {
        return $http.post( URLS.FETCH + '/' + data.school_id + '/ad-hoc-pickup-locations', { 'location' : data } ).then( cacheResults );
    };

    model.updateAdhocPickupLocation = (school_id, location_id, data) => {
        return $http.put( URLS.FETCH + '/' + school_id + '/ad-hoc-pickup-locations/' + location_id, data).then( cacheResults );
    };

    model.getAdhocPickupLocations = (school_id, params = {}) => {
        if (params.length === 0) {
            return $http.get( URLS.FETCH + '/' + school_id + '/ad-hoc-pickup-locations').then( cacheResults );
        }
        return $http.get( URLS.FETCH + '/' + school_id + '/ad-hoc-pickup-locations?' + toQueryString(params)).then( cacheResults );
    };

    model.removeAddhocPickupLocation = (data) => {
       return $http.delete(URLS.FETCH + '/' + data.school_id + '/ad-hoc-pickup-locations/' + data.location_id).then(cacheResults);
    }

}
SchoolsModel.$inject = ['$http', 'APPURL'];
export default SchoolsModel;
