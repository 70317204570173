import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import DriveDeadlineForm from './DriveDeadlineForm.vue'

export default angular.module( 'admin.dashboard.students.view.drivedeadline', [])
    .directive('driveDeadline', ['createVueComponent', function (createVueComponent) {
      return createVueComponent(Vue.component('driveDeadline', DriveDeadlineForm))
    }])
;
