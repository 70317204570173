"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminStudentViewRequirementsController = /** @class */ (function () {
    function AdminStudentViewRequirementsController($state, $stateParams, FlashService, UsersModel, $q, $window, lessonPlans, sessionTypes, courseTypes) {
        var _this = this;
        this.FlashService = FlashService;
        this.UsersModel = UsersModel;
        this.loading = true;
        this.lessonPlans = lessonPlans;
        this.requirements = [];
        this.sessionTypes = sessionTypes;
        this.courseTypes = courseTypes;
        this.requirementTypes = [
            { name: "Permit", value: "permit" },
            { name: "Course", value: "course_type" },
            { name: "Session Type", value: "session_type" },
        ];
        this.$window = $window;
        this.$stateParams = $stateParams;
        this.displayLessonPlanType = this.displayLessonPlanType.bind(this);
        var getStudent = UsersModel.getStudentByID($stateParams.userId);
        var getRequirements = UsersModel.requirements($stateParams.userId);
        $q.all([getRequirements, getStudent]).then(function (responses) {
            var response = responses[0];
            var requirements = response.requirements;
            _this.requirements = requirements;
            _this.student = responses[1];
            _this.loading = false;
        });
    }
    AdminStudentViewRequirementsController.prototype.displayLessonPlanType = function (type) {
        if (type === "course_type") {
            return "Course Type";
        }
        if (type === "session_type") {
            return "Session Type";
        }
        if (type === "permit") {
            return "Permit";
        }
        return "";
    };
    AdminStudentViewRequirementsController.prototype.removeFromLessonPlan = function (index, requirement) {
        var _this = this;
        var $confirm = this.$window.confirm("Are you sure you want to delete this rule?");
        if ($confirm === false) {
            return;
        }
        this.UsersModel.removeRequirement(this.$stateParams.userId, requirement.id).then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.requirements = _this.requirements
                .filter(function (req) {
                return requirement.id !== req.id;
            })
                .sort(function (a, b) { return (a.sequence > b.sequence ? 1 : -1); });
        });
    };
    AdminStudentViewRequirementsController.prototype.addRequirement = function (requirement) {
        var _this = this;
        if (!requirement) {
            return;
        }
        var $confirm = this.$window.confirm("Are you sure you want to add this rule?");
        if ($confirm === false) {
            return;
        }
        var requestBody = {};
        if (requirement.type === "course_type") {
            requirement.course_type_id = requirement.courseType.id;
            requestBody = {
                sequence: requirement.sequence,
                type: requirement.type,
                course_type_id: requirement.course_type_id,
                events: requirement.events,
            };
        }
        if (requirement.type === "session_type") {
            requirement.minutes_required = requirement.hours_required * 60;
            requirement.session_type_id = requirement.sessionType.id;
            requestBody = {
                sequence: requirement.sequence,
                type: requirement.type,
                session_type_id: requirement.session_type_id,
                minutes_required: requirement.minutes_required,
                events: requirement.events,
            };
        }
        if (requirement.type === "permit") {
            requestBody = {
                sequence: requirement.sequence,
                type: requirement.type,
                days: requirement.days,
                events: requirement.events,
            };
        }
        this.UsersModel.addRequirement(this.$stateParams.userId, requestBody).then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.requirements.push(response.requirement);
            _this.requirements = _this.requirements.sort(function (a, b) {
                return a.sequence > b.sequence ? 1 : -1;
            });
            _this.requirement = {};
        });
    };
    return AdminStudentViewRequirementsController;
}());
AdminStudentViewRequirementsController.$inject = [
    "$state",
    "$stateParams",
    "FlashService",
    "UsersModel",
    "$q",
    "$window",
    "lessonPlans",
    "sessionTypes",
    "courseTypes",
];
exports.default = AdminStudentViewRequirementsController;
