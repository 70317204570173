function dcCourseTypesController(UserService, CourseTypesModel, FlashService) {

    const vm = this;
    vm.currentUser = UserService.getCurrentUser();

    vm.addCourseType = addCourseType;
    vm.updatePayRatio = updatePayRatio;

    init();

    function init() {
        getCourseTypes();
    }

    function getCourseTypes() {
        CourseTypesModel.get().then(response => {
            vm.courseTypes = response.courseTypes;
        })
    }

    function addCourseType() {
        CourseTypesModel.insert({'name': vm.courseTypeName}).then(response => {
            vm.courseTypes = vm.courseTypes.concat(response.courseType);
            vm.courseTypeName = null;
            FlashService.setMessage({'type': 'success', 'message': response.message});
        });
    }

    function updatePayRatio(courseType) {
        vm.updating = true;
        CourseTypesModel.update(courseType).then(response => {
            FlashService.setMessage({'type': 'success', 'message': response.message});
            vm.updating = false;
        });
    }

}
dcCourseTypesController.$inject = ['UserService', 'CourseTypesModel', 'FlashService'];

export default dcCourseTypesController;
