import angular from 'angular'
import StudentDrivesViewReportCardController from './student-drive-report-card.controller'
import template from './student-drive-report-card.html'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.drives.view.reportcard', {
            'url' : '/report-card',
            'controller' : StudentDrivesViewReportCardController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.student,
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.drives.view.reportcard', [] )
    .config( config )
    .controller( 'StudentDrivesViewReportCardController', StudentDrivesViewReportCardController );
