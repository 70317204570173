import angular from 'angular'
import template from './create-adhoc-pickup-location.html'
import controller from './create-adhoc-pickup-location.controller'
function CreateAdhocPickupLocation() {
    return {
        restrict: 'E',
        scope: {
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.createadhocpickuplocation', [])
    .controller('CreateAdhocPickupLocationController', controller)
    .directive('createAdhocPickupLocation', CreateAdhocPickupLocation);

