"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var ReportBuilder = /** @class */ (function () {
    function ReportBuilder($http, APPURL) {
        var _this = this;
        this.getReportByID = function (id) {
            return _this.$http.get(_this.URLS.FETCH + '/' + id).then(_this.cacheResults);
        };
        this.getReports = function (data) {
            if (data === void 0) { data = null; }
            if (data !== null) {
                return _this.$http.get(_this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(_this.cacheResults);
            }
            return _this.$http.get(_this.URLS.FETCH).then(_this.cacheResults);
        };
        this.updateReport = function (request, data) {
            return _this.$http.put(_this.URLS.FETCH + '/' + request.id, data).then(_this.cacheResults);
        };
        this.downloadReport = function (report) {
            return _this.$http.get(_this.URLS.FETCH + '/' + report.id + '/download').then(_this.cacheResults);
        };
        this.createReport = function (request) {
            return _this.$http.post(_this.URLS.FETCH, request).then(_this.cacheResults);
        };
        this.destroyReport = function (report) {
            return _this.$http.delete(_this.URLS.FETCH + '/' + report.id).then(_this.cacheResults);
        };
        this.getReportBuilderConfig = function () {
            return _this.$http.get(_this.URLS.FETCH + '/builder').then(_this.cacheResults);
        };
        this.getPreview = function (config) {
            return _this.$http.post(_this.URLS.FETCH + '/builder', config).then(_this.cacheResults);
        };
        this.$http = $http;
        this.APPURL = APPURL;
        this.URLS = {
            FETCH: APPURL + '/api/v1/reports',
            UPDATE: APPURL + '/api/v1/reports',
            CREATE: APPURL + '/api/v1/reports'
        };
        this.tasks = [];
        this.cacheResults = this.cacheResults.bind(this);
        this.extract = this.extract.bind(this);
    }
    ReportBuilder.prototype.extract = function (result) {
        return result.data;
    };
    ReportBuilder.prototype.cacheResults = function (result) {
        this.tasks = this.extract(result);
        return this.tasks;
    };
    return ReportBuilder;
}());
ReportBuilder.$inject = ['$http', 'APPURL'];
exports.default = ReportBuilder;
