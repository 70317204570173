import angular from 'angular'
import template from './usage-reports.html'
import UsageReportsController from './usage-reports.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.usagereport', {
            url : '/usage-report',
            controller : UsageReportsController,
            controllerAs: 'vm',
            template: template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.usagereport', [] )
    .config(config)
    .controller( 'UsageReportsController', UsageReportsController )
;
