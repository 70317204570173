import angular from 'angular';
import template from './student-metadata.html';
import controller from './student-metadata.controller';

function getStudentMetaDataPolicy(PoliciesModel) {
    return PoliciesModel.getByID('studentmetadata').then(response => {
        let policy = {
            id: 0,
            studentmetadata: {
                preferred_pronoun: false
            }
        }
        const metaData = response.policy.policymeta.find(meta => {
            return meta.meta_key === "preferred_pronoun";
        });
        policy.id = response.policy.id;
        policy.studentmetadata = {
            preferred_pronoun: metaData.meta_value
        }
        return policy;
    });
}
getStudentMetaDataPolicy.$inject = ['PoliciesModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.settings.policies.studentmetadata', {
            url : '/student-metadata',
            controller : controller,
            controllerAs : 'vm',
            template: template,
            resolve: {
                studentmetadata: getStudentMetaDataPolicy
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.settings.policies.studentmetadata', [] )
    .config(config)
    .controller( 'AdminSettingsPoliciesStudentMetaDataController', controller)
;
