import moment from 'moment'
import {isJson} from '../helpers/json'
import { Coupon } from '../../classes/Coupon'

class OrderDetailsService {
    constructor(UserService, $rootScope, CrmTasksModel, OrdersModel, PayPalService){
        this.order = {};
        this.currentUser = UserService.getCurrentUser();
        this.$rootScope = $rootScope;
        this.CrmTasksModel = CrmTasksModel;
        this.OrdersModel = OrdersModel;
        this.PayPalService = PayPalService;
        this.details = {}
        this.billingConfig = {}
        this.payment = {}
    }

    submitPayment() {
        //use this.setPayment in component to set payment before calling this submit function
        if(this.billingConfig.gateway === 'paypal' && this.details.hasOwnProperty('id')) {
            this.payment.charge_id = this.details.id;
        }
        return this.OrdersModel.submitPayment({'order_id' : this.payment.order_id, 'payment': this.payment})
    }

    configureBilling(response) {
        let billingConfig = {
            policyMeta: {},
            gateway: '',
            gateway_config: {}
        };
        response.policy.policymeta.forEach(meta => {
            billingConfig.policyMeta[meta.meta_key] = meta.meta_value;
        });
        billingConfig.policyMeta.id = response.policy.id;

        billingConfig.gateway = billingConfig.policyMeta.billing_gateway;
        billingConfig.gateway_config = {
            paypal_rest_api_client_id : billingConfig.policyMeta.paypal_rest_api_client_id
        }
        this.setBillingConfig(billingConfig)
        return billingConfig;
    }

    setupOrderDetails(response, payment) {
        let order = response.data.order;
        order.student = payment.student;
        order.payments = response.data.payments;
        order.lineitems = order.lineitems.map(lineitem => {
            lineitem.product_details = isJson(lineitem.product_details) ? JSON.parse(lineitem.product_details) : lineitem.product_details;
            return lineitem;
        })
        const coupons = [].concat(order.coupons).map(coupon => {
            return new Coupon(coupon);
        })
        order.created_at = moment(order.created_at).format('MM/DD/YYYY h:mm a');
        order.coupons = coupons;
        this.setOrder(order);
        return order;
    }

    setOrder(data){
        this.order = data;
    }

    clearOrder(){
        this.order = {}
    }
    
    getOrder() {
        return this.order;
    }
    
    setPayment(data){
        this.payment = data;
    }

    clearPayment(){
        this.payment = {}
    }
    
    getPayment() {
        return this.payment;
    }

    setPayPalDetails(details) {
        this.details = details
    }
    getPayPalDetails() {
        return this.details
    }
    handlePayPalPayment(details) {
        // console.log('PaPal Payment Details: ', details)
        // this.setPayPalDetails(details) //save details
        // this.submitPayment(payment);
    }
    setBillingConfig(config) {
        this.billingConfig = config
    }
}
OrderDetailsService.$inject = ['UserService', '$rootScope', 'CrmTasksModel', 'OrdersModel', 'PayPalService'];
export default OrderDetailsService;