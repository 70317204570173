export const Auth = angular.module('constants.auth', [])
    .constant("CSRF_TOKEN", '')
    .constant('AUTH_EVENTS', {
        loginSuccess: 'auth-login-success',
        loginFailed: 'auth-login-failed',
        logoutSuccess: 'auth-logout-success',
        sessionTimeout: 'auth-session-timeout',
        notAuthenticated: 'auth-not-authenticated',
        conflict: 'conflict',
        notAuthorized: 'auth-not-authorized',
        authorized: 'authorized',
    })
    .constant('USER_ROLES', {
        all: '*',
        admin: 'admin',
        student: 'student',
        parent: 'parent',
        instructor: 'instructor',
        owner: 'owner',
        administrator: 'administrator',
        scheduling_manager: 'scheduling_manager',
        vehicle_manager: 'vehicle_manager',
        super_admin : 'super_admin',
        staff: 'staff',
        guest: 'guest'
    })
;