"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FlashService = /** @class */ (function () {
    function FlashService($rootScope) {
        this.$rootScope = $rootScope;
        this.message = '';
        this.type = '';
        this.disappearSetting = false;
    }
    FlashService.prototype.getMessage = function () {
        return this.message;
    };
    FlashService.prototype.setMessage = function (newMessage) {
        this.message = newMessage.message;
        this.type = newMessage.type;
        this.$rootScope.$broadcast('NEW_FLASH_MESSAGE');
    };
    FlashService.prototype.getType = function () {
        return this.type;
    };
    FlashService.prototype.setType = function (newType) {
        this.type = newType;
    };
    FlashService.prototype.toggleDisappearSetting = function () {
        this.disappearSetting = !this.disappearSetting;
    };
    FlashService.prototype.setDisappearSetting = function (setting) {
        this.disappearSetting = setting;
    };
    FlashService.prototype.getDisappearSetting = function () {
        return this.disappearSetting;
    };
    FlashService.prototype.clearFlash = function () {
        this.$rootScope.$broadcast('CLEAR_FLASH_MESSAGE');
    };
    return FlashService;
}());
FlashService.$inject = ['$rootScope'];
exports.default = FlashService;
