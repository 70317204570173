"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EditNoteController = /** @class */ (function () {
    function EditNoteController($stateParams, student, note, $sce, UsersModel, FlashService) {
        this.$stateParams = $stateParams;
        this.student = student;
        this.note = note;
        this.UsersModel = UsersModel;
        this.notifications = [];
        this.submitting = false;
        this.$sce = $sce;
        this.FlashService = FlashService;
        this.formatNoteContent = this.formatNoteContent.bind(this);
        this.handleEditNoteChange = this.handleEditNoteChange.bind(this);
        this.submit = this.submit.bind(this);
    }
    EditNoteController.prototype.handleEditNoteChange = function (data) {
        this.note.content = data.html;
    };
    EditNoteController.prototype.formatNoteContent = function (html) {
        return this.$sce.trustAsHtml(html);
    };
    EditNoteController.prototype.submit = function () {
        var _this = this;
        this.submitting = true;
        this.UsersModel.editStudentNote(this.student.id, this.note.id, this.note).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.submitting = false;
        }).catch(function (Error) {
            _this.submitting = false;
        });
    };
    return EditNoteController;
}());
EditNoteController.$inject = ["$stateParams", "student", "note", "$sce", "UsersModel", "FlashService"];
exports.default = EditNoteController;
