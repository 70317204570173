"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var time_1 = require("../../../common/helpers/time");
var saveToDisk_1 = require("../../../common/helpers/saveToDisk");
var StudentAccountEditPermitController = /** @class */ (function () {
    function StudentAccountEditPermitController($state, UserService, UsersModel, FlashService, PermitModel, permitData, FilesModel) {
        this.initialLoad = true;
        this.$state = $state;
        this.UserService = UserService;
        this.UsersModel = UsersModel;
        this.PermitModel = PermitModel;
        this.FlashService = FlashService;
        this.submitted = false;
        this.FilesModel = FilesModel;
        this.files = permitData.files;
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.permit = {
            'number': '',
            'issue_date': '',
            'expiration_date': ''
        };
        if (permitData.permit.number) {
            this.permit.number = permitData.permit.number;
        }
        if (permitData.permit.issuedate) {
            this.permit.issue_date = (0, time_1.dateStringToDate)(permitData.permit.issuedate);
        }
        if (permitData.permit.expirationdate) {
            this.permit.expiration_date = (0, time_1.dateStringToDate)(permitData.permit.expirationdate);
        }
        this.updateIssueDate = this.updateIssueDate.bind(this);
        this.updateExpirationDate = this.updateExpirationDate.bind(this);
    }
    StudentAccountEditPermitController.prototype.formatDate = function (datestring) {
        return new Date(datestring).toLocaleDateString("en-US", {
            day: "2-digit", "month": "2-digit", "year": "numeric"
        });
    };
    StudentAccountEditPermitController.prototype.deleteFile = function (id) {
        var _this = this;
        this.FilesModel.destroy(id).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.files = _this.files.filter(function (file) {
                return file.id !== id;
            });
        }).catch(function (Error) {
        });
    };
    StudentAccountEditPermitController.prototype.downloadFile = function (id) {
        var _this = this;
        this.FilesModel.get(id).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            (0, saveToDisk_1.saveToDisk)(response.temporary_url);
        }).catch(function (Error) {
        });
    };
    StudentAccountEditPermitController.prototype.handleFileUpload = function (formData) {
        var _this = this;
        formData.append("students", [this.UserService.getCurrentUser().id]);
        this.FilesModel.uploadFile("permit", "permit", formData).then(function (response) {
            _this.files.push(response.file);
        }).catch(function (Error) {
        });
    };
    StudentAccountEditPermitController.prototype.updateIssueDate = function (newDate) {
        this.permit.issue_date = newDate.selected[0];
    };
    StudentAccountEditPermitController.prototype.updateExpirationDate = function (newDate) {
        this.permit.expiration_date = newDate.selected[0];
    };
    StudentAccountEditPermitController.prototype.updatePermit = function () {
        var _this = this;
        this.submitted = true;
        var permit = Object.assign({}, this.permit);
        this.PermitModel.update(this.UserService.getCurrentUser().id, permit).then(function (response) {
            _this.submitted = false;
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.$state.go('drivecoach.student.dashboard');
        }).catch(function (error) {
            _this.submitted = false;
        });
    };
    return StudentAccountEditPermitController;
}());
StudentAccountEditPermitController.$inject = ['$state', 'UserService', 'UsersModel', 'FlashService', 'PermitModel', 'permitData', 'FilesModel'];
exports.default = StudentAccountEditPermitController;
