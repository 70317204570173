"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var saveToDisk_1 = require("../../common/helpers/saveToDisk");
var ReportBuilderController = /** @class */ (function () {
    function ReportBuilderController($window, $rootScope, $state, FlashService, ReportBuilder) {
        var _this = this;
        this.$window = $window;
        this.$state = $state;
        this.FlashService = FlashService;
        this.loading = true;
        this.page_by = 10;
        this.totalItems = 0;
        this.currentPage = 1;
        this.ReportBuilder = ReportBuilder;
        this.data = {};
        this.reports = [];
        this.ReportBuilder.getReports().then(function (response) {
            _this.reports = response.reports.data;
            _this.currentPage = response.reports.current_page;
            _this.totalItems = response.reports.total;
        });
        this.ReportBuilder.getReportBuilderConfig().then(function (response) {
            _this.data = response.data;
            _this.loading = false;
        }).catch(function (Error) {
            _this.loading = false;
        });
    }
    ReportBuilderController.prototype.downloadReport = function (report) {
        var _this = this;
        this.ReportBuilder.downloadReport(report).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            (0, saveToDisk_1.saveToDisk)(response.url);
        });
    };
    ReportBuilderController.prototype.deleteReport = function (report) {
        var _this = this;
        this.ReportBuilder.destroyReport(report).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.ReportBuilder.getReports({ page: _this.currentPage }).then(function (response) {
                _this.reports = response.reports.data;
                _this.currentPage = response.reports.current_page;
                _this.totalItems = response.reports.total;
            });
        });
    };
    ReportBuilderController.prototype.pageChange = function () {
        var _this = this;
        this.ReportBuilder.getReports({ page: this.currentPage }).then(function (response) {
            _this.reports = response.reports.data;
            _this.currentPage = response.reports.current_page;
            _this.totalItems = response.reports.total;
        });
    };
    return ReportBuilderController;
}());
ReportBuilderController.$inject = [
    "$window",
    "$rootScope",
    "$state",
    "FlashService",
    "ReportBuilder",
];
exports.default = ReportBuilderController;
