import {States} from "../../../../../common/constants/states";

class StudentLocationsCreateResidenceController {

    constructor($state, $scope, UsersModel, UserService, FlashService) {
        this.$state = $state;
        this.$scope = $scope;
        this.UsersModel = UsersModel;
        this.UserService = UserService;
        this.FlashService = FlashService;
        this.states = States;

        this.student = UserService.getCurrentUser();
        this.student.pickuplocation = {};
        this.hasPickupLocation = false;
        this.submitted = false;
    }

    submitPickupLocation() {
        let location = this.student.pickuplocation;

        this.submitted = true;
        this.UsersModel.addPickupLocation({
            'student_id': this.student.id,
            'pickuplocation': {
                'name' : location.name,
                'street': location.street,
                'city': location.city,
                'state': location.state,
                'zip': location.zip
            }
        }).then(response => {
            let type = 'success';
            if(response.message === 'Your pick up location was not in the school\'s service area. Your pick up location is the driving school.') { type = 'info' } 
            this.FlashService.setMessage({
                'type': type,
                'message': response.message
            });
          
            if (type === 'info') {
              //dont change state until info alert is closed
              this.$scope.$on('MESSAGE_CLEARED', () => {
                this.submitted = false;
                this.$state.go('drivecoach.student.dashboard.locations.index', {}, {'reload': true});
              })
            } else {
              this.submitted = false;
              this.$state.go('drivecoach.student.dashboard.locations.index', {}, {'reload': true});
            }
            
        }).catch(Error => {
            this.submitted = false;
        });
    }

}

StudentLocationsCreateResidenceController.$inject = ['$state', '$scope', 'UsersModel', 'UserService', 'FlashService'];
export default StudentLocationsCreateResidenceController;
