import {isJson} from "../../../common/helpers/json";
import {States} from "../../../common/constants/states";

class NewCustomPickupLocationModalController {

    constructor($rootScope, UserService, FlashService, SchoolsModel, $uibModalInstance, data){
        this.pickuplocation = {
            'hidden' : true
        };
        this.locationType = data.locationType;
        this.states = States;
        this.currentUser = UserService.getCurrentUser();
        this.FlashService = FlashService;
        this.SchoolsModel = SchoolsModel;
        this.$uibModalInstance = $uibModalInstance;
        this.ok = this.close.bind(this);
    }

    submitPickupLocation() {
        this.close({
            'school_id' : this.currentUser.school_id,
            'name' : this.pickuplocation.name,
            'address' : {
                'street': this.pickuplocation.street,
                'city': this.pickuplocation.city,
                'state': this.pickuplocation.state,
                'zip': this.pickuplocation.zip,
            },
            'is_adhoc' : true,
            'save' : this.pickuplocation.save
        });
        this.pickuplocation = {};
    }

    close(data) {
        this.$uibModalInstance.close(data);
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
NewCustomPickupLocationModalController.$inject = ['$rootScope','UserService', 'FlashService','SchoolsModel','$uibModalInstance', 'data'];
export default NewCustomPickupLocationModalController;