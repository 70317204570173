import angular from 'angular'
import template from './admin-view-staff.html'
import AdminStaffViewController from './admin-view-staff.controller'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.staff.view', {
            'url' : '/:staffId/view',
            'controller' : AdminStaffViewController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.staff.view', [] )
    .config(config)
    .controller( 'AdminStaffViewController', AdminStaffViewController )
;
