<template>
    <div :class="flashStyle" v-if="isVisible">
    <button ng-if="type !== 'info'" type="button" class="close" data-dismiss="alert" aria-label="Close" data-cy="closeFlash" ng-click="closeFlash()">
          <span aria-hidden="true">&times;</span>
    </button>
    <p data-cy="flashContent">{{ message }}</p>
    <button ng-if="type === 'info'" type="button" class="btn btn-light mt-10" data-dismiss="alert" aria-label="Continue" data-cy="closeFlash" ng-click="closeInfoFlash()">
      Continue
    </button>
</div>
</template>
<script>
export default {
    data() {
        return {
            isVisible : false,
            type: "",
            message: ""
        }
    },
    methods: {
        newFlash() {
            this.message = this.$store.state.flashMessage.message;
            this.type = this.$store.state.flashMessage.type;
            this.isVisible = true;
            if ( this.type === 'success' ) {
                return this.$timeout( () => {
                    this.isVisible = false;
                    this.type = '';
                    return this.message = '';
                }, 7000 );
            }
        },

        closeFlash() {
            this.isVisible = false;
            this.type = '';
            return this.message = '';
        },
    
        closeInfoFlash() {
            this.closeFlash();
            this.$emit('MESSAGE_CLEARED')
        }
    },
    computed: {
        flashStyle: function() {
            return `alert alert-${type} fade in`
        }
    }
}
</script>