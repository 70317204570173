"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StudentOnlineDriversEdSuccessController = /** @class */ (function () {
    function StudentOnlineDriversEdSuccessController($rootScope, $state, PoliciesModel, $sce) {
        var _this = this;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.PoliciesModel = PoliciesModel;
        this.successMessage = "";
        this.$sce = $sce;
        PoliciesModel.getByID('onlinedriversed').then(function (response) {
            _this.successMessage = _this.$sce.trustAsHtml(response.policy.policymeta.find(function (meta) { return meta.meta_key === "success_message"; }).meta_value);
        });
    }
    return StudentOnlineDriversEdSuccessController;
}());
StudentOnlineDriversEdSuccessController.$inject = ["$rootScope", "$state", "PoliciesModel", "$sce"];
exports.default = StudentOnlineDriversEdSuccessController;
