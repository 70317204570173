import angular from 'angular'
import controller from './add-fee.controller'
import template from './add-fee.html'

function dsAddFee() {
    return {
        restrict : 'E',
        scope : {
            'student' : '=',
            'fees' : '='
        },
        template : template,
        link :  (scope, element, attrs ) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}
dsAddFee.$inject = [];

export default angular.module( 'admin.directives.addfee', [] )
    .controller( 'dsAddFeeController', controller )
    .directive('addFee', dsAddFee)
;
