import { inDollars } from '../../../../DriveScout/Billing/Teller';
import { saveToDisk } from '../../helpers/saveToDisk';

class OrderController {
    constructor(OrdersModel, FlashService, OrderDetailsService, PoliciesModel, UserService, UsersModel, VueStoreFactory){
        this.OrdersModel = OrdersModel;
        this.FlashService = FlashService;
        this.OrderDetailsService = OrderDetailsService;
        this.PoliciesModel = PoliciesModel;
        this.UsersModel = UsersModel;
        this.VueStoreFactory = VueStoreFactory;

        this.loading = true;
        this.submitting = false;
        this.modalShown = false;
        this.billingConfig = {}; //used for order details modal
        this.clearPaymentForm = false;
        this.currentUser = UserService.getCurrentUser();

        this.showOrderDetails = this.showOrderDetails.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.submitPayment = this.submitPayment.bind(this)
        this.voidPayment = this.voidPayment.bind(this)
        this.voidOrder = this.voidOrder.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
        this.printOrder = this.printOrder.bind(this);
    }

    centsToDollars(cents) {
        return inDollars(cents);
    }

    showOrderDetails(order) {
        this.OrdersModel.getDetails({id: order.id}).then(response => { 
            this.order = this.OrderDetailsService.setupOrderDetails(response, order); 

            const billing = this.VueStoreFactory.state.policies.policies.find(policy => policy.name === 'billing');
            const drives = this.VueStoreFactory.state.policies.policies.find(policy => policy.name === 'drives');
            const resp = {
                policy: {
                    id : billing.id,
                    policymeta: [...drives.policymeta, ...billing.policymeta]
                }
            }
            this.billingConfig = this.OrderDetailsService.configureBilling(resp); 
            if (this.billingConfig.gateway === 'paypal') {
                // console.log('Configuring PayPal Gateway');
                this.configurePaypal(order, billingConfig);
            }
            this.clearPaymentForm = false; 
            this.modalShown = true;
            this.loading = false; 
        }) 
    }

    closeModal() {
        this.modalShown = false;
        this.clearPaymentForm = true;
    }

    submitPayment(data) {
        let payment = data.payment;
        this.submitting = true;
        this.OrderDetailsService.setPayment(payment)
        this.OrderDetailsService.submitPayment().then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.order.payments.push(response.payment);
            this.submitting = false;
            this.order.paid = response.order.paid;
            this.order.notes = response.order.notes;
            this.clearPaymentForm = true;
        }).catch(Error => {
            this.submitting = false;
        })
    }

    voidPayment(data) {
        let { payment_id, index } = data;
        this.submitting = true;
        this.UsersModel.voidPayment(this.order.student.id, payment_id).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.order.payments.splice(index, 1, response.payment)
        }).catch(Error => {
            this.submitting = false;
        });
    }

    voidOrder(data) {
        this.submitting = true;
        this.OrdersModel.voidOrder(data.order_id).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.order.status = 'VOID';
        }).catch(Error => {
            this.submitting = false;
        });
    }

    sendOrder(data) {
        this.submitting = true;
        this.OrdersModel.sendOrder(data.order_id, {'recipient': data.receipt_email}).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        }).catch(Error => {
            this.submitting = false;
        });
    }

    printOrder(data) {
        this.submitting = true;
        this.OrdersModel.printOrder(data.order_id).then(response => {
            this.submitting = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            saveToDisk(response.url);
        }).catch(Error => {
            this.submitting = false;
        });
    }
}
OrderController.$inject = ['OrdersModel', 'FlashService', 'OrderDetailsService', 'PoliciesModel', 'UserService', 'UsersModel', 'VueStoreFactory'];
export default OrderController;