import angular from 'angular'
import Vue from 'vue';
import 'ngVue';
  
import dcMiniCalendarAgendaVue from './MiniCalendarAgenda.vue'

export default angular.module( 'directives.minicalendaragendavue', [] )
  .directive('dcMiniCalendarAgendaVue', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcMiniCalendarAgendaVue', dcMiniCalendarAgendaVue))
  }])
