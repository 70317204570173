class ProductCourseTypesController {
    constructor(CourseTypesModel){
        this.coursetypes = [];
        this.coursetype = {};
        this.hours = null;
        CourseTypesModel.get().then( response => {
            this.coursetypes = response.courseTypes;
            if (this.coursetypes.length > 0) {
            	this.coursetype = this.coursetypes[0];
            }
        })
    }

    addCourseTypeToProduct(){
        this.coursetype.hours = this.hours * 60;
        this.product.coursetypes.push(Object.assign({}, this.coursetype));
        this.coursetype.hours = null;
    }

    removeCourseType(index){
        this.product.coursetypes.splice(index, 1);
    }
}
ProductCourseTypesController.$inject = ['CourseTypesModel'];
export default ProductCourseTypesController;
