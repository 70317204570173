import angular from 'angular'
import Vue from 'vue';
import 'ngVue';
  
import dcCourseAttendance from './CourseAttendance.vue'

export default angular.module( 'directives.courseattendance', [] )
  .directive('dcCourseAttendance', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcCourseAttendance', dcCourseAttendance))
  }])
