function InstructorEventController($scope, UsersModel, ClassroomsModel, VehiclesModel, event, UserService, LocationsModel) {

    const vm = this;

    vm.event = event;
    vm.vehicles = [];
    vm.instructors = [];
    vm.classrooms = [];

    vm.getInstructors = getInstructors;
    vm.getClassrooms = getClassrooms;
    vm.getVehicles = getVehicles;
    vm.updateForm = updateForm;
    vm.clearForm = clearForm;
    vm.clearField = clearField;

    init();

    function init(){
        LocationsModel.getLocations().then(response => {
            // set initial location object for ng-options
            vm.locations = response;
            vm.locations.forEach( location => {
                if (location.id === event.location.id) {
                    vm.event.location = location;
                }
            } );

            const variableData = ['vm.event.date', 'vm.event.time', 'vm.event.duration.hours', 'vm.event.duration.minutes'];
            $scope.$watchGroup( variableData , ( newValues, oldValues ) => {
                if ( vm.event.date && vm.event.time && vm.event.duration && vm.event.location && newValues != oldValues ) {
                    clearForm();
                    updateForm();
                }
            } );
        })
    }

    function getInstructors( name, all ) {

        const params = {
            'location' : vm.event.location
        };

        if ( name ) {
            params.name = name;
        }

        if ( false === all ) {
            params.date = {
                'date' : moment( vm.event.date ).format( 'YYYY-MM-DD' ),
                'time' : moment( vm.event.time ).format( 'HH:mm:ss' ),
                'duration' : ( parseInt( vm.event.duration.hours ) * 60 ) + parseInt( vm.event.duration.minutes )
            }
        }

        params.event = vm.event;

        params.exclude = {
            'events' : [vm.event]
        };

        UsersModel.searchInstructors( params ).then( response => {
            vm.instructors = response.instructors;
        } );

    }

    function getClassrooms( name, all ) {

        const params = {
            'location' : vm.event.location
        };

        if ( false === all ) {
            params.date = {
                'date' : moment( vm.event.date ).format( 'YYYY-MM-DD' ),
                'time' : moment( vm.event.time ).format( 'HH:mm:ss' ),
                'duration' : ( parseInt( vm.event.duration.hours ) * 60 ) + parseInt( vm.event.duration.minutes )
            }
        }

        if ( name ) {
            params.name = name;
        }

        params.event = vm.event;

        params.exclude = {
            'events' : [vm.event]
        };

        ClassroomsModel.search( params ).then( response => {
            vm.classrooms = _.values( response.classrooms );
        } );
    }

    function getVehicles( name, all ) {

        const params = {
            'location' : vm.event.location,
            'status' : 'available'
        };

        if ( name ) {
            params.name = name;
        }

        if ( false === all ) {
            params.date = {
                'date' : moment( vm.event.date ).format( 'YYYY-MM-DD' ),
                'time' : moment( vm.event.time ).format( 'HH:mm:ss' ),
                'duration' : ( parseInt( vm.event.duration.hours ) * 60 ) + parseInt( vm.event.duration.minutes )
            }
        }

        params.event = vm.event;

        params.exclude = {
            'events' : [vm.event]
        };

        VehiclesModel.searchVehicles( params ).then( response => {
            vm.vehicles = response.vehicles;
        } );
    }

    function updateForm() {
        getClassrooms( null, false );
        getInstructors( null, false );
        getVehicles( null, false );
    }

    function clearForm() {
        vm.event.classroom = null;
        vm.event.instructor = null;
        vm.event.vehicle = null;
    }

    function clearField( field ) {
        vm.event[field] = null;
    }

}
InstructorEventController.$inject = ['$scope', 'UsersModel', 'ClassroomsModel', 'VehiclesModel', 'event', 'UserService', 'LocationsModel'];

export default InstructorEventController;