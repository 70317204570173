import angular from 'angular'
import InstructorRequestTimeOffController from './instructor-request-time-off.controller'
import template from './instructor-request-time-off.html'
import InstructorRTODay from './single-day/instructor-rto-day.module'
import InstrutorRTORange from './range/instructor-rto-range.module'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.requesttimeoff', {
            'url' : '/request-time-off',
            'controller' : InstructorRequestTimeOffController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.requesttimeoff', [
    InstructorRTODay.name,
    InstrutorRTORange.name
] )
    .config(config)
    .controller( 'InstructorRequestTimeOffController', InstructorRequestTimeOffController )
