function AdminSchoolsLocationsClassroomsController($state, $stateParams, LocationsModel, FlashService) {

    const vm = this;
    vm.updateClassroomName = updateClassroomName;
    init();

    function init() {
        LocationsModel.getLocationByID($stateParams.locationId).then(function (response) {
            vm.location = response;
        });
    }

    function updateClassroomName(index) {
        if (vm.location.classrooms[index].name !== '') {
            LocationsModel.editClassroom(vm.location.classrooms[index]).then(function (response) {
                FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
            });
        }
    }


}
AdminSchoolsLocationsClassroomsController.$inject = ['$state', '$stateParams', 'LocationsModel', 'FlashService']

export default AdminSchoolsLocationsClassroomsController;