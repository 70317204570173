function StudentProgressReportController( UserService, UsersModel ) {
    const vm = this;
    vm.loading = true;
    vm.student = UserService.getCurrentUser();
    vm.courses = [];

    UsersModel.getStudentUsageDetails(vm.student.id).then( response => {
        vm.loading = false;
        vm.usagedetails = response.data;
    });
    UsersModel.getStudentCourseDetails(vm.student.id).then( response => {
        vm.loading = false;
        vm.courses = response.data;
    });
}
StudentProgressReportController.$inject = ['UserService', 'UsersModel'];
export default StudentProgressReportController;