import controller from "./loading.controller";
import template from './loading.html'
export default () => {
    return {
        restrict : 'E',
        scope : {},
        template : template,
        controller : controller,
        controllerAs : 'vm',
        bindToController : true,
        replace: true
    }
}