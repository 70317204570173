import angular from 'angular'
import template from './admin-settings-policies-drives.html'
import AdminSettingsPoliciesDrivesController from './admin-settings-policies-drives.controller'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.settings.policies.drives', {
            url : '/drives',
            controller : AdminSettingsPoliciesDrivesController,
            controllerAs : 'vm',
            template: template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.administrator
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.settings.policies.drives', [] )
    .config(config)
    .controller( 'AdminSettingsPoliciesDrivesController', AdminSettingsPoliciesDrivesController )
;
