import angular from 'angular'
import template from './admin-school-locations.html'
import AdminSchoolsLocationsController from './admin-school-locations.controller'
import Index from './index/admin-school-campus-index.module'
import Create from './create/admin-school-create-campus.module'
import Edit from './edit/admin-school-edit-campus.module'
import View from './view/admin-school-campus-view.module'
import Zones from './zones/campus-zones.module'

let locationModules = [
    Index.name,
    Create.name,
    Edit.name,
    View.name,
    Zones.name
];

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.schools.view.locations', {
            'url': '/locations',
            'abstract': true,
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })

    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.schools.view.locations', locationModules)
    .config(config)
    .controller('AdminSchoolsLocationsController', AdminSchoolsLocationsController)

;
