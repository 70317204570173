class AdminSchoolBillingController {

    constructor($state, $stateParams, UserService, SchoolsModel, FlashService, TIMEZONES, OnlineDriversEdConfig){
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UserService = UserService;
        this.SchoolsModel = SchoolsModel;
        this.FlashService = FlashService;
        this.TIMEZONES = TIMEZONES;
        this.defaultPaymentMethod = '';
        this.plan = {
            'name' : '',
            'description' : ''
        };
        this.onlineDriversEd = {
            'name' : '',
            'description' : ''
        }
        this.OnlineDriversEdConfig = OnlineDriversEdConfig;
        this.paymentMethods = [];
        this.invoices = [];
        this.SchoolsModel = SchoolsModel;
        this.currentUser = UserService.getCurrentUser();
        this.getPlan();
        this.getInvoices();
        this.getPaymentMethods();
    }

    getPaymentMethods(){
        this.SchoolsModel.getPaymentMethods().then(response => {
            this.paymentMethods = response.payment_methods;
            this.defaultPaymentMethod = response.default_payment_method;
        });
    }

    getPlan(){
        this.SchoolsModel.getSchoolPlan(this.currentUser.school_id).then(response => {
            this.plan = response.drivescout.plan;
            if (response.hasOwnProperty('online_drivers_ed_ca')){
                this.onlineDriversEd = response.online_drivers_ed_ca.plan;
            }
        });
    }

    getInvoices(){
        this.SchoolsModel.getSchoolInvoices(this.currentUser.school_id).then(response => {
            this.invoices = response.invoices;
        })
    }

    /** @todo **/
    cancelSubscription(){
        this.SchoolsModel.cancelSubscription(this.currentUser.school_id).then(response => {
            this.plan = response.plan;
        })
    }

}

AdminSchoolBillingController.$inject = ['$state', '$stateParams', 'UserService', 'SchoolsModel', 'FlashService', 'TIMEZONES', 'OnlineDriversEdConfig'];
export default AdminSchoolBillingController;
