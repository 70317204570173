
function PayPalService(OrdersModel) {

    const service = this;

    service.paypalLoaded = false;
    service.script = null;

    service.purchaseUnits = [];


    service.init = init;
    service.makePurchaseUnits = makePurchaseUnits;
    service.setPurchaseUnits = setPurchaseUnits;
    service.getPurchaseUnits = getPurchaseUnits;
    service.getClientToken = getClientToken;
    service.setClientToken = setClientToken;
    service.cleanUp = cleanUp;

    function init(clientID, clientToken)
    {
        if (service.paypalLoaded || window.paypal) {
            // console.log('paypal already setup')
            service.getClientToken().then(response => {
                setupPaypal();
            });
        } else {
            const PAYPAL_SCRIPT = 'https://www.paypal.com/sdk/js?components=hosted-fields,buttons&client-id=' + clientID; // + '&debug=true';
            const script = document.createElement('script');
            script.setAttribute('src', PAYPAL_SCRIPT);
            script.setAttribute('data-client-token', clientToken)
            script.addEventListener('load', setupPaypal)
            service.script = script;
            document.head.appendChild(script);
        }
    }

    function setPurchaseUnits(purchaseUnits)
    {
        service.purchaseUnits = purchaseUnits;
    }

    function makePurchaseUnits(data)
    {
        let products = data.products;
        let paidToday = data.paidToday;
        let surchargeAmountBilled = data.surchargeAmountBilled;
        let surchargeBilledSeparately = data.surchargeBilledSeparately;

        let productTotalInCents = data.products.reduce(function(carry, product){
            return product.price + carry;
        }, 0);
        let productTotal = parseFloat(productTotalInCents / 100).toFixed(2).toString();

        if (surchargeBilledSeparately === false) {
            surchargeAmountBilled = 0;
        }
        let orderDiscountedByAdmin = paidToday !== productTotal + surchargeAmountBilled;
        if (orderDiscountedByAdmin) {
            let itemTotal;
            if (surchargeBilledSeparately) {
                itemTotal = parseFloat(parseFloat(paidToday) - parseFloat(surchargeAmountBilled)).toFixed(2)
            } else {
                itemTotal = paidToday;
            }
            service.purchaseUnits = [{
                amount: {
                    value: paidToday,
                    currency_code: 'USD',
                    breakdown: {
                        item_total: {
                            value: paidToday,
                            currency_code: 'USD'
                        }
                    }
                },
                items: [
                    {
                        name: 'Drive Scout Order (Discounted)',
                        description: 'Discounted Drive Scout Order. See data in DriveScout for more accurate order information.',
                        quantity: 1,
                        unit_amount: {
                            value: itemTotal,
                            currency_code: 'USD'
                        },
                    },
                ],
            }]
        } else {
            service.purchaseUnits = [{
                amount: {
                    value: paidToday,
                    currency_code: 'USD',
                    breakdown: {
                        item_total: {
                            value: paidToday,
                            currency_code: 'USD'
                        }
                    }
                },
                items: products.map(product => {
                    return {
                        name: product.name,
                        description: product.description,
                        quantity: 1,
                        unit_amount: {
                            value: parseFloat(product.price / 100).toFixed(2).toString(),
                            currency_code: 'USD'
                        },
                    }
                })
            }]
        }

        if (surchargeBilledSeparately) {
            service.purchaseUnits[0].items.push({
                name: 'Driving School Surcharge',
                quantity: 1,
                unit_amount: {
                    value: surchargeAmountBilled,
                    currency_code: 'USD'
                }
            })
        }
    }

    /**
     * remove the paypal javascript from the header. This is because the client id is unique to every order request
     */
    function cleanUp()
    {

    }

    function getPurchaseUnits()
    {
        return service.purchaseUnits;
    }

    function setClientToken(token){
        service.client_token = token;
        if (service.script) {
            service.script.setAttribute('data-client-token', token)
        }
    }

    function getClientToken()
    {
        return OrdersModel.getClientToken().then(response => {
            setClientToken(response.client_token);
            return response.client_token;
        }).catch(Error => {

        })
    }

    function setupPaypal()
    {
        if (window.paypal) {
            service.paypalLoaded = true;

            if (window.paypal.HostedFields.isEligible()) {
                window.paypal.HostedFields.render({
                    createOrder: (data, actions) => {

                        return actions.order.create({
                            purchase_units: service.getPurchaseUnits(),
                        });
                    },
                    styles: {
                        'input': {
                            'font-size': '17px',
                            'font-family': 'helvetica, tahoma, calibri, sans-serif',
                            'color': '#3a3a3a'
                        },
                        ':focus': {
                            'color': 'black'
                        }
                    },
                    fields: {
                        number: {
                            selector: '#card_number',
                            placeholder: 'card number'
                        },
                        cvv: {
                            selector: '#cvc',
                            placeholder: 'card security number'
                        },
                        expirationDate: {
                            selector: '#expiration_date',
                            placeholder: 'mm/yy'
                        }
                    }
                }).then(hf => {
                    if (service.onApprove) {
                        const handler = service.onApprove;
                        service.onApprove = () => {
                            hf.submit({
                                // Cardholder Name
                                cardholderName: document.getElementById('name_on_card').value,
                                // Billing Address
                                billingAddress: {
                                    streetAddress: document.getElementById('card-billing-address-street').value,      // address_line_1 - street
                                    extendedAddress: document.getElementById('billing_address_street').value,       // address_line_2 - unit
                                    region: document.getElementById('billing_address_state').value,           // admin_area_1 - state
                                    locality: document.getElementById('billing_address_city').value,          // admin_area_2 - town / city
                                    postalCode: document.getElementById('billing_address_zip').value,           // postal_code - postal_code
                                    countryCodeAlpha2: 'US'   // country_code - country
                                }
                                // redirect after successful order approval
                            }).then(function (data, actions) {
                                // window.location.replace('http://www.somesite.com/review');
                                // console.log(data, actions)
                                service.paypal_order_id = details.id;
                                handler(details)
                            }).catch(function (err) {
                                // console.log('error: ', JSON.stringify(err));
                                document.getElementById("consoleLog").innerHTML = JSON.stringify(err);
                            });
                        }

                    }

                });
            } else {
                window.paypal.Buttons({
                    commit: false,
                    // onClick is called when the button is clicked
                    onClick: (data, actions) => {

                        // You must return a promise from onClick to do async validation
                        return service.validateForm(service.getFormData())
                            .then(data => {
                                // If there is a validation error, reject, otherwise resolve
                                if (data.hasOwnProperty('message') && data.message === 'Ok.') {
                                    return actions.resolve();
                                }

                                if (data.hasOwnProperty('errors')) {
                                    // document.querySelector('#error').classList.remove('hidden');
                                    return actions.reject();
                                } else {
                                    return actions.resolve();
                                }
                            }).catch(Error => {
                                // console.log(Error)
                                return  actions.reject();
                            });
                    },
                    createOrder: (data, actions) => {

                        return actions.order.create({
                            purchase_units: service.getPurchaseUnits(),
                        });
                    },
                    onError: function (Error) {
                        // console.log(Error)
                        // Show an error page here, when an error occurs
                    },
                    onCancel: function (data) {
                        // Show a cancel page, or return to cart
                    },
                    onApprove: (data, actions) =>  {
                        // This function captures the funds from the transaction
                        return actions.order.capture().then(details => {
                            // This function shows a transaction success message to your buyer
                            // alert('Thanks for your purchase!');
                            service.paypal_order_id = details.id;
                            if (service.onApprove) {
                                service.onApprove(details);
                            }
                            service.cleanUp();
                        });
                    }
                }).render('#paypal-button-container');
            }
        }
    }
}
PayPalService.$inject = ['OrdersModel'];

export default PayPalService;