import angular from 'angular'
import template from './instructor-agenda.html'
import InstructorAgendaController from './instructor-agenda.controller'

function getSessionTypes(DriveSessionTypesModel){
    return DriveSessionTypesModel.get().then(response => {
        return response.sessionTypes;
    });
}
getSessionTypes.$inject = ['DriveSessionTypesModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.agenda', {
            'url' : '/agenda/:date',
            'controller' : InstructorAgendaController,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                'sessionTypes' : getSessionTypes
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.agenda', [] )
    .config(config)
    .controller( 'InstructorAgendaController', InstructorAgendaController )
;
