import template from './student-usage-detail.html'
function dcStudentUsageDetail() {
    return {
        restrict : 'E',
        scope : {
            'usageDetails' : '='
        },
        template : template,
        link :  (scope, element, attrs ) => {

        },
        controller: 'dcStudentUsageDetailController',
        controllerAs: 'vm',
        bindToController: true
    }
}

export default dcStudentUsageDetail;
