"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StudentClassesController = /** @class */ (function () {
    function StudentClassesController(student, UsersModel, CoursesModel, FlashService, $window) {
        var _this = this;
        this.loading = false;
        this.classes = [];
        this.student = student;
        this.UsersModel = UsersModel;
        this.CoursesModel = CoursesModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.UsersModel.getStudentClasses(this.student.id, null).then(function (response) {
            _this.classes = response.classes;
        });
        this.cancel = this.cancel.bind(this);
    }
    StudentClassesController.prototype.cancel = function (class_id) {
        var _this = this;
        var confirm = this.$window.confirm('Are you sure you want to cancel this class?');
        if (confirm === false) {
            return false;
        }
        this.UsersModel.cancelStudentClass(this.student.id, class_id).then(function (response) {
            _this.FlashService.setMessage({
                'message': response.message,
                'type': 'success'
            });
            _this.classes = _this.classes.filter(function (class_date) {
                return class_date.id !== class_id;
            });
        }).catch(function (Error) {
            console.error(Error);
        });
    };
    return StudentClassesController;
}());
StudentClassesController.$inject = [
    "student",
    "UsersModel",
    "CoursesModel",
    "FlashService",
    "$window"
];
exports.default = StudentClassesController;
