import angular from 'angular'
import CreatePassFailReportCardController from './create-pass-fail-report-card.controller'
import template from './create-pass-fail-report-card.html'

function getStudent($stateParams, UsersModel ) {
    return UsersModel.getStudentByID($stateParams.studentId).then(response => {
        return response;
    });
}
getStudent.$inject = ["$stateParams", "UsersModel"];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.createpassfailreportcard', {
            'url' : '/:driveId/:studentId/create-pass-fail-report-card',
            'controller' : 'CreatePassFailReportCardController',
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                'student' : getStudent
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


export default angular.module( 'admin.dashboard.createpassfailreportcard', [] )
    .config(config)
    .controller('CreatePassFailReportCardController', CreatePassFailReportCardController)
;
