
class InstructorReportCardController {
    
    constructor(reportcard)
    {
        this.reportcard = reportcard;
    }

}
InstructorReportCardController.$inject = [
    "reportcard",
];
export default InstructorReportCardController;
