function AdminSettingsPoliciesPayRatiosController(
  DriveSessionTypesModel,
  CourseTypesModel,
  FlashService,
  PayRatiosModel,
  $q
) {
  let vm = this;
  vm.formData = {};
  vm.formData.policymeta = {};
  vm.updating = false;

  vm.updatePolicies = updatePolicies.bind(this);
  vm.updatePayRatios = updatePayRatios.bind(this);
  vm.updateCoursePayRatios = updateCoursePayRatios.bind(this);

  init();

  function getSessionTypes() {
    DriveSessionTypesModel.get().then((response) => {
      vm.sessionTypes = response.sessionTypes;
    });
  }

  function init() {
    PayRatiosModel.get().then(function(response) {
      response.misc.eventPayRatio = parseFloat(response.misc.eventPayRatio);
      vm.payRatios = response;
    });
    getSessionTypes();
    getCourseTypes();
  }

  function updatePayRatios() {
    vm.updating = true;
    const requests = [];
    vm.sessionTypes.forEach((sessionType) => {
      requests.push(DriveSessionTypesModel.update(sessionType));
    });
    $q.all(requests)
      .then((responses) => {
        FlashService.setMessage({
          type: "success",
          message: responses[0].message,
        });
        vm.updating = false;
      })
      .catch((Error) => {
        vm.updating = false;
      });
  }

  function getCourseTypes() {
    CourseTypesModel.get().then((response) => {
      vm.courseTypes = response.courseTypes;
    });
  }

  function updateCoursePayRatios() {
    vm.updating = true;
    const requests = [];
    vm.courseTypes.forEach((courseType) => {
      requests.push(CourseTypesModel.update(courseType));
    });
    $q.all(requests)
      .then((responses) => {
        FlashService.setMessage({
          type: "success",
          message: responses[0].message,
        });
        vm.updating = false;
      })
      .catch((Error) => {
        vm.updating = false;
      });
  }

  function updatePolicies() {
    PayRatiosModel.updateCollection(vm.payRatios.misc).then(function(response) {
      FlashService.setMessage({ type: "success", message: response.message });
    });
  }
}
AdminSettingsPoliciesPayRatiosController.$inject = [
  "DriveSessionTypesModel",
  "CourseTypesModel",
  "FlashService",
  "PayRatiosModel",
  "$q",
];

export default AdminSettingsPoliciesPayRatiosController;
