"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminCreateDriveService = /** @class */ (function () {
    function AdminCreateDriveService($rootScope) {
        this.driveZones = [];
        this.drive = {};
        this.date = null;
        this.clearDrive = this.clearDrive.bind(this);
    }
    AdminCreateDriveService.prototype.setDate = function (date) {
        this.date = date;
    };
    AdminCreateDriveService.prototype.getDate = function () {
        if (this.date === null) {
            return new Date();
        }
        return this.date;
    };
    AdminCreateDriveService.prototype.clearDrive = function () {
        this.drive = {};
    };
    return AdminCreateDriveService;
}());
AdminCreateDriveService.$inject = ['$rootScope'];
exports.default = AdminCreateDriveService;
