import angular from 'angular'

import template from './instructor-course.html'
import InstructorCourseController from './instructor-course.controller'
import InstructorViewCourse from './view/instructor-view-course.module.js'
import InstructorCourseAttendance from './attendance/instructor-course-attendance.module.js'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.course', {
            url : '/course/:courseId',
            abstract: true,
            template : template,
            controller : InstructorCourseController,
            controllerAs : 'vm',
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.course', [
    InstructorCourseAttendance.name,
    InstructorViewCourse.name
] )
    .config(config)
    .controller( 'InstructorCourseController', InstructorCourseController );
