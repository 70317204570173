<template>
  <Bar
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    'instructor' : { type: Object, default: () => {} },
    'year' : { type: Number, default: 2022 },
    'schedulingHistory': { type: Array, default: () => []},
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true
      }
    }
  },
  methods: {
    processChartData(){
        const data = [1,2,3,4,5,6,7,8,9,10,11,12].map((month) => {
            const record = this.schedulingHistory.find((r) => r.month == month);
            if (record){
                return record.minutes_worked / 60;
            }
            return 0;
        });
        return data;
    }
  },
  computed:{
    chartData(){
        return {
            labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'November', 'December' ],
            datasets: [ { label: `Scheduling History for ${this.instructor.fullname} in ${this.year}`, data: this.processChartData() } ]
        }
    }
  }
}
</script>
