import template from './student-footer-nav.html'
function dcStudentFooterNav() {
    return {
        restrict : 'E',
        scope : {},
        template : template,
        link : ( scope, element, attrs ) => {

        },
        controller : 'dcStudentFooterNavController',
        controllerAs : 'vm',
        bindToController : true
    }
}
dcStudentFooterNav.$inject = [];
export default dcStudentFooterNav;