import { toQueryString } from '../helpers/queryString'
function ImportsModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/import',
            UPDATE : APPURL + '/api/v1/import',
            CREATE : APPURL + '/api/v1/import'

        },
        events;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        events = extract( result );
        return events;
    }

    model.show = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.get = function ( data ) {
        if ( data ) {
            return $http.get( URLS.FETCH + '?' + toQueryString( data ) ).then( cacheResults );
        }
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = function ( request ) {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.synczones = function( data ){
        return $http.put( URLS.FETCH , data ).then( cacheResults );
    };

    model.create = function ( request ) {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.delete = function ( data ) {
        return $http.delete( URLS.FETCH + '/' + data.id ).then( cacheResults );
    };

    model.search = function ( params ) {
        return $http.post( URLS.FETCH + '/search', params ).then( cacheResults );
    };

    model.batchCreate = function ( courses ) {
        return $http.post( URLS.CREATE + '/batch', courses ).then( cacheResults );
    };

}
ImportsModel.$inject = ['$http', 'APPURL'];
export default ImportsModel;
