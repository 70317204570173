class UsageReportsController {

    constructor(UserService, UsageReportModel, $q, FlashService, DriveSessionTypesModel){
        this.UsageReportModel = UsageReportModel;
        this.UserService = UserService;
        this.currentUser = UserService.getCurrentUser();
        this.FlashService = FlashService;
        this.sessionTypes = [];
        this.studentUsageTypeData = [];
        this.studentStatuses = [
            {'name': 'All'},
            {'name': 'Inactive'},
            {'name': 'Unverified'},
            {'name': 'Active'},
            {'name': 'Unpaid Fees'},
            {'name': 'Archived'}
        ];
        this.outputModes = [
            {
                'name' : 'CSV (Excel)',
                'value' : 'csv'
            }
        ];
        this.outputMode = this.outputModes[0];
        this.school = this.UserService.getCurrentUser.school;
        this.sendOptions = [
            {
                'name' : 'Deliver as Email',
                'value' : 'Y'
            },
            {
                'name' : 'Preview',
                'value' : 'N'
            },
        ];
        this.filterMinorOptions = [
            {
                'name' : 'All',
                'value' : 'all'
            },
            {
                'name' : 'Users Under 18',
                'value' : 'under_18'
            },
            {
                'name' : 'Only Users Over 18',
                'value' : 'over_18'
            },
        ];
        this.filterByAge = this.filterMinorOptions[0];
        this.send = this.sendOptions[1];

        this.studentStatus = this.studentStatuses[0];
        DriveSessionTypesModel.get().then(response => {
            const sessionTypes = response.sessionTypes;
            this.sessionTypes = sessionTypes.filter(type => {
                return type.status === 'Active';
            });
            this.sessionType = this.sessionTypes[0];
        });

        this.$q = $q;
        this.data = [];
        this.busy = false;
    }

    getSessionTypeUsageReport()
    {
        this.studentUsageTypeData = [];
        this.busy = true;
        this.UsageReportModel.getStudentUsageSessionTypes({
            'session_type_id': this.sessionType.id,
            'student_status' : this.studentStatus.name,
            'output_mode' : this.outputMode.value,
            'send' : this.send.value,
            'filter_minors' : this.filterByAge.value
        }). then(response => {
            if (this.outputMode.value === 'csv' && this.send.value === 'Y') {
                this.FlashService.setMessage({
                    'type' : 'success',
                    'message' : response.message
                })
            } else {
                this.studentUsageTypeData = response.data;
                this.table = {
                    'headers' : Object.keys(response.data[0]),
                    'rows' : response.data
                }
                if (response.data.length === 0) {
                    this.FlashService.setMessage({
                        'type' : 'info',
                        'message' : 'We could not find any users matching your search criteria.'
                    });
                }
            }
            this.busy = false;
        }).catch(error => {
            this.busy = false;
        })
    }
}

UsageReportsController.$inject = ['UserService', 'UsageReportModel', '$q', 'FlashService', 'DriveSessionTypesModel'];
export default UsageReportsController;
