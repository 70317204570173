import moment from 'moment'
class InstructorCreatePassFailReportCardController {

    constructor($state, $stateParams, DrivesModel, FlashService, ReportcardsModel, student){
        this.reportcard = {
            student: {
                firstname: student.firstname,
                lastname: student.lastname
            },
        };

        this.drive = [];
        this.tasks = [];
        this.grades = [];
        this.gradeIncomplete = true;

        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ReportcardsModel = ReportcardsModel;
        this.FlashService = FlashService;

        DrivesModel.getDriveByID($stateParams.driveId).then(response => {
            this.drive = response.drive;
            this.driveDate = moment(this.drive.drive_date + ' ' + this.drive.start_time);
            this.untilDrive = this.driveDate.fromNow();
        } );
    }
    
    submitGrade( grade ) {
        const data = {
            'drive_id' : this.$stateParams.driveId,
            'student_id' : this.$stateParams.studentId,
            'grade' : grade
        };

        this.ReportcardsModel.submitPassFailGrade(data).then(response => {
            this.FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            if( response.redirect === 'dashboard' ){
                this.$state.go( 'drivecoach.instructor.dashboard' );
            }
            if( response.redirect === 'drive' ){
                this.$state.go('drivecoach.instructor.dashboard.drive', { 'driveId' : this.drive.id });
            }
        });
    }
}
InstructorCreatePassFailReportCardController.$inject = [ '$state', '$stateParams', 'DrivesModel', 'FlashService', 'ReportcardsModel', 'student'];

export default InstructorCreatePassFailReportCardController;
