import angular from 'angular'
import template from './batch-create-entity.html'
import AdminBatchCreateController from './batch-create-entity.controller'
import CreateShiftModalInstanceController from './create-shift.controller'
function dcAdminBatchCreate() {
    return {
        restrict: 'E',
        scope: {
            'entity': '@',
            'context': '@'
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: AdminBatchCreateController,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.batchcreate', [])
    .controller('dcAdminBatchCreateController', AdminBatchCreateController)
    .controller('CreateShiftModalInstanceController', CreateShiftModalInstanceController)
    .directive('dcAdminBatchCreate', dcAdminBatchCreate);