import moment from 'moment'
import rangeRight from 'lodash/rangeRight'
import uniqBy from 'lodash/uniqBy'

function VehicleAPI($http, $q) {

    const model = this;
    model.getMakes = getMakes;
    model.getModelsByYear = getModelsByYear;
    model.getYears = getYears;

    let URLS = {
      BASE : 'https://vpic.nhtsa.dot.gov/api/vehicles/',
      MAKES : 'GetMakesForVehicleType/passenger?format=json',
      MODELS : 'GetModelsForMakeYear/make/'
    },
    responseData;

    function extract( result ) {
        return result.data.Results;
    }

    function cacheResults( result ) {
      responseData = extract( result );
      return responseData;
    }
  
    function formatResponse(arr, type) {
      return arr.map(el => {
        let formattedName = el[type].split("")[0].toUpperCase() + el[type].split("").slice(1).join('')
        formattedName = formattedName.replace(/\./g, '');
        return formattedName;
      }).sort();
    }

    function getMakes() {
      let url = URLS.BASE + URLS.MAKES
      const req = {
        method: 'GET',
        url: url,
        withCredentials: false,
      }
      return $http(req)
        .then((response) => {
          let cached = uniqBy(cacheResults(response), 'MakeId');
          return formatResponse(cached, 'MakeName')
        });
    }

    function getModelsByYear(make, year) {
      //remove whitespace from make string
      make = encodeURIComponent(make.trim())
      let url_cars = URLS.BASE + URLS.MODELS + make + '/modelyear/' + year + '/vehicletype/passenger?format=json'
      let url_trucks = URLS.BASE + URLS.MODELS + make + '/modelyear/' + year + '/vehicletype/truck?format=json'
      return $q.all([
        $http({method: 'GET', url: url_cars, withCredentials: false}),
        $http({method: 'GET', url: url_trucks, withCredentials: false})
      ]).then(responses => {
        
        const data = [].concat(...responses);
        
        let cached = data.map(result => cacheResults(result))
        //this line removes duplicates and concats the two arrays
        let merged = uniqBy([].concat(...cached), 'Model_ID');
        if(merged.length > 0) {
          return formatResponse(merged, 'Model_Name').sort()
        } else return {
          error: 'No models available for that year/make combination. Please select a different year or make.'
        }

      }).catch(Error => {
        console.error(Error);
      });
    }

    function getYears() {
      let nextYear = moment().add(2, 'years').format('YYYY')
      return rangeRight(1941, nextYear).map(el => el.toString());
    }

}
VehicleAPI.$inject = ['$http', '$q'];
export default VehicleAPI;
