<template>
  <tippy :content="content" :placement="placement" :arrow="arrow" :boundary="boundary">
    <template v-slot:trigger>
      <!-- check how to add class to template div?? inline-block -->
      <slot></slot> <!-- the tooltip target element goes here (wrapped in the ngvue component in the markup) -->
    </template>
  </tippy>
</template>

<script>
  export default {
    name: 'dcTooltip',
    props: {
      content: { type: String, required: true },
      placement: { type: String, default: 'top' }, //'top', 'bottom', 'left', 'right'
      arrow: { type: Boolean, default: true },
      boundary: { type: String, default: 'window' } 
    }
  }
</script>

<style lang="scss"></style>