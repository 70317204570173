"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var time_1 = require("../../../../../common/helpers/time");
var AdminViewStudentCancelationsController = /** @class */ (function () {
    function AdminViewStudentCancelationsController(student, UsersModel, CancelationModel, FlashService, $window) {
        var _this = this;
        this.loading = false;
        this.cancelations = [];
        this.student = student;
        this.UsersModel = UsersModel;
        this.CancelationModel = CancelationModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.CancelationModel.getCancelations({ 'student_id': this.student.id }).then(function (response) {
            _this.cancelations = response.cancelations.data.map(function (cancelation) {
                var cancelDate = (0, time_1.dateTimeStringToDate)(cancelation.drive_date + ' ' + cancelation.drive_start_time);
                cancelation.drive_date = cancelDate.toLocaleDateString('en-US');
                cancelation.drive_start_time = cancelDate.toLocaleTimeString('en-US', { hour: "numeric", minute: "numeric" });
                cancelation.canceled_on = new Date(cancelation.canceled_on).toLocaleString('en-US', { hour12: true });
                return cancelation;
            });
            _this.totalItems = response.cancelations.total;
            _this.currentPage = response.cancelations.current_page;
        });
    }
    AdminViewStudentCancelationsController.prototype.pageChange = function () {
        var _this = this;
        this.CancelationModel.getCancelations({ 'student_id': this.student.id }).then(function (response) {
            _this.cancelations = response.cancelations.data.map(function (cancelation) {
                var cancelDate = (0, time_1.dateTimeStringToDate)(cancelation.drive_date + ' ' + cancelation.drive_start_time);
                cancelation.drive_date = cancelDate.toLocaleDateString('en-US');
                cancelation.drive_start_time = cancelDate.toLocaleTimeString('en-US', { hour: "numeric", minute: "numeric" });
                cancelation.canceled_on = new Date(cancelation.canceled_on).toLocaleString('en-US', { hour12: true });
                return cancelation;
            });
            _this.currentPage = response.cancelations.current_page;
            _this.totalItems = response.cancelations.total;
        });
    };
    return AdminViewStudentCancelationsController;
}());
AdminViewStudentCancelationsController.$inject = [
    "student",
    "UsersModel",
    "CancelationModel",
    "FlashService",
    "$window"
];
exports.default = AdminViewStudentCancelationsController;
