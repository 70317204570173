import template from './mini-calendar-agenda.html'
import dcMiniCalendarAgendaController from './mini-calendar-agenda.controller'
function dcMiniCalendar() {
    return {
        restrict : 'E',
        scope : {
            'paging' : '='
        },
        template : template,
        link : ( scope, element, attrs ) => {

        },
        controller : dcMiniCalendarAgendaController,
        controllerAs : 'vm',
        bindToController: true
    }
}

export default dcMiniCalendar;
