import angular from "angular";
import controller from "./admin-instructor-create-time-off-request.controller";
import template from "./admin-instructor-create-time-off-request.html";
import InstructorRTODay from "./single-day/instructor-rto-day.module";
import InstrutorRTORange from "./range/instructor-rto-range.module";

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state(
    "drivecoach.admin.instructors.view.timeoffrequests.create",
    {
      url: "/create",
      controller: controller,
      controllerAs: "vm",
      template: template,
      data: {
        authorizedRoles: [
          USER_ROLES.admin,
          USER_ROLES.owner,
          USER_ROLES.instructor,
        ],
      },
    }
  );
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("admin.dashboard.instructors.view.timeoffrequests.create", [
    InstructorRTODay.name,
    InstrutorRTORange.name,
  ])
  .config(config)
  .controller(
    "AdminInstructorsViewTimeOffRequestsCreateController",
    controller
  );
