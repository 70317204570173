import angular from 'angular'
import template from './student-search.html'
import controller from './student-search.controller'
function dsStudentSearch() {
    return {
        restrict: 'E',
        scope: {
            'ngModel' : '=',
            'ngChange' : '=',
            'disabled' : '=',
            'drive' : '=',
            'schooloverride' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.studentsearch', [])
    .controller('dsStudentSearchController', controller)
    .directive('dsStudentSearch', dsStudentSearch);

