import angular from 'angular'
import template from './instructor-calendar.html'
import InstructorCalendarController from './instructor-calendar.controller'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.calendar', {
            'url' : '/calendar',
            'controller' : InstructorCalendarController,
            'controllerAs' : 'vm',
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } );
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.calendar', [])
    .config( config )
    .controller( 'InstructorCalendarController', InstructorCalendarController );