class AdminViewTimeOffRequestsController {

    constructor($state, $stateParams, UsersModel, TimeoffRequestsModel, FlashService, $window){
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.TimeoffRequestsModel = TimeoffRequestsModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.search = this.search.bind(this)
        this.currentPage = 1;

        this.requests = [];

        //filter options
        this.instructors = [];
        this.statusOptions = [
            'All',
            'Approved',
            'Pending',
            'Rejected'
        ];
        this.searchStatus = 'All'

        //get school instructors
        this.UsersModel.listInstructors().then(response => {
            this.instructors = response.instructors
        })

        this.TimeoffRequestsModel.get().then(response => {
          this.requests = response.timeoffrequests.data;
          this.totalItems = response.timeoffrequests.total;
        })
    }

    pageChanged() {
        const searchData = {
            'page' : this.currentPage
        };
        searchData.status = this.searchStatus;
        
        if( (typeof this.searchInstructor !== 'undefined' && this.searchInstructor.fullname !== 'All')) {
            searchData.instructor_id = this.searchInstructor.id
        }
        this.TimeoffRequestsModel.get(searchData).then(response => {
            this.requests = response.timeoffrequests.data;
            this.totalItems = response.timeoffrequests.total;
        });
    }

    deleteRTO(request, $index) {
        const confirmDelete = this.$window.confirm('Are you sure you want to delete this time off request?');
        if (!confirmDelete) {
            return;
        }
        this.TimeoffRequestsModel.delete(request).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.requests.splice($index, 1);
            // this.$state.go('drivecoach.admin.calendar', {}, {'reload': true});
        })
    }

    search() {
        this.busy = true;
        const searchData = {};
        if( this.searchStatus !== 'All' ){
            searchData.status = this.searchStatus;
        }
        if( typeof this.searchInstructor !== 'undefined' && this.searchInstructor.fullname !== 'All'){
            searchData.instructor_id = this.searchInstructor.id
        }
        this.TimeoffRequestsModel.get(searchData).then(response => {
            this.currentPage = 1;
            this.requests = response.timeoffrequests.data;
            this.totalItems = response.timeoffrequests.total;
        }).catch(Error => {
            this.busy = false;
        });
    }


}
AdminViewTimeOffRequestsController.$inject = ['$state', '$stateParams', 'UsersModel', 'TimeoffRequestsModel', 'FlashService', '$window'];
export default AdminViewTimeOffRequestsController;
