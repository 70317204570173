import template from './instructor-footer-nav.html'
function dcInstructorFooterNav( $rootScope, $state, $window ) {
    return {
        restrict : 'E',
        scope : {},
        template : template,
        link : function ( scope, element, attrs ) {

            scope.navBack = function () {
                /*if($rootScope.previousState.params){
                 $state.go($rootScope.previousState.name, $rootScope.previousState.params);
                 }else{
                 $state.go($rootScope.previousState.name);
                 }*/
                if ( $rootScope.getHistory().length > 1 ) {
                    $window.history.back();
                }
            }

        }
    }
}
dcInstructorFooterNav.$inject = ['$rootScope', '$state', '$window'];
export default dcInstructorFooterNav;