import moment from 'moment'

function InstructorsViewTimeOffRequestsViewController($stateParams, UserService, UsersModel, TimeoffRequestsModel ) {

    const vm = this;
    vm.request = {};

    init();

    function init(){
        UsersModel.getInstructorByID( UserService.getCurrentUser().id ).then( function ( response ) {
            vm.instructor = response;
        } );

        TimeoffRequestsModel.getRequestByID( $stateParams.timeOffRequestId ).then( function ( response ) {
            vm.request = {
                'id' : response.id,
                'user_id' : response.user_id,
                'begin' : moment( response.begin ).format( 'MM/DD/YYYY hh:mm a' ),
                'end' : moment( response.end ).format( 'MM/DD/YYYY hh:mm a' ),
                'status' : response.status,
                'reason' : response.reason,
                'notes' : response.notes
            }
        } );
    }

}
InstructorsViewTimeOffRequestsViewController.$inject = ['$stateParams', 'UserService', 'UsersModel', 'TimeoffRequestsModel'];
export default InstructorsViewTimeOffRequestsViewController;