import angular from 'angular'
import controller from './complete-setup.controller'
import template from './complete-setup.html'

function CompleteSetupDirective() {
    return {
        restrict : 'E',
        scope : {
            'student' : '=',
            'title' : '=',
            'state' : '='
        },
        template : template,
        link : ( scope, element, attrs ) => {

        } ,
        controller : controller,
        controllerAs : 'vm',
        bindToController: true
    }
}

export default angular.module( 'student.directives.completesetup', [] )
    .directive( 'completeSetup', CompleteSetupDirective)
    .controller( 'CompleteSetupController' , controller );