"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function TasksModel($http, APPURL) {
    var model = this, URLS = {
        FETCH: APPURL + '/api/v1/tasks',
        UPDATE: APPURL + '/api/v1/tasks',
        CREATE: APPURL + '/api/v1/tasks'
    }, tasks;
    function extract(result) {
        return result.data;
    }
    function cacheResults(result) {
        tasks = extract(result);
        return tasks;
    }
    model.getTaskByID = function (id) {
        return $http.get(URLS.FETCH + '/' + id).then(cacheResults);
    };
    model.get = function () {
        return $http.get(URLS.FETCH).then(cacheResults);
    };
    model.update = function (request) {
        return $http.put(URLS.FETCH + '/' + request.id, request).then(cacheResults);
    };
    model.create = function (request) {
        return $http.post(URLS.FETCH, request).then(cacheResults);
    };
    model.destroy = function (task) {
        return $http.delete(URLS.FETCH + '/' + task.id).then(cacheResults);
    };
}
TasksModel.$inject = ['$http', 'APPURL'];
exports.default = TasksModel;
