<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 text-center">
        <i class="fa fa-circle-o-notch fa-spin"></i> Loading...
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'dcLoading',
}
</script>

<style lang="scss" scoped>

</style>
