
export default function OpenReplayService(ENV, OPENREPLAY_PROJECT_KEY, OPENREPLAY_INGEST_POINT, UserService){
   
    this.loadOpenReplay = loadOpenReplay.bind(this);
    this.setUser = setUser.bind(this);

    function loadOpenReplay() {
        if (ENV !== 'production') {
            return;
        }

        var initOpts = {
            projectKey: OPENREPLAY_PROJECT_KEY,
            ingestPoint: OPENREPLAY_INGEST_POINT,
            defaultInputMode: 2,
            obscureTextNumbers: false,
            obscureTextEmails: false,
          };
          // let user = UserService.getCurrentUser();
          // var startOpts = { userID: user.email, metadata: { school_id: user.school_id } };
         
          (function(A,s,a,y,e,r){
            r=window.OpenReplay=[e,r,y,[s-1, e]];
            s=document.createElement('script');s.src=A;s.async=!a;
            document.getElementsByTagName('head')[0].appendChild(s);
            r.start=function(v){r.push([0])};
            r.stop=function(v){r.push([1])};
            r.setUserID=function(id){r.push([2,id])};
            r.setUserAnonymousID=function(id){r.push([3,id])};
            r.setMetadata=function(k,v){r.push([4,k,v])};
            r.event=function(k,p,i){r.push([5,k,p,i])};
            r.issue=function(k,p){r.push([6,k,p])};
            r.isActive=function(){return false};
            r.getSessionToken=function(){};
          })("//static.openreplay.com/14.0.7/openreplay-assist.js",1,0,initOpts);
    }

    function setUser()
    {
      if (ENV !== 'production') {
          return;
      }
      let user = UserService.getCurrentUser();
      if (user) {
        window.OpenReplay.setUserID(user.id);
        window.OpenReplay.setMetadata('school_id', user.school_id);
      }
    }
}
OpenReplayService.$inject = ['ENV', 'OPENREPLAY_PROJECT_KEY', 'OPENREPLAY_INGEST_POINT', 'UserService'];
