import { saveToDisk } from "../../../common/helpers/saveToDisk";
class IntegrationsController {
  constructor($state, SchoolsModel, IntegrationsModel, FlashService, UserService) {
    this.busy = false;
    this.SchoolsModel = SchoolsModel;
    this.IntegrationsModel = IntegrationsModel;
    this.loading = false;
    this.FlashService = FlashService;

    this.school = UserService.getCurrentUser().school;
  }

  downloadWordPressPlugin() {
    this.busy = true;

    this.IntegrationsModel.downloadWordPressPlugin()
      .then((response) => {
        let fileUrl = window.URL.createObjectURL(response.data);
        let fileLink = document.createElement('a');

        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'drivescout-wordpress-plugin.zip');
        document.body.appendChild(fileLink)

        fileLink.click();
        this.busy = false;
      })
      .catch((Error) => {
        this.busy = false;
      });
  }
}
IntegrationsController.$inject = ["$state", "SchoolsModel", "IntegrationsModel", "FlashService", "UserService"];

export default IntegrationsController;
