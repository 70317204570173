class AdminInstructorsViewTimeOffRequestsController {

    constructor($state, $stateParams, UsersModel, TimeoffRequestsModel, FlashService, $window){
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.TimeoffRequestsModel = TimeoffRequestsModel;
        this.FlashService = FlashService;
        this.$window = $window;

        this.requests = [];
        UsersModel.getInstructorByID($stateParams.instructorId).then(response => {
            this.instructor = response;
        });

        UsersModel.getInstructorTimeOffRequests({'id': $stateParams.instructorId}).then(response => {
            this.requests = response.timeoffrequests.data;
            this.totalItems = response.timeoffrequests.total;
        });
    }

    pageChanged() {
        this.UsersModel.getInstructorTimeOffRequests({
            'id': this.$stateParams.instructorId,
            'page': this.currentPage
        }).then(response => {
            this.requests = response.timeoffrequests.data;
            this.totalItems = response.timeoffrequests.total;
        });
    }

    deleteRTO(request, $index) {
        const confirmDelete = this.$window.confirm('Are you sure you want to delete this time off request?');
        if (!confirmDelete) {
            return;
        }
        this.TimeoffRequestsModel.delete(request).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.requests.splice($index, 1);
            // this.$state.go('drivecoach.admin.calendar', {}, {'reload': true});
        })
    }


}
AdminInstructorsViewTimeOffRequestsController.$inject = ['$state', '$stateParams', 'UsersModel', 'TimeoffRequestsModel', 'FlashService', '$window'];
export default AdminInstructorsViewTimeOffRequestsController;
