import angular from 'angular'
import moment from 'moment'
import template from './instructor-event.html'
import InstructorEventController from './instructor-event.controller'
function getEvent( EventsModel, $stateParams ) {
    return EventsModel.show( $stateParams.eventId ).then( response => {
        const durMoment = moment.duration( response.event.duration, 'minutes' ).format( 'H:mm' ).split( ':' );
        response.event.location = angular.fromJson( response.event.location );
        response.event.date = moment( response.event.date ).toDate();
        response.event.time = moment( response.event.time, 'HH:mm:ss' ).toDate();
        response.event.duration = {};
        response.event.duration.hours = durMoment[0];
        response.event.duration.minutes = durMoment[1];

        return response.event;
    } );
}
getEvent.$inject = ['EventsModel', '$stateParams'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.instructor.dashboard.event', {
            url : '/event/:eventId',
            template : template,
            controller : InstructorEventController,
            controllerAs : 'vm',
            resolve : {
                event : getEvent
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'instructor.dashboard.event', [] )
    .config(config)
    .controller( 'InstructorEventController', InstructorEventController )
;
