import { Task } from "../../../../classes/Task";

class AdminInstructorTasksController {

    constructor($rootScope, $stateParams, UsersModel, AdminTasksService, UserService, instructor){
        this.AdminTasksService = AdminTasksService;
        this.UsersModel = UsersModel;
        this.$stateParams = $stateParams;
        this.instructor = instructor;

        this.tasks = []

        this.AdminTasksService.clearTasks();

        this.handleResponse = this.handleResponse.bind(this);

        this.user = UserService.getCurrentUser();

        $rootScope.$on('INSTRUCTOR_TASK_CREATED', () => {
            this.filterTasks(this.filteringBy, this.currentPage, this.$stateParams.instructorId);
        })

        $rootScope.$on('TASKS_UPDATED', () => {
            this.filterTasks(this.filteringBy, this.currentPage, this.$stateParams.instructorId);
        })

        this.AdminTasksService.loadTasks({'user_id':this.$stateParams.instructorId}).then(response => {
            this.handleResponse(response);
        });

    }

    filterTasks(filterBy = 'all', page = 1, userId = this.$stateParams.instructorId){
        this.filteringBy = filterBy;

        this.AdminTasksService.filterTasks(filterBy, page, userId).then(response => {
            this.handleResponse(response);
        })
    }

    pageChanged(){
        this.filterTasks(this.filteringBy, this.currentPage, this.$stateParams.instructorId);
    }

    handleResponse(response){
        this.tasks_last_page = response.data.last_page;
        this.currentPage = response.data.current_page;
        let tasks = response.data.data.map(task => new Task(task));
        this.totalItems = response.data.total;
        this.AdminTasksService.setTasks(tasks);
        this.tasks = this.AdminTasksService.getTasks();
    }
}

AdminInstructorTasksController.$inject = ['$rootScope', '$stateParams', 'UsersModel', 'AdminTasksService', 'UserService', 'instructor'];
export default AdminInstructorTasksController;