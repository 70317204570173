import angular from 'angular'
import StudentLocationsIndexController from './student-locations-index.controller'
import template from './student-locations-index.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.dashboard.locations.index', {
            url : '/index',
            template : template,
            controller : StudentLocationsIndexController,
            controllerAs : 'vm',
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.parent,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.dashboard.locations.index', [] )
    .config( config )
    .controller( 'StudentLocationsIndexController', StudentLocationsIndexController )
;
