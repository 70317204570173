import moment from 'moment'
function AdminInstructorsEditCertificationsController( $state, $stateParams, UsersModel, FlashService, UsermetaModel ) {

    let vm = this;
    vm.today = moment().format( 'M/D/YYYY' );

    vm.editCertification = editCertification;

    init();

    function init(){
        UsersModel.getInstructorByID( $stateParams.instructorId ).then( response => {
            response.usermeta.certificate.expiration_date = moment( response.usermeta.certificate.expiration_date ).format('MM/DD/YYYY');
            response.usermeta.certificate.issue_date = moment( response.usermeta.certificate.issue_date ).format('MM/DD/YYYY');
            vm.instructor = response;
            vm.certificate = {
                'number' : vm.instructor.usermeta.certificate.number,
                'issue_date' : vm.instructor.usermeta.certificate.issue_date,
                'expiration_date' : vm.instructor.usermeta.certificate.expiration_date
            };
        } );
    }

    function editCertification( isValid ) {
        vm.submitted = true;

        if ( isValid ) {
            let issue_date = moment(vm.certificate.issue_date).format('YYYY-MM-DD');
            let expiration_date = moment(vm.certificate.expiration_date).format('YYYY-MM-DD');
            let usermeta = {
                'user_id' : vm.instructor.id,
                'meta_key' : 'certificate',
                'meta_value' : {
                    'number' : vm.certificate.number,
                    'issue_date' : issue_date,
                    'expiration_date' : expiration_date
                }
            };

            UsermetaModel.update( usermeta ).then( response => {
                FlashService.setMessage( {
                    'type' : 'success',
                    'message' : response.message
                } );
                vm.submitted = false;
                $state.go( 'drivecoach.admin.instructors.view', { 'instructorId' : response.usermeta.user_id } );
            } );
        } else {
            FlashService.setMessage( { 'type' : 'error', 'message' : 'Please fill the form.' } );
        }
    }

}
AdminInstructorsEditCertificationsController.$inject = [ '$state', '$stateParams', 'UsersModel', 'FlashService', 'UsermetaModel'];

export default AdminInstructorsEditCertificationsController;