"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var json_1 = require("../helpers/json");
var UsermetaModel = /** @class */ (function () {
    function UsermetaModel($http, APPURL) {
        this.getUserByID = function (userId) {
            return this.$http.get(this.URLS.FETCH + '/' + userId).then(this.cacheResults);
        };
        this.get = function () {
            return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
        };
        this.update = function (meta) {
            var id = (meta.user_id) ? meta.user_id : meta.meta_id;
            return this.$http.put(this.URLS.FETCH + '/' + id, meta).then(this.cacheResults);
        };
        this.delete = function (user) {
            return this.$http.delete(this.URLS.FETCH + '/' + user.id).then(this.cacheResults);
        };
        this.URLS = {
            FETCH: APPURL + '/api/v1/usermeta',
            UPDATE: APPURL + '/api/v1/usermeta',
            CREATE: APPURL + '/api/v1/usermeta'
        },
            this.usermeta = [];
        this.user = {};
        this.metadata = {};
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    UsermetaModel.prototype.extract = function (result) {
        return result.data;
    };
    UsermetaModel.prototype.cacheResults = function (result) {
        this.usermeta = this.extract(result);
        return this.usermeta;
    };
    // TODO: Turn meta data stored as json into objects
    UsermetaModel.prototype.cacheUser = function (result) {
        var user = this.extract(result);
        this.metadata = {};
        user.usermeta.forEach(function (meta) {
            this.metadata[meta.meta_key] = (0, json_1.isJson)(meta.meta_value) ? JSON.parse(meta.meta_value) : meta.meta_value;
        });
        user.usermeta = this.metadata;
        return user;
    };
    return UsermetaModel;
}());
UsermetaModel.$inject = ['$http', 'APPURL'];
exports.default = UsermetaModel;
