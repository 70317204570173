class Toast {
    constructor(type, message){
        this.type = type;
        this.message = message;
    }
}

class ToasterController {
    constructor($rootScope, $scope, ToasterService, $timeout){
        this.toast = [];
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.ToasterService = ToasterService;
        this.$timeout = $timeout;

        this.newToast = this.newToast.bind(this);

        $scope.$on('NEW_TOAST', this.newToast);
    }

    newToast() {
        this.toast.push(new Toast(
            this.ToasterService.getType(),
            this.ToasterService.getMessage()
        ));
        this.isVisible = true;
        let index = this.toast.length - 1;
        this.$timeout(index => {
           this.closeToast(index)
        }, 3000)
    }

    closeToast(index) {
        this.toast.splice(index, 1);
    }
}
ToasterController.$inject = ['$rootScope', '$scope', 'ToasterService', '$timeout'];
export default ToasterController;