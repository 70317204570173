function EntitiesModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/events',
            UPDATE : APPURL + '/api/v1/events',
            CREATE : APPURL + '/api/v1/events'

        };

    model.isDriveSession = isDriveSession;
    model.isCourse = isCourse;
    model.isEvent = isEvent;
    model.isRTO = isRTO;

    /////////////////////////////////////

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        events = extract( result );
        return events;
    }


    function isDriveSession(item){
        return item.type === 'Drive Session';
    }

    function isCourse(item){
        return item.type === 'Course';
    }

    function isEvent(item){
        return item.type === 'Event';
    }

    function isRTO(item){
        return item.typeDisplay === 'RTO';
    }

}
EntitiesModel.$inject = ['$http', 'APPURL'];
export default EntitiesModel;
