"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFileByID = exports.DeleteFile = exports.UploadFile = void 0;
var HttpClient_1 = require("../core/HttpClient");
var UploadFile = function (file_type, name, formData) {
    return HttpClient_1.HttpClient.post('files?file_type=' + file_type + '&name=' + name, formData, {
        headers: {
            "Content-Type": undefined
        }
    });
};
exports.UploadFile = UploadFile;
var DeleteFile = function (id) {
    return HttpClient_1.HttpClient.delete('files/' + id);
};
exports.DeleteFile = DeleteFile;
var GetFileByID = function (id) {
    return HttpClient_1.HttpClient.get('files/' + id);
};
exports.GetFileByID = GetFileByID;
