import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcEmailTemplates from './EmailTemplates.vue'

export default angular.module( 'directives.emailtemplates', [] )
  .directive('dcEmailTemplates', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcEmailTemplates', dcEmailTemplates))
  }])
