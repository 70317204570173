"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Years = exports.Months = void 0;
var lodash_1 = require("lodash");
exports.Months = [
    { 'name': '1', 'value': "01" },
    { 'name': '2', 'value': "02" },
    { 'name': '3', 'value': "03" },
    { 'name': '4', 'value': "04" },
    { 'name': '5', 'value': "05" },
    { 'name': '6', 'value': "06" },
    { 'name': '7', 'value': "07" },
    { 'name': '8', 'value': "08" },
    { 'name': '9', 'value': "09" },
    { 'name': '10', 'value': "10" },
    { 'name': '11', 'value': "11" },
    { 'name': '12', 'value': "12" }
];
//this pulls in the current year plus 15 years in the future for cc forms
var min = new Date().getFullYear();
var max = min + 15;
var yearRange = (0, lodash_1.range)(min, max + 1);
exports.Years = yearRange.map(function (year) {
    var val = year.toString();
    if (year < 10) {
        val = "0" + year.toString();
    }
    else {
        val = val.toString();
    }
    return { name: year.toString(), value: val };
});
