"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var time_1 = require("../../../common/helpers/time");
var InstructorStudentReportCardsController = /** @class */ (function () {
    function InstructorStudentReportCardsController(student, reportcards, UsersModel) {
        this.totalItems = reportcards.total;
        this.currentPage = reportcards.current_page;
        this.reportcards = reportcards.data.map(function (reportcard) {
            reportcard.start = (0, time_1.dateTimeStringToDate)(reportcard.drive.drive_date + ' ' + reportcard.drive.start_time).toLocaleDateString('en-US');
            return reportcard;
        });
        this.page_by = reportcards.per_page;
        this.pageChanged = this.pageChanged.bind(this);
        this.loading = false;
        this.student = student;
        this.UsersModel = UsersModel;
        this.student = student;
    }
    InstructorStudentReportCardsController.prototype.pageChanged = function () {
        var _this = this;
        this.UsersModel.getStudentReportcards({
            'id': this.student.id,
            'page': this.currentPage
        }).then(function (response) {
            _this.reportcards = response.data.map(function (reportcard) {
                reportcard.start = (0, time_1.dateTimeStringToDate)(reportcard.drive.drive_date + ' ' + reportcard.drive.start_time).toLocaleDateString('en-US');
                return reportcard;
            });
            _this.totalItems = response.total;
            _this.currentPage = response.current_page;
            _this.loading = false;
        });
    };
    return InstructorStudentReportCardsController;
}());
InstructorStudentReportCardsController.$inject = [
    "student",
    "reportcards",
    "UsersModel",
];
exports.default = InstructorStudentReportCardsController;
