<template>
    <label>
        <input
            type="color"
            class="color-picker"
            v-bind:value="defaultColor"
            @input="$emit('colorChanged', $event.target.value)"
        >
        {{ label }}
    </label>
</template>
<style scoped>
label {
  display: flex;
  align-items: center;
}

.color-picker {
  margin-right: 10px;
  width: 26px;
  height: 30px;
  padding: 0;
  border: none;
  background: transparent;
}
</style>
<script>
export default {
    props: {
        defaultColor: {
            type: String,
            default: "#cccccc"
        },
        label: {
            type: String,
            default: "Choose a color"
        }
    }
}
</script>