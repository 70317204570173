<!--//this component is used to send emails on view course and course roster page currently -->
<template>
  <section>
    <section>
      <button @click="openModal()" class="btn btn-block btn-dc">
        Send Text Message
      </button>
    </section>

    <dcModal :modalShown="modalShown" :modalClasses="['col-sm-6']">
      <template #header>
        <h3 class="modal-title text-center">SMS Messenger</h3>
      </template>
      <template #body>
        <dcLoading v-if="loading"></dcLoading>
        <section>
          <form>
            <div style="margin-bottom: 1em" v-if="users.length > 0">
              <p style="margin-bottom: 5px">To:</p>
              <div>
                <span
                  v-for="user in users"
                  :key="user.id"
                  style="
                    display: inline-block;
                    padding: 5px;
                    margin-bottom: 5px;
                    margin-right: 5px;
                    background: #eee;
                  "
                  >{{ user.name }}</span
                >
              </div>
            </div>
            <div style="margin-bottom: 1em">
              <label>Text Message :</label>
              <input type="text" v-model="sms.body" class="form-control" />
            </div>
            <div>
              <label for="include_guardians"
                ><input
                  type="checkbox"
                  name="include_guardians"
                  id="include_guardians"
                  v-model="sms.include_guardians"
                />
                Include Guardians</label
              >
            </div>
            <div>
              <button
                style="margin-top: 2em"
                @click.prevent="sendSMS()"
                class="btn btn-blue"
              >
                Send SMS
              </button>
            </div>
          </form>
        </section>
      </template>
      <template #footer>
        <button class="btn btn-light" type="button" @click="handleClose">
          Close
        </button>
      </template>
    </dcModal>
  </section>
</template>

<script>
import dcModal from "../modal/Modal.vue";
import dcLoading from "../loading/Loading.vue";
export default {
  name: "dcSMSMessenger",
  components: {
    dcModal,
    dcLoading,
  },
  props: {
    users: { type: Array, default: () => [] },
    placeholder: { type: String },
  },
  data() {
    return {
      sms: {
        body: "",
        include_guardians: false,
      },
      modalShown: false,
      loading: false,
    };
  },
  methods: {
    openModal: function () {
      this.modalShown = true;
    },
    handleClose: function () {
      if (this.modalShown === true) {
        this.modalShown = false;
      }
      this.emitEvent("close-modal");
    },
    emitEvent: function (eventName, dataObject) {
      this.$emit("emit-event", {
        eventName: eventName,
        dataObject: dataObject,
      });
    },
    sendSMS: function () {
      const data = {
        recipients: this.users.map((user) => {
          return user.id;
        }),
        mode: "users",
        type: "sms",
        body: this.sms.body,
        include_guardians: this.sms.include_guardians ? "1" : "0",
      };
      this.$store.dispatch("SEND_SMS", data);
      this.sms.body = "";
      this.handleClose();
    },
  },
  created() {},
  watch: {
    smsSent(newVal) {
      if (newVal === true) {
        this.sms.body = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  text-align: left;
}
</style>
