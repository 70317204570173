import angular from 'angular'
import template from './admin-school-campus-view.html'
import AdminSchoolLocationViewController from './admin-school-campus-view.controller'
import LocationsViewVehicles from './vehicles/admin-campus-vehicles.module'
import LocationsViewClassrooms from './classrooms/admin-campus-classrooms.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.schools.view.locations.view', {
            'url': '/view/:locationId',
            'controller': AdminSchoolLocationViewController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })

    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


export default angular.module('admin.dashboard.schools.view.locations.view', [
    LocationsViewVehicles.name,
    LocationsViewClassrooms.name
])
    .config(config)
    .controller('AdminSchoolLocationViewController', AdminSchoolLocationViewController)
;
