import { toQueryString } from "../helpers/queryString"
function UsageReportModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/reports/usage',
            UPDATE : APPURL + '/api/v1/reports/usage',
            CREATE : APPURL + '/api/v1/reports/usage'
        },
        policies;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        policies = extract( result );
        return policies;
    }

    model.get = function (data) {
        return $http.get( URLS.FETCH + '?' + toQueryString(data)).then( cacheResults );
    };

    model.getStudentUsageSessionTypes = function (data) {
        return $http.get( URLS.FETCH + '/session-types?' + toQueryString(data)).then( cacheResults );
    };
}
UsageReportModel.$inject = ['$http', 'APPURL'];
export default UsageReportModel;