import angular from 'angular'
import template from './product-form.html'
import controller from './product-form.controller'
function dsProductForm() {
    return {
        restrict: 'E',
        scope: {
            'product' : '=',
            'submit' : '='
        },
        template: template,
        link: (scope, element, attrs) => {

        },
        controller: controller,
        controllerAs: 'vm',
        bindToController: true
    }
}

export default angular.module('admin.directives.productform', [])
    .controller('dsProductFormController', controller)
    .directive('productForm', dsProductForm);

