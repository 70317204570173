import controller from './fee-detail.controller'
import template from './fee-detail.html'
function FeeDetail() {
    return {
        restrict : 'E',
        scope : {
            'fee' : '=',
            'payments' : '='
        },
        template : template,
        controller : controller,
        controllerAs : 'vm',
        bindToController : true
    }
}
export default angular.module( 'directives.feedetail', [] )
    .directive( 'feeDetail', FeeDetail)
    .controller( 'FeeDetailController', controller );