import {States} from "../../../../../common/constants/states";

class StudentLocationsCreateHighschoolController {

    constructor($state, UsersModel, UserService, FlashService, SchoolsModel){
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.UserService = UserService;
        this.FlashService = FlashService;
        this.SchoolsModel = SchoolsModel;
        this.states = States;

        this.submitted = false;
        this.student = UserService.getCurrentUser();
        this.hasPickupLocation = false;
        this.highSchoolName = null;

        this.submitPickupLocation = this.submitPickupLocation.bind(this)
       
        UsersModel.getPickupLocations( this.student.id ).then( response => {
            this.state = response.pickuplocations[0].address.state;
        } );
    }

    searchHighSchools() {

        this.highschools = [];
        this.loadingSchools = true;

        const data = {
            'name' : this.highSchoolName,
            'state' : this.state
        };

        this.SchoolsModel.getHighSchools( data ).then( response => {
            this.loadingSchools = false;
            this.highschools = response;
        } )
    }

    selectHighschool() {
        const selectedHighSchool = this.selectedHighSchool;

        this.highschool = {
            'name' : selectedHighSchool.name,
            'street' : selectedHighSchool.street,
            'city' : selectedHighSchool.city,
            'state' : selectedHighSchool.state,
            'zip' : selectedHighSchool.zip,
            'lat' : selectedHighSchool.lat,
            'lng' : selectedHighSchool.lng
        };
        this.hasPickupLocation = true;
    }

    submitPickupLocation() {
        this.submitted = true;
        this.UsersModel.addPickupLocation({
            'student_id' : this.student.id,
            'pickuplocation' : {
                'name' : this.highschool.name,
                'street' : this.highschool.street,
                'city' : this.highschool.city,
                'state' : this.highschool.state,
                'zip' : this.highschool.zip,
                'lat' : this.highschool.lat,
                'lng' : this.highschool.lng
            }
        }).then( response => {
            this.FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            this.$state.go( 'drivecoach.student.dashboard.locations.index', {}, { 'reload' : true } );
        } ).catch(response => {
            this.submitted = false;
        });
    }

}
StudentLocationsCreateHighschoolController.$inject = [ '$state', 'UsersModel', 'UserService', 'FlashService', 'SchoolsModel'];
export default StudentLocationsCreateHighschoolController;
