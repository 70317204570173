import angular from 'angular'
import Vue from 'vue';
import 'ngVue';
  
import dcBarChart from './BarChart.vue'

export default angular.module( 'directives.barchart', [] )
  .directive('dcBarChart', ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component('dcBarChart', dcBarChart))
  }])
