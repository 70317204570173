import angular from "angular";
import template from "./integrations.html";
import IntegrationsController from "./integrations.controller";

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
  $stateProvider.state("drivecoach.admin.settings.integrations", {
    url: "/integrations",
    controller: IntegrationsController,
    controllerAs: "vm",
    template: template,
    data: {
      authorizedRoles: [
        USER_ROLES.admin,
        USER_ROLES.owner,
        USER_ROLES.administrator,
      ],
    },
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider", "USER_ROLES"];

export default angular
  .module("admin.settings.integrations", [])
  .config(config)
  .controller("IntegrationsController", IntegrationsController);
