import {States} from "../../../../common/constants/states";
import { isJson } from "../../../../common/helpers/json";
class AdminInstructorCreate {

    constructor($rootScope, UserService, $state, UsersModel, FlashService){
        this.loading = true;
        this.$rootScope = $rootScope;
        this.UserService = UserService;
        this.$state = $state;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;

        this.user = UserService.getCurrentUser();
        this.school = this.user.school
        this.school.address = isJson(this.user.school.address) ? JSON.parse( this.user.school.address ) : this.user.school.address;
        this.section = 'contactInfo';
        this.states = States;
        this.formData = {
            'usertype_id' : 3,
            'locations' : [],
            'school_id' : this.school.id
        };

        $rootScope.$on('MESSAGE_CLEARED', () => {
            this.submitted = false;
        });
    }

    createInstructor() {
        this.submitted = true;

        let data = Object.assign({}, this.formData);
        this.UsersModel.createInstructor(data).then(response => {
            this.FlashService.setMessage( { 'type' : 'success', 'message' : response.message } );
            this.formData = {
                'usertype_id' : 3,
                'availability' : null
            };
            this.submitted = false;
            this.$state.go( 'drivecoach.admin.instructors.index', {}, { 'reload' : true } );
        }).catch(Error => {
            this.submitted = false;
        });
        
    }

}
AdminInstructorCreate.$inject = ['$rootScope', 'UserService', '$state', 'UsersModel', 'FlashService'];

export default AdminInstructorCreate;
