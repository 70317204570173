class AdminSchoolsLocationsIndexController {

    constructor(SchoolsModel, $stateParams, LocationsModel, FlashService, $window){
        this.$window = $window;
        this.SchoolsModel = SchoolsModel;
        this.LocationsModel = LocationsModel;
        this.FlashService = FlashService;
        this.loading = true;
        this.submitting = false;
        SchoolsModel.getSchoolByID($stateParams.schoolId).then(response => {
            this.locations = response.locations;
            this.loading = false;
        });
    }

    disableLocation(location) {
        this.LocationsModel.disableLocation(location).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    }

    activateLocation(location) {
        this.LocationsModel.activateLocation(location).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    }

    deleteLocation(location) {
        const confirm = this.$window.confirm('Are you sure you want to delete this location? This will delete all of its zones and sectors as well.');
        if (!confirm) {
            return false;
        }

        this.submitting = true;
        this.LocationsModel.deleteLocation(location.id).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message' : response.message
            });
            this.locations = this.locations.filter(l => {
                return l.id !== location.id;
            });
            this.submitting = false;
        }).catch(Error => {
            this.submitting = false;
        })
    }

}
AdminSchoolsLocationsIndexController.$inject = ['SchoolsModel', '$stateParams', 'LocationsModel', 'FlashService', '$window'];
export default AdminSchoolsLocationsIndexController