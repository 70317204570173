import angular from 'angular'
import template from './emails.html'
import controller from './emails.controller'

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state('drivecoach.admin.instructors.emails', {
            'url' : '/:instructorId/emails',
            'controller' : controller,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
                instructor : ['UsersModel', '$stateParams', function ( UsersModel, $stateParams ) {
                    return UsersModel.getInstructorByID( $stateParams.instructorId ).then( response => {
                        return response;
                    } );
                }]
            },
            data: {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.dashboard.instructors.emails', [] )
    .config(config)
    .controller( 'AdminInstructorEmailsController', controller)
;
