import angular from 'angular'
import template from './instructor-view-time-off-requests.html'
import controller from './instructor-view-time-off-requests.controller'
import TimeOffRequestView from './view/instructor-view-time-off-request-detail.module'
function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.instructor.dashboard.timeoffrequests', {
            'url': '/time-off-requests',
            'controller': controller,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('instructor.dashboard.timeoffrequests', [
    TimeOffRequestView.name
])
    .config(config)
    .controller('InstructorsViewTimeOffRequestsController', controller)
