import angular from 'angular'
import Vue from 'vue';
import 'ngVue';

import dcEmailEditor from './EmailEditor.vue'

export default angular.module( 'directives.emaileditor', [] )
    .directive('dcEmailEditor', ['createVueComponent', function (createVueComponent) {
        return createVueComponent(Vue.component('dcEmailEditor', dcEmailEditor))
    }])
