class StudentReportCardController {

    constructor(UsersModel, user, reportData){
        this.UsersModel = UsersModel;
        this.user = user;
        this.reportData = reportData;
        this.totalItems = reportData.reportcards.total;
        if (this.totalItems > 0) {
            this.reportcard = reportData.reportcards.data[0];
        } else {
            this.reportcard = null;
        }

        this.currentPage = 1;
    }
    
    pageChanged() {
        const data = { 'id' : this.user.id, 'page' : this.currentPage };
        this.UsersModel.getStudentReportcards( data ).then( response => {
            this.reportcard = response.reportcards.data[0];
        } );
    }

}

StudentReportCardController.$inject = ['UsersModel', 'user', 'reportData'];
export default StudentReportCardController;