import angular from 'angular'
import StudentSettingsController from './student-settings.controller'
import Communication from './communication/student-edit-communication-settings.module'

import template from './student-settings.html'
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.settings', {
            url : '/settings',
            controller : StudentSettingsController,
            controllerAs : 'vm',
            template : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.settings', [
    Communication.name
] )
    .config( config )
    .controller( 'StudentSettingsController', StudentSettingsController )
;
