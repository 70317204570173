<template>
  <div class="well session-status course-status">
    <div class="row">
        <div class="col-xs-12">
            <strong class="session-type-name">{{ title }}</strong>
        </div>
    </div>

    <slot></slot>
    
    <div class="row" v-if="showProgressBar">
        <div class="col-xs-12 text-center">
            <k-progress class="mt-15"
                        status="success"
                        :line-height="20"
                        color="#5cb85c"
                        :show-text="true"
                        :percent="parseFloat(percentComplete)" 
                        :border="true"></k-progress>
        </div>
    </div>
  </div>
</template>

<script>
  import KProgress from 'k-progress';
  export default {
    name: 'dcStudentProgressOverview',
    components: { KProgress },
    props: {
      title: { type: String, required: true },
      percentComplete: { default: 0 },
      showProgressBar: { default: true }
    }
  }
</script>

<style lang="scss">
  .k-progress-outer-bg-border, .k-progress-outer-line {
    border-radius: 4px !important;
  }
</style>