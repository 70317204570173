"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var json_1 = require("../../../../common/helpers/json");
var AdminInstructorAddLocationController = /** @class */ (function () {
    function AdminInstructorAddLocationController($state, $stateParams, UsersModel, FlashService, campuses, UserService, LocationsModel) {
        var _this = this;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.LocationsModel = LocationsModel;
        this.user = UserService.getCurrentUser();
        this.unchecked = true;
        this.formData = {
            school_id: 0,
            location: {
                id: 0,
                zones: [],
                zoneparts: [],
            },
            instructor_id: $stateParams.instructorId,
            sectors: [],
        };
        this.school = this.user.school;
        this.locations = campuses;
        this.school.address = (0, json_1.isJson)(this.user.school.address)
            ? JSON.parse(this.user.school.address)
            : this.user.school.address;
        this.formData.school_id = this.school.id;
        this.loading = true;
        UsersModel.getInstructorByID($stateParams.instructorId).then(function (response) {
            _this.instructor = response;
            // const locations = uniqBy([...this.instructor.locations,...campuses], 'id');
            _this.loading = false;
        });
    }
    AdminInstructorAddLocationController.prototype.getZones = function () {
        this.formData.sectors = [];
        this.zones = this.formData.location.zones;
        //this.getSectorsFromZone(this.zones[0]);
    };
    AdminInstructorAddLocationController.prototype.getSectorsFromZone = function (zone) {
        return this.formData.location.zoneparts.filter(function (sector) {
            return sector.zone_id === zone.id;
        });
    };
    AdminInstructorAddLocationController.prototype.parseSector = function (sector) {
        delete sector.geom;
        delete sector.created_at;
        delete sector.updated_at;
        delete sector.pivot;
        return sector;
    };
    AdminInstructorAddLocationController.prototype.handleClick = function (sector) {
        var index = (0, lodash_1.findIndex)(this.formData.sectors, { id: sector.id });
        // if sector is already selected and now being unchecked, remove from array
        if (this.isSelected(sector)) {
            this.formData.sectors.splice(index, 1);
        }
        else {
            this.formData.sectors.push(sector);
        }
    };
    AdminInstructorAddLocationController.prototype.isSelected = function (sector) {
        return (0, lodash_1.findIndex)(this.formData.sectors, { id: sector.id }) > -1;
    };
    AdminInstructorAddLocationController.prototype.submit = function () {
        var _this = this;
        this.submitted = true;
        this.checkSelect();
        this.UsersModel.addInstructorLocation(this.formData).then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.submitted = false;
            _this.$state.go("drivecoach.admin.instructors.locations", { instructorId: _this.instructor.id }, { reload: true });
        });
    };
    AdminInstructorAddLocationController.prototype.checkSelect = function () {
        this.unchecked = true;
        for (var i = this.formData.sectors.length - 1, itm = void 0; i >= 0; i--) {
            itm = this.formData.sectors[i];
            if (itm) {
                this.unchecked = false;
                break;
            }
        }
    };
    return AdminInstructorAddLocationController;
}());
AdminInstructorAddLocationController.$inject = [
    "$state",
    "$stateParams",
    "UsersModel",
    "FlashService",
    "campuses",
    "UserService",
    "LocationsModel",
];
exports.default = AdminInstructorAddLocationController;
