import angular from 'angular'
import SignupController from './signup.controller'
import StudentSignup from './student/student-signup.module';
import template from './signup.html'
function config($stateProvider, $urlRouterProvider){
    $stateProvider
        .state('drivecoach.signup', {
            url: '/signup',
            controller: SignupController,
            template: template
        });
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

export default angular.module('signup', [
        StudentSignup.name,
])
    .controller('SignupController', SignupController)
    .config(config);
