import {toQueryString} from "../helpers/queryString";

function CrmTasksModel($http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/crm/tasks',
            UPDATE : APPURL + '/api/v1/crm/tasks',
            CREATE : APPURL + '/api/v1/crm/tasks'

        },
        tasks;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        tasks = extract( result );
        return tasks;
    }

    model.getTaskByID = function ( id ) {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    };

    model.get = (data = null) => {
        if( data !== null ){
           return $http.get(URLS.FETCH + '?' + toQueryString(data)).then(cacheResults)
        }
        return $http.get( URLS.FETCH ).then( cacheResults );
    };

    model.update = function ( request ) {
        return $http.put( URLS.FETCH + '/' + request.id, request ).then( cacheResults );
    };

    model.create = function ( request ) {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    };

    model.destroy = function ( task ) {
        return $http.delete( URLS.FETCH + '/' + task.id ).then( cacheResults );
    }

}
CrmTasksModel.$inject = ['$http', 'APPURL'];
export default CrmTasksModel;
