"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../../../../../common/helpers/queryString");
var EmailsController = /** @class */ (function () {
    function EmailsController($stateParams, $http, APPURL, student, $sce) {
        var _this = this;
        this.$stateParams = $stateParams;
        this.$http = $http;
        this.APPURL = APPURL;
        this.student = student;
        this.notifications = [];
        this.currentPage = 0;
        this.$sce = $sce;
        this.pageChanged = this.pageChanged.bind(this);
        this.formatEmailContent = this.formatEmailContent.bind(this);
        $http
            .get(APPURL + "/api/v1/users/students/" + $stateParams.userId + "/notifications")
            .then(function (response) {
            _this.notifications = response.data.notifications.data.map(function (notif) {
                notif.created_at = new Date(notif.created_at).toLocaleString('en-US');
                return notif;
            });
            _this.currentPage = response.data.notifications.current_page;
            _this.page_by = response.data.notifications.per_page;
            _this.totalItems = response.data.notifications.total;
        })
            .catch(function (Error) {
            console.error(Error);
        });
    }
    EmailsController.prototype.formatNotifName = function (type) {
        return type.toUpperCase();
    };
    EmailsController.prototype.formatEmailContent = function (html) {
        return this.$sce.trustAsHtml(html);
    };
    EmailsController.prototype.pageChanged = function () {
        var _this = this;
        this.$http
            .get(this.APPURL + "/api/v1/users/students/" + this.$stateParams.userId + "/notifications?" + (0, queryString_1.toQueryString)({
            page_by: this.page_by,
            page: this.currentPage
        }))
            .then(function (response) {
            _this.notifications = response.data.notifications.data.map(function (notif) {
                notif.created_at = new Date(notif.created_at).toLocaleString('en-US');
                return notif;
            });
            _this.currentPage = response.data.notifications.current_page;
            _this.page_by = response.data.notifications.per_page;
            _this.totalItems = response.data.notifications.total;
        })
            .catch(function (Error) {
            console.error(Error);
        });
    };
    return EmailsController;
}());
EmailsController.$inject = ["$stateParams", "$http", "APPURL", "student", "$sce"];
exports.default = EmailsController;
